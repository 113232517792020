export const OrderedMedallion: React.FC<{
   color?: string;
   strokeWidth?: string;
}> = ({ color, strokeWidth }: any) => {
   return (
      <svg
         width="18"
         height="18"
         viewBox="0 0 18 18"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <rect width="18" height="18" rx="1.31165" fill="#1B1B48" />
         <path
            d="M3.5 8.54846V3.87572H8.17274V8.54846H3.5ZM4.27879 4.65451V7.76967H7.39395V4.65451H4.27879ZM9.73033 3.87572H14.4031V8.54846H9.73033V3.87572ZM13.6243 7.76967V4.65451H10.5091V7.76967H13.6243ZM3.5 14.7788V10.106H8.17274V14.7788H3.5ZM4.27879 10.8848V14H7.39395V10.8848H4.27879ZM5.05758 5.4333V6.99088H6.61516V5.4333H5.05758ZM12.8455 6.99088V5.4333H11.2879V6.99088H12.8455ZM5.05758 11.6636V13.2212H6.61516V11.6636H5.05758ZM13.6243 10.106V10.8848H12.0667V10.106H9.73033V14.7788H10.5091V12.4424H11.2879V13.2212H14.4031V10.106H13.6243ZM13.6243 14V14.7788H14.4031V14H13.6243ZM12.0667 14V14.7788H12.8455V14H12.0667Z"
            fill="#FCFCFD"
         />
      </svg>
   );
};
