export const FacebookIcon: React.FC<{
   color?: string;
   strokeWidth?: string;
}> = ({ color, strokeWidth }: any) => {
   return (
      <svg
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0001 16.668C13.682 16.668 16.6667 13.6832 16.6667 10.0013C16.6667 6.3194 13.682 3.33464 10.0001 3.33464C6.31818 3.33464 3.33341 6.3194 3.33341 10.0013C3.33341 13.6832 6.31818 16.668 10.0001 16.668ZM10.0001 18.3346C14.6024 18.3346 18.3334 14.6036 18.3334 10.0013C18.3334 5.39893 14.6024 1.66797 10.0001 1.66797C5.39771 1.66797 1.66675 5.39893 1.66675 10.0013C1.66675 14.6036 5.39771 18.3346 10.0001 18.3346Z"
            fill={color ? color : "#777E90"}
         />
         <path
            d="M10.0001 8.33203C10.0001 7.8718 10.3732 7.4987 10.8334 7.4987H11.6667C12.127 7.4987 12.5001 7.12561 12.5001 6.66536C12.5001 6.20513 12.127 5.83203 11.6667 5.83203H10.8334C9.45266 5.83203 8.33341 6.95132 8.33341 8.33203V9.9987H7.50008C7.03985 9.9987 6.66675 10.3718 6.66675 10.832C6.66675 11.2923 7.03984 11.6654 7.50008 11.6654H8.33341V16.6654C8.33341 17.1256 8.7065 17.4987 9.16675 17.4987C9.627 17.4987 10.0001 17.1256 10.0001 16.6654V11.6654H11.6667C12.127 11.6654 12.5001 11.2923 12.5001 10.832C12.5001 10.3718 12.127 9.9987 11.6667 9.9987H10.0001V8.33203Z"
            fill={color ? color : "#777E90"}
         />
      </svg>
   );
};

export const TwitterIcon: React.FC<{
   color?: string;
   strokeWidth?: string;
}> = ({ color, strokeWidth }: any) => {
   return (
      <svg
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.65067 11.6665C1.27257 11.6704 0.382969 13.2403 1.23197 14.4298C2.44782 16.133 4.65261 17.5 8.33346 17.5C14.0377 17.5 18.6165 12.8514 17.9755 7.35925L18.9146 5.48108C19.5659 4.17857 18.4342 2.69685 17.0062 2.98245L15.7655 3.23057C15.4366 3.05702 15.0962 2.92606 14.8053 2.83117C14.2383 2.64627 13.552 2.5 12.9168 2.5C11.7757 2.5 10.7909 2.79276 10.0082 3.37994C9.23446 3.96053 8.79863 4.72354 8.55946 5.42421C8.44921 5.74717 8.37579 6.07112 8.32868 6.38203C7.88596 6.24269 7.43453 6.05522 6.99258 5.82629C5.98992 5.30693 5.17912 4.64852 4.72175 4.07126C3.95439 3.10275 2.32819 3.17456 1.75754 4.43351C0.953258 6.20789 1.17388 8.31122 1.89683 10.0108C2.13915 10.5803 2.45436 11.1434 2.83945 11.6652C2.77352 11.666 2.71046 11.6663 2.65067 11.6665ZM8.33337 15.8333C5.11392 15.8333 3.4492 14.6672 2.58841 13.4614C2.54973 13.4072 2.58873 13.3333 2.65529 13.3332C3.53099 13.3307 5.32849 13.2892 6.51399 12.5954C6.5753 12.5595 6.56181 12.4691 6.49447 12.4465C3.731 11.5173 2.16014 7.58216 3.27546 5.12158C3.30103 5.06517 3.37687 5.05772 3.41534 5.10628C4.6894 6.71432 7.47507 8.28933 9.89871 8.33242C9.95129 8.33333 9.99104 8.28558 9.98304 8.23361C9.88538 7.60044 9.51246 4.16667 12.9167 4.16667C13.7296 4.16667 14.9391 4.56319 15.3846 4.96942C15.4052 4.98819 15.4331 4.99672 15.4605 4.99125L17.333 4.61675C17.401 4.60315 17.4549 4.67371 17.4239 4.73573L16.2625 7.05849C16.2544 7.07462 16.2519 7.09312 16.255 7.11088C17.0684 11.6833 13.3234 15.8333 8.33337 15.8333Z"
            fill={color ? color : "#777E90"}
         />
      </svg>
   );
};

export const InstagramIcon: React.FC<{
   color?: string;
   strokeWidth?: string;
}> = ({ color, strokeWidth }: any) => {
   return (
      <svg
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3334 3.33464H6.66675C4.8258 3.33464 3.33341 4.82702 3.33341 6.66797V13.3346C3.33341 15.1756 4.8258 16.668 6.66675 16.668H13.3334C15.1743 16.668 16.6667 15.1756 16.6667 13.3346V6.66797C16.6667 4.82702 15.1743 3.33464 13.3334 3.33464ZM6.66675 1.66797C3.90532 1.66797 1.66675 3.90654 1.66675 6.66797V13.3346C1.66675 16.0961 3.90532 18.3346 6.66675 18.3346H13.3334C16.0948 18.3346 18.3334 16.0961 18.3334 13.3346V6.66797C18.3334 3.90654 16.0948 1.66797 13.3334 1.66797H6.66675Z"
            fill={color ? color : "#777E90"}
         />
         <path
            d="M14.1666 6.66667C14.6268 6.66667 14.9999 6.29357 14.9999 5.83333C14.9999 5.3731 14.6268 5 14.1666 5C13.7063 5 13.3333 5.3731 13.3333 5.83333C13.3333 6.29357 13.7063 6.66667 14.1666 6.66667Z"
            fill={color ? color : "#777E90"}
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.1666 9.9987C14.1666 12.2999 12.3011 14.1654 9.99992 14.1654C7.69874 14.1654 5.83325 12.2999 5.83325 9.9987C5.83325 7.69751 7.69874 5.83203 9.99992 5.83203C12.3011 5.83203 14.1666 7.69751 14.1666 9.9987ZM12.4999 9.9987C12.4999 11.3794 11.3807 12.4987 9.99992 12.4987C8.61917 12.4987 7.49992 11.3794 7.49992 9.9987C7.49992 8.61795 8.61917 7.4987 9.99992 7.4987C11.3807 7.4987 12.4999 8.61795 12.4999 9.9987Z"
            fill={color ? color : "#777E90"}
         />
      </svg>
   );
};
