import * as Immutable from "seamless-immutable";
import {
   CHANGE_STATUS_SUCCESS,
   GET_ORDERS_SUCCESS,
   GET_SINGLE_ORDER_SUCCESS,
} from "./types";

const initialState = Immutable.from<any>({
   list: [],
   single: null,
});

export default function OrdersReducer(state = initialState, action: any) {
   const { type, payload } = action;
   switch (type) {
      case GET_ORDERS_SUCCESS: {
         return state.setIn(["list"], payload);
      }

      case GET_SINGLE_ORDER_SUCCESS: {
         return state.setIn(["single"], payload);
      }

      case CHANGE_STATUS_SUCCESS: {
         const updatedOrder = {
            ...state.single,
            orderStatus: payload,
         };
         return state.setIn(["single"], { ...updatedOrder });
      }

      default:
         return state;
   }
}
