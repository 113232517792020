const BackgroundImage = ({
   image,
   style,
   onClick,
}: {
   image: string;
   style?: any;
   onClick?: () => void;
}) => {
   return (
      <div
         style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100%",
            borderRadius: "12px",
            paddingBottom: "100%",
            ...style,
         }}
         onClick={onClick}
      ></div>
   );
};

export default BackgroundImage;
