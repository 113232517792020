import { useCallback, useEffect, useRef, useState } from "react";

export const useSpaceNavigationGestures = (
   componentRef: React.RefObject<HTMLDivElement>,
   settings?: { minZoom?: number; maxZoom?: number },
) => {
   const initialX = window.document.body.clientWidth / 2;
   const initialY = window.screen.availHeight / 2;

   const componentHolderRef = useRef<HTMLDivElement>(null);
   // const componentRef = useRef<HTMLDivElement>(null);

   const [style, setStyle] = useState<React.CSSProperties>({
      transform: `translate(${initialX}px, ${initialY}px) rotate(0deg) scale(1)`,
   });

   const [isMoving, setIsMoving] = useState<boolean>(false);
   const [initialPositionX, setInitialPositionX] = useState<number>(initialX);
   const [initialPositionY, setInitialPositionY] = useState<number>(initialY);

   const [gestureStartScale, setGestureStartScale] = useState<number>(0);

   const [positionX, setPositionX] = useState<number>(initialX);
   const [positionY, setPositionY] = useState<number>(initialY);
   const [scale, setScale] = useState<number>(1);

   const [startX, setStartX] = useState<number>(0);
   const [startY, setStartY] = useState<number>(0);

   const [startPosition, setStartPosition] = useState<{ x: number; y: number }>({
      x: 0,
      y: 0,
   });
   const [graphPosition, setGraphPosition] = useState<{ x: number; y: number }>({
      x: 0,
      y: 0,
   });

   const render = useCallback(
      (positionX: number, positionY: number, scale: number, isMoving: boolean) => {
         setStyle({
            transform: `translate3D(${positionX}px, ${positionY}px, 0px) scale(${scale})`,
            userSelect: isMoving ? "none" : undefined,
            cursor: isMoving ? "grabbing" : "default",
         });
      },
      [],
   );

   const onMove = useCallback(
      (position: { x: number; y: number }) => {
         if (!isMoving) return;

         const thisPositionX = graphPosition.x + (position.x - startPosition.x);
         const thisPositionY = graphPosition.y + (position.y - startPosition.y);

         setPositionX(thisPositionX);
         setPositionY(thisPositionY);

         render(thisPositionX, thisPositionY, scale, true);
      },
      [isMoving, graphPosition, startPosition, render, scale],
   );
   const onStart = useCallback(
      (position: { x: number; y: number }) => {
         setIsMoving(true);
         onMove(position);

         setStartPosition({
            x: position.x,
            y: position.y,
         });
      },
      [onMove],
   );

   useEffect(() => {
      const onWheel = (event: WheelEvent) => {
         event.preventDefault();

         if (event.ctrlKey) {
            setScale((oldValue) =>
               Math.max(
                  Math.min(oldValue - event.deltaY * 0.01, settings?.maxZoom ?? 100),
                  settings?.minZoom ?? 0.01,
               ),
            );
         } else {
            setPositionX((oldValue) => oldValue - event.deltaX * 2);
            setPositionY((oldValue) => oldValue - event.deltaY * 2);

            setGraphPosition({
               x: positionX,
               y: positionY,
            });
         }

         render(positionX, positionY, scale, isMoving);
      };

      const onGestureStart = (event: any) => {
         event.preventDefault();

         setStartX(event.pageX - positionX);
         setStartY(event.pageY - positionY);
         setStartPosition({
            x: event.pageX,
            y: event.pageY,
         });
         setGestureStartScale(scale);
      };
      const onGestureChange = (event: any) => {
         event.preventDefault();

         setScale(
            Math.max(
               Math.min(gestureStartScale * event.scale, settings?.maxZoom ?? 100),
               settings?.minZoom ?? 0.01,
            ),
         );

         setPositionX(event.pageX - startX);
         setPositionY(event.pageY - startY);
         setGraphPosition({
            x: event.pageX - startX,
            y: event.pageY - startY,
         });

         render(positionX, positionY, scale, isMoving);
      };
      const onGestureEnd = (event: any) => {
         event.preventDefault();
      };

      componentHolderRef.current?.addEventListener("wheel", onWheel, {
         passive: false,
      });
      componentHolderRef.current?.addEventListener("gesturestart", onGestureStart);
      componentHolderRef.current?.addEventListener("gesturechange", onGestureChange);
      componentHolderRef.current?.addEventListener("gestureend", onGestureEnd);

      return () => {
         componentHolderRef.current?.removeEventListener("wheel", onWheel);
         componentHolderRef.current?.removeEventListener("gesturestart", onGestureStart);
         componentHolderRef.current?.removeEventListener(
            "gesturechange",
            onGestureChange,
         );
         componentHolderRef.current?.removeEventListener("gestureend", onGestureEnd);
      };
   }, [positionX, positionY, scale, isMoving]);

   useEffect(() => {
      if (!componentHolderRef.current || !componentRef.current) return;

      setGraphPosition({
         x:
            (componentHolderRef.current.clientWidth - componentRef.current.clientWidth) /
            2,
         y:
            (componentHolderRef.current.clientHeight -
               componentRef.current.clientHeight) /
            2,
      });
   }, []);

   return {
      style,
      componentHolderRef,
      componentRef,
      scale,
      reset: () => {
         setGestureStartScale(0);
         setPositionX(initialPositionX);
         setPositionY(initialPositionY);
         setScale(1);
         setStartX(0);
         setStartY(0);

         render(initialPositionX, initialPositionY, 1, false);
      },
      setScale: (value: number) => {
         setScale(value);
         render(positionX, positionY, value, isMoving);
      },
      setPosition: (x: number, y: number) => {
         setPositionX(x);
         setPositionY(y);

         setInitialPositionX(x);
         setInitialPositionY(y);

         setGraphPosition({
            x,
            y,
         });

         render(x, y, scale, isMoving);
      },
      onMouseDown: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
         onStart({
            x: event.clientX,
            y: event.clientY,
         });
      },
      onTouchStart: (event: React.TouchEvent<HTMLDivElement>) => {
         onStart({
            x: event.touches[0].clientX,
            y: event.touches[0].clientY,
         });
      },
      onMouseMove: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
         onMove({
            x: event.clientX,
            y: event.clientY,
         });
      },
      onTouchMove: (event: React.TouchEvent<HTMLDivElement>) => {
         onMove({
            x: event.touches[0].clientX,
            y: event.touches[0].clientY,
         });
      },
      onMouseUp: () => {
         setIsMoving(false);
         setGraphPosition({
            x: positionX,
            y: positionY,
         });
         render(positionX, positionY, scale, false);
      },
      onWheel: (event: React.WheelEvent<HTMLDivElement>) => {
         setScale((oldValue) =>
            Math.min(
               Math.max(
                  oldValue - 0.01 * event.deltaY * -1,
                  Math.max(settings?.minZoom ?? 0.01, 0),
               ),
               settings?.maxZoom ?? 100,
            ),
         );
         render(positionX, positionY, scale, isMoving);
      },
   };
};
