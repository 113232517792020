import * as Immutable from "seamless-immutable";
import {
   DELETE_PRODUCT_SUCCESS,
   GET_PRODUCTS,
   GET_SINGLE_PRODUCT_SUCCESS,
   GET_SINGLE_QR_SUCCESS,
   SET_PRODUCT_AVAILABILITY_SUCCESS,
} from "./types";

const initialState = Immutable.from<any>({
   list: undefined,
   single: null,
});

export default function ProductsReducer(state = initialState, action: any) {
   const { type, payload } = action;
   switch (type) {
      case GET_PRODUCTS: {
         return state.setIn(["list"], payload);
      }

      case DELETE_PRODUCT_SUCCESS: {
         const { list } = state;

         const updatedList = list.filter((p: any) => p.id !== payload);
         return state.setIn(["list"], updatedList);
      }

      case GET_SINGLE_PRODUCT_SUCCESS: {
         return state.setIn(["single"], payload);
      }

      case GET_SINGLE_QR_SUCCESS: {
         const updatedQR =
            payload &&
            payload.replace(
               "<svg",
               '<svg viewBox="0 0 1000 1000" preserveAspectRatio="xMidYMid meet" style="width: 100%; height: auto;"',
            );
         return state.setIn(["single"], { ...state.single, qr: updatedQR });
      }

      case SET_PRODUCT_AVAILABILITY_SUCCESS: {
         const { list } = state;

         const updatedList = list.map((item: any) => {
            if (item.id === payload.id) {
               return {
                  ...item,
                  isActive: payload.status,
               };
            }
            return item;
         });
         return state.setIn(["list"], updatedList);
      }
      default:
         return state;
   }
}
