import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router";
import { Box, Divider, Grid } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import loginFlowImage from "../../assets/login-flow-image.jpg";
import NovaButton from "../../components/shared/NovaButton";
import NovaTitle from "../../components/shared/NovaTitle";
import NovaLink from "../../components/shared/NovaLink";
import LogoDark from "../../components/shared/icons/logo-dark";
import NovaTextInput from "../../components/shared/NovaTextInput";
import NovaPasswordInput from "../../components/shared/NovaPasswordInput";
import { useAppDispatch, useLanguage } from "../../store/hooks";
import { userLogin } from "./async-actions";
import TranslatedText from "../../components/translated-text";
import ForgotPasswordModal from "./forgot-password";

const Login = () => {
   useLanguage();
   const { t } = useTranslation();
   const userFromStore: any = localStorage.getItem("user");
   const user: any = JSON.parse(userFromStore);
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const [formFields, setFormFields] = useState<{
      email: string;
      password: string;
   }>({
      email: "",
      password: "",
   });

   const onFieldChange = (event: any, field: string) => {
      setFormFields({ ...formFields, [field]: event.target.value });
   };

   const handleLogin = async () => {
      dispatch(userLogin(formFields.email, formFields.password, navigate));
   };

   useEffect(() => {
      const keyDownHandler = (event: any) => {
         if (event.key === "Enter") {
            event.preventDefault();
            handleLogin();
         }
      };

      document.addEventListener("keydown", keyDownHandler);

      return () => {
         document.removeEventListener("keydown", keyDownHandler);
      };
   }, [formFields.email, formFields.password]);

   const [openForgotPasswordModal, setOpenCreateModal] = useState(false);
   const handleOpenForgotPasswordModalModal = () => {
      setOpenCreateModal(true);
   };

   const handleCloseForgotPasswordModalModal = () => {
      setOpenCreateModal(false);
   };

   if (user) {
      return <Navigate to="/" replace />;
   }

   return (
      <Box
         style={{
            height: "100%",
         }}
      >
         <Box
            sx={{
               width: "100%",
               height: "100%",
               display: "flex",
               flexDirection: "row",
            }}
         >
            <Box
               sx={{
                  width: "100%",
                  background: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
               }}
            >
               <div
                  style={{
                     maxWidth: "400px",
                     width: "100%",
                     paddingInline: "20px",
                  }}
               >
                  <LogoDark />

                  <NovaTitle
                     variant="h2"
                     style={{
                        marginTop: "24px",
                        marginBottom: 0,
                        maxWidth: "304px",
                     }}
                  >
                     <TranslatedText text="auth-flow.login.title" />
                  </NovaTitle>

                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        marginTop: "12px",
                        marginBottom: 0,
                     }}
                  >
                     <TranslatedText text="auth-flow.login.do-not-have-an-account" />
                     &nbsp;
                     <NovaLink
                        to="../sign-up"
                        style={{
                           color: "#CAA45D",
                        }}
                     >
                        <TranslatedText text="buttons.sign-up-here" />
                     </NovaLink>
                  </NovaText>

                  <Box sx={{ padding: "10px" }}>
                     <Box>
                        <NovaTextInput
                           value={formFields.email}
                           label={t("auth-flow.login.email")}
                           placeholder={t("auth-flow.login.email-placeholder")}
                           onChange={(event) => onFieldChange(event, "email")}
                        />
                     </Box>
                     <Box
                        sx={{
                           marginTop: "16px",
                        }}
                     >
                        <NovaPasswordInput
                           label={t("auth-flow.login.password")}
                           placeholder={t("auth-flow.login.password-placeholder")}
                           value={formFields.password}
                           onChange={(event) => onFieldChange(event, "password")}
                        />
                     </Box>
                  </Box>

                  <Box
                     style={{
                        color: "#CAA45D",
                        paddingRight: "10px",
                        textDecoration: "none",
                        cursor: "pointer",
                     }}
                     onClick={handleOpenForgotPasswordModalModal}
                  >
                     <NovaText
                        type={NovaTextTypes.BaseNormal}
                        style={{
                           textAlign: "right",
                           marginTop: "0",
                        }}
                     >
                        <TranslatedText text="buttons.forgot-password" />
                     </NovaText>
                  </Box>

                  <div
                     style={{
                        display: "flex",
                        width: "100%",
                     }}
                  >
                     <NovaButton
                        style={{
                           width: "100%",
                           borderRadius: "32px",
                           textTransform: "none",
                           ":hover": {
                              border: "none",
                           },
                        }}
                        onClick={handleLogin}
                     >
                        <TranslatedText text="buttons.log-in" />
                     </NovaButton>
                  </div>

                  {/* <Divider
                        sx={{
                           margin: "16px 0",
                        }}
                     >
                        Or
                     </Divider>
                     <div
                        style={{
                           display: "flex",
                           width: "100%",
                        }}
                     >
                        <NovaButton
                           background="#fff"
                           border="1px solid #ccc"
                           style={{
                              width: "100%",
                              borderRadius: "32px",
                              textTransform: "none",
                           }}
                           startIcon={<GoogleIcon />}
                        >
                           Google
                        </NovaButton>
                     </div>
                     <div
                        style={{
                           display: "flex",
                           width: "100%",
                           marginTop: "16px",
                        }}
                     >
                        <NovaButton
                           background="#fff"
                           border="1px solid #ccc"
                           style={{
                              width: "100%",
                              borderRadius: "32px",
                              textTransform: "none",
                           }}
                           startIcon={<FacebookIcon />}
                        >
                           Facebook
                        </NovaButton>
                     </div> */}
               </div>
            </Box>

            <Box
               sx={{
                  display: {
                     width: "100%",
                     xs: "none",
                     md: "flex",
                  },
               }}
            >
               <div
                  style={{
                     width: "100%",
                     height: "100%",
                     background: `url(${loginFlowImage})`,
                     backgroundRepeat: "no-repeat",
                     backgroundSize: "cover",
                     backgroundPosition: "center",
                     display: "flex",
                     alignItems: "end",
                     justifyContent: "end",
                     paddingRight: "20px",
                     paddingBottom: "23px",
                  }}
               >
                  <LogoDark />
               </div>
            </Box>
            {openForgotPasswordModal && (
               <ForgotPasswordModal handleClose={handleCloseForgotPasswordModalModal} />
            )}
         </Box>
      </Box>
   );
};

export default Login;
