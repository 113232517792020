import { useNavigate } from "react-router-dom";
import { Grid, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Close } from "@mui/icons-material";
import { DetailsModalFlower } from "../shared/icons/details-modal-flower";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import { useAppDispatch } from "../../store/hooks";
import { MemorialsActions } from "../../pages/home/actions";

import onlineMemorial from "../../assets/online-memorial.jpeg";
import lifeStory from "../../assets/life-story.jpeg";
import TranslatedText from "../translated-text";

const CreateMPModal = ({ handleClose }: { handleClose?: () => void }) => {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();

   const onSelectMemorialPageType = (type: string) => {
      dispatch(MemorialsActions.setMemorialPageType(type));
      navigate("../memorial-page/create");
   };

   return (
      <Modal open={true} onClose={handleClose}>
         <Box
            sx={{
               position: "absolute",
               top: "50%",
               left: "50%",
               width: {
                  xs: "calc(100% - 20px)",
                  sm: "unset",
               },
               transform: "translate(-50%, -50%)",
               bgcolor: "background.paper",
               boxShadow: 24,
               borderRadius: "24px",
               display: "flex",
               alignItems: "baseline",
               border: "1px solid #E9DDC8",
               outline: "none",
               overflow: "hidden",
            }}
         >
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  width: "100%",
                  position: "relative",
                  padding: "24px",
               }}
            >
               <Box
                  sx={{
                     width: "100%",
                     display: "flex",
                     flexDirection: "column",
                     zIndex: 10,
                  }}
               >
                  {handleClose && (
                     <Box
                        sx={{
                           width: "100%",
                           display: "flex",
                           justifyContent: "flex-end",
                           zIndex: 10,
                        }}
                     >
                        <IconButton onClick={handleClose}>
                           <Close />
                        </IconButton>
                     </Box>
                  )}

                  <Box>
                     <NovaText
                        type={NovaTextTypes.XLNormal}
                        style={{ margin: "0 0 8px 0" }}
                     >
                        <TranslatedText text="memorial-page-flow.modal.title" />
                     </NovaText>
                  </Box>

                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: {
                           xs: "column",
                           sm: "row",
                        },
                        gap: "16px",
                     }}
                  >
                     <Box
                        sx={{
                           width: {
                              xs: "100%",
                              sm: "232px",
                           },
                           borderRadius: "32px",
                           padding: "16px",
                           cursor: "pointer",
                           ":hover": {
                              background: "#CAD1E2",
                           },
                        }}
                        onClick={() => onSelectMemorialPageType("online-memorial")}
                     >
                        <img
                           src={onlineMemorial}
                           alt="Online Memorial"
                           width={200}
                           height={200}
                           style={{
                              objectFit: "cover",
                              borderRadius: "32px",
                           }}
                        />

                        <NovaText
                           type={NovaTextTypes.MediumNormal}
                           style={{
                              fontWeight: "600",
                           }}
                        >
                           <TranslatedText text="memorial-page-flow.modal.online-memorial.title" />
                        </NovaText>
                        <NovaText type={NovaTextTypes.MediumNormal} style={{ margin: 0 }}>
                           <TranslatedText text="memorial-page-flow.modal.online-memorial.description" />
                        </NovaText>
                     </Box>

                     <Box
                        sx={{
                           width: {
                              xs: "100%",
                              sm: "232px",
                           },
                           borderRadius: "32px",
                           padding: "16px",
                           cursor: "pointer",
                           ":hover": {
                              background: "#CAD1E2",
                           },
                        }}
                        onClick={() => onSelectMemorialPageType("life-story")}
                     >
                        <img
                           src={lifeStory}
                           alt="Life Story"
                           width={200}
                           height={200}
                           style={{
                              objectFit: "cover",
                              borderRadius: "32px",
                           }}
                        />

                        <NovaText
                           type={NovaTextTypes.MediumNormal}
                           style={{
                              fontWeight: "600",
                           }}
                        >
                           <TranslatedText text="memorial-page-flow.modal.life-story.title" />
                        </NovaText>
                        <NovaText type={NovaTextTypes.MediumNormal} style={{ margin: 0 }}>
                           <TranslatedText text="memorial-page-flow.modal.life-story.description" />
                        </NovaText>
                     </Box>
                  </Box>
               </Box>

               <Box
                  sx={{
                     position: "absolute",
                     top: "0",
                     right: "0",
                     zIndex: 1,
                  }}
               >
                  <DetailsModalFlower />
               </Box>
            </Box>
         </Box>
      </Modal>
   );
};

export default CreateMPModal;
