import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PersonBox from "./person-box";
import ReactFamilyTree from "./ref-function";
import { ReactFamilyTreeClickPlusData, ReactFamilyTreeRef } from "./types";
import { PersonData } from "./types";

import AddModal from "./add-modal";
import EditModal from "./edit-modal";

import "./styles.css";
import { Box } from "@mui/material";
import NovaButton from "../shared/NovaButton";
import TranslatedText from "../translated-text";

const Tree = ({
   data,
   isEditable,
   canAddMore = true,
   searchTerm,
   onUpdate,
}: {
   data: any[];
   isEditable?: boolean;
   /** @default true */
   canAddMore?: boolean;
   searchTerm?: string;
   onUpdate?: any;
}) => {
   const myFamilyTreeHolderRef = useRef<HTMLDivElement>(null);
   const reactFamilyTreeRef = useRef<ReactFamilyTreeRef>(null);
   //* Tools
   const [withYears, setWithYears] = useState<boolean>(true);
   const [plusData, setPlusData] = useState<ReactFamilyTreeClickPlusData>();
   const [selectedPersonId, setSelectedPersonId] = useState<number>();

   //* Callbacks
   const onClickPersonBox = useCallback(
      (person: PersonData & { dataId: number }) => setSelectedPersonId(person.dataId),
      [],
   );
   const onClickPlus = useCallback((plusData: ReactFamilyTreeClickPlusData) => {
      setPlusData(plusData);
   }, []);

   const selectedPersonData = useMemo(
      () => reactFamilyTreeRef.current?.data.find((item) => item.id === selectedPersonId),
      [selectedPersonId],
   );

   const handleAddSave = (form: any) => {
      if (!plusData) return;

      reactFamilyTreeRef.current?.addDataItem(
         [
            {
               name: form[0].name,
               Base64: form[0].Base64,
               gender: form[0].gender,
               bornYear: form[0].bornYear,
               deathYear: form[0].deathYear,
               unknown: form[0].unknown,
            },
            ...(plusData.type === "parents"
               ? [
                    {
                       name: form[1].name,
                       Base64: form[1].Base64,
                       gender: form[1].gender,
                       bornYear: form[1].bornYear,
                       deathYear: form[1].deathYear,
                       unknown: form[1].unknown,
                    },
                 ]
               : []),
         ],
         plusData,
         {
            icon: form[0].divorced ? "heartBroken" : "heart",
            iconColor: form[0].divorced ? "purple" : "red",
         },
      );

      setPlusData(undefined);
   };
   const handleEditSave = (form: any) => {
      reactFamilyTreeRef.current?.setData(
         data.map((item) =>
            item.id === selectedPersonId
               ? {
                    ...item,
                    ...form,
                 }
               : item,
         ),
      );

      setSelectedPersonId(undefined);
   };
   const resetTree = useCallback(() => {
      if (!myFamilyTreeHolderRef.current) return;

      reactFamilyTreeRef.current?.setPosition(
         (myFamilyTreeHolderRef.current.clientWidth -
            (reactFamilyTreeRef.current?.treeComponent?.clientWidth || 0)) /
            2,
         (myFamilyTreeHolderRef.current.clientHeight -
            (reactFamilyTreeRef.current?.treeComponent?.clientHeight || 0)) /
            2,
      );

      reactFamilyTreeRef.current?.setScale(() => 1);
   }, []);

   useEffect(() => {
      if (!myFamilyTreeHolderRef.current) return;

      reactFamilyTreeRef.current?.setPosition(
         (myFamilyTreeHolderRef.current.clientWidth -
            (reactFamilyTreeRef.current.treeComponent?.clientWidth || 0)) /
            2,
         (myFamilyTreeHolderRef.current.clientHeight -
            (reactFamilyTreeRef.current.treeComponent?.clientHeight || 0)) /
            2,
      );
   }, []);

   useEffect(() => {
      reactFamilyTreeRef.current?.setData(data);
   }, [data]);

   return (
      <>
         <div className="myFamilyTreeHolder" ref={myFamilyTreeHolderRef}>
            <ReactFamilyTree
               data={data}
               renderItem={(person, dataId) => {
                  return (
                     <PersonBox
                        {...person}
                        dataId={dataId}
                        withYears={withYears}
                        onClick={onClickPersonBox}
                        inEditMode={isEditable}
                     />
                  );
               }}
               onDataUpdate={onUpdate}
               onClickPlus={onClickPlus}
               searchKeyword={searchTerm}
               inEditMode={isEditable}
               canAddMore={canAddMore}
               // haveCollapseExpandButton
               ref={reactFamilyTreeRef}
            />
         </div>

         <Box
            sx={{
               display: "flex",
               flexDirection: "row",
               gap: "20px",
               marginTop: "20px",
            }}
         >
            <NovaButton
               style={{
                  borderRadius: "999px",
                  padding: "6px 14px",
               }}
               onClick={resetTree}
            >
               <TranslatedText text="buttons.reset" />
            </NovaButton>

            <Box
               sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                  marginLeft: "auto",
               }}
            >
               <NovaButton
                  onClick={() =>
                     reactFamilyTreeRef.current?.setScale((scale) => scale + 0.1)
                  }
                  style={{
                     fontSize: "20px",
                     borderRadius: "999px",
                     padding: "5px 0px",
                  }}
               >
                  +
               </NovaButton>
               <NovaButton
                  onClick={() =>
                     reactFamilyTreeRef.current?.setScale((scale) => scale - 0.1)
                  }
                  style={{
                     fontSize: "20px",
                     borderRadius: "999px",
                     padding: "5px 0px",
                  }}
               >
                  -
               </NovaButton>
            </Box>
         </Box>

         {isEditable && !!plusData && (
            <AddModal
               data={plusData}
               handleSave={handleAddSave}
               handleClose={() => setPlusData(undefined)}
            />
         )}
         {isEditable && !!selectedPersonId && (
            <EditModal
               data={{
                  ...selectedPersonData,
                  isAlive: (selectedPersonData as any)?.deathYear === null,
               }}
               handleSave={handleEditSave}
               handleClose={() => setSelectedPersonId(undefined)}
            />
         )}
      </>
   );
};

export default Tree;
