import { memorialAPI } from "./memorial";
import { productsAPI } from "./products";
import { usersAPI } from "./users";
import { ordersAPI } from "./orders";
import { ekontAPI } from "./ekont";
import { paymentsAPI } from "./payments";
import { subscriptionsAPI } from "./subscriptions";
import { affiliatesAPI } from "./affiliates";

export interface API {
   usersAPI: {
      userLogin: (email: string, password: string) => Promise<any>;
   };
   memorialAPI: {
      createMemorialPage: (memorial: any) => Promise<any>;
   };
   productsAPI: {
      getProducts: () => Promise<any>;
   };
}
const api: any = {
   usersAPI,
   memorialAPI,
   productsAPI,
   ordersAPI,
   ekontAPI,
   paymentsAPI,
   subscriptionsAPI,
   affiliatesAPI,
};

export default api;
