import { AlertsActions } from "../../components/alerts/actions";
import { SingleMemorialPageActions } from "./actions";

export const getSinglePage =
   (pageId: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.memorialAPI
         .getPage(pageId)
         .catch((error: any) => {
            const { response } = error;
            // dispatch(LoginActions.loginFail());
            dispatch(SingleMemorialPageActions.getMemorialPageFailed());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(SingleMemorialPageActions.getMemorialPage(res.data));
               dispatch(SingleMemorialPageActions.getMemorialPageSuccess());
            }
         });
   };

export const requestAccess =
   (pageId: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.memorialAPI
         .requestPageAccess(pageId)
         .catch((error: any) => {
            const { response } = error;
            // dispatch(LoginActions.loginFail());
            // dispatch(SingleMemorialPageActions.getMemorialPageFailed());
            dispatch(
               AlertsActions.createAlert({
                  type: "error",
                  message: response?.data?.title ?? "Something went wrong",
               }),
            );
         })
         .then((res: any) => {
            if (res && res.data) {
               //   dispatch(SingleMemorialPageActions.getMemorialPage(res.data));
               //   dispatch(SingleMemorialPageActions.getMemorialPageSuccess());
               dispatch(
                  AlertsActions.createAlert({
                     type: "info",
                     message: "Request sent successfully",
                  }),
               );
            }
         });
   };
