import { createSelector } from "@reduxjs/toolkit";

const getAffiliates = (state: any) => state.affiliates;

const getVouchers = createSelector(
   getAffiliates,
   (affiliate) => affiliate && affiliate.vouchers,
);
const getAffiliateUsers = createSelector(
   getAffiliates,
   (affiliate) => affiliate && affiliate.users,
);

const getAffiliateQR = createSelector(
   getAffiliates,
   (affiliate) => affiliate && affiliate.qr,
);

const getSingleVoucher = createSelector(
   [getVouchers, (_: any, voucherId: any) => voucherId],
   (vouchers, voucherId) => {
      if (voucherId && vouchers && vouchers.length > 0) {
         return vouchers.find((voucher: any) => voucher.id === Number(voucherId));
      }
      return null;
   },
);

const getUnusedVouchers = createSelector(
   getVouchers,
   (vouchers) => vouchers && vouchers.filter((voucher: any) => !voucher.sold),
);

const getObligations = createSelector(getVouchers, (vouchers) => {
   const filteredVouchers = vouchers && vouchers.filter((voucher: any) => voucher.sold);
   if (filteredVouchers && filteredVouchers.length > 0) {
      return filteredVouchers.reduce(
         (accumulator: any, current: any) =>
            accumulator + current?.subscriptionPlan?.priceForAffiliate,
         0,
      );
   }
   return 0;
});

export const AffiliatesSelectors = {
   getVouchers,
   getAffiliateUsers,
   getAffiliateQR,
   getSingleVoucher,
   getUnusedVouchers,
   getObligations,
};
