import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
   Avatar,
   Box,
   Chip,
   Container,
   Grid,
   IconButton,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import NovaTitle from "../../components/shared/NovaTitle";
import NovaPasswordInput from "../../components/shared/NovaPasswordInput";
import NovaButton from "../../components/shared/NovaButton";
import { useAppDispatch } from "../../store/hooks";
import {
   changePassword,
   deleteAccount,
   userEdit,
   validateVoucher,
} from "./async-actions";
import { getMemorialPages, saveProfilePhoto } from "../home/async-actions";
import { ProfileImageChange } from "../../components/shared/icons/profile-image-change";
import { LoginSelectors } from "../Login/selectors";
import NovaTextInput from "../../components/shared/NovaTextInput";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import { SubscriptionsSelectors } from "../subscriptions/selectors";
import { deleteCard, getCards, getSubscribers } from "../subscriptions/async-actions";
import { HomeSelectors } from "../home/selectors";
import { MasterCardLogo, VisaLogo } from "../../components/shared/icons/payment-methods";
import AddPaymentMethod from "../../components/subscriptions/add-payment-method";
import ConfirmationModal from "../../components/ConfirmationModal";
import { Close } from "@mui/icons-material";
import TranslatedText from "../../components/translated-text";

const Profile = () => {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const user = useSelector(LoginSelectors.getUser);
   const subscribers = useSelector(SubscriptionsSelectors.getSubscribers);
   const pages = useSelector(HomeSelectors.getMemorialPages);
   const cards = useSelector(SubscriptionsSelectors.getCards);

   const [form, setForm] = useState({
      current_password: "",
      password: "",
      confirm_password: "",
      fullName: user.fullName || "",
   });

   const [deleteModalOpened, setDeleteModalOpened] = useState(false);
   const [voucher, setVoucher] = useState("");
   const [voucherRegisterMessage, setVoucherRegisterMessage] = useState<any>(null);
   const [isDeletingCard, setIsDeletingCard] = useState<string | undefined>();

   const onAccountChange = ({ field, value }: any) => {
      setForm({
         ...form,
         [field]: value,
      });
   };

   const onFieldChange = (event: any, field: string) => {
      onAccountChange({ field, value: event.target.value });
   };

   const onPasswordChange = () => {
      dispatch(changePassword(form.current_password, form.confirm_password));
      setForm({
         ...form,
         current_password: "",
         password: "",
         confirm_password: "",
      });
   };

   const handleProfileImageSave = (profilePhotoBase64: string | ArrayBuffer | null) => {
      dispatch(saveProfilePhoto(profilePhotoBase64));
   };

   const onFullnameChange = () => {
      dispatch(userEdit(form.fullName));
   };

   const handleCardDelete = (paymentMethodId: string) => {
      dispatch(deleteCard(paymentMethodId));
   };

   const handleDeleteButtonClick = () => {
      setDeleteModalOpened(true);
   };

   const handleDelete = () => {
      dispatch(deleteAccount(navigate));
   };

   const onVoucherValidate = () => {
      setVoucher("");
      dispatch(validateVoucher(voucher, setVoucherRegisterMessage));
   };

   useEffect(() => {
      if (user) {
         setForm({
            ...form,
            fullName: user.fullName,
         });
      }

      if (user && user?.role === 1) {
         dispatch(getSubscribers());
         dispatch(getMemorialPages());
         dispatch(getCards());
      }
   }, [user]);

   return (
      <>
         <Container
            sx={{
               padding: "80px 10px",
               display: "flex",
               flexDirection: "column",
               gap: "32px",
            }}
         >
            <Box
               sx={{
                  background: "#fff",
                  borderRadius: "16px",
                  padding: "40px 32px",
                  position: "relative",
               }}
            >
               <NovaTitle
                  variant="h3"
                  style={{
                     margin: 0,
                     color: "#1B1B48",
                  }}
               >
                  <TranslatedText text="footer.profile-settings" />
               </NovaTitle>

               <Box
                  sx={{
                     display: "flex",
                     flexDirection: {
                        xs: "column",
                        md: "row",
                     },
                     gap: "32px",
                     marginTop: "32px",
                  }}
               >
                  <Dropzone
                     accept={{
                        "image/png": [".png"],
                        "image/jpeg": [".jpg"],
                     }}
                     onDrop={(acceptedFiles) => {
                        if (acceptedFiles.length > 0) {
                           const reader = new FileReader();
                           reader.onload = (e) => {
                              const imageDataUrl = reader.result;
                              handleProfileImageSave(imageDataUrl);
                           };
                           reader.readAsDataURL(acceptedFiles[0]);
                        }
                     }}
                  >
                     {({ getRootProps, getInputProps }) => (
                        <Box
                           sx={{
                              width: "153px",
                              height: "153px",
                              borderRadius: "100%",
                              border: "3px solid #fff",
                              position: "relative",
                           }}
                        >
                           <Avatar
                              src={user?.photoUrl}
                              sx={{ width: "100%", height: "100%" }}
                           />
                           <div
                              {...getRootProps()}
                              style={{
                                 height: "100%",
                              }}
                           >
                              <input {...getInputProps()} />

                              <IconButton
                                 sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    right: 0,
                                    width: "32px",
                                    height: "32px",
                                    border: "3px solid #fff",
                                    background: "#1B1B48",
                                    ":hover": {
                                       backgroundColor: "#CAA45D",
                                    },
                                    ":hover svg path": {
                                       fill: "#1B1B48",
                                    },
                                 }}
                              >
                                 <ProfileImageChange />
                              </IconButton>
                           </div>
                        </Box>
                     )}
                  </Dropzone>

                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                        gap: "16px",
                        flex: 1,
                     }}
                  >
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: {
                              xs: "column",
                              md: "row",
                           },
                           alignItems: "center",
                           gap: "16px",
                           width: "100%",
                        }}
                     >
                        <Box
                           sx={{
                              maxWidth: {
                                 xs: "none",
                                 md: "300px",
                              },
                              width: "100%",
                           }}
                        >
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ margin: 0 }}
                           >
                              {user?.role === 1 ? (
                                 <TranslatedText text="profile.your-name" />
                              ) : (
                                 <TranslatedText text="profile.agency-name" />
                              )}
                           </NovaText>
                           <NovaTextInput
                              value={form?.fullName || ""}
                              placeholder="Your name"
                              onChange={(event) => onFieldChange(event, "fullName")}
                           />
                        </Box>

                        <Box
                           sx={{
                              maxWidth: {
                                 xs: "none",
                                 md: "300px",
                              },
                              width: "100%",
                           }}
                        >
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ margin: 0 }}
                           >
                              <TranslatedText text="profile.your-email" />
                           </NovaText>
                           <NovaTextInput
                              value={user?.email || ""}
                              disabled
                              placeholder="User email"
                           />
                        </Box>
                     </Box>

                     <NovaButton
                        disabled={
                           user?.fullName === form.fullName || form.fullName.length < 3
                        }
                        style={{
                           marginTop: "24px",
                           borderRadius: "32px",
                        }}
                        onClick={onFullnameChange}
                     >
                        <TranslatedText text="buttons.save" />
                     </NovaButton>
                  </Box>
               </Box>
            </Box>

            {user?.role === 1 && (
               <Box
                  sx={{
                     background: "#fff",
                     borderRadius: "16px",
                     padding: "40px 32px",
                     position: "relative",
                  }}
               >
                  <NovaTitle
                     variant="h3"
                     style={{
                        margin: 0,
                        color: "#1B1B48",
                     }}
                  >
                     <TranslatedText text="profile.register-code.title" />
                  </NovaTitle>

                  <Box
                     sx={{
                        marginTop: "32px",
                     }}
                  >
                     <Box
                        sx={{
                           maxWidth: "300px",
                        }}
                     >
                        <NovaTextInput
                           label={
                              <TranslatedText text="profile.register-code.enter-code" />
                           }
                           value={voucher || ""}
                           style={{
                              width: "100%",
                              maxWidth: "300px",
                              marginBottom: "16px",
                           }}
                           onChange={(event) => setVoucher(event.target.value)}
                        />
                     </Box>
                     {voucherRegisterMessage && (
                        <Box
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "8px 16px",
                              borderRadius: "12px",
                              background:
                                 voucherRegisterMessage.status === "success"
                                    ? "#1B1B48"
                                    : "#e22739",
                              color: "#fff",
                           }}
                        >
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ margin: 0 }}
                           >
                              {voucherRegisterMessage.message}
                           </NovaText>
                           <IconButton onClick={() => setVoucherRegisterMessage(null)}>
                              <Close sx={{ color: "#fff" }} />
                           </IconButton>
                        </Box>
                     )}
                     <NovaButton
                        disabled={!voucher}
                        style={{
                           marginTop: "24px",
                           borderRadius: "32px",
                        }}
                        onClick={onVoucherValidate}
                     >
                        <TranslatedText text="buttons.register" />
                     </NovaButton>
                  </Box>
               </Box>
            )}

            <Box
               sx={{
                  background: "#fff",
                  borderRadius: "16px",
                  padding: "40px 32px",
                  position: "relative",
               }}
            >
               <NovaTitle
                  variant="h3"
                  style={{
                     margin: 0,
                     color: "#1B1B48",
                  }}
               >
                  <TranslatedText text="profile.change-password.title" />
               </NovaTitle>

               <Box
                  sx={{
                     marginTop: "32px",
                  }}
               >
                  <Box
                     sx={{
                        maxWidth: "300px",
                     }}
                  >
                     <NovaPasswordInput
                        label={
                           <TranslatedText text="profile.change-password.current-password" />
                        }
                        value={form.current_password}
                        onChange={(event) => onFieldChange(event, "current_password")}
                     />
                  </Box>

                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: {
                           xs: "column",
                           md: "row",
                        },
                        alignItems: {
                           xs: "flex-start",
                           md: "center",
                        },
                        gap: "16px",
                        marginTop: "16px",
                     }}
                  >
                     <Box sx={{ maxWidth: "300px", width: "100%" }}>
                        <NovaPasswordInput
                           label={
                              <TranslatedText text="profile.change-password.new-password" />
                           }
                           value={form.password}
                           onChange={(event) => onFieldChange(event, "password")}
                        />
                     </Box>

                     <Box sx={{ maxWidth: "300px", width: "100%" }}>
                        <NovaPasswordInput
                           value={form.confirm_password}
                           label={
                              <TranslatedText text="profile.change-password.repeat-password" />
                           }
                           onChange={(event) => onFieldChange(event, "confirm_password")}
                        />
                     </Box>
                  </Box>
                  {form.password !== form.confirm_password && (
                     <NovaText
                        type={NovaTextTypes.BaseNormal}
                        style={{
                           color: "red",
                        }}
                     >
                        Passwords do not match
                     </NovaText>
                  )}

                  <NovaButton
                     disabled={
                        form.password.length < 6 ||
                        form.confirm_password.length < 6 ||
                        form.password !== form.confirm_password
                     }
                     style={{
                        marginTop: "24px",
                        borderRadius: "32px",
                     }}
                     onClick={onPasswordChange}
                  >
                     <TranslatedText text="buttons.save" />
                  </NovaButton>
               </Box>
            </Box>

            {user?.role === 1 && (
               <Box
                  sx={{
                     background: "#fff",
                     borderRadius: "16px",
                     padding: "40px 32px",
                     position: "relative",
                  }}
               >
                  <NovaTitle
                     variant="h3"
                     style={{
                        margin: 0,
                        color: "#1B1B48",
                     }}
                  >
                     <TranslatedText text="profile.subscriptions.title" />
                  </NovaTitle>
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        marginTop: "24px",
                        maxHeight: "500px",
                        overflow: "auto",
                        padding: "0 16px",
                     }}
                  >
                     {subscribers ? (
                        subscribers.map((subscription: any) => {
                           const pageById = pages?.find(
                              (page: any) => page.id === subscription.memorialPageId,
                           );

                           const getSubscriptionPeriodKey = () => {
                              switch (subscription?.subscriptionPlan.name.toLowerCase()) {
                                 case "free":
                                    return "profile.subscriptions.plans.free";
                                 case "1 month":
                                    return "profile.subscriptions.plans.1month";
                                 case "1 year":
                                    return "profile.subscriptions.plans.1year";
                                 case "lifetime":
                                    return "profile.subscriptions.plans.lifetime";
                                 default:
                                    return "";
                              }
                           };

                           return (
                              <Box
                                 key={subscription.id}
                                 sx={{
                                    border: "1px solid #CAA45D",
                                    borderRadius: "12px",
                                    padding: "16px 24px",
                                 }}
                              >
                                 <Box
                                    sx={{
                                       display: "flex",
                                       alignItems: "center",
                                       justifyContent: "space-between",
                                    }}
                                 >
                                    <NovaText
                                       type={NovaTextTypes.MediumNormal}
                                       style={{ margin: 0, fontWeight: "600" }}
                                    >
                                       <TranslatedText
                                          text={getSubscriptionPeriodKey()}
                                       />
                                       &nbsp;
                                       <TranslatedText
                                          text="profile.subscriptions.plan-for"
                                          props={{
                                             page: pageById?.fullName,
                                          }}
                                       />
                                    </NovaText>
                                    <Box
                                       sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "8px",
                                       }}
                                    >
                                       <Chip
                                          label={
                                             <TranslatedText
                                                text={getSubscriptionPeriodKey()}
                                             />
                                          }
                                          sx={{
                                             height: "28px",
                                          }}
                                       />
                                       <Chip
                                          label={
                                             <TranslatedText
                                                text={`profile.subscriptions.plan-status.${subscription?.status}`}
                                             />
                                          }
                                          sx={{
                                             height: "28px",
                                          }}
                                       />
                                    </Box>
                                 </Box>
                                 {subscription?.subscriptionPlan.name.toLowerCase() !==
                                    "lifetime" && (
                                    <Box
                                       sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "8px",
                                       }}
                                    >
                                       <NovaText
                                          type={NovaTextTypes.MediumNormal}
                                          style={{ margin: 0 }}
                                       >
                                          <TranslatedText text="profile.subscriptions.from" />
                                          &nbsp;
                                          {dayjs(subscription?.startDate).format(
                                             "DD-MM-YYYY",
                                          )}
                                       </NovaText>
                                       <NovaText
                                          type={NovaTextTypes.MediumNormal}
                                          style={{ margin: 0 }}
                                       >
                                          <TranslatedText text="profile.subscriptions.to" />
                                          &nbsp;
                                          {dayjs(subscription?.endDate).format(
                                             "DD-MM-YYYY",
                                          )}
                                       </NovaText>
                                    </Box>
                                 )}
                              </Box>
                           );
                        })
                     ) : (
                        <NovaText
                           type={NovaTextTypes.BaseNormal}
                           style={{
                              fontStyle: "italic",
                              textAlign: "center",
                           }}
                        >
                           No Subscriptions yet
                        </NovaText>
                     )}
                  </Box>
               </Box>
            )}

            {user?.role === 1 && (
               <Box
                  sx={{
                     background: "#fff",
                     borderRadius: "16px",
                     padding: "40px 32px",
                     position: "relative",
                  }}
               >
                  <NovaTitle
                     variant="h3"
                     style={{
                        margin: 0,
                        color: "#1B1B48",
                     }}
                  >
                     <TranslatedText text="profile.payment-methods.title" />
                  </NovaTitle>
                  <Grid
                     container
                     spacing={3}
                     sx={{
                        marginTop: "24px",
                     }}
                  >
                     <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                           maxHeight: "500px",
                           overflow: "auto",
                           padding: "0 16px",
                        }}
                     >
                        <List
                           sx={{
                              width: "100%",
                              maxWidth: 360,
                              bgcolor: "background.paper",
                           }}
                        >
                           {cards ? (
                              cards.map((card: any, index: number) => {
                                 return (
                                    <ListItem
                                       key={index}
                                       secondaryAction={
                                          <IconButton
                                             edge="end"
                                             aria-label="delete"
                                             onClick={() => setIsDeletingCard(card.id)}
                                          >
                                             <DeleteIcon />
                                          </IconButton>
                                       }
                                       sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "24px",
                                       }}
                                    >
                                       <ListItemAvatar>
                                          {card.cardBrand === "visa" && <VisaLogo />}
                                          {card.cardBrand === "mastercard" && (
                                             <MasterCardLogo />
                                          )}
                                       </ListItemAvatar>
                                       <ListItemText
                                          primary={
                                             <Box
                                                sx={{
                                                   display: "flex",
                                                   alignitems: "center",
                                                   gap: "8px",
                                                }}
                                             >
                                                <NovaText
                                                   type={NovaTextTypes.BaseNormal}
                                                   style={{ margin: 0 }}
                                                >
                                                   ***{card.last4}
                                                </NovaText>
                                                {/* {card.isDefault && (
                                                   <Chip
                                                      label="default"
                                                      sx={{
                                                         height: "24px",
                                                         background: "#1B1B48",
                                                         color: "#CAA45D",
                                                      }}
                                                   />
                                                )} */}
                                             </Box>
                                          }
                                          secondary={
                                             <TranslatedText
                                                text="profile.payment-methods.expires-on"
                                                props={{
                                                   expMonth: card.expMonth,
                                                   expYear: card.expYear,
                                                }}
                                             />
                                          }
                                       />
                                    </ListItem>
                                 );
                              })
                           ) : (
                              <NovaText
                                 type={NovaTextTypes.BaseNormal}
                                 style={{
                                    fontStyle: "italic",
                                    textAlign: "center",
                                 }}
                              >
                                 No Payment methods yet
                              </NovaText>
                           )}
                        </List>
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <Box
                           sx={{
                              padding: "16px 16px 24px 16px",
                              background: "#F3F5F7",
                              borderRadius: "24px",
                              marginTop: "24px",
                           }}
                        >
                           <NovaText type={NovaTextTypes.XLNormal}>
                              <TranslatedText text="profile.payment-methods.add-new-method" />
                           </NovaText>
                           <AddPaymentMethod />
                        </Box>
                     </Grid>
                  </Grid>

                  <ConfirmationModal
                     isOpened={isDeletingCard !== undefined}
                     onCancelCallback={() => setIsDeletingCard(undefined)}
                     onConfirmCallback={() => {
                        if (isDeletingCard !== undefined) {
                           handleCardDelete(isDeletingCard);
                           setIsDeletingCard(undefined);
                        }
                     }}
                  />
               </Box>
            )}

            <Box
               sx={{
                  background: "#fff",
                  borderRadius: "16px",
                  padding: "40px 32px",
                  position: "relative",
               }}
            >
               <NovaTitle
                  variant="h3"
                  style={{
                     margin: 0,
                     color: "#cc0000",
                  }}
               >
                  <TranslatedText text="profile.delete-account.title" />
               </NovaTitle>
               <Box>
                  {pages && (
                     <NovaText type={NovaTextTypes.MediumLight}>
                        <TranslatedText text="profile.delete-account.owner-message" />
                        &nbsp;
                        {pages.map((page: any, index: number) => {
                           return (
                              <b key={index}>
                                 {page.fullName}
                                 {pages.length !== index + 1 && ", "}
                              </b>
                           );
                        })}
                        . <TranslatedText text="profile.delete-account.remove-message" />
                     </NovaText>
                  )}
                  <NovaButton
                     disabled={pages && pages.length > 0}
                     style={{
                        borderRadius: "32px",
                        background: pages && pages.length > 0 ? "#f9e5e5" : "#cc0000",
                        border: pages && pages.length > 0 ? "1px solid #f4cccc" : "none",
                        color: pages && pages.length > 0 ? "#cc0000" : "#fff",
                        marginTop: "24px",
                     }}
                     onClick={handleDeleteButtonClick}
                  >
                     <TranslatedText text="buttons.delete-account" />
                  </NovaButton>
               </Box>
            </Box>
         </Container>

         <ConfirmationModal
            isOpened={deleteModalOpened}
            message="Are you sure you want to delete your account?"
            onConfirmCallback={handleDelete}
            onCancelCallback={() => setDeleteModalOpened(false)}
         />
      </>
   );
};

export default Profile;
