import { Box } from "@mui/material";
import NovaTitle from "../../shared/NovaTitle";
import NovaText, { NovaTextTypes } from "../../shared/NovaText";

const StatsCard = ({
   title,
   number,
   styles,
   isUnused,
}: {
   title: string;
   number: number;
   styles?: any;
   isUnused?: boolean;
}) => {
   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            ...styles,
         }}
      >
         <NovaTitle
            variant="h5"
            style={{
               margin: 0,
               color: isUnused ? "green" : "#23262F",
            }}
         >
            {number}
         </NovaTitle>
         <NovaText
            type={NovaTextTypes.MediumLight}
            style={{
               margin: 0,
               color: "#777E90",
            }}
         >
            {title}
         </NovaText>
      </Box>
   );
};

export default StatsCard;
