import {
   GET_AFFILIATE_VOUCHERS,
   GET_AFFILIATE_USERS,
   UPDATE_VOUCHER,
   GENERATE_VOUCHER_QR_FAIL,
   GENERATE_VOUCHER_QR_SUCCESS,
} from "./types";

const setAffiliateVouchers = (payload: any) => ({
   type: GET_AFFILIATE_VOUCHERS,
   payload,
});

const setAffiliateUsers = (payload: any) => ({
   type: GET_AFFILIATE_USERS,
   payload,
});

const updateVoucher = (payload: any) => ({
   type: UPDATE_VOUCHER,
   payload,
});

const generateVoucherQRfail = () => ({
   type: GENERATE_VOUCHER_QR_FAIL,
});

const generateVoucherQRsuccess = (payload: any) => ({
   type: GENERATE_VOUCHER_QR_SUCCESS,
   payload,
});

export const AffiliatesActions = {
   setAffiliateVouchers,
   setAffiliateUsers,
   updateVoucher,
   generateVoucherQRfail,
   generateVoucherQRsuccess,
};
