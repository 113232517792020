import { useState } from "react";
import { Box, Divider } from "@mui/material";
import { Link } from "react-router-dom";

import { useMediaQuery } from "../store/hooks";

import LogoDark from "./shared/icons/logo-dark";
import ContactSupportModal from "./ContactSupportModal";
import TranslatedText from "./translated-text";

const Footer = () => {
   const mediaQuery = useMediaQuery();

   const [isOpenedModal, setIsOpenedModal] = useState<boolean>(false);

   return (
      <>
         <Box
            sx={{
               borderTop: "1px solid #E9DDC8",
               background: "#fff",
            }}
         >
            <Box
               sx={{
                  display: "flex",
                  background: "#fff",
                  alignItems: "center",
                  padding: {
                     xs: "20px 24px",
                     md: "20px 80px",
                  },
                  maxWidth: "1440px",
                  width: "100%",
                  margin: "0 auto",
                  justifyContent: "space-between",
               }}
            >
               <Link
                  to="/"
                  style={{
                     display: "inline-flex",
                     justifyContent: "center",
                  }}
               >
                  <LogoDark width={mediaQuery.md ? 90 : undefined} />
               </Link>

               <Box
                  sx={{
                     flex: 1,
                     display: "flex",
                     flexDirection: {
                        xs: "column",
                        md: "row",
                     },
                     alignItems: {
                        xs: "flex-end",
                        md: "center",
                     },
                     justifyContent: "flex-end",
                     fontSize: {
                        xs: "14px",
                        md: "16px",
                     },
                     gap: 1,
                  }}
               >
                  <Link
                     to="/"
                     style={{
                        color: "#000",
                        textDecoration: "none",
                     }}
                  >
                     <TranslatedText text="footer.home" />
                  </Link>
                  <Divider
                     sx={{
                        display: {
                           xs: "none",
                           md: "block",
                        },
                        background: "#E4CB8D",
                        margin: "0 8px",
                        height: "12px",
                     }}
                     orientation="vertical"
                  />
                  <Link
                     to="/profile"
                     style={{
                        color: "#000",
                        textDecoration: "none",
                     }}
                  >
                     <TranslatedText text="footer.profile-settings" />
                  </Link>
                  <Divider
                     sx={{
                        display: {
                           xs: "none",
                           md: "block",
                        },
                        background: "#E4CB8D",
                        margin: "0 8px",
                        height: "12px",
                     }}
                     orientation="vertical"
                  />
                  <p
                     style={{
                        color: "#000",
                        textDecoration: "none",
                        cursor: "pointer",
                        margin: 0,
                     }}
                     onClick={() => setIsOpenedModal(true)}
                  >
                     <TranslatedText text="footer.contact-support" />
                  </p>
               </Box>
            </Box>
         </Box>

         {isOpenedModal && (
            <ContactSupportModal
               opened={isOpenedModal}
               onClose={() => setIsOpenedModal(false)}
            />
         )}
      </>
   );
};

export default Footer;
