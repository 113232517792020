import { useEffect, useState } from "react";
import {
   Box,
   Container,
   Paper,
   Tab,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   TableSortLabel,
   Tabs,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getUsers } from "../Login/async-actions";
import { getAffiliateUsers } from "../vouchers/async-actions";

import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import NovaTextInput from "../../components/shared/NovaTextInput";

type Order = "asc" | "desc";

const columns = [
   {
      id: "id",
      width: 70,
      numeric: true,
      disablePadding: false,
      label: "ID",
   },
   {
      id: "photoUrl",
      width: 70,
      numeric: false,
      disablePadding: false,
      label: "Photo",
   },
   {
      id: "fullName",
      numeric: false,
      disablePadding: false,
      label: "Name",
   },
   {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
   },
];

const Users = () => {
   const dispatch = useAppDispatch();

   const [tabValue, setTabValue] = useState(0);
   const [searchValue, setSearchValue] = useState("");
   const [order, setOrder] = useState<Order>("asc");
   const [orderBy, setOrderBy] = useState("id");

   const users = useAppSelector((state) => state.login.users);
   const affiliateUsers = useAppSelector((state) => state.affiliates.users);

   const a11yProps = (index: number) => {
      return {
         id: `simple-tab-${index}`,
         "aria-controls": `simple-tabpanel-${index}`,
      };
   };

   const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
   };
   const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
   };

   const rows = (tabValue === 0 ? users : affiliateUsers)?.filter((user: any) => {
      return (
         user.fullName.toLowerCase().includes(searchValue.toLowerCase()) ||
         user.email.toLowerCase().includes(searchValue.toLowerCase())
      );
   });

   useEffect(() => {
      dispatch(getUsers());
      dispatch(getAffiliateUsers());
   }, [dispatch]);

   return (
      <Container
         sx={{
            padding: "32px 0 120px 0",
         }}
      >
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between",
               padding: "24px 0",
            }}
         >
            <Tabs
               value={tabValue}
               sx={{
                  "& .MuiButtonBase-root.Mui-selected": {
                     color: "#fff",
                     background: "#1B1B48",
                     borderRadius: "32px",
                  },
                  "& .MuiTabs-indicator": {
                     display: "none",
                  },
               }}
               onChange={handleChangeTabs}
            >
               <Tab label="Users" {...a11yProps(0)} sx={{ textTransform: "none" }} />
               <Tab label="Affiliates" {...a11yProps(1)} sx={{ textTransform: "none" }} />
            </Tabs>

            <Box
               sx={{
                  width: "300px",
               }}
            >
               <NovaTextInput
                  placeholder="Search"
                  value={searchValue}
                  fullwidth
                  onChange={(event) => setSearchValue(event.target.value)}
               />
            </Box>
         </Box>
         <Box>
            <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
               <TableContainer>
                  <Table
                     sx={{ minWidth: 750 }}
                     aria-labelledby="tableTitle"
                     size="medium"
                  >
                     <TableHead
                        sx={{
                           background: "rgba(0, 0, 0, 6%)",
                        }}
                     >
                        <TableRow>
                           {columns.map((column: any) => (
                              <TableCell
                                 width={column.width}
                                 align={column.numeric ? "right" : "left"}
                                 padding={column.disablePadding ? "none" : "normal"}
                                 sortDirection={orderBy === column.id ? order : false}
                                 key={column.id}
                              >
                                 <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : "asc"}
                                    onClick={(event) =>
                                       handleRequestSort(event, column.id)
                                    }
                                 >
                                    {column.label}
                                    {orderBy === column.id ? (
                                       <Box component="span" sx={visuallyHidden}>
                                          {order === "desc"
                                             ? "sorted descending"
                                             : "sorted ascending"}
                                       </Box>
                                    ) : null}
                                 </TableSortLabel>
                              </TableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rows?.map((row: any, index: any) => {
                           return (
                              <TableRow
                                 hover
                                 role="checkbox"
                                 tabIndex={-1}
                                 key={row.id}
                                 sx={{ cursor: "pointer" }}
                              >
                                 {columns.map((key, index) =>
                                    key.id === "photoUrl" ? (
                                       <TableCell align="left" key={index}>
                                          <Box
                                             sx={{
                                                width: "50px",
                                                height: "50px",
                                                backgroundColor: "#ebebeb",
                                                borderRadius: "6px",
                                                overflow: "hidden",
                                             }}
                                          >
                                             {row[key.id] ? (
                                                <img
                                                   src={row[key.id]}
                                                   alt=""
                                                   width="100%"
                                                   height="100%"
                                                   style={{
                                                      objectFit: "cover",
                                                   }}
                                                />
                                             ) : (
                                                <NovaText
                                                   type={NovaTextTypes.BaseNormal}
                                                   style={{
                                                      fontSize: "20px",
                                                      textAlign: "center",
                                                      margin: "11px 0px",
                                                   }}
                                                >
                                                   {row.fullName[0]}
                                                </NovaText>
                                             )}
                                          </Box>
                                       </TableCell>
                                    ) : (
                                       <TableCell align="left" key={index}>
                                          {row[key.id]}
                                       </TableCell>
                                    ),
                                 )}
                              </TableRow>
                           );
                        })}
                     </TableBody>
                  </Table>
               </TableContainer>
            </Paper>
         </Box>
      </Container>
   );
};

export default Users;
