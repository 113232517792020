import {
   DELETE_FILE_FAIL,
   DELETE_FILE_SUCCESS,
   UPLOAD_FILE_FAIL,
   UPLOAD_FILE_SUCCESS,
} from "./types";

const uploadFileFail = () => ({
   type: UPLOAD_FILE_FAIL,
});

const uploadFileSuccess = () => ({
   type: UPLOAD_FILE_SUCCESS,
});

const deleteFileFail = () => ({
   type: DELETE_FILE_FAIL,
});

const deleteFileSuccess = () => ({
   type: DELETE_FILE_SUCCESS,
});

export const MemorialPageActions = {
   uploadFileFail,
   uploadFileSuccess,
   deleteFileFail,
   deleteFileSuccess,
};
