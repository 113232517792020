import * as Immutable from "seamless-immutable";

import { CREATE, REMOVE } from "./types";

export type Alert = {
   id: number;
   type: "info" | "success" | "warn" | "error";
   message: string;
};

interface InitialState {
   alerts: Alert[];
}

const initialState = Immutable.from<InitialState>({
   alerts: [],
});

export default function AlertReducer(state = initialState, action: any) {
   const { type, payload } = action;

   switch (type) {
      case CREATE: {
         return state.updateIn(["alerts"], (alerts) => alerts.concat(payload));
      }

      case REMOVE: {
         return state.updateIn(["alerts"], (alerts) =>
            alerts.filter((alert) => alert.id !== payload.alertId),
         );
      }

      default:
         return state;
   }
}
