import { useNavigate } from "react-router-dom";
import { Box, FormControlLabel, Grid, IconButton, Switch } from "@mui/material";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import {
   CheckOutlined,
   CloseOutlined,
   EditOutlined,
   RemoveRedEyeOutlined,
   VisibilityOffOutlined,
} from "@mui/icons-material";
import { useAppDispatch } from "../../store/hooks";
import {
   setPlanActivity,
   setPlanVisibility,
} from "../../pages/subscriptions/async-actions";
import { PLAN_TYPE } from "../../pages/subscriptions/create-plan";

const Plan = ({ plan }: any) => {
   const dispatch = useAppDispatch();
   const navigator = useNavigate();
   const onVisibilityChange = (event: any) => {
      dispatch(setPlanVisibility(plan.id, event.target.checked));
   };

   const onActivityChange = (event: any) => {
      dispatch(setPlanActivity(plan.id, event.target.checked));
   };

   const handlePlanEdit = (id: any) => {
      return navigator(`./${id}`);
   };

   return (
      <Box
         sx={{
            background: "#fff",
            borderRadius: "24px",
            padding: "8px 16px 16px 16px",
         }}
      >
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between",
            }}
         >
            <NovaText type={NovaTextTypes.MediumNormal} style={{ fontWeight: "900" }}>
               {plan.name}
            </NovaText>
            <IconButton onClick={() => handlePlanEdit(plan.id)}>
               <EditOutlined />
            </IconButton>
         </Box>
         <Box>
            <NovaText
               type={NovaTextTypes.BaseNormal}
               style={{ fontWeight: "700", margin: 0 }}
            >
               Plan type
            </NovaText>
            <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
               {PLAN_TYPE[plan.type]}
            </NovaText>
         </Box>
         <Box
            sx={{
               display: "flex",
               alignItems: "baseline",
               justifyContent: "space-between",
            }}
         >
            <Grid container spacing={3}>
               <Grid item xs={6}>
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        fontWeight: "700",
                        marginBottom: 0,
                     }}
                  >
                     Active
                  </NovaText>
                  <FormControlLabel
                     sx={{
                        margin: 0,
                     }}
                     value={plan.isActive}
                     control={
                        <Switch
                           checked={plan.isActive}
                           onChange={(event) => onActivityChange(event)}
                           inputProps={{ "aria-label": "controlled" }}
                           icon={<CloseOutlined sx={{ fill: "#fff" }} />}
                           checkedIcon={<CheckOutlined sx={{ fill: "#fff" }} />}
                           sx={{
                              height: "30px",
                              padding: 0,
                              display: "flex",
                              marginLeft: "16px",
                              "&.MuiSwitch-root": {
                                 margin: 0,
                              },
                              "& .MuiSwitch-switchBase": {
                                 "&.Mui-checked": {
                                    color: "#CAA45D",
                                    "& + .MuiSwitch-track": {
                                       opacity: 1,
                                       border: "1px solid #CAA45D",
                                       background: "#fff",
                                    },
                                 },
                              },
                              "& .MuiSwitch-track": {
                                 borderRadius: 20 / 2,
                                 backgroundColor: "#E9E9EA",
                                 opacity: 1,
                              },
                              "& .MuiSwitch-thumb": {
                                 boxShadow: "none",
                              },
                              ".MuiButtonBase-root": {
                                 width: "30px",
                                 height: "30px",
                                 background: "#CAA45D",
                                 "&:hover": {
                                    background: "#CAA45D",
                                 },
                              },
                              ".MuiButtonBase-root.Mui-checked": {
                                 padding: "8px 12px",
                                 left: "7px",
                                 "&:hover": {
                                    background: "#CAA45D",
                                 },
                              },
                           }}
                        />
                     }
                     label=""
                     labelPlacement="start"
                  />
               </Grid>
               <Grid item xs={6}>
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{ fontWeight: "700", marginBottom: 0 }}
                  >
                     Visible
                  </NovaText>
                  <FormControlLabel
                     sx={{
                        margin: 0,
                     }}
                     value={plan.isVisible}
                     control={
                        <Switch
                           checked={plan.isVisible}
                           onChange={(event) => onVisibilityChange(event)}
                           inputProps={{ "aria-label": "controlled" }}
                           icon={<VisibilityOffOutlined sx={{ fill: "#fff" }} />}
                           checkedIcon={<RemoveRedEyeOutlined sx={{ fill: "#fff" }} />}
                           sx={{
                              height: "30px",
                              padding: 0,
                              display: "flex",
                              marginLeft: "16px",
                              "&.MuiSwitch-root": {
                                 margin: 0,
                              },
                              "& .MuiSwitch-switchBase": {
                                 "&.Mui-checked": {
                                    color: "#CAA45D",
                                    "& + .MuiSwitch-track": {
                                       opacity: 1,
                                       border: "1px solid #CAA45D",
                                       background: "#fff",
                                    },
                                 },
                              },
                              "& .MuiSwitch-track": {
                                 borderRadius: 20 / 2,
                                 backgroundColor: "#E9E9EA",
                                 opacity: 1,
                              },
                              "& .MuiSwitch-thumb": {
                                 boxShadow: "none",
                              },
                              ".MuiButtonBase-root": {
                                 width: "30px",
                                 height: "30px",
                                 background: "#CAA45D",
                                 "&:hover": {
                                    background: "#CAA45D",
                                 },
                              },
                              ".MuiButtonBase-root.Mui-checked": {
                                 padding: "8px 12px",
                                 left: "7px",
                                 "&:hover": {
                                    background: "#CAA45D",
                                 },
                              },
                           }}
                        />
                     }
                     label=""
                     labelPlacement="start"
                  />
               </Grid>
            </Grid>
         </Box>
      </Box>
   );
};

export default Plan;
