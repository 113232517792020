import {
   ADD_TO_CART,
   REMOVE_FROM_CART,
   CHANGE_ORDER_QUANTITY,
   ADD_CURRENCY,
} from "./types";

const addToCart = (payload: any) => ({
   type: ADD_TO_CART,
   payload,
});

const removeFromCart = (payload: any) => ({
   type: REMOVE_FROM_CART,
   payload,
});

const changeOrderQuantity = (payload: any) => ({
   type: CHANGE_ORDER_QUANTITY,
   payload,
});

const addCurrency = (payload: any) => ({
   type: ADD_CURRENCY,
   payload,
});

export const CartActions = {
   addToCart,
   removeFromCart,
   changeOrderQuantity,
   addCurrency,
};
