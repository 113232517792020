const BigFlower: React.FC<{
   size?: number;
}> = ({ size = 400 }) => {
   return (
      <svg
         width={size}
         height={size * 1.0125}
         viewBox="0 0 800 810"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M373.486 518.213C341.979 456.661 301.677 442.676 246.968 408.495C236.818 402.725 257.54 396.551 261.059 391.959C277.542 380.912 296.118 371.448 311.124 358.782C347.89 332.237 371.762 293.518 397.92 257.508C400.119 254.658 401.561 255.432 403.197 257.754C410.603 269.242 418.995 280.078 426.137 291.688C432.962 300.185 439.401 309.016 445.364 318.146C453.087 326.044 459.209 335.403 466.843 343.425C482.64 358.114 501.903 367.965 519.143 380.613C524.825 384.254 530.7 387.597 535.995 391.801C540.815 396.181 555.064 399.137 555.029 405.839C481.55 454.603 467.248 452.034 416.426 530.65C393.681 566.994 402.934 559.5 373.468 518.231L373.486 518.213Z"
            fill="#F3F5F7"
         />
         <path
            d="M730.098 243.526C744.294 248.381 806.709 275.138 799.408 292.835C790.296 322.389 777.331 352.031 756.555 375.111C752.228 377.873 747.548 390.961 742.113 385.666C721.443 364.503 696.814 347.914 670.409 334.738C667.401 332.451 657.849 331.73 661.279 326.452C671.623 310.409 681.228 293.662 688.687 275.947C692.029 267.31 695.126 258.602 698.433 249.964C701.177 247.009 698.749 242.893 700.984 239.761C708.284 232.443 721.566 241.626 730.115 243.508L730.098 243.526Z"
            fill="#F3F5F7"
         />
         <path
            d="M133.379 483.079C122.015 504.189 109.894 525.053 102.488 548.238C97.7915 554.255 101.327 570.861 90.9132 569.893C57.9114 561.713 23.8014 548.696 0 523.61C2.21654 485.208 25.8596 449.55 50.347 420.735C57.542 414.648 65.1591 431.254 71.2634 434.456C88.8022 448.828 107.854 461.846 129.157 469.903C137.408 472.436 140.908 476.341 133.379 483.079Z"
            fill="#F3F5F7"
         />
         <path
            d="M236.376 712.698C383.758 661.946 286.142 627.924 381.031 745.541C391.234 752.244 351.02 777.206 345.32 782.132C328.872 792.511 311.105 800.902 292.598 806.954C287.778 807.006 282.378 812.319 278.349 807.253C273.529 800.902 268.533 794.639 265.455 787.163C256.254 773.829 249.851 758.805 244.274 743.729C242.075 733.526 234.863 723.306 236.376 712.716V712.698Z"
            fill="#F3F5F7"
         />
         <path
            d="M660.874 480.439C659.573 475.303 664.305 475.144 666.856 473.983C695.811 461.458 721.565 442.547 744.311 420.804C772.123 443.69 789.346 480.949 798.81 515.833C796.505 537.207 728.074 567.817 707.14 570.385C699.031 571.212 700.543 559.249 697.852 554.183C692.768 537.647 685.063 522.184 677.481 506.879C671.359 498.453 666.82 488.989 660.839 480.457L660.874 480.439Z"
            fill="#F3F5F7"
         />
         <path
            d="M266.14 21.0099C272.772 13.1113 277.539 -4.74416 290.487 1.1842C305.316 5.28303 319.601 10.9123 333.058 18.3536C340.64 22.5579 389.351 52.4988 380.274 60.6613C359.533 82.8618 343.103 109.179 331.704 137.36C327.446 149.463 312.3 128.776 305.422 126.401C293.037 117.464 278.929 112.099 265.313 105.52C259.754 101.351 236.885 98.5183 237.378 92.3964C242.11 66.7303 252.946 43.0873 266.157 21.0099H266.14Z"
            fill="#F3F5F7"
         />
         <path
            d="M557.879 79.1087C558.582 84.5973 561.379 90.1563 560.306 95.6976C444.061 136.932 492.491 172.01 442.865 90.4377V90.4025C414.296 50.8215 404.058 64.2439 435.811 37.751L435.776 37.7158C442.196 32.1041 449.303 27.5127 456.903 23.6953C470.783 16.3948 507.971 -1.75965 521.833 3.58818C539.126 25.7184 550.437 52.2113 557.879 79.0736V79.1087Z"
            fill="#F3F5F7"
         />
         <path
            d="M99.32 348.822C91.4566 354.1 83.857 359.729 76.3806 365.429C71.5605 369.387 67.1627 373.82 62.3074 377.725C59.6335 379.731 57.628 382.422 55.2532 384.727C49.2545 391.394 25.5234 352.604 22.7616 346.782C13.1742 329.999 7.38654 311.493 2.2674 293.022C-0.230606 286.97 1.03599 284.701 5.89126 280.145C21.0904 265.544 39.2273 255.393 58.1206 246.686C66.7581 245.067 95.2388 228.179 96.8748 242.745C104.492 271.525 117 299.425 132.744 324.687C135.893 329.806 135.559 331.178 130.299 333.553C119.269 337.212 109.312 343.07 99.32 348.84V348.822Z"
            fill="#F3F5F7"
         />
         <path
            d="M535.817 703.071C541.042 707.979 562.609 709.861 560.674 716.968C555.538 739.591 538.21 784.749 519.475 797.08C519.405 796.992 519.317 796.887 519.193 796.746C519.493 797.116 519.774 797.485 519.932 797.942C518.49 802.076 517.575 802.463 512.245 801.267C494.108 799.227 476.763 792.683 460.789 783.922C446.101 777.237 434.666 765.169 421.49 756.145C416.107 751.501 413.38 748.95 419.449 743.391C505.507 653.393 425.853 644.562 535.835 703.054L535.817 703.071Z"
            fill="#F3F5F7"
         />
         <path
            d="M619.377 209.916C612.446 211.235 605.374 211.816 598.39 212.731C590.034 214.683 588.046 213.188 589.295 204.902C590.228 177.882 591.67 150.386 587.607 123.612C586.516 111.192 586.463 110.893 598.584 108.835C626.853 104.05 656.882 103.047 684.325 112.599L684.29 112.564C689.778 124.245 696.234 208.966 682.988 210.848C661.948 209.195 640.592 207.963 619.359 209.898L619.377 209.916Z"
            fill="#F3F5F7"
         />
         <path
            d="M687.104 674.736C684.941 681.614 686.26 692.063 677.411 693.946C650.391 704.764 620.098 703.005 591.952 699.276C585.372 697.816 585.126 696.496 586.463 689.952C591.952 660.873 590.579 630.704 589.049 601.273C588.275 591.826 597.704 596.506 603.122 596.559C628.595 601.273 655.211 599.778 680.807 596.559C687.087 596.101 687.439 596.277 688.125 602.54C691.995 626.341 691.836 651.075 687.087 674.736H687.104Z"
            fill="#F3F5F7"
         />
         <path
            d="M112.48 131.787C114.925 120.95 115.453 112.612 127.996 110.483C154.401 103.288 183.022 103.447 209.55 110.131C212.47 110.624 211.802 113.421 211.52 115.532C207.457 138.841 206.56 162.343 207.65 185.933C205.856 192.548 216.411 216.314 204.097 213.376C173.98 206.357 142.984 208.961 112.41 209.911C107.625 184.35 107.554 157.206 112.498 131.787H112.48Z"
            fill="#F3F5F7"
         />
         <path
            d="M122.982 695.853C106.428 688.605 109.172 614.562 112.444 597.428C118.373 594.596 125.269 596.601 131.619 596.267C148.894 600.102 167.242 598.448 184.763 596.513C190.744 595.51 197.06 596.9 202.918 594.385C210.464 592.485 214.968 594.613 212.1 602.653C207.527 624.748 205.785 648.338 209.462 670.785C209.743 677.118 209.268 683.415 210.253 689.748C211.151 695.448 210.113 696.539 204.343 697.489C177.99 703.945 149.14 703.857 122.964 695.853H122.982Z"
            fill="#F3F5F7"
         />
         <path
            d="M352.234 143.139C362.859 118.106 378.164 95.4657 396.019 75.1298C400.646 64.223 430.235 111.667 433.085 116.892C436.075 120.622 437.658 125.143 440.139 129.171C443.411 137.721 457.906 154.397 449.04 161.944C431.484 177.988 416.813 195.949 401.613 214.156C398.341 218.888 394.717 212.977 392.747 209.934C378.938 193.627 366.325 176.105 350.475 161.68C344.071 156.473 350.352 149.067 352.216 143.121L352.234 143.139Z"
            fill="#F3F5F7"
         />
         <path
            d="M684.325 368.292C699.665 378.125 714.407 388.856 727.512 401.504C732.034 406.342 713.985 416.756 710.836 420.873C692.435 434.049 673.401 447.682 652.221 455.669C646.275 458.835 646.38 458.695 642.193 453.312C574.044 376.419 580.236 430.267 642.264 352.354C647.999 344.56 677.253 364.527 684.325 368.292Z"
            fill="#F3F5F7"
         />
         <path
            d="M382.983 714.346C369.332 696.825 358.953 677.193 349.788 657.068C348.081 653.321 345.847 649.556 349.629 645.704C366.025 631.648 380.784 616.044 393.837 598.84C399.079 591.926 399.132 591.944 404.321 598.681C416.336 614.971 430.392 629.607 445.398 643.135C455.495 650.911 453.261 648.993 449.127 658.897C438.555 684.458 421.86 706.676 404.48 727.962C396.511 734.946 398.587 733.169 392.289 726.15C388.982 722.456 386.185 718.199 382.983 714.328V714.346Z"
            fill="#F3F5F7"
         />
         <path
            d="M246.914 121.187C272.528 130.458 295.537 145.587 316.753 162.492C319.48 164.674 319.796 166.767 318.758 169.687C312.285 187.754 309.646 206.63 307.042 225.505C304.879 231.188 310.93 248.638 299.83 242.728C281.587 235.093 262.888 228.725 244.188 222.304C238.277 219.296 232.876 219.841 232.489 211.591C227.792 182.899 227.845 153.661 231.698 124.882C232.19 114.221 240.406 119.868 246.914 121.187Z"
            fill="#F3F5F7"
         />
         <path
            d="M488.567 204.645C486.825 194.16 485.084 183.658 481.9 173.49C479.331 165.292 480.211 162.829 487.898 157.288C503.52 146.029 519.933 136.161 537.471 128.174C544.508 123.899 552.741 122.263 560.164 118.639C563.243 117.267 565.424 117.725 566.339 121.436C571.617 147.49 569.558 174.581 568.872 201.056C565.653 208.638 569.664 219.193 558.863 220.67C539.055 227.936 518.367 233.53 498.875 241.956C493.844 245.316 492.314 236.309 492.032 231.683C492.032 222.518 490.027 213.511 488.567 204.645Z"
            fill="#F3F5F7"
         />
         <path
            d="M174.878 377.686C202.391 408.066 215.884 398.092 188.916 418.217C176.531 428.226 165.941 440.03 155.51 451.957C152.994 454.825 150.742 455.933 146.907 454.332C125.006 445.29 104.371 433.275 86.0405 418.252C81.9944 413.221 66.1268 406.096 72.3718 399.024C86.7793 384.687 102.911 372.584 120.344 362.223C160.119 342.221 147.453 348.325 174.895 377.703L174.878 377.686Z"
            fill="#F3F5F7"
         />
         <path
            d="M217.94 564.958C215.178 580.421 134.52 575.161 119.48 573.63C115.961 568.511 120.43 562.143 122.083 557.006C129.859 531.375 142.753 507.486 157.794 485.426C159.149 483.333 160.661 483.28 163.019 484.019C182.457 492.076 203.638 493.008 223.798 497.863C229.145 499.288 242.568 495.752 236.939 506.096C228.248 524.99 222.496 544.938 217.922 564.958H217.94Z"
            fill="#F3F5F7"
         />
         <path
            d="M289.82 664.957C281.939 668.177 231.222 700.422 231.169 684.783C230.008 653.47 227.915 621.278 234.723 590.545C248.69 584.986 263.591 581.749 277.752 576.437H277.734C284.56 573.358 292.23 572.443 298.756 568.151C302.186 566.04 304.895 567.852 306.444 571.652C308.255 594.838 314.026 617.372 320.64 639.678C326.058 645.361 294.921 662.037 289.82 664.94V664.957Z"
            fill="#F3F5F7"
         />
         <path
            d="M488.478 607.938C499.174 562.886 474.968 556.553 525.649 576.678C579.602 595.378 566.426 578.226 569.734 650.193C567.886 657.124 570.93 695.808 559.196 689.018C541.517 681.823 523.556 675.279 507.864 664.214C499.666 658.883 491.821 653.025 483.887 647.326C476.006 641.538 481.002 637.703 482.603 630.491C485.523 627.359 484.168 622.926 485.927 619.443C486.912 615.643 487.352 611.72 488.478 607.938Z"
            fill="#F3F5F7"
         />
         <path
            d="M234.529 303.133C235.444 308.34 235.409 308.006 229.867 308.587C206.946 310.416 184.305 314.972 162.404 321.991C159.36 322.976 157.021 322.273 154.998 319.212C148.788 311.472 104.387 230.955 123.298 231.553C153.027 229.548 183.637 229.213 212.364 237.956C218.187 239.663 215.864 246.963 217.817 251.291C222.18 268.865 227.528 286.351 234.547 303.116L234.529 303.133Z"
            fill="#F3F5F7"
         />
         <path
            d="M622.068 318.043C603.21 313.557 584.105 310.232 564.931 308.403C562.028 306.767 564.033 304.145 563.664 302.017C570.806 283.282 577.72 264.248 582.311 244.792C581.713 237.509 588.081 235.662 593.781 235.011C605.145 233.146 616.685 231.545 628.454 230.349C637.566 232.055 682.653 225.617 681.721 236.383C671.852 262.84 660.259 289.404 644.198 312.818L644.093 312.783C638.569 325.361 632.57 321.192 622.085 318.043H622.068Z"
            fill="#F3F5F7"
         />
         <path
            d="M658.079 516.569C660.805 522.831 689.11 576.274 677.236 575.008C647.172 578.984 616.51 577.928 586.745 572.158C581.643 569.836 583.367 564.611 581.714 560.442C579.55 554.76 579.075 548.62 577.351 542.85C573.252 530.519 569.118 518.257 565.705 505.785C565.318 496.761 579.55 500.244 585.584 498.273C645.87 488.756 631.727 468.315 658.079 516.569Z"
            fill="#F3F5F7"
         />
         <path
            d="M180.03 351.485C177.251 349.656 176.442 346.296 174.207 344.026C172.308 342.109 174.436 341.546 175.668 341.106C197.323 333.841 220.157 330.252 242.973 328.863C250.484 331.413 255.938 351.661 261.55 358.839C263.203 361.864 262.781 363.711 259.738 365.664C217.36 389.448 232.559 401.885 196.003 368.021C190.515 362.656 185.448 356.886 180.048 351.485H180.03Z"
            fill="#F3F5F7"
         />
         <path
            d="M261.022 452.472C243.448 487.743 249.148 478.244 210.376 474.075C205.046 471.7 169.387 470.222 177.497 461.285C181.93 456.078 186.627 451.1 191.535 446.333H191.553C199.838 440.563 217.237 413.261 227.739 420.174C235.198 428.442 269.871 439.19 261.022 452.49V452.472Z"
            fill="#F3F5F7"
         />
         <path
            d="M581.764 382.261C578.352 384.759 574.605 390.459 570.031 387.117C559.054 379.869 547.232 373.94 536.836 365.778C530.837 361.486 537.856 358.829 538.7 354.185C541.744 348.222 545.016 342.381 548.393 336.594C550.487 323.664 563.293 330.401 571.984 330.472C587.587 330.947 602.241 336.682 617.598 338.476C635.683 340.745 586.936 377.353 581.782 382.261H581.764Z"
            fill="#F3F5F7"
         />
         <path
            d="M548.238 470.909C545.617 461.621 530.47 449.589 540.339 441.18C546.479 436.799 552.636 432.472 558.969 428.373C565.249 424.696 571.423 413.667 578.865 421.46C594.451 434.354 609.157 448.216 621.964 463.926C626.291 471.209 583.315 476.803 577.316 478.017C570.614 479.459 563.753 478.632 556.998 479.565C552.196 479.969 550.296 474.269 548.255 470.909H548.238Z"
            fill="#F3F5F7"
         />
         <path
            d="M471.75 554.419C472.963 576.443 468.566 598.274 463.816 619.683C463.288 622.041 462.971 625.277 460.86 625.946C444.588 615.883 431.905 597.377 418.641 583.128C414.7 578.677 411.463 572.503 416.776 567.946C425.273 558.764 430.48 547.452 438.818 538.428C440.155 537.214 441.281 536.985 443.04 538.076C452.241 544.198 462.637 548.402 471.75 554.436V554.419Z"
            fill="#F3F5F7"
         />
         <path
            d="M337.35 184.44C344.914 178.987 377.142 226.361 384.337 233.714C389.087 239.977 367.924 258.782 365.004 266.856C359.674 274.491 346.357 262.247 339.567 260.383C335.134 258.078 327.024 257.533 326.813 251.165C326.197 234.998 329.627 219.201 331.844 203.281C334.289 197.247 334.465 190.527 337.333 184.458L337.35 184.44Z"
            fill="#F3F5F7"
         />
         <path
            d="M416.494 229.283C430.444 213.803 443.093 193.819 460.069 182.578C465.944 187.24 465.874 209.264 469.797 216.09C471.274 252.346 484.415 248.581 448.687 265.082C444.095 268.002 437.921 272.295 434.068 265.997C428.087 257.641 422.212 249.197 416.477 240.665C411.745 234.948 411.745 234.209 416.477 229.266L416.494 229.283Z"
            fill="#F3F5F7"
         />
         <path
            d="M357.724 540.431C361.031 538.056 365.165 543.597 366.309 546.518C370.302 551.689 373.556 557.319 377.69 562.403C382.88 567.856 388.105 575.033 380.488 581.138C370.073 597.111 355.613 610.463 342.138 624.044C335.295 636.041 325.004 562.825 326.921 558.409C328.082 552.657 335.84 552.868 339.939 549.948C345.92 546.852 351.549 543.158 357.724 540.448V540.431Z"
            fill="#F3F5F7"
         />
         <path
            d="M543.822 559.742C533.901 555.66 490.397 545.668 494.091 533.266C496.483 526.089 488.198 499.104 499.315 500.669C545.687 503.959 537.683 487.089 552.565 532.475C554.993 538.579 556.259 545.264 558.036 551.685C563.278 567.992 554.465 562.891 543.822 559.742Z"
            fill="#F3F5F7"
         />
         <path
            d="M292.633 547.426C286.353 549.397 234.845 574.394 241.565 557.19C245.207 547.62 256.008 498.821 266.123 500.774C277.505 500.51 288.922 501.46 300.268 499.894C304.42 499.314 306.021 500.932 305.809 505.242C305.247 516.606 304.508 527.988 304.877 539.37C304.49 544.893 296.662 545.298 292.633 547.426Z"
            fill="#F3F5F7"
         />
         <path
            d="M246.878 274.976C245.928 269.61 232.717 242.836 243.694 245.105C259.544 249.468 275.218 255.361 289.96 262.644C295.431 264.896 306.355 265.687 305.739 273.533C305.387 283.033 306.073 292.462 307.34 301.873C307.692 304.406 307.639 306.64 304.086 306.623C258.928 303.421 262.411 320.96 246.878 275.011V274.976Z"
            fill="#F3F5F7"
         />
         <path
            d="M552.793 246.091C560.446 245.563 560.2 245.493 558.282 253.093C554.746 263.032 543.875 307.943 533.408 307.503C484.855 304.372 494.601 320.31 494.46 270.227C493.58 263.683 544.631 248.308 552.793 246.074V246.091Z"
            fill="#F3F5F7"
         />
         <path
            d="M309.259 331.085C308.978 334.797 281.341 355.502 280.092 347.094C279.213 342.678 264.629 327.866 274.006 327.69C279.477 328.974 311.529 324.33 309.277 331.103L309.259 331.085Z"
            fill="#F3F5F7"
         />
         <path
            d="M274.954 472.66C284.207 448.506 284.664 458.586 302.203 468.35C305.387 469.124 309.222 473.926 307.463 477.321C299.881 482.757 287.18 478.359 277.979 479.696C271.171 480.435 273.3 477.198 274.971 472.66H274.954Z"
            fill="#F3F5F7"
         />
         <path
            d="M521.901 339.004C512.789 357.194 515.023 348.803 495.461 337.491C490.8 334.237 488.777 327.147 496.763 328.608C504.327 328.502 511.874 328.537 519.439 328.572C528.692 328.361 524.628 333.779 521.901 339.004Z"
            fill="#F3F5F7"
         />
         <path
            d="M499.966 468.348C509.958 463.089 515.411 452.78 521.885 468.313C523.415 472.236 531.49 480.574 522.413 480.012C514.039 479.519 479.243 482.474 499.966 468.366V468.348Z"
            fill="#F3F5F7"
         />
         <path
            d="M452.715 520.771C452.31 513.436 460.068 506.874 464.132 500.91C473.315 491.129 472.277 511.817 472.646 516.971C476.376 538.345 462.777 529.162 452.715 520.754V520.771Z"
            fill="#F3F5F7"
         />
         <path
            d="M332.143 279.28C337.086 281.373 353.129 286.14 346.533 293.124C340.675 300.565 327.797 320.831 328.572 298.103C329.697 292.667 321.552 276.201 332.143 279.28Z"
            fill="#F3F5F7"
         />
         <path
            d="M455.335 295.901C453.717 292.612 449.442 289.516 452.503 286.437C457.077 285.856 473.965 272.786 473.331 282.18C471.713 286.49 473.067 313.581 466.77 309.957C463.586 304.75 459.68 300.141 455.335 295.884V295.901Z"
            fill="#F3F5F7"
         />
         <path
            d="M344.281 523.452C338.758 523.804 325.195 537.736 326.18 525.105C327.657 518.948 326.338 502.975 329.944 499.369C334.096 500.53 336.4 506.071 339.813 508.816C340.728 514.111 351.564 518.79 344.299 523.452H344.281Z"
            fill="#F3F5F7"
         />
      </svg>
   );
};

export default BigFlower;
