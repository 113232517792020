import {
   GET_ORDERS_SUCCESS,
   GET_SINGLE_ORDER_SUCCESS,
   CHANGE_STATUS_SUCCESS,
   GET_ORDERS_FAIL,
   GET_SINGLE_ORDER_FAIL,
} from "./types";

const getOrdersSuccess = (payload: any) => ({
   type: GET_ORDERS_SUCCESS,
   payload,
});

const getOrdersFail = () => ({
   type: GET_ORDERS_FAIL,
});

const getSingleOrderSuccess = (payload: any) => ({
   type: GET_SINGLE_ORDER_SUCCESS,
   payload,
});

const getSingleOrdersFail = () => ({
   type: GET_SINGLE_ORDER_FAIL,
});

const changeStatusSuccess = (payload: any) => ({
   type: CHANGE_STATUS_SUCCESS,
   payload,
});

const changeStatusFail = () => ({
   type: GET_SINGLE_ORDER_FAIL,
});

export const OrdersActions = {
   getOrdersSuccess,
   getOrdersFail,
   getSingleOrderSuccess,
   getSingleOrdersFail,
   changeStatusSuccess,
   changeStatusFail,
};
