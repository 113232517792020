import { Navigate } from "react-router-dom";
import { roles } from "./PrivateRoutes";

const DesiderRoute = () => {
   const user: any = JSON.parse(localStorage.getItem("user") as any);
   const withoutCredentials = ["affiliate"];
   const location = window.location.pathname.slice(1);

   if (!user && !withoutCredentials.includes(location)) {
      return <Navigate to="login" replace />;
   }

   if (!user && withoutCredentials.includes(location)) {
      return <Navigate to={`${location}`} replace />;
   }

   if (user && user.role === roles.ADMIN) {
      return <Navigate to="admin" replace />;
   } else {
      return <Navigate to="dashboard" replace />;
   }
};

export default DesiderRoute;
