export const columns: readonly any[] = [
   {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Payment Method",
   },
   {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
   },
   {
      id: "amount",
      numeric: false,
      disablePadding: false,
      label: "Amount",
   },
   {
      id: "userFullName",
      numeric: false,
      disablePadding: false,
      label: "User",
   },
   {
      id: "created",
      numeric: false,
      disablePadding: false,
      label: "Created on",
   },
];
