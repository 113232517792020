import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
   Avatar,
   Badge,
   BadgeProps,
   Box,
   Button,
   Divider,
   IconButton,
   ListItemIcon,
   Menu,
   MenuItem,
} from "@mui/material";
import {
   Logout,
   ManageAccounts,
   QrCode2,
   SearchOutlined,
   ShoppingCartOutlined,
   Storefront,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import LanguageIcon from "@mui/icons-material/Language";
import LogoDark from "./shared/icons/logo-dark";
import { useAppDispatch, useLanguage, useMediaQuery } from "../store/hooks";
import { userLogout } from "../pages/Login/async-actions";
import { LoginSelectors } from "../pages/Login/selectors";
import NovaText, { NovaTextTypes } from "./shared/NovaText";
import { CartSelectors } from "../pages/products/clients/selectors";
import TranslatedText from "./translated-text";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
   "& .MuiBadge-badge": {
      right: -3,
      top: 8,
      border: `2px solid #fff`,
      padding: "0 4px",
      height: "16px",
      minWidth: "16px",
      fontSize: "10px",
      width: "16px",
   },
}));

const Header = () => {
   const user = useSelector(LoginSelectors.getUser);
   const order = useSelector(CartSelectors.getOrder);
   const isUserAdmin = useSelector(LoginSelectors.isUserAdmin);
   const isAgency = useSelector(LoginSelectors.isAgency);
   const isUserClient = useSelector(LoginSelectors.isUserClient);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [cartEl, setCartEl] = useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const cartOpen = Boolean(cartEl);
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const language = useLanguage();
   const mediaQuery = useMediaQuery();

   const onProfileClick = useCallback(() => {
      handleClose();
      navigate("../profile");
   }, [navigate]);

   const onRegisterVoucherClick = useCallback(() => {
      handleClose();
      navigate("../register-voucher");
   }, [navigate]);

   const handleChangeLanguage = () => {
      language.changeLanguage(language.language === "bg" ? "en" : "bg");
   };
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };
   const handleCartClick = (event: React.MouseEvent<HTMLElement>) => {
      setCartEl(event.currentTarget);
   };
   const handleCartClose = () => {
      setCartEl(null);
   };
   const handleLogout = () => {
      dispatch(userLogout(navigate));
   };
   const handleOrderSelected = () => {
      navigate("../cart");
      handleCartClose();
   };

   return (
      <>
         <Box
            sx={{
               borderBottom: "1px solid #E9DDC8",
               background: "#fff",
            }}
         >
            <Box
               sx={{
                  display: "flex",
                  background: "#fff",
                  alignItems: "center",
                  padding: {
                     xs: "20px 24px",
                     md: "20px 80px",
                  },
                  maxWidth: "1440px",
                  width: "100%",
                  margin: "0 auto",
                  justifyContent: "space-between",
               }}
            >
               <Link
                  to="/"
                  style={{
                     display: "inline-flex",
                     justifyContent: "center",
                     paddingRight: isUserAdmin ? "40px" : "0",
                     marginRight: isUserAdmin ? "40px" : "0",
                     borderRight: isUserAdmin ? "1px solid #E9DDC8" : "none",
                  }}
               >
                  <LogoDark width={mediaQuery.md ? 90 : undefined} />
               </Link>

               {isUserAdmin && (
                  <Box
                     sx={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: 1,
                     }}
                  >
                     <Link
                        to="/"
                        style={{
                           color: "#000",
                           textDecoration: "none",
                        }}
                     >
                        Board
                     </Link>
                     <Divider
                        sx={{
                           background: "#E4CB8D",
                           margin: "0 8px",
                           height: "12px",
                        }}
                        orientation="vertical"
                     />
                     <Link
                        to="products"
                        style={{
                           color: "#000",
                           textDecoration: "none",
                        }}
                     >
                        Products
                     </Link>
                     <Divider
                        sx={{
                           background: "#E4CB8D",
                           margin: "0 8px",
                           height: "12px",
                        }}
                        orientation="vertical"
                     />
                     <Link
                        to="orders"
                        style={{
                           color: "#000",
                           textDecoration: "none",
                        }}
                     >
                        Orders
                     </Link>
                     <Divider
                        sx={{
                           background: "#E4CB8D",
                           margin: "0 8px",
                           height: "12px",
                        }}
                        orientation="vertical"
                     />
                     <Link
                        to="subscriptions"
                        style={{
                           color: "#000",
                           textDecoration: "none",
                        }}
                     >
                        Subscriptions
                     </Link>
                     <Divider
                        sx={{
                           background: "#E4CB8D",
                           margin: "0 8px",
                           height: "12px",
                        }}
                        orientation="vertical"
                     />
                     <Link
                        to="payments"
                        style={{
                           color: "#000",
                           textDecoration: "none",
                        }}
                     >
                        Payments
                     </Link>
                     <Divider
                        sx={{
                           background: "#E4CB8D",
                           margin: "0 8px",
                           height: "12px",
                        }}
                        orientation="vertical"
                     />
                     <Link
                        to="vouchers"
                        style={{
                           color: "#000",
                           textDecoration: "none",
                        }}
                     >
                        Vouchers
                     </Link>
                     <Divider
                        sx={{
                           background: "#E4CB8D",
                           margin: "0 8px",
                           height: "12px",
                        }}
                        orientation="vertical"
                     />
                     <Link
                        to="users"
                        style={{
                           color: "#000",
                           textDecoration: "none",
                        }}
                     >
                        Users
                     </Link>
                  </Box>
               )}

               {isUserClient && (
                  <>
                     <Box
                        sx={{
                           flex: 1,
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "flex-end",
                           gap: 1,
                        }}
                     >
                        <Link
                           to="../products"
                           style={{
                              display: "inline-flex",
                              alignItems: "center",
                              textDecoration: "none",
                           }}
                        >
                           <Button
                              variant="contained"
                              sx={{
                                 background: "#CAA45D",
                                 color: "#000",
                                 border: "none",
                                 boxShadow: "none",
                                 padding: "6px 12px",
                                 minWidth: "auto",
                                 borderRadius: "32px",
                                 ":hover": {
                                    background: "#CAD1E2",
                                 },
                              }}
                              startIcon={
                                 <Storefront sx={{ fontSize: "16px", color: "#000" }} />
                              }
                           >
                              <TranslatedText text="buttons.shop" />
                           </Button>
                        </Link>
                     </Box>

                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                        }}
                     >
                        <Divider
                           sx={{
                              background: "#E4CB8D",
                              margin: "0 8px",
                              height: "12px",
                           }}
                           orientation="vertical"
                        />

                        <IconButton onClick={handleCartClick}>
                           <StyledBadge
                              badgeContent={order && order?.length}
                              color="secondary"
                           >
                              <ShoppingCartOutlined
                                 sx={{
                                    color: "#151623",
                                    fontSize: "16px",
                                 }}
                              />
                           </StyledBadge>
                        </IconButton>

                        <Menu
                           anchorEl={cartEl}
                           open={cartOpen}
                           onClose={handleCartClose}
                           onClick={handleCartClose}
                           PaperProps={{
                              elevation: 0,
                              sx: {
                                 overflow: "visible",
                                 filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                 mt: 1.5,
                                 minWidth: "350px",
                                 "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                 },
                                 "&::before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                 },
                              },
                           }}
                           transformOrigin={{ horizontal: "right", vertical: "top" }}
                           anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        >
                           {order.length > 0 &&
                              order.map((o: any, index: number) => {
                                 return (
                                    <Box key={index}>
                                       <MenuItem onClick={handleOrderSelected}>
                                          <Box
                                             sx={{
                                                display: "flex",
                                                alignItems: "start",
                                                gap: "16px",
                                             }}
                                          >
                                             <Box
                                                sx={{
                                                   flex: "0 0 auto",
                                                   background: `url(${o.product.photos[0].photoUrl})`,
                                                   backgroundSize: "cover",
                                                   backgroundPosition: "center center",
                                                   backgroundRepeat: "no-repeat",
                                                   width: "50px",
                                                   height: "50px",
                                                   borderRadius: "8px",
                                                }}
                                             />
                                             <Box>
                                                <NovaText
                                                   type={NovaTextTypes.MediumNormal}
                                                   style={{
                                                      margin: 0,
                                                   }}
                                                >
                                                   {o.name}
                                                </NovaText>
                                                <NovaText
                                                   type={NovaTextTypes.MediumNormal}
                                                   style={{
                                                      margin: 0,
                                                   }}
                                                >
                                                   {o.quantity} x {o.product.name}
                                                </NovaText>
                                                <NovaText
                                                   type={NovaTextTypes.BaseNormal}
                                                   style={{
                                                      margin: 0,
                                                      lineHeight: "14px",
                                                   }}
                                                >
                                                   <TranslatedText text="single-product.metal-medallion" />
                                                </NovaText>
                                                <NovaText
                                                   type={NovaTextTypes.BaseNormal}
                                                   style={{
                                                      margin: "8px 0 0 0",
                                                      fontSize: "12px",
                                                      lineHeight: "12px",
                                                   }}
                                                >
                                                   <TranslatedText text="cart.for-page" />
                                                   &nbsp;
                                                   <b>
                                                      <i>{o.memorialPage.fullName}</i>
                                                   </b>
                                                </NovaText>
                                                <div
                                                   style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      marginTop: "6px",
                                                   }}
                                                >
                                                   <div
                                                      style={{
                                                         height: "2px",
                                                         width: "18px",
                                                         background: "#CAA45D",
                                                         display: "inline-block",
                                                      }}
                                                   />
                                                   <NovaText
                                                      type={NovaTextTypes.MediumNormal}
                                                      style={{
                                                         margin: "0 0 0 10px",
                                                         color: "#CAA45D",
                                                         fontWeight: "900",
                                                      }}
                                                   >
                                                      {o.totalPrice}
                                                      {o.currency}
                                                   </NovaText>
                                                </div>
                                             </Box>
                                          </Box>
                                       </MenuItem>
                                       <Divider />
                                    </Box>
                                 );
                              })}
                           {order.length < 1 && (
                              <Box
                                 sx={{
                                    padding: "0 16px",
                                 }}
                              >
                                 <NovaText type={NovaTextTypes.BaseNormal}>
                                    <TranslatedText text="messages.empty-cart" />
                                 </NovaText>
                              </Box>
                           )}
                           {order.length > 0 && (
                              <Box
                                 sx={{
                                    padding: "16px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "8px",
                                 }}
                              >
                                 <Link
                                    to="../cart"
                                    style={{
                                       background: "#CAA45D",
                                       color: "#151623",
                                       borderRadius: "4px",
                                       padding: "8px 16px",
                                       width: "100%",
                                       textAlign: "center",
                                       textDecoration: "none",
                                    }}
                                 >
                                    <TranslatedText text="buttons.cart" />
                                 </Link>
                                 <Link
                                    to="../checkout"
                                    style={{
                                       background: "#1B1B48",
                                       color: "#CAA45D",
                                       borderRadius: "4px",
                                       padding: "8px 16px",
                                       width: "100%",
                                       textAlign: "center",
                                       textDecoration: "none",
                                    }}
                                 >
                                    <TranslatedText text="buttons.checkout" />
                                 </Link>
                              </Box>
                           )}
                        </Menu>

                        <Divider
                           sx={{
                              background: "#E4CB8D",
                              margin: "0 8px",
                              height: "12px",
                           }}
                           orientation="vertical"
                        />

                        <Button
                           variant="contained"
                           sx={{
                              background: "#fff",
                              color: "#151623",
                              border: "none",
                              boxShadow: "none",
                              padding: "6px 12px",
                              minWidth: "auto",
                              borderRadius: "32px",
                              ":hover": {
                                 background: "#CAA45D",
                              },
                           }}
                           startIcon={
                              <LanguageIcon
                                 sx={{
                                    fontSize: "16px",
                                 }}
                              />
                           }
                           onClick={handleChangeLanguage}
                        >
                           {language.language === "bg" ? "en" : "bg"}
                        </Button>
                     </Box>
                  </>
               )}

               <Box>
                  {isAgency && (
                     <Button
                        variant="contained"
                        sx={{
                           background: "#fff",
                           color: "#151623",
                           border: "none",
                           boxShadow: "none",
                           padding: "6px 12px",
                           minWidth: "auto",
                           borderRadius: "32px",
                           ":hover": {
                              background: "#CAA45D",
                           },
                        }}
                        startIcon={
                           <LanguageIcon
                              sx={{
                                 fontSize: "16px",
                              }}
                           />
                        }
                        onClick={handleChangeLanguage}
                     >
                        {language.language === "bg" ? "en" : "bg"}
                     </Button>
                  )}
                  <IconButton
                     onClick={handleClick}
                     size="small"
                     aria-controls={open ? "account-menu" : undefined}
                     aria-haspopup="true"
                     aria-expanded={open ? "true" : undefined}
                     sx={{
                        marginLeft: {
                           xs: "0px",
                           md: "20px",
                        },
                     }}
                  >
                     <Avatar src={user?.photoUrl} sx={{ width: 32, height: 32 }}>
                        {user?.fullName?.[0]}
                     </Avatar>
                  </IconButton>
               </Box>
            </Box>
         </Box>

         <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
               elevation: 0,
               sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                     width: 32,
                     height: 32,
                     ml: -0.5,
                     mr: 1,
                  },
                  "&::before": {
                     content: '""',
                     display: "block",
                     position: "absolute",
                     top: 0,
                     right: 14,
                     width: 10,
                     height: 10,
                     bgcolor: "background.paper",
                     transform: "translateY(-50%) rotate(45deg)",
                     zIndex: 0,
                  },
               },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
         >
            <MenuItem onClick={onProfileClick}>
               <Avatar src={user?.photoUrl} sx={{ width: 32, height: 32 }}>
                  {user?.fullName?.[0]}
               </Avatar>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     gap: "0px",
                  }}
               >
                  {user?.fullName}
                  <span
                     style={{
                        opacity: 0.5,
                     }}
                  >
                     {user?.email}
                  </span>
               </Box>
            </MenuItem>
            <Divider />
            {((user && user.role === 1) || user.role === 2) && (
               <MenuItem onClick={onProfileClick}>
                  <ListItemIcon>
                     <ManageAccounts fontSize="small" />
                  </ListItemIcon>
                  <TranslatedText text="buttons.profile" />
               </MenuItem>
            )}
            {user && user.role === 1 && (
               <MenuItem onClick={onRegisterVoucherClick}>
                  <ListItemIcon>
                     <QrCode2 fontSize="small" />
                  </ListItemIcon>
                  <TranslatedText text="buttons.register-code" />
               </MenuItem>
            )}
            <MenuItem onClick={handleLogout}>
               <ListItemIcon>
                  <Logout fontSize="small" />
               </ListItemIcon>
               <TranslatedText text="buttons.logout" />
            </MenuItem>
         </Menu>
      </>
   );
};

export default Header;
