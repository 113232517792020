import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Percent } from "@mui/icons-material";
import Handshake from "@mui/icons-material/Handshake";
import { Box, Grid } from "@mui/material";
import InfoItem from "./info-item";
import { useAppDispatch, useLanguage } from "../../store/hooks";
import LogoDark from "../../components/shared/icons/logo-dark";
import NovaTitle from "../../components/shared/NovaTitle";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import NovaTextInput from "../../components/shared/NovaTextInput";
import NovaButton from "../../components/shared/NovaButton";
import AffiliateFlower from "../../assets/affiliate-flower.svg";
import { affiliateSignUp } from "../sign-up/async-actions";
import TranslatedText from "../../components/translated-text";
import { checkEmailIsValid } from "../../utils/functions";

const AffiliateRegister = () => {
   useLanguage();
   const { t } = useTranslation();
   const dispatch = useAppDispatch();
   const [account, setAccount] = useState({
      name: "",
      email: "",
      website: "",
      phone: "",
   });

   const [isFormValid, setIsFormValid] = useState(false);

   const onAccountChange = ({ field, value }: any) => {
      setAccount({
         ...account,
         [field]: value,
      });
   };

   const handleSubmit = async () => {
      dispatch(
         affiliateSignUp(account.name, account.email, account.website, account.phone),
      );
   };

   useEffect(() => {
      if (
         account.name &&
         account.email &&
         account.phone &&
         account.name.length >= 6 &&
         checkEmailIsValid(account.email) &&
         account.phone.length >= 6
      ) {
         return setIsFormValid(true);
      }
      return setIsFormValid(false);
   }, [account]);

   useEffect(() => {
      const keyDownHandler = (event: any) => {
         if (event.key === "Enter") {
            event.preventDefault();
            handleSubmit();
         }
      };

      document.addEventListener("keydown", keyDownHandler);

      return () => {
         document.removeEventListener("keydown", keyDownHandler);
      };
   }, [account.email, account.name, account.phone]);

   const onFieldChange = (event: any, field: string) => {
      onAccountChange({ field, value: event.target.value });
   };

   const partnershipBenefits = t("register-affiliate.offer.partnership.benefits", {
      returnObjects: true,
   });

   const whatWeOfferBenefits = t("register-affiliate.offer.what-we-offer.benefits", {
      returnObjects: true,
   });

   return (
      <Box
         style={{
            height: "100%",
         }}
      >
         <Grid
            container
            spacing={0}
            sx={{
               height: "100%",
            }}
         >
            <Grid
               item
               xs={0}
               sm={6}
               lg={7}
               sx={{
                  display: {
                     xs: "none",
                     md: "flex",
                  },
                  height: "100%",
               }}
            >
               <Box
                  sx={{
                     width: "100%",
                     height: "100%",
                     background: `url(${AffiliateFlower})`,
                     backgroundRepeat: "no-repeat",
                     backgroundSize: "cover",
                     backgroundPosition: "center",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                  }}
               >
                  <Box
                     sx={{
                        padding: "62px 75px",
                        background: "#fff",
                        maxWidth: "650px",
                        width: "100%",
                        borderRadius: "24px",
                     }}
                  >
                     <NovaText type={NovaTextTypes.XLNormal}>
                        <TranslatedText text={"register-affiliate.offer.title"} />
                     </NovaText>
                     <NovaText
                        type={NovaTextTypes.MediumNormal}
                        style={{
                           color: "#777E90",
                           marginBottom: "20px",
                        }}
                     >
                        <TranslatedText text={"register-affiliate.offer.subtitle"} />
                     </NovaText>
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           gap: "20px",
                        }}
                     >
                        <InfoItem
                           icons={<Handshake sx={{ fill: "#fff" }} />}
                           title={t("register-affiliate.offer.partnership.title")}
                           listItems={partnershipBenefits}
                        />
                        <InfoItem
                           icons={<Percent sx={{ fill: "#fff" }} />}
                           title={t("register-affiliate.offer.what-we-offer.title")}
                           listItems={whatWeOfferBenefits}
                        />
                     </Box>
                  </Box>
               </Box>
            </Grid>
            <Grid
               item
               xs={12}
               md={6}
               lg={5}
               style={{
                  background: "#fff",
                  display: "flex",
               }}
            >
               <Box
                  style={{
                     width: "100%",
                     background: "#fff",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                  }}
               >
                  <div
                     style={{
                        maxWidth: "520px",
                        width: "100%",
                     }}
                  >
                     <LogoDark />
                     <NovaTitle
                        variant="h2"
                        style={{
                           marginTop: "24px",
                           marginBottom: "0",
                        }}
                     >
                        <TranslatedText text="register-affiliate.title" />
                     </NovaTitle>
                     <div
                        style={{
                           marginTop: "24px",
                           padding: "10px",
                        }}
                     >
                        <div>
                           <NovaTextInput
                              value={account.name}
                              label={t("register-affiliate.fields.name")}
                              placeholder={t(
                                 "register-affiliate.fields.name-placeholder",
                              )}
                              onChange={(event) => onFieldChange(event, "name")}
                           />
                           {account.name && account.name.length < 6 && (
                              <NovaText
                                 type={NovaTextTypes.BaseNormal}
                                 style={{ color: "red", margin: 0 }}
                              >
                                 <TranslatedText
                                    text="messages.register-validation.name"
                                    props={{
                                       count: 6,
                                    }}
                                 />
                              </NovaText>
                           )}
                        </div>
                        <div
                           style={{
                              marginTop: "16px",
                           }}
                        >
                           <NovaTextInput
                              value={account.email}
                              type="email"
                              label={t("register-affiliate.fields.name")}
                              placeholder={t(
                                 "register-affiliate.fields.email-placeholder",
                              )}
                              onChange={(event) => onFieldChange(event, "email")}
                           />
                           {account.email && !checkEmailIsValid(account.email) && (
                              <NovaText
                                 type={NovaTextTypes.BaseNormal}
                                 style={{ color: "red", margin: 0 }}
                              >
                                 <TranslatedText text="checkout.error.email" />
                              </NovaText>
                           )}
                        </div>

                        <div
                           style={{
                              marginTop: "16px",
                           }}
                        >
                           <NovaTextInput
                              value={account.phone}
                              type="phone"
                              label={t("register-affiliate.fields.phone")}
                              placeholder={t(
                                 "register-affiliate.fields.phone-placeholder",
                              )}
                              onChange={(event) => onFieldChange(event, "phone")}
                           />
                           {account.phone && account.phone.length < 6 && (
                              <NovaText
                                 type={NovaTextTypes.BaseNormal}
                                 style={{ color: "red", margin: 0 }}
                              >
                                 <TranslatedText
                                    text="messages.register-validation.phone-number"
                                    props={{
                                       count: 6,
                                    }}
                                 />
                              </NovaText>
                           )}
                        </div>

                        <div
                           style={{
                              marginTop: "16px",
                           }}
                        >
                           <NovaTextInput
                              value={account.website}
                              type="website"
                              label={t("register-affiliate.fields.website")}
                              placeholder={t(
                                 "register-affiliate.fields.website-placeholder",
                              )}
                              onChange={(event) => onFieldChange(event, "website")}
                           />
                        </div>
                     </div>
                     <div
                        style={{
                           display: "flex",
                           width: "100%",
                           marginTop: "16px",
                        }}
                     >
                        <NovaButton
                           disabled={!isFormValid}
                           style={{
                              width: "100%",
                              borderRadius: "32px",
                           }}
                           onClick={handleSubmit}
                        >
                           <TranslatedText text="buttons.request-to-join" />
                        </NovaButton>
                     </div>
                  </div>
               </Box>
            </Grid>
         </Grid>
      </Box>
   );
};

export default AffiliateRegister;
