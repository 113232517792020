import { memo, useCallback, useMemo } from "react";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import { Box } from "@mui/material";
import dayjs from "dayjs";

import defaultPersonImage from "../../assets/default-person-image.jpg";
import maleUnknownImage from "../../assets/male-unknown-image.png";
import femaleUnknownImage from "../../assets/female-unknown-image.png";

type PersonBoxProps = any & {
   dataId: number;
   withYears?: boolean;
   /** @default false */
   integrated?: boolean;
   /** @default false */
   inEditMode?: boolean;
   onClick?: (person: any & { dataId: number }) => void;
};

function PersonBox({
   dataId,
   withYears,
   integrated,
   inEditMode,
   onClick,
   ...person
}: PersonBoxProps) {
   const onClickElement = useCallback(
      () => onClick?.({ ...person, dataId }),
      [onClick, person, dataId],
   );

   const bornYear = useMemo(() => {
      const date = new Date(person.bornYear ?? person.bornYear);

      return date.getFullYear();
   }, [person]);

   const deathYear = useMemo(() => {
      const date = new Date(person.deathYear ?? person.deathYear);

      return date.getFullYear();
   }, [person]);

   return (
      <div className={`personBox ${person.gender}`} onClick={onClickElement}>
         <div
            className={`personBoxImageHolder${
               !integrated && withYears && person.deathYear ? " died" : ""
            }`}
         >
            <div className="personBoxImage">
               <img
                  src={
                     person.unknown
                        ? person.gender === "male"
                           ? maleUnknownImage
                           : femaleUnknownImage
                        : person.Base64
                        ? person.Base64
                        : person.photoUrl ?? defaultPersonImage
                  }
                  alt="Person"
               />
            </div>
            <div
               className={`personBoxImageBottomRightIcon${
                  !integrated && !inEditMode && person.pageId ? " shown" : ""
               }`}
            >
               <svg viewBox="0 0 448 512">
                  <path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-32 252c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92H92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z" />
               </svg>
            </div>
         </div>
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
               gap: "8px",
            }}
         >
            <NovaText
               type={NovaTextTypes.BaseNormal}
               style={{
                  display: "block",
                  margin: 0,
                  width: "100%",
                  maxWidth: "160px",
                  textAlign: "center",
                  lineHeight: "18px",
                  minWidth: 0,
               }}
            >
               {person.name}
            </NovaText>
            {withYears && !person.unknown && person.bornYear !== null && (
               <NovaText
                  type={NovaTextTypes.BaseNormal}
                  style={{
                     margin: 0,
                     width: "100%",
                     textAlign: "center",
                     lineHeight: "14px",
                     fontSize: "13px",
                     color: "#00000080",
                  }}
               >
                  {bornYear}
                  {person.deathYear && `- ${deathYear}`}
               </NovaText>
            )}
         </Box>
      </div>
   );
}

export default memo(PersonBox);
