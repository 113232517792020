import { AlertsActions } from "../../components/alerts/actions";
import { LoginActions } from "../Login/actions";
import { getUser } from "../Login/async-actions";

export const changePassword =
   (oldPassword: string, newPassword: string) =>
   (dispatch: any, _: any, { api }: any) => {
      api.usersAPI
         .changePassword(oldPassword, newPassword)
         .catch((error: any) => {
            // const { response } = error;
            // dispatch(LoginActions.loginFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               // dispatch(LoginActions.updateUser(updatedUser));
            }
         });
   };

export const userEdit =
   (fullName: string) =>
   (dispatch: any, _: any, { api }: any) => {
      api.usersAPI
         .userEdit(fullName)
         .catch((error: any) => {})
         .then((res: any) => {
            if (res && res.data) {
               const user: any = JSON.parse(localStorage.getItem("user") as any);
               if (user) {
                  const updatedUser = {
                     ...user,
                     fullName,
                  };
                  localStorage.setItem("user", JSON.stringify(updatedUser));
                  dispatch(LoginActions.updateUser(updatedUser));
               }
            }
         });
   };

export const deleteAccount =
   (navigate: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.usersAPI
         .deleteAccount()
         .catch((error: any) => {
            dispatch(
               AlertsActions.createAlert({
                  type: "error",
                  message: error.response?.data?.title ?? "An error occurred",
               }),
            );
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(LoginActions.logoutSuccess());
               localStorage.removeItem("user");
               return navigate("/");
            }
         });
   };

export const validateVoucher =
   (
      voucher: string,
      setValidationMessage: ({
         status,
         message,
      }: {
         status: string;
         message: string;
      }) => void,
   ) =>
   (dispatch: any, _: any, { api }: any) => {
      api.affiliatesAPI
         .useVoucher(voucher)
         .catch((error: any) => {
            dispatch(
               AlertsActions.createAlert({
                  type: "error",
                  message: error.response?.data?.title ?? "An error occurred",
               }),
            );
         })
         .then((res: any) => {
            if (res && res.data) {
               setValidationMessage({
                  status: "success",
                  message: `Code '${voucher}' was successfully registered!`,
               });
            }
         });
   };
