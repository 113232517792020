import {
   Dispatch,
   Fragment,
   memo,
   SetStateAction,
   useCallback,
   useEffect,
   useMemo,
   useRef,
   useState,
} from "react";
import { Tooltip } from "@mui/material";
import { useSpaceNavigationGestures } from "./hooks";
import { icons } from "./icons";
import ReactFamilyTreeItem from "./tree-item";
import {
   ReactFamilyTreeClickPlusData,
   ReactFamilyTreeColor,
   ReactFamilyTreeItemData,
   ReactFamilyTreeItemId,
} from "./types";

type ReactFamilyTreePairProps = {
   treeData: ReactFamilyTreeItemData[];
   partnersIds: ReactFamilyTreeItemId[];
   verticalGap: number;
   horizontalGap: number;
   linkColor: ReactFamilyTreeColor;
   linkWidth: number;
   backgroundColor: ReactFamilyTreeColor;
   folds: ReactFamilyTreeItemId[];
   setFolds: React.Dispatch<React.SetStateAction<ReactFamilyTreeItemId[]>>;
   linkRef?: React.RefObject<HTMLDivElement>;
   /** @default false */
   haveCollapseExpandButton?: boolean;
   searchKeyword?: string;
   inEditMode?: boolean;
   /** @default true */
   canAddMore?: boolean;
   spaceNavigationGestures: ReturnType<typeof useSpaceNavigationGestures>;
   renderItem: (item: any, dataId: number) => JSX.Element;
   onClickMinus?: (dataId: ReactFamilyTreeItemId) => void;
   onClickPlus?: (data: ReactFamilyTreeClickPlusData) => void;
   onChangeConnectionType?: (
      partnerId: ReactFamilyTreeItemId[],
      divorced: boolean,
   ) => void;
   setRenderedNotes?: Dispatch<SetStateAction<ReactFamilyTreeItemId[]>>;
};

function ReactFamilyTreePair({
   treeData,
   partnersIds,
   verticalGap,
   horizontalGap,
   linkColor,
   linkWidth,
   backgroundColor,
   folds,
   setFolds,
   linkRef,
   haveCollapseExpandButton,
   searchKeyword,
   inEditMode,
   canAddMore = true,
   spaceNavigationGestures,
   renderItem,
   onClickMinus,
   onClickPlus,
   onChangeConnectionType,
   setRenderedNotes,
}: ReactFamilyTreePairProps) {
   const pairItemsRef = useRef<HTMLDivElement>(null);
   const horizontalLinkRef = useRef<HTMLDivElement>(null);
   const horizontalLink2Ref = useRef<HTMLDivElement>(null);
   const pairItemLinkFirstRef = useRef<HTMLDivElement>(null);
   const pairItemLinkFirst2Ref = useRef<HTMLDivElement>(null);
   const pairItemLinkLastRef = useRef<HTMLDivElement>(null);
   const pairItemLinkLast2Ref = useRef<HTMLDivElement>(null);

   const [itemHeight, setItemHeight] = useState<number>(0);
   const [horizontalLinkDimensions, setHorizontalLinkDimensions] = useState<DOMRect>();
   const [horizontalLink2Dimensions, setHorizontalLink2Dimensions] = useState<DOMRect>();
   const [horizontalLinkWidth, setHorizontalLinkWidth] = useState<number>(0);
   const [horizontalLink2Width, setHorizontalLink2Width] = useState<number>(0);
   const [switchLinks, setSwitchLinks] = useState<boolean>(false);
   const [switch2Links, setSwitch2Links] = useState<boolean>(false);
   const [pairItemLinkFirstDimensions, setPairItemLinkFirstDimensions] =
      useState<DOMRect>();
   const [pairItemLinkFirst2Dimensions, setPairItemLinkFirst2Dimensions] =
      useState<DOMRect>();
   const [pairItemLinkLastDimensions, setPairItemLinkLastDimensions] =
      useState<DOMRect>();
   const [pairItemLinkLast2Dimensions, setPairItemLinkLast2Dimensions] =
      useState<DOMRect>();

   const partnersData = useMemo<ReactFamilyTreeItemData[]>(() => {
      const otherPartners = treeData.filter((item) => partnersIds.includes(item.id));
      const thisPartner = treeData.filter((item) =>
         otherPartners.some((partner) => partner.partnersIds?.includes(item.id)),
      );

      if (otherPartners.length === 1) return [...thisPartner, ...otherPartners];
      return [otherPartners[1], ...thisPartner, otherPartners[0]];
   }, [treeData, partnersIds]);
   const childrenData = useMemo<ReactFamilyTreeItemData[]>(() => {
      const parentsIds = partnersData.map((parentData) => parentData?.id);

      return treeData.filter((item) =>
         item.parentsIds?.some((parentId) => parentsIds.includes(parentId)),
      );
   }, [treeData, partnersData]);
   const childrenGroups = useMemo(() => {
      const groups: Record<string, ReactFamilyTreeItemData[]> = {};

      childrenData.forEach((child, index) => {
         if (child.parentsIds) {
            const parentsStringIndex = [...child.parentsIds].sort().join("-");
            (groups[parentsStringIndex] = groups[parentsStringIndex] || []).push(child);
         }
      });

      const readyGroups = Object.values(groups);

      return [
         ...(readyGroups[0]?.[0]?.parentsIds?.includes(partnersData[0].id)
            ? [readyGroups[0], ...(readyGroups[1] ? [readyGroups[1]] : [])]
            : [...(readyGroups[1] ? [readyGroups[1]] : []), readyGroups[0]]),
      ];
   }, [childrenData, partnersData]);
   const isFolded = useMemo<boolean>(
      () => folds.includes(childrenData[0]?.id),
      [folds, childrenData],
   );
   const horizontalLineStyle = useMemo<React.CSSProperties>(
      () => ({
         position: "absolute",
         width: Math.abs(horizontalLinkWidth),
         height: linkWidth,
         top: (verticalGap + linkWidth) / -2,
         left:
            (pairItemLinkFirstDimensions?.x ?? 0) -
            (horizontalLinkDimensions?.x ?? 0) -
            20,
         backgroundColor: linkColor,
      }),
      [
         horizontalLinkWidth,
         linkWidth,
         verticalGap,
         pairItemLinkFirstDimensions,
         horizontalLinkDimensions,
         linkColor,
      ],
   );
   const horizontalLine2Style = useMemo<React.CSSProperties>(
      () => ({
         position: "absolute",
         width: Math.abs(horizontalLink2Width),
         height: linkWidth,
         top: (verticalGap + linkWidth) / -2,
         left:
            (pairItemLinkFirst2Dimensions?.x ?? 0) -
            (horizontalLink2Dimensions?.x ?? 0) -
            20,
         backgroundColor: linkColor,
      }),
      [
         horizontalLink2Width,
         linkWidth,
         verticalGap,
         pairItemLinkFirst2Dimensions,
         horizontalLink2Dimensions,
         linkColor,
      ],
   );
   const linkStyle = useMemo<React.CSSProperties>(
      () => ({
         width: horizontalGap,
         height: linkWidth,
         backgroundColor: linkColor,
      }),
      [horizontalGap, linkWidth, linkColor],
   );
   const linkDownIconStyle = useMemo<React.CSSProperties>(
      () => ({
         width: 20 + linkWidth / 2,
         height: 20 + linkWidth / 2,
         backgroundColor: backgroundColor,
         border: `solid ${Math.max(linkWidth / 2, 3)}px ${backgroundColor}`,
         zIndex: 1,
      }),
      [linkWidth, backgroundColor],
   );
   const linkDownDotStyle = useMemo<React.CSSProperties>(
      () => ({
         backgroundColor: linkColor,
      }),
      [linkColor],
   );
   const linkDownStyle = useMemo<React.CSSProperties>(
      () => ({
         width: linkWidth,
         height: (itemHeight + verticalGap) / 2,
         top: linkWidth,
         backgroundColor: linkColor,
      }),
      [linkWidth, verticalGap, itemHeight, linkColor],
   );
   const linkChildPlusStyle = useMemo<React.CSSProperties>(
      () => ({
         width: linkWidth,
         backgroundColor: linkColor,
      }),
      [linkWidth, linkColor],
   );
   const linkDownButton = useMemo<React.CSSProperties>(
      () => ({
         backgroundColor: linkColor,
      }),
      [linkColor],
   );
   const gapStyle = useMemo<React.CSSProperties>(
      () => ({
         width: horizontalGap,
      }),
      [horizontalGap],
   );
   const pairChildren = useMemo<React.CSSProperties>(
      () => ({
         marginTop: verticalGap + linkWidth,
      }),
      [verticalGap, linkWidth],
   );

   const onClickLinkDownButton = useCallback(() => {
      const id = childrenData[0]?.id;

      if (id !== undefined) {
         const scale = spaceNavigationGestures.scale;
         spaceNavigationGestures.setScale(1);

         setFolds((oldValues) => {
            if (oldValues.includes(id)) return oldValues.filter((value) => value !== id);

            return [...oldValues, id];
         });

         setTimeout(() => {
            spaceNavigationGestures.setScale(scale);
         }, 1);
      }
   }, [childrenData, spaceNavigationGestures]);

   useEffect(() => {
      setItemHeight(pairItemsRef.current?.clientHeight ?? 0);
   }, [pairItemsRef.current?.clientHeight, renderItem]);

   useEffect(() => {
      setPairItemLinkFirstDimensions(
         pairItemLinkFirstRef.current?.getBoundingClientRect(),
      );
      setPairItemLinkLastDimensions(pairItemLinkLastRef.current?.getBoundingClientRect());
   }, [folds, pairItemLinkFirstRef.current, pairItemLinkLastRef.current]);
   useEffect(() => {
      setPairItemLinkFirst2Dimensions(
         pairItemLinkFirst2Ref.current?.getBoundingClientRect(),
      );
      setPairItemLinkLast2Dimensions(
         pairItemLinkLast2Ref.current?.getBoundingClientRect(),
      );
   }, [folds, pairItemLinkFirst2Ref.current, pairItemLinkLast2Ref.current]);

   useEffect(() => {
      const value =
         (pairItemLinkLastDimensions?.x ?? 0) -
         (pairItemLinkFirstDimensions?.x ?? 0) +
         linkWidth;
      setHorizontalLinkWidth(value);
      if (value < 0) setSwitchLinks(true);
   }, [folds, pairItemLinkLastDimensions, pairItemLinkFirstDimensions, linkWidth]);
   useEffect(() => {
      const value =
         (pairItemLinkLast2Dimensions?.x ?? 0) -
         (pairItemLinkFirst2Dimensions?.x ?? 0) +
         linkWidth;
      setHorizontalLink2Width(value);
      if (value < 0) setSwitch2Links(true);
   }, [folds, pairItemLinkLast2Dimensions, pairItemLinkFirst2Dimensions, linkWidth]);

   useEffect(() => {
      setHorizontalLinkDimensions(horizontalLinkRef.current?.getBoundingClientRect());
   }, [folds, pairItemLinkFirstDimensions, horizontalLinkRef.current]);
   useEffect(() => {
      setHorizontalLink2Dimensions(horizontalLink2Ref.current?.getBoundingClientRect());
   }, [folds, pairItemLinkFirst2Dimensions, horizontalLink2Ref.current]);

   return (
      <div
         className="pair"
         key={
            JSON.stringify(partnersData) + JSON.stringify(childrenData) + folds.join("")
         }
      >
         <div className="pairItems" ref={pairItemsRef}>
            {partnersData.map((item, index) => {
               const partnersHaveParents = partnersData.some(
                  (partnerData) => partnerData?.parentsIds,
               );
               const haveChildren = childrenData.some(
                  (children) =>
                     children.parentsIds?.includes(partnersData[index]?.id) &&
                     children.parentsIds?.includes(partnersData[index + 1]?.id),
               );

               const divorced =
                  partnersData[index]?.childLinkIcon === "heartBroken" ||
                  partnersData[index + 1]?.childLinkIcon === "heartBroken";

               return (
                  <Fragment key={item.id}>
                     <ReactFamilyTreeItem
                        dataId={item.id}
                        item={item}
                        linkColor={linkColor}
                        linkWidth={linkWidth}
                        verticalGap={verticalGap}
                        withLink={(item.parentsIds?.length ?? 0) > 0}
                        linkRef={linkRef}
                        searchKeyword={searchKeyword}
                        inEditMode={inEditMode}
                        withLeftPartnerPlus={
                           divorced &&
                           canAddMore &&
                           index === 0 &&
                           partnersData.length < 3 &&
                           ((item.parentsIds?.length ?? 0) > 0 ||
                              !partnersData.some((partner) => partner.parentsIds))
                        }
                        withRightPartnerPlus={
                           divorced &&
                           canAddMore &&
                           index === 1 &&
                           partnersData.length < 3 &&
                           ((item.parentsIds?.length ?? 0) > 0 ||
                              !partnersData.some((partner) => partner.parentsIds))
                        }
                        withParentPlus={
                           inEditMode &&
                           canAddMore &&
                           !item.parentsIds &&
                           !partnersHaveParents &&
                           (childrenData.length === 0 ||
                              childrenData.some((children) =>
                                 children.parentsIds?.includes(item.id),
                              ))
                        }
                        renderItem={renderItem}
                        onClickMinus={onClickMinus}
                        onClickPlus={onClickPlus}
                        setRenderedNotes={setRenderedNotes}
                     />
                     {partnersData.length - 1 > index && (
                        <div className="link" style={linkStyle}>
                           {(partnersData[index]?.childLinkIcon ||
                              partnersData[index + 1]?.childLinkIcon) && (
                              <svg style={linkDownIconStyle}>
                                 {partnersData[index]?.childLinkIcon === "heartBroken"
                                    ? icons["heartBroken"](
                                         partnersData[index]?.childLinkIconColor,
                                      )
                                    : partnersData[index + 1]?.childLinkIcon ===
                                      "heartBroken"
                                    ? icons["heartBroken"](
                                         partnersData[index + 1]?.childLinkIconColor,
                                      )
                                    : partnersData[index]?.childLinkIcon === "heart"
                                    ? icons["heart"](
                                         partnersData[index]?.childLinkIconColor,
                                      )
                                    : partnersData[index + 1]?.childLinkIcon === "heart"
                                    ? icons["heart"](
                                         partnersData[index + 1]?.childLinkIconColor,
                                      )
                                    : item.childLinkIcon
                                    ? icons[item.childLinkIcon](item.childLinkIconColor)
                                    : undefined}
                              </svg>
                           )}

                           {haveChildren && (
                              <>
                                 <span className="linkDownText">
                                    {
                                       childrenGroups[
                                          childrenGroups.length === 1 ? 0 : index
                                       ]?.length
                                    }{" "}
                                    {childrenGroups[
                                       childrenGroups.length === 1 ? 0 : index
                                    ]?.length === 1
                                       ? "child"
                                       : "children"}
                                 </span>

                                 {!item.childLinkIcon && (
                                    <div
                                       className="linkDownDot"
                                       style={linkDownDotStyle}
                                    />
                                 )}
                                 {haveCollapseExpandButton && (
                                    <div
                                       className={`linkDownButton${
                                          !isFolded ? " opened" : ""
                                       }${inEditMode ? " inEditMode" : ""}`}
                                       style={linkDownButton}
                                       onClick={onClickLinkDownButton}
                                    >
                                       <svg viewBox="0 0 448 512" fill="#f8f8f8">
                                          <path d="M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0L224 284.5l154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.4-24.6 9.4-33.9 0z" />
                                       </svg>
                                    </div>
                                 )}
                                 {!isFolded && (
                                    <div
                                       className="linkDown"
                                       style={linkDownStyle}
                                       ref={
                                          index === 0
                                             ? childrenGroups[0]?.length === 1
                                                ? switchLinks
                                                   ? pairItemLinkFirstRef
                                                   : pairItemLinkLastRef
                                                : undefined
                                             : childrenGroups[1]?.length === 1
                                             ? switch2Links
                                                ? pairItemLinkFirst2Ref
                                                : pairItemLinkLast2Ref
                                             : undefined
                                       }
                                    />
                                 )}
                              </>
                           )}

                           <div
                              className={`linkChildPlus${
                                 inEditMode && canAddMore ? " shown" : ""
                              }${item?.childLinkIcon ? " withIcon" : ""}`}
                              style={linkChildPlusStyle}
                           >
                              <Tooltip title="Add child">
                                 <div
                                    className="itemPlusMinusIcon"
                                    onClick={() => {
                                       onClickPlus?.({
                                          type: "child",
                                          parentIds: [
                                             partnersData[index].id,
                                             partnersData[index + 1].id,
                                          ],
                                       });
                                    }}
                                 >
                                    <svg viewBox="0 0 448 512">
                                       <path d="M416 208H272V64c0-17.7-14.3-32-32-32h-32c-17.7 0-32 14.3-32 32v144H32c-17.7 0-32 14.3-32 32v32c0 17.7 14.3 32 32 32h144v144c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V304h144c17.7 0 32-14.3 32-32v-32c0-17.7-14.3-32-32-32z" />
                                    </svg>
                                 </div>
                              </Tooltip>
                           </div>

                           <div
                              className={`linkChangeConnection${
                                 inEditMode ? " shown" : ""
                              }${item?.childLinkIcon ? " withIcon" : ""}`}
                              style={linkChildPlusStyle}
                           >
                              <Tooltip title={divorced ? "Marriage" : "Divorce"}>
                                 <div
                                    className="itemPlusMinusIcon"
                                    onClick={() => {
                                       onChangeConnectionType?.(
                                          [
                                             partnersData[index]?.id,
                                             partnersData[index + 1]?.id,
                                          ],
                                          divorced,
                                       );
                                    }}
                                 >
                                    {icons[divorced ? "heart" : "heartBroken"]("white")}
                                 </div>
                              </Tooltip>
                           </div>
                        </div>
                     )}
                  </Fragment>
               );
            })}
         </div>
         {childrenData.length > 0 && !isFolded && (
            <div
               style={{
                  display: "flex",
                  gap: "40px",
               }}
            >
               {childrenGroups.map((thisChildrenData, childrenGroupIndex) => (
                  <div
                     className="pairChildren"
                     style={pairChildren}
                     key={childrenGroupIndex}
                  >
                     {childrenGroupIndex === 0 ? (
                        <div style={horizontalLineStyle} ref={horizontalLinkRef} />
                     ) : (
                        <div style={horizontalLine2Style} ref={horizontalLink2Ref} />
                     )}

                     {thisChildrenData.map((item, index) => {
                        const linkRef =
                           childrenGroupIndex === 0
                              ? index === 0
                                 ? switchLinks
                                    ? pairItemLinkLastRef
                                    : pairItemLinkFirstRef
                                 : index === thisChildrenData.length - 1
                                 ? pairItemLinkLastRef
                                 : undefined
                              : index === 0
                              ? switch2Links
                                 ? pairItemLinkLast2Ref
                                 : pairItemLinkFirst2Ref
                              : index === thisChildrenData.length - 1
                              ? pairItemLinkLast2Ref
                              : undefined;

                        return (
                           <Fragment key={item.id}>
                              {item.partnersIds?.length ? (
                                 <ReactFamilyTreePair
                                    treeData={treeData}
                                    partnersIds={item?.partnersIds}
                                    verticalGap={verticalGap}
                                    horizontalGap={horizontalGap}
                                    linkColor={linkColor}
                                    linkWidth={linkWidth}
                                    backgroundColor={backgroundColor}
                                    folds={folds}
                                    setFolds={setFolds}
                                    renderItem={renderItem}
                                    onClickMinus={onClickMinus}
                                    onClickPlus={onClickPlus}
                                    onChangeConnectionType={onChangeConnectionType}
                                    haveCollapseExpandButton={haveCollapseExpandButton}
                                    searchKeyword={searchKeyword}
                                    spaceNavigationGestures={spaceNavigationGestures}
                                    inEditMode={inEditMode}
                                    canAddMore={canAddMore}
                                    linkRef={linkRef}
                                    setRenderedNotes={setRenderedNotes}
                                 />
                              ) : (
                                 <ReactFamilyTreeItem
                                    dataId={item.id}
                                    item={item}
                                    linkColor={linkColor}
                                    linkWidth={linkWidth}
                                    verticalGap={verticalGap}
                                    withLink={(item?.parentsIds?.length ?? 0) > 0}
                                    linkRef={linkRef}
                                    searchKeyword={searchKeyword}
                                    inEditMode={inEditMode}
                                    withRightPartnerPlus={inEditMode && canAddMore}
                                    renderItem={renderItem}
                                    onClickMinus={onClickMinus}
                                    onClickPlus={onClickPlus}
                                    setRenderedNotes={setRenderedNotes}
                                 />
                              )}
                              {thisChildrenData.length - 1 > index && (
                                 <div className="gap" style={gapStyle} />
                              )}
                           </Fragment>
                        );
                     })}
                  </div>
               ))}
            </div>
         )}
      </div>
   );
}

export default memo(ReactFamilyTreePair) as typeof ReactFamilyTreePair;
