const ACTION_TYPE = "PRODUCTS";
export const GET_PRODUCTS = `${ACTION_TYPE}/GET_PRODUCTS`;
export const GET_PRODUCTS_FAIL = `${ACTION_TYPE}/GET_PRODUCTS_FAIL`;
export const GET_SINGLE_PRODUCT_FAIL = `${ACTION_TYPE}/GET_SINGLE_PRODUCT_FAIL`;
export const GET_SINGLE_PRODUCT_SUCCESS = `${ACTION_TYPE}/GET_SINGLE_PRODUCT_SUCCESS`;
export const GET_SINGLE_QR_FAIL = `${ACTION_TYPE}/GET_SINGLE_QR_FAIL`;
export const GET_SINGLE_QR_SUCCESS = `${ACTION_TYPE}/GET_SINGLE_QR_SUCCESS`;

export const DELETE_PRODUCT_FAIL = `${ACTION_TYPE}/DELETE_PRODUCT_FAIL`;
export const DELETE_PRODUCT_SUCCESS = `${ACTION_TYPE}/DELETE_PRODUCT_SUCCESS`;

export const SET_PRODUCT_AVAILABILITY_FAIL = `${ACTION_TYPE}/SET_PRODUCT_AVAILABILITY_FAIL`;
export const SET_PRODUCT_AVAILABILITY_SUCCESS = `${ACTION_TYPE}/SET_PRODUCT_AVAILABILITY_SUCCESS`;
