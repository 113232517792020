export const rows: Array<any> = [
   {
      id: 1,
      status: 0,
      address: "123 Main St",
      city: "Example City",
      postalCode: "12345",
      country: "BG",
      phoneNumber: "+359123",
      deliveryPrice: 0,
      currency: "BGN",
      customerName: "aleksa",
      amount: 299.94,
      orderItemsCount: 2,
   },
];

export const columns: readonly any[] = [
   {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
   },
   {
      id: "address",
      numeric: false,
      disablePadding: false,
      label: "Address",
   },
   {
      id: "city",
      numeric: false,
      disablePadding: false,
      label: "City",
   },
   {
      id: "phoneNumber",
      numeric: false,
      disablePadding: false,
      label: "Phone",
   },
   {
      id: "amount",
      numeric: false,
      disablePadding: false,
      label: "Amount",
   },
   {
      id: "orderItemsCount",
      numeric: false,
      disablePadding: false,
      label: "Items",
   },
   {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "",
   },
];
