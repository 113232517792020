export const limitations: any = {
   free: {
      // Free
      briefTributeLength: 250,
      biographyLength: 500,
      biographyFilesLength: 1,
      photosLength: 4,
      treeNodesLength: 5,
      memoriesLength: 1,
      memoryDescriptionLength: 250,
   },
   0: {
      // Month
      briefTributeLength: 250,
      memoryDescriptionLength: 500,
   },
   1: {
      // Year
      briefTributeLength: 250,
      memoryDescriptionLength: 500,
   },
   2: {
      // Lifetime
      briefTributeLength: 250,
      memoryDescriptionLength: 500,
   },
};
