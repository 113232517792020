import { useState } from "react";
import { Grid } from "@mui/material";
import MemorialCard from "./MemorialCard";
import CreateMemorialCard from "./CreateMemorialCard";
import CreateMPModal from "./create-mp-modal";

export enum CardType {
   PUBLISHED,
   DRAFT,
}

const MemorialCardContainer = ({
   type,
   items,
   onUpgrade,
}: {
   type: any;
   items: Array<any>;
   onUpgrade: (pageId: number) => void;
}) => {
   const [openCreateModal, setOpenCreateModal] = useState(false);
   const handleOpenCreateModal = () => {
      setOpenCreateModal(true);
   };

   const handleCloseCreateModal = () => {
      setOpenCreateModal(false);
   };

   return (
      <Grid container spacing={3}>
         {type === CardType.PUBLISHED && (
            <Grid item xs={12} md={6} lg={4}>
               <CreateMemorialCard onClick={handleOpenCreateModal} />
            </Grid>
         )}
         {items &&
            items.length > 0 &&
            items.map((item: any, index: number) => {
               return (
                  <Grid item xs={12} md={6} lg={4} key={index}>
                     <MemorialCard type={type} item={item} onUpgrade={onUpgrade} />
                  </Grid>
               );
            })}

         {openCreateModal && <CreateMPModal handleClose={handleCloseCreateModal} />}
      </Grid>
   );
};

export default MemorialCardContainer;
