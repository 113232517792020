import { memo } from "react";
import { Box, IconButton } from "@mui/material";
import { Delete, Download } from "@mui/icons-material";

import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";

type FilesListProps = {
   files?: any[];
   handleRemoveFile?: (fileId: number) => void;
};

function FilesList({ files, handleRemoveFile }: FilesListProps) {
   const handleDownloadFile = (file: any) => {
      if (!file || !file.url) {
         console.error("File URL is missing.");
         return;
      }

      const link = document.createElement("a");
      link.href = file.url;

      link.download = file.name;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
   };

   return (
      <Box
         sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "6px",
            marginTop: "16px",
         }}
      >
         {files?.map((file: any, index: number) => {
            return (
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     borderRadius: "32px",
                     padding: "4px 12px",
                     background: "#1B1B48",
                     color: "#CAA45D",
                     svg: { fill: "#CAA45D" },
                     gap: "8px",
                  }}
                  key={index}
               >
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        margin: 0,
                     }}
                  >{`${file.name} ${file.id > 1 ? file.id : ""}`}</NovaText>

                  <Box>
                     {file.url && (
                        <IconButton
                           sx={{
                              padding: "4px",
                           }}
                           onClick={() => handleDownloadFile(file)}
                        >
                           <Download
                              sx={{
                                 fontSize: "16px",
                              }}
                           />
                        </IconButton>
                     )}
                     {handleRemoveFile && (
                        <IconButton
                           sx={{
                              padding: "4px",
                           }}
                           onClick={() => handleRemoveFile?.(file.id)}
                        >
                           <Delete
                              sx={{
                                 fontSize: "16px",
                              }}
                           />
                        </IconButton>
                     )}
                  </Box>
               </Box>
            );
         })}
      </Box>
   );
}

export default memo(FilesList);
