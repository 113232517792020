import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@mui/icons-material";
import { Avatar, Box, Container, Grid, IconButton, InputAdornment } from "@mui/material";
import { saveProfilePhoto } from "./async-actions";
import { useAppDispatch } from "../../store/hooks";
import { LoginSelectors } from "../Login/selectors";
import { ProfileImageChange } from "../../components/shared/icons/profile-image-change";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import StatsCard from "../../components/home/affiliate/stats-card";
import AffiliateTable from "../../components/home/affiliate/affiliate-table";
import { affiliateColumns } from "../../components/home/affiliate/table-columns";
import { getVouchers } from "../vouchers/async-actions";
import { AffiliatesSelectors } from "../vouchers/selectors";
import NovaTextInput from "../../components/shared/NovaTextInput";
import TranslatedText from "../../components/translated-text";

const HomeAffiliate = () => {
   const dispatch = useAppDispatch();
   const { t } = useTranslation();
   const vouchers = useSelector(AffiliatesSelectors.getVouchers);
   const user = useSelector(LoginSelectors.getUser);
   const unusedVouchers = useSelector(AffiliatesSelectors.getUnusedVouchers);
   const obligations = useSelector(AffiliatesSelectors.getObligations);
   const [searchValue, setSearchValue] = useState<string>("");
   const [filteredVouchers, setFilteredVouchers] = useState(vouchers || []);
   const [requestModalOpened, setRequestModalOpened] = useState(false);

   const handleProfileImageSave = (profilePhotoBase64: string | ArrayBuffer | null) => {
      dispatch(saveProfilePhoto(profilePhotoBase64));
   };

   const handleRequestModalOpen = () => {
      setRequestModalOpened(true);
   };

   const handleRequestModalClose = () => {
      setRequestModalOpened(false);
   };

   useEffect(() => {
      dispatch(getVouchers());
   }, []);

   const handleSearch = (event: any) => {
      setSearchValue(event.target.value);
   };

   useEffect(() => {
      if (searchValue.length > 0) {
         setFilteredVouchers(vouchers.filter((v: any) => v?.code.includes(searchValue)));
      } else {
         setFilteredVouchers(vouchers);
      }
   }, [searchValue, vouchers]);

   if (!filteredVouchers) return null;

   return (
      <Box sx={{ paddingBottom: "80px" }}>
         <Box
            sx={{
               background: "#fff",
               height: "136px",
            }}
         />
         <Container>
            <Dropzone
               accept={{
                  "image/png": [".png"],
                  "image/jpeg": [".jpg"],
               }}
               onDrop={(acceptedFiles) => {
                  if (acceptedFiles.length > 0) {
                     const reader = new FileReader();
                     reader.onload = (e) => {
                        const imageDataUrl = reader.result;
                        handleProfileImageSave(imageDataUrl);
                     };
                     reader.readAsDataURL(acceptedFiles[0]);
                  }
               }}
            >
               {({ getRootProps, getInputProps }) => (
                  <Box
                     sx={{
                        width: "153px",
                        height: "153px",
                        borderRadius: "100%",
                        border: "3px solid #fff",
                        marginTop: "-76px",
                        position: "relative",
                     }}
                  >
                     <Avatar src={user?.photoUrl} sx={{ width: "100%", height: "100%" }}>
                        {user?.fullName?.[0]}
                     </Avatar>
                     <div
                        {...getRootProps()}
                        style={{
                           height: "100%",
                        }}
                     >
                        <input {...getInputProps()} />

                        <IconButton
                           sx={{
                              position: "absolute",
                              bottom: 0,
                              right: 0,
                              width: "32px",
                              height: "32px",
                              border: "3px solid #fff",
                              background: "#1B1B48",
                              ":hover": {
                                 backgroundColor: "#CAA45D",
                              },
                              ":hover svg path": {
                                 fill: "#1B1B48",
                              },
                           }}
                        >
                           <ProfileImageChange />
                        </IconButton>
                     </div>
                  </Box>
               )}
            </Dropzone>
            <Grid container>
               <Grid item xs={2}>
                  <NovaText
                     type={NovaTextTypes.XLNormal}
                     style={{
                        fontFamily: "Cormorant Infant, serif",
                        fontWeight: "bold",
                        margin: "16px 0 0 0",
                     }}
                  >
                     {user?.fullname}
                  </NovaText>
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        fontFamily: "Cormorant Infant, serif",
                        fontWeight: "bold",
                        margin: "0 0 0 0",
                     }}
                  >
                     {user?.email}
                  </NovaText>
               </Grid>
               <Grid item xs={10}>
                  <Box
                     sx={{
                        background: "#fff",
                        borderRadius: "16px",
                        padding: "40px 0",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                     }}
                  >
                     <StatsCard
                        title={t("affiliates.total-plates")}
                        number={vouchers?.length}
                        styles={{ flex: 1 }}
                     />
                     <StatsCard
                        title={t("affiliates.unused-plates")}
                        number={unusedVouchers?.length}
                        styles={{ flex: 1 }}
                     />
                     <StatsCard
                        title={t("affiliates.obligation")}
                        number={obligations}
                        styles={{ flex: 1 }}
                     />
                  </Box>
                  <Box
                     sx={{
                        marginTop: "24px",
                     }}
                  >
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "space-between",
                        }}
                     >
                        <NovaText type={NovaTextTypes.LGBold}>
                           <TranslatedText text="affiliates.qr-plates.title" />
                        </NovaText>
                        <NovaTextInput
                           placeholder={t(
                              "create-memorial-page.family-tree.search-placeholder",
                           )}
                           value={searchValue}
                           InputProps={{
                              startAdornment: (
                                 <InputAdornment position="start">
                                    <SearchOutlined />
                                 </InputAdornment>
                              ),
                           }}
                           onChange={handleSearch}
                        />
                        {/* <NovaButton
                           style={{
                              background: "#CAA45D",
                              color: "#fff",
                              padding: "8px 16px",
                              textDecoration: "none",
                              borderRadius: "32px",
                           }}
                           onClick={handleRequestModalOpen}
                        >
                           Request QR plates
                        </NovaButton> */}
                     </Box>
                     <AffiliateTable columns={affiliateColumns} rows={filteredVouchers} />
                  </Box>
                  {/* <Box sx={{ marginTop: "24px" }}>
                     <NovaText type={NovaTextTypes.LGBold}>Requests history</NovaText>
                     <RequestsTable
                        columns={requestsColumns}
                        rows={[
                           {
                              id: 0,
                              amount: 70,
                              address: "ul. Pliska 2",
                              status: "sent",
                              createdOn: "01/03/2023",
                           },
                           {
                              id: 2,
                              medallionId: "MED001 (6х)",
                              clientName: "Ivan Petrov",
                              status: "used",
                              date: "01/03/2023",
                           },
                        ]}
                     />
                  </Box>
                  <Box sx={{ marginTop: "24px" }}>
                     <NovaText type={NovaTextTypes.LGBold}>Requests history</NovaText>
                     <RequestsTable
                        columns={requestsColumns}
                        rows={[
                           {
                              id: 0,
                              amount: 70,
                              address: "ul. Pliska 2",
                              status: "sent",
                              createdOn: "01/03/2023",
                           },
                        ]}
                     />
                  </Box> */}
               </Grid>
            </Grid>
         </Container>
         {/* {requestModalOpened && (
            <RequestAffiliatesModal onClose={handleRequestModalClose} />
         )} */}
      </Box>
   );
};

export default HomeAffiliate;
