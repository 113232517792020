import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IosShareIcon from "@mui/icons-material/IosShare";
import { CardType } from "./MemorialCardContainer";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import memorialHeaderImage from "../../assets/memorial-header-image.jpg";
import { OrderedMedallion } from "../shared/icons/ordered-medallion";
import { NotOrderedMedallion } from "../shared/icons/not-ordered-medallion";
import { useAppDispatch } from "../../store/hooks";
import { deleteMemorialPages } from "../../pages/home/async-actions";
import NovaButton from "../shared/NovaButton";
import LoaderOverlay from "../LoaderOverlay";
import TranslatedText from "../translated-text";
import { changeVisibilityMemorialPage } from "../../pages/memorial-page/async-actions";
import ConfirmationModal from "../ConfirmationModal";

const ITEM_HEIGHT = 48;

const MemorialCard = ({ type, item, onUpgrade }: any) => {
   const { t } = useTranslation();
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const { subscription } = item;
   const [showUpgrade, setShowUpgrad] = useState(true);
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [isDeleting, setIsDeleting] = useState<boolean>(false);

   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleEdit = () => {
      navigate(`../memorial-page/edit/${item.id}`);
   };
   const handleChangeVisibility = () => {
      dispatch(changeVisibilityMemorialPage(item.id, !item.isPublic));
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleDelete = () => {
      setIsLoading(true);
      dispatch(deleteMemorialPages(item.id));
      handleClose();
   };

   useEffect(() => {
      if (!subscription) {
         return setShowUpgrad(true);
      }

      if (subscription && subscription.subscriptionPlan.type !== 2) {
         return setShowUpgrad(true);
      }

      return setShowUpgrad(false);
   }, [subscription]);

   return (
      <LoaderOverlay isLoading={isLoading} loadingText="Deleting...">
         <Box
            sx={{
               borderTopLeftRadius: "16px",
               borderTopRightRadius: "16px",
               overflow: "hidden",
            }}
         >
            {/* {type === CardType.PUBLISHED ? "published" : "draft"} */}
            <Box
               sx={{
                  position: "relative",
               }}
            >
               <Link
                  to={`../memorial-page/${item.id}`}
                  style={{
                     textDecoration: "none",
                     color: "#151623",
                  }}
               >
                  <Box
                     sx={{
                        height: "175px",
                        background: `url(${item.profilePhotoUrl || memorialHeaderImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                     }}
                  />
               </Link>

               <Box
                  sx={{
                     display: "flex",
                     position: "absolute",
                     top: 8,
                     left: 8,
                     width: "30px",
                     height: "30px",
                     borderRadius: "999px",
                     backgroundColor: "#F3F5F7",
                     alignItems: "center",
                     justifyContent: "center",
                     cursor: "pointer",
                  }}
               >
                  <Tooltip
                     title={
                        <TranslatedText
                           text="home.memorial-page-card.change-visibility"
                           props={{
                              isPublic: item.isPublic
                                 ? t("home.memorial-page-card.visibility.public")
                                 : t("home.memorial-page-card.visibility.private"),
                           }}
                        />
                     }
                     arrow
                  >
                     <IconButton onClick={handleChangeVisibility}>
                        {item.isPublic ? (
                           <LockOpenIcon
                              sx={{
                                 width: "20px",
                              }}
                           />
                        ) : (
                           <LockIcon
                              sx={{
                                 width: "20px",
                              }}
                           />
                        )}
                     </IconButton>
                  </Tooltip>
               </Box>
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     gap: "8px",
                     position: "absolute",
                     top: "10px",
                     right: "10px",
                  }}
               >
                  {!item?.isMine && (
                     <Box
                        sx={{
                           background: "#1B1B48",
                           color: "#CAA45D",
                           padding: "0 8px",
                           borderRadius: "32px",
                        }}
                     >
                        <NovaText
                           type={NovaTextTypes.BaseNormal}
                           style={{ margin: 0, fontSize: "12px" }}
                        >
                           <TranslatedText text="home.memorial-page-card.page-status.inherited" />
                        </NovaText>
                     </Box>
                  )}
                  {/* TODO: uncomment once we have indicator for page created from QR */}
                  {/* {item?.subscription &&
                     !item?.subscription?.subscriptionPlan?.stripeId && (
                        <Box
                           sx={{
                              background: "#1B1B48",
                              color: "#CAA45D",
                              padding: "0 8px",
                              borderRadius: "32px",
                           }}
                        >
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ margin: 0, fontSize: "12px" }}
                           >
                              <TranslatedText text="home.memorial-page-card.page-status.registered-qr" />
                           </NovaText>
                        </Box>
                     )} */}
               </Box>
            </Box>
            <Box
               sx={{
                  padding: "18px 16px 10px 16px",
                  background: "#F3F5F7",
                  borderBottomLeftRadius: "16px",
                  borderBottomRightRadius: "16px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "baseline",
               }}
            >
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "baseline",
                     justifyContent: "space-between",
                     width: "100%",
                  }}
               >
                  {item.fullName ? (
                     <NovaText
                        type={NovaTextTypes.XLNormal}
                        style={{
                           margin: 0,
                           fontFamily: "Cormorant Infant, serif",
                           fontWeight: "bold",
                           flex: 1,
                           whiteSpace: "nowrap",
                           overflow: "hidden",
                           textOverflow: "ellipsis",
                           paddingRight: "4px",
                        }}
                     >
                        <Link
                           to={`../memorial-page/${item.id}`}
                           style={{
                              textDecoration: "none",
                              color: "#151623",
                           }}
                        >
                           {item.fullName}
                        </Link>
                     </NovaText>
                  ) : (
                     <NovaText
                        type={NovaTextTypes.XLNormal}
                        style={{
                           margin: "0 0 0 0",
                           fontStyle: "italic",
                           flex: 1,
                           whiteSpace: "nowrap",
                           overflow: "hidden",
                           textOverflow: "ellipsis",
                           paddingRight: "4px",
                        }}
                     >
                        <TranslatedText text={"messages.qr-memorial-card.no-name"} />
                     </NovaText>
                  )}
                  <Box
                     sx={{
                        flex: "0 0 auto",
                     }}
                  >
                     {item.hasMedallions ? (
                        <Tooltip
                           title={
                              <TranslatedText text="home.memorial-page-card.purchased-plate-tooltip" />
                           }
                           arrow
                        >
                           <IconButton
                              sx={{
                                 cursor: "default",
                                 ":hover": {
                                    backgroundColor: "transparent",
                                 },
                              }}
                              disableRipple
                           >
                              <OrderedMedallion />
                           </IconButton>
                        </Tooltip>
                     ) : (
                        <Tooltip
                           title={
                              <TranslatedText text="home.memorial-page-card.not-purchased-plate-tooltip" />
                           }
                           arrow
                        >
                           <IconButton
                              sx={{
                                 cursor: "default",
                                 ":hover": {
                                    backgroundColor: "transparent",
                                 },
                              }}
                              disableRipple
                           >
                              <NotOrderedMedallion />
                           </IconButton>
                        </Tooltip>
                     )}
                  </Box>
               </Box>
               {item.birthDay || item.deathDay ? (
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        margin: "0 0 0 0",
                     }}
                  >
                     {dayjs(item.birthDay).format("YYYY")}
                     &nbsp;
                     {item.deathDay && `- ${dayjs(item.deathDay).format("YYYY")}`}
                  </NovaText>
               ) : (
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        margin: "0 0 0 0",
                        fontStyle: "italic",
                     }}
                  >
                     <TranslatedText text={"messages.qr-memorial-card.no-date"} />
                  </NovaText>
               )}

               <NovaText
                  type={NovaTextTypes.MediumNormal}
                  style={{
                     margin: "8px 0",
                     color: "#1B1B48",
                     borderRadius: "32px",
                     fontSize: "14px",
                     fontWeight: "700",
                  }}
               >
                  {subscription && subscription?.subscriptionPlan?.name && (
                     <TranslatedText
                        text={`plans.${
                           ["free", "1month", "1year", "lifetime"].includes(
                              subscription?.subscriptionPlan?.name
                                 .toLowerCase()
                                 .replace(/\s/g, ""),
                           )
                              ? subscription?.subscriptionPlan?.name
                                   .toLowerCase()
                                   .replace(/\s/g, "")
                              : "affiliate"
                        }`}
                     />
                  )}
                  {!subscription && <TranslatedText text={`plans.free`} />}
               </NovaText>
               <div
                  style={{
                     display: "flex",
                     alignItems: "center",
                     width: "100%",
                  }}
               >
                  <div
                     style={{
                        height: "2px",
                        width: "30px",
                        background: "#CAA45D",
                        display: "inline-block",
                        flex: "0 0 auto",
                     }}
                  />
                  {item.city ? (
                     <NovaText
                        type={NovaTextTypes.LGBold}
                        style={{
                           flex: 1,
                           margin: "0 0 0 10px",
                           color: "#CAA45D",
                           whiteSpace: "nowrap",
                           overflow: "hidden",
                           textOverflow: "ellipsis",
                        }}
                     >
                        {item.city}
                     </NovaText>
                  ) : (
                     <NovaText
                        type={NovaTextTypes.BaseNormal}
                        style={{
                           margin: "0 0 0 10px",
                           fontStyle: "italic",
                           color: "#CAA45D",
                        }}
                     >
                        <TranslatedText text={"messages.qr-memorial-card.no-city"} />
                     </NovaText>
                  )}
               </div>
            </Box>
            <Box
               sx={{
                  display: "flex",
                  marginTop: "8px",
               }}
            >
               {showUpgrade && (
                  <NovaButton
                     style={{
                        padding: "2px 16px",
                        borderRadius: "24px",
                        fontSize: "13px",
                     }}
                     onClick={() => onUpgrade(item.id)}
                  >
                     <TranslatedText text="buttons.upgrade-plan" />
                  </NovaButton>
               )}
               <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                     aria-label="more"
                     id="long-button"
                     aria-controls={open ? "long-menu" : undefined}
                     aria-expanded={open ? "true" : undefined}
                     aria-haspopup="true"
                     sx={{
                        borderRadius: "32px",
                        border: "1px solid #CAA45D",
                        height: "32px",
                     }}
                     onClick={handleClick}
                  >
                     <MoreHorizIcon />
                  </IconButton>
               </Box>
               <Menu
                  id="long-menu"
                  MenuListProps={{
                     "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                     style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "200px",
                        padding: "0 16px",
                        borderRadius: "12px",
                     },
                  }}
               >
                  {type === CardType.DRAFT && <Divider />}
                  {type === CardType.DRAFT && (
                     <MenuItem onClick={handleClose} disableRipple>
                        <VisibilityIcon sx={{ marginRight: "8px" }} fontSize="small" />
                        Preview
                     </MenuItem>
                  )}
                  <MenuItem onClick={handleEdit} disableRipple>
                     <EditIcon sx={{ marginRight: "8px" }} fontSize="small" />
                     <TranslatedText text="buttons.edit" />
                  </MenuItem>
                  {item?.isMine && <Divider />}
                  {item?.isMine && (
                     <MenuItem onClick={() => setIsDeleting(true)} disableRipple>
                        <DeleteOutlineIcon sx={{ marginRight: "8px" }} fontSize="small" />
                        <TranslatedText text="buttons.delete" />
                     </MenuItem>
                  )}
                  {type === CardType.DRAFT && <Divider />}
                  {type === CardType.DRAFT && (
                     <MenuItem onClick={handleClose} disableRipple>
                        <IosShareIcon sx={{ marginRight: "8px" }} fontSize="small" />
                        Publish
                     </MenuItem>
                  )}
               </Menu>
            </Box>
         </Box>

         <ConfirmationModal
            isOpened={isDeleting}
            onCancelCallback={() => setIsDeleting(false)}
            onConfirmCallback={() => {
               handleDelete();
               setIsDeleting(false);
            }}
         />
      </LoaderOverlay>
   );
};

export default MemorialCard;
