export const NovaTextTypes = {
   BaseNormal: "base_normal",
   MediumLight: "medium_light",
   MediumNormal: "medium_normal",
   LGBold: "lg_bold",
   XLNormal: "xl_normal",
};

const NovaText = ({ type, style, children, renderHTML }: any) => {
   const getStyle = () => {
      if (type === NovaTextTypes.BaseNormal) {
         return {
            fontFamily: "Montserrat, sans-serif",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "27px",
            ...style,
         };
      }
      if (type === NovaTextTypes.MediumLight) {
         return {
            fontFamily: "Montserrat, sans-serif",
            fontSize: "16px",
            fontWeight: 300,
            lineHeight: "27px",
            ...style,
         };
      }

      if (type === NovaTextTypes.MediumNormal) {
         return {
            fontFamily: "Montserrat, sans-serif",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "27px",
            ...style,
         };
      }
      if (type === NovaTextTypes.LGBold) {
         return {
            fontFamily: "Montserrat, sans-serif",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "27px",
            ...style,
         };
      }
      if (type === NovaTextTypes.XLNormal) {
         return {
            fontFamily: "Montserrat, sans-serif",
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "30px",
            ...style,
         };
      }
   };
   if (renderHTML) {
      return (
         <p style={{ ...getStyle() }} dangerouslySetInnerHTML={{ __html: children }} />
      );
   }
   return <p style={{ ...getStyle() }}>{children}</p>;
};

export default NovaText;
