import { createSelector } from "@reduxjs/toolkit";

const getSubscriptions = (state: any) => state.subscriptions;

const getSubscriptionsList = createSelector(
   getSubscriptions,
   (subscriptions) => subscriptions && subscriptions.list,
);

const getSubscriptionPlanById = createSelector(
   [getSubscriptionsList, (_: any, subscriptionId: any) => subscriptionId],
   (subscriptions, subscriptionId) => {
      if (subscriptionId && subscriptions && subscriptions.length > 0) {
         return subscriptions.find(
            (subscription: any) => subscription.id === Number(subscriptionId),
         );
      }
      return null;
   },
);

const getCards = createSelector(
   getSubscriptions,
   (subscriptions) => subscriptions && subscriptions.cards,
);

const getSubscribers = createSelector(
   getSubscriptions,
   (subscriptions) => subscriptions && subscriptions.subscribers,
);

const getAffiliatesSubscriptionsList = createSelector(
   getSubscriptionsList,
   (subscriptions) =>
      subscriptions &&
      subscriptions.filter((s: any) => s.data.clientType === 0 && s.type === 2),
);

export const SubscriptionsSelectors = {
   getSubscriptionsList,
   getSubscriptionPlanById,
   getCards,
   getSubscribers,
   getAffiliatesSubscriptionsList,
};
