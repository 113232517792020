import {
   Dispatch,
   memo,
   SetStateAction,
   useCallback,
   useEffect,
   useMemo,
   useState,
} from "react";
import { Tooltip } from "@mui/material";

import {
   ReactFamilyTreeClickPlusData,
   ReactFamilyTreeColor,
   ReactFamilyTreeItemId,
} from "./types";

import ConfirmationModal from "../ConfirmationModal";

type ReactFamilyTreeItemProps = {
   dataId: number;
   item?: any;
   linkWidth: number;
   verticalGap: number;
   linkColor: ReactFamilyTreeColor;
   /** @default false */
   withLink?: boolean;
   linkRef?: React.RefObject<HTMLDivElement>;
   searchKeyword?: string;
   inEditMode?: boolean;
   withParentPlus?: boolean;
   withLeftPartnerPlus?: boolean;
   withRightPartnerPlus?: boolean;
   renderItem: (item: any, dataId: number) => JSX.Element;
   onClickMinus?: (dataId: number) => void;
   onClickPlus?: (data: ReactFamilyTreeClickPlusData) => void;
   setRenderedNotes?: Dispatch<SetStateAction<ReactFamilyTreeItemId[]>>;
};

function ReactFamilyTreeItem({
   dataId,
   item,
   linkWidth,
   verticalGap,
   linkColor,
   withLink,
   linkRef,
   searchKeyword,
   inEditMode,
   withParentPlus,
   withLeftPartnerPlus,
   withRightPartnerPlus,
   renderItem,
   onClickMinus,
   onClickPlus,
   setRenderedNotes,
}: ReactFamilyTreeItemProps) {
   const [isDeletingNode, setIsDeletingNode] = useState<boolean>(false);

   const onClickParentsPlus = useCallback(() => {
      onClickPlus?.({
         type: "parents",
         childId: dataId,
      });
   }, [onClickPlus, dataId]);
   const onClickPartnerPlus = useCallback(() => {
      onClickPlus?.({
         type: "partner",
         partnerId: dataId,
      });
   }, [onClickPlus, dataId]);

   const linkStyle = useMemo<React.CSSProperties>(
      () => ({
         position: "absolute",
         width: linkWidth,
         height: verticalGap / 2,
         top: 0,
         left: "50%",
         backgroundColor: linkColor,
         transform: "translate(-50%, -100%)",
      }),
      [linkWidth, verticalGap, linkColor],
   );
   const itemParentsPlusStyle = useMemo<React.CSSProperties>(
      () => ({
         width: linkWidth,
         backgroundColor: linkColor,
      }),
      [linkWidth, linkColor],
   );
   const itemPartnerPlusStyle = useMemo<React.CSSProperties>(
      () => ({
         height: linkWidth,
         backgroundColor: linkColor,
      }),
      [linkWidth, linkColor],
   );
   const isSearchResult = useMemo<boolean | undefined>(() => {
      if (!item || !searchKeyword || !searchKeyword.trim()?.length) return;

      return JSON.stringify(item)
         .toLocaleLowerCase()
         .includes(searchKeyword.toLocaleLowerCase());
   }, [searchKeyword, item]);

   useEffect(() => {
      setRenderedNotes?.((oldValue) => [...oldValue, dataId]);

      return () => {
         setRenderedNotes?.((oldValue) => oldValue.filter((id) => id !== dataId));
      };
   }, [setRenderedNotes, dataId]);

   return (
      <>
         <div
            className={`item${
               isSearchResult !== undefined
                  ? isSearchResult
                     ? " isSearchResult"
                     : " isNotSearchResult"
                  : ""
            }${inEditMode ? " inEditMode" : ""}`}
         >
            {withLink && <div style={linkStyle} ref={linkRef} />}
            {item && <div className="itemRendered">{renderItem(item, dataId)}</div>}

            <div className={`itemSearchOutline${isSearchResult ? "" : " hidden"}`} />

            <Tooltip title="Add parent">
               <div
                  className={`itemParentsPlus${withParentPlus ? " shown" : ""}`}
                  style={itemParentsPlusStyle}
               >
                  <div className="itemPlusMinusIcon" onClick={onClickParentsPlus}>
                     <svg viewBox="0 0 448 512">
                        <path d="M416 208H272V64c0-17.7-14.3-32-32-32h-32c-17.7 0-32 14.3-32 32v144H32c-17.7 0-32 14.3-32 32v32c0 17.7 14.3 32 32 32h144v144c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V304h144c17.7 0 32-14.3 32-32v-32c0-17.7-14.3-32-32-32z" />
                     </svg>
                  </div>
               </div>
            </Tooltip>
            <Tooltip title="Remove member">
               <div className="itemMinus">
                  <div
                     className="itemPlusMinusIcon"
                     onClick={() => setIsDeletingNode(true)}
                  >
                     <svg viewBox="0 0 448 512">
                        <path d="M416 208H32c-17.7 0-32 14.3-32 32v32c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32v-32c0-17.7-14.3-32-32-32z" />
                     </svg>
                  </div>
               </div>
            </Tooltip>
            <Tooltip title="Add partner">
               <div
                  className={`itemPartnerPlus left${withLeftPartnerPlus ? " shown" : ""}`}
                  style={itemPartnerPlusStyle}
               >
                  <div className="itemPlusMinusIcon" onClick={onClickPartnerPlus}>
                     <svg viewBox="0 0 448 512">
                        <path d="M416 208H272V64c0-17.7-14.3-32-32-32h-32c-17.7 0-32 14.3-32 32v144H32c-17.7 0-32 14.3-32 32v32c0 17.7 14.3 32 32 32h144v144c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V304h144c17.7 0 32-14.3 32-32v-32c0-17.7-14.3-32-32-32z" />
                     </svg>
                  </div>
               </div>
            </Tooltip>
            <Tooltip title="Add partner">
               <div
                  className={`itemPartnerPlus right${
                     withRightPartnerPlus ? " shown" : ""
                  }`}
                  style={itemPartnerPlusStyle}
               >
                  <div className="itemPlusMinusIcon" onClick={onClickPartnerPlus}>
                     <svg viewBox="0 0 448 512">
                        <path d="M416 208H272V64c0-17.7-14.3-32-32-32h-32c-17.7 0-32 14.3-32 32v144H32c-17.7 0-32 14.3-32 32v32c0 17.7 14.3 32 32 32h144v144c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V304h144c17.7 0 32-14.3 32-32v-32c0-17.7-14.3-32-32-32z" />
                     </svg>
                  </div>
               </div>
            </Tooltip>
         </div>

         <ConfirmationModal
            isOpened={isDeletingNode}
            onCancelCallback={() => setIsDeletingNode(false)}
            onConfirmCallback={() => {
               onClickMinus?.(dataId);
               setIsDeletingNode(false);
            }}
         />
      </>
   );
}

export default memo(ReactFamilyTreeItem) as typeof ReactFamilyTreeItem;
