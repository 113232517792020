import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./payment-form";

//todo change
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_TOKEN}`);

const AddPaymentMethod = () => {
   return (
      <Elements stripe={stripePromise}>
         <PaymentForm />
      </Elements>
   );
};

export default AddPaymentMethod;
