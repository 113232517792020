import { useTranslation, Trans } from "react-i18next";

const TranslatedText = ({
   text,
   html = false,
   props,
}: {
   text: string;
   html?: boolean;
   props?: any;
}) => {
   const { t } = useTranslation();

   if (html) {
      return <Trans i18nKey={text}>{t(text)}</Trans>;
   } else {
      return <>{t(text, { ...props })}</>;
   }
};

export default TranslatedText;
