import { useNavigate, useParams } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import NovaButton from "../../components/shared/NovaButton";
import affiliateFlower from "../../assets/affiliate-flower.svg";
import NovaTitle from "../../components/shared/NovaTitle";

const PaymentCancel = () => {
   const { orderId, memorialPageId } = useParams();
   const navigate = useNavigate();
   const pageType = orderId ? "Order" : "Subscription";
   return (
      <Container>
         <Box
            sx={{
               width: "100%",
               marginTop: "80px",
               background: "#fff",
               border: "1px solid #E9DDC8",
               position: "relative",
               overflow: "hidden",
               borderRadius: "32px",
            }}
         >
            <img
               src={affiliateFlower}
               alt="Heart"
               style={{
                  position: "absolute",
                  bottom: "-520px",
                  right: "-160px",
               }}
            />

            <Box
               sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  zIndex: 2,
               }}
            >
               <Box
                  sx={{
                     width: "100%",
                     padding: "32px 0",
                     borderBottom: "1px solid #E9DDC8",
                     display: "flex",
                     justifyContent: "center",
                     gap: "8px",
                  }}
               >
                  <WarningAmberIcon
                     sx={{
                        fill: "#FAAD14",
                        fontSize: "40px",
                     }}
                  />
                  <NovaTitle
                     variant="h3"
                     style={{
                        textAlign: "center",
                        margin: 0,
                        color: "#FAAD14",
                     }}
                  >
                     Purchase Not Completed
                  </NovaTitle>
               </Box>

               <Box
                  sx={{
                     padding: "60px 0",
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "center",
                  }}
               >
                  <NovaTitle
                     variant="h3"
                     style={{
                        textAlign: "center",
                        margin: "0px 0px 20px 0px",
                        maxWidth: "600px",
                     }}
                  >
                     Unfortunately, The payment for your {pageType} #
                     {orderId || memorialPageId} was cancelled!
                  </NovaTitle>

                  <NovaText
                     type={NovaTextTypes.MediumNormal}
                     style={{
                        textAlign: "center",
                        margin: "20px 0 60px 0",
                     }}
                  >
                     Try again or contact us if you need help.
                  </NovaText>

                  <NovaButton
                     style={{
                        borderRadius: "32px",
                     }}
                     onClick={() => navigate("../dashboard")}
                  >
                     Dashboard&nbsp;
                     <ArrowForward />
                  </NovaButton>
               </Box>
            </Box>
         </Box>
      </Container>
   );
};

export default PaymentCancel;
