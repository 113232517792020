import {
   APPROVE_ACCESS_FAIL,
   APPROVE_ACCESS_SUCCESS,
   GET_MEMORIALS,
   GET_MY_REQUESTS,
   GET_MY_PAGES_REQUESTS,
   REJECT_ACCESS_FAIL,
   REJECT_ACCESS_SUCCESS,
   UPDATE_MEMORIALS,
   GET_EDITED_MEMORIAL,
   SET_MEMORIAL_PAGE_TYPE,
} from "./types";

const getMemorials = (payload: any) => ({
   type: GET_MEMORIALS,
   payload,
});

const getEditedMemorial = (payload: any) => ({
   type: GET_EDITED_MEMORIAL,
   payload,
});

const updateMemorials = (payload: any) => ({
   type: UPDATE_MEMORIALS,
   payload,
});

const getMyRequests = (payload: any) => ({
   type: GET_MY_REQUESTS,
   payload,
});

const getMyPagesRequests = (payload: any) => ({
   type: GET_MY_PAGES_REQUESTS,
   payload,
});

const approvePageRequestSuccess = (payload: any) => ({
   type: APPROVE_ACCESS_SUCCESS,
   payload,
});

const approvePageRequestFail = () => ({
   type: APPROVE_ACCESS_FAIL,
});

const rejectPageRequestSuccess = (payload: any) => ({
   type: REJECT_ACCESS_SUCCESS,
   payload,
});

const rejectPageRequestFail = () => ({
   type: REJECT_ACCESS_FAIL,
});

const setMemorialPageType = (payload: any) => ({
   type: SET_MEMORIAL_PAGE_TYPE,
   payload,
});

export const MemorialsActions = {
   getMemorials,
   getEditedMemorial,
   updateMemorials,
   getMyRequests,
   getMyPagesRequests,
   approvePageRequestSuccess,
   approvePageRequestFail,
   rejectPageRequestSuccess,
   rejectPageRequestFail,
   setMemorialPageType,
};
