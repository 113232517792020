import { Box, IconButton } from "@mui/material";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import NovaButton from "../shared/NovaButton";
import dayjs from "dayjs";
import { useAppDispatch } from "../../store/hooks";
import { approveRequest, rejectRequest } from "../../pages/home/async-actions";

import TranslatedText from "../translated-text";
import { RequestsType } from "../../pages/home/home-client";

const Request = ({ item, type }: { item: any; type: any }) => {
   const {
      id,
      userFullName,
      created,
      memorialPageId,
      memorialPageFullName,
      memorialPagePublicId,
      memorialPagePublicPhoto,
      accessLevel,
      status,
   } = item;
   const dispatch = useAppDispatch();
   const handleConfirm = () => {
      dispatch(approveRequest(id, memorialPageId));
   };

   const handleReject = () => {
      dispatch(rejectRequest(id));
   };
   return (
      <Box
         sx={{
            borderRadius: "16px",
            padding: "24px",
            border: "1px solid #E9DDC8",
            display: "flex",
            alignItems: "flex-start",
         }}
      >
         <Box
            sx={{
               width: "32px",
               height: "32px",
               background: `url(${memorialPagePublicPhoto})`,
               backgroundSize: "cover",
               backgroundRepeat: "no-repeat",
               backgroundPosition: "center center",
               borderRadius: "100%",
               border: "1px solid #E9DDC8",
            }}
         ></Box>
         <Box
            sx={{
               marginLeft: "16px",
               flex: 1,
            }}
         >
            <Box sx={{ display: "flex", alignItems: "baseline" }}>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                  }}
               >
                  {type === RequestsType.MY_PAGES_REQUESTS ? (
                     <NovaText type={NovaTextTypes.MediumNormal} style={{ margin: 0 }}>
                        {userFullName}
                     </NovaText>
                  ) : (
                     <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
                        <TranslatedText
                           text="home.requests.page-name"
                           props={{
                              name: memorialPageFullName,
                           }}
                        />
                     </NovaText>
                  )}
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{ margin: 0, color: "#777E90" }}
                  >
                     <TranslatedText
                        text="home.requests.created-on"
                        props={{
                           date: dayjs(created).format("DD-MM-YYYY"),
                        }}
                     />
                  </NovaText>
               </Box>
            </Box>
            {type === RequestsType.MY_PAGES_REQUESTS && (
               <NovaText type={NovaTextTypes.BaseNormal}>
                  <TranslatedText
                     text="home.requests.page-name"
                     props={{
                        name: memorialPageFullName,
                     }}
                  />
               </NovaText>
            )}
         </Box>
         {type === RequestsType.MY_PAGES_REQUESTS && (
            <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
               <NovaButton
                  style={{ borderRadius: "32px", padding: "8px 18px" }}
                  onClick={handleConfirm}
               >
                  Confirm
               </NovaButton>
               <IconButton
                  sx={{
                     border: "1px solid #CAA45D",
                     width: "72px",
                     borderRadius: "32px",
                  }}
                  onClick={handleReject}
               >
                  <DeleteOutlineIcon />
               </IconButton>
            </Box>
         )}
      </Box>
   );
};

export default Request;
