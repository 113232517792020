import { Box } from "@mui/material";
import { memo } from "react";

import NovaTitle from "./shared/NovaTitle";
import NovaText, { NovaTextTypes } from "./shared/NovaText";
import NovaButton from "./shared/NovaButton";

import greenHeart from "../assets/green-heart.svg";
import affiliateFlower from "../assets/affiliate-flower.svg";

type SuccessfullyFinishedScreenProps = {
   title?: string;
   text?: string | React.ReactNode;
   buttonText?: string | React.ReactNode;
   onClickButton?: () => void;
   otherButtons?: React.ReactNode;
};

function SuccessfullyFinishedScreen({
   title,
   text,
   buttonText,
   onClickButton,
   otherButtons,
}: SuccessfullyFinishedScreenProps) {
   return (
      <Box
         sx={{
            position: "relative",
         }}
      >
         <img
            src={affiliateFlower}
            alt="Flower"
            style={{
               position: "absolute",
               bottom: "-520px",
               right: "-160px",
            }}
         />

         <Box
            sx={{
               position: "relative",
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
               zIndex: 2,
            }}
         >
            <NovaTitle
               variant="h3"
               style={{
                  textAlign: "center",
                  margin: "12px 0 60px 0",
               }}
            >
               {title}
            </NovaTitle>

            <NovaTitle
               variant="h3"
               style={{
                  textAlign: "center",
                  margin: "0px 0px 20px 0px",
               }}
            >
               Thank you!
            </NovaTitle>

            <img src={greenHeart} alt="Heart" />

            <NovaText
               type={NovaTextTypes.BaseNormal}
               style={{
                  textAlign: "center",
                  margin: "20px 0 60px 0",
               }}
            >
               {text}
            </NovaText>

            <Box
               sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "16px",
               }}
            >
               {buttonText && (
                  <NovaButton
                     style={{
                        borderRadius: "32px",
                     }}
                     onClick={onClickButton}
                  >
                     {buttonText}
                  </NovaButton>
               )}
               {otherButtons}
            </Box>
         </Box>
      </Box>
   );
}

export default memo(SuccessfullyFinishedScreen);
