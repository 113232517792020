import { useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { EditIcon } from "../shared/icons/edit";
import { UploadIcon } from "../shared/icons/upload-icon";
import NovaTextInput from "../shared/NovaTextInput";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import { FormControlLabel, Switch } from "@mui/material";
import NovaButton from "../shared/NovaButton";
import TranslatedText from "../translated-text";

const style = {
   position: "absolute" as "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   bgcolor: "background.paper",
   boxShadow: 24,
   p: 4,
   outline: "none",
   borderRadius: "24px",
};

const EditModal = ({ data, handleSave, handleClose }: any) => {
   const { t } = useTranslation();
   const [userData, setUserData] = useState(data);

   const onFormChange = useCallback((value: any, field: any) => {
      setUserData((oldValue: any) => ({ ...oldValue, [field]: value }));
   }, []);
   const onSaveClick = useCallback(() => handleSave?.(userData), [handleSave, userData]);

   useEffect(() => {
      setUserData(data);
   }, [data]);

   return (
      <Modal open={true} onClose={handleClose}>
         <Box sx={style}>
            <NovaText type={NovaTextTypes.XLNormal} style={{ margin: 0 }}>
               Edit {data?.name}
            </NovaText>

            <Box
               sx={{
                  display: "flex",
                  gap: "48px",
               }}
            >
               <Box>
                  <Box
                     sx={{
                        display: "flex",
                        position: "relative",
                        width: "100%",
                        gap: "24px",
                        marginTop: userData?.type === "parents" ? "10px" : "24px",
                     }}
                  >
                     <Box
                        sx={{
                           height: "158px",
                        }}
                     >
                        <Dropzone
                           accept={{
                              "image/png": [".png"],
                              "image/jpeg": [".jpg"],
                           }}
                           onDrop={(acceptedFiles) => {
                              if (acceptedFiles.length > 0) {
                                 const reader = new FileReader();
                                 reader.onload = (e) => {
                                    const imageDataUrl = reader.result;
                                    onFormChange(imageDataUrl, "Base64");
                                 };
                                 reader.readAsDataURL(acceptedFiles[0]);
                              }
                           }}
                        >
                           {({ getRootProps, getInputProps }) => (
                              <Box
                                 sx={{
                                    maxWidth: "100%",
                                    width: "158px",
                                    maxHeight: "158px",
                                    height: "100%",
                                    border: "2px dashed #E9DDC8",
                                    borderRadius: "16px",
                                    cursor: "pointer",
                                    overflow: "hidden",
                                 }}
                              >
                                 <div
                                    {...getRootProps()}
                                    style={{
                                       height: "100%",
                                    }}
                                 >
                                    <input {...getInputProps()} />
                                    <div
                                       style={{
                                          height: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                       }}
                                    >
                                       {(userData?.Base64 ?? userData?.photoUrl) && (
                                          <Box
                                             sx={{
                                                position: "relative",
                                                "&:hover > .edit-button": {
                                                   display: "inline-flex",
                                                   background: "#fff",
                                                },
                                             }}
                                          >
                                             <Box
                                                sx={{
                                                   background: `url(${
                                                      userData?.Base64 ??
                                                      userData?.photoUrl
                                                   })`,
                                                   backgroundSize: "cover",
                                                   backgroundRepeat: "no-repeat",
                                                   backgroundPosition: "center center",
                                                   width: "158px",
                                                   height: "158px",
                                                }}
                                             />
                                             <Box
                                                className="edit-button"
                                                sx={{
                                                   position: "absolute",
                                                   top: "50%",
                                                   left: "50%",
                                                   transform: "translate(-50%, -50%)",
                                                   background: "rgba(255, 255, 255, .8)",
                                                   display: "none",
                                                   alignItems: "center",
                                                   justifyContent: "center",
                                                   border: "1px solid #E9DDC8",
                                                   borderRadius: "50%",
                                                   width: "50px",
                                                   height: "50px",
                                                   color: "#1B1B48",
                                                }}
                                             >
                                                <EditIcon />
                                             </Box>
                                          </Box>
                                       )}
                                       {!userData?.photoUrl && !userData?.Base64 && (
                                          <Box
                                             sx={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                                gap: "4px",
                                                border: "1px solid #E9DDC8",
                                                borderRadius: "32px",
                                                padding: "10px 24px",
                                             }}
                                          >
                                             <UploadIcon />
                                             <TranslatedText text="buttons.upload" />
                                          </Box>
                                       )}
                                    </div>
                                 </div>
                              </Box>
                           )}
                        </Dropzone>
                     </Box>
                     <Box>
                        <NovaTextInput
                           label={t("create-memorial-page.family-tree.tree.name")}
                           placeholder={t("create-memorial-page.family-tree.tree.name")}
                           value={userData?.name}
                           fullwidth
                           onChange={(event) => {
                              onFormChange(event.target.value, "name");
                           }}
                        />
                        <Box
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "16px",
                              marginTop: "16px",
                           }}
                        >
                           <Box
                              sx={{
                                 display: "flex",
                                 flexDirection: " column",
                              }}
                           >
                              <NovaText
                                 type={NovaTextTypes.BaseNormal}
                                 style={{
                                    margin: 0,
                                 }}
                              >
                                 <TranslatedText text="create-memorial-page.family-tree.tree.birth-date" />
                              </NovaText>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                 <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                       value={
                                          userData?.bornYear && dayjs(userData?.bornYear)
                                       }
                                       slotProps={{ textField: { size: "small" } }}
                                       openTo="year"
                                       views={["year", "month", "day"]}
                                       minDate={dayjs("01-01-1000")}
                                       maxDate={dayjs()}
                                       onChange={(event) =>
                                          setUserData({
                                             ...userData,
                                             bornYear: dayjs(event).format("DD-MM-YYYY"),
                                          })
                                       }
                                    />
                                 </DemoContainer>
                              </LocalizationProvider>
                           </Box>

                           {!userData?.isAlive && (
                              <Box
                                 sx={{
                                    display: "flex",
                                    flexDirection: " column",
                                 }}
                              >
                                 <NovaText
                                    type={NovaTextTypes.BaseNormal}
                                    style={{
                                       margin: 0,
                                    }}
                                 >
                                    <TranslatedText text="create-memorial-page.family-tree.tree.dead-date" />
                                 </NovaText>
                                 <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                       <DatePicker
                                          value={
                                             userData?.deathYear &&
                                             dayjs(userData?.deathYear)
                                          }
                                          slotProps={{ textField: { size: "small" } }}
                                          openTo="year"
                                          views={["year", "month", "day"]}
                                          minDate={dayjs("01-01-1000")}
                                          maxDate={dayjs()}
                                          onChange={(event) =>
                                             setUserData({
                                                ...userData,
                                                deathYear:
                                                   dayjs(event).format("DD-MM-YYYY"),
                                             })
                                          }
                                       />
                                    </DemoContainer>
                                 </LocalizationProvider>
                              </Box>
                           )}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "end", mt: "10px" }}>
                           <FormControlLabel
                              value="start"
                              control={
                                 <Switch
                                    checked={userData?.isAlive}
                                    onChange={(event) =>
                                       onFormChange(event.target.checked, "isAlive")
                                    }
                                    inputProps={{ "aria-label": "controlled" }}
                                    sx={{
                                       height: "35px",
                                       padding: 0,
                                       display: "flex",
                                       marginLeft: "16px",
                                       "& .MuiSwitch-switchBase": {
                                          "&.Mui-checked": {
                                             color: "#CAA45D",
                                             "& + .MuiSwitch-track": {
                                                opacity: 1,
                                                border: "1px solid #CAA45D",
                                                background: "#fff",
                                             },
                                          },
                                       },
                                       "& .MuiSwitch-track": {
                                          borderRadius: 20 / 2,
                                          backgroundColor: "#E9E9EA",
                                          opacity: 1,
                                       },
                                       "& .MuiSwitch-thumb": {
                                          boxShadow: "none",
                                       },
                                       ".MuiButtonBase-root": {
                                          height: "35px",
                                       },
                                       ".MuiButtonBase-root.Mui-checked": {
                                          padding: "8px 12px",
                                       },
                                    }}
                                 />
                              }
                              label={t(
                                 "create-memorial-page.family-tree.tree.person-is-alive",
                              )}
                              labelPlacement="start"
                           />
                        </Box>
                        {userData?.type === "partner" && (
                           <Box
                              sx={{ display: "flex", justifyContent: "end", mt: "10px" }}
                           >
                              <FormControlLabel
                                 value="start"
                                 control={
                                    <Switch
                                       checked={userData?.divorced}
                                       onChange={(event) =>
                                          onFormChange(event.target.checked, "divorced")
                                       }
                                       inputProps={{ "aria-label": "controlled" }}
                                       sx={{
                                          height: "35px",
                                          padding: 0,
                                          display: "flex",
                                          marginLeft: "16px",
                                          "& .MuiSwitch-switchBase": {
                                             "&.Mui-checked": {
                                                color: "#CAA45D",
                                                "& + .MuiSwitch-track": {
                                                   opacity: 1,
                                                   border: "1px solid #CAA45D",
                                                   background: "#fff",
                                                },
                                             },
                                          },
                                          "& .MuiSwitch-track": {
                                             borderRadius: 20 / 2,
                                             backgroundColor: "#E9E9EA",
                                             opacity: 1,
                                          },
                                          "& .MuiSwitch-thumb": {
                                             boxShadow: "none",
                                          },
                                          ".MuiButtonBase-root": {
                                             height: "35px",
                                          },
                                          ".MuiButtonBase-root.Mui-checked": {
                                             padding: "8px 12px",
                                          },
                                       }}
                                    />
                                 }
                                 label={t(
                                    "create-memorial-page.family-tree.tree.divorced",
                                 )}
                                 labelPlacement="start"
                              />
                           </Box>
                        )}
                        <Box>
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{
                                 margin: 0,
                              }}
                           >
                              <TranslatedText text="create-memorial-page.family-tree.tree.gender.title" />
                           </NovaText>
                           <Box
                              className="chipSelector"
                              sx={{
                                 marginTop: "8px",
                                 display: "flex",
                                 gap: "8px",
                              }}
                           >
                              <div
                                 className={`chip${
                                    userData?.gender === "male" ? " active" : ""
                                 }`}
                                 onClick={() => onFormChange("male", "gender")}
                              >
                                 <TranslatedText text="create-memorial-page.family-tree.tree.gender.male" />
                              </div>
                              <div
                                 className={`chip${
                                    userData?.gender === "female" ? " active" : ""
                                 }`}
                                 onClick={() => onFormChange("female", "gender")}
                              >
                                 <TranslatedText text="create-memorial-page.family-tree.tree.gender.female" />
                              </div>
                           </Box>
                        </Box>
                     </Box>
                  </Box>
               </Box>
            </Box>

            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginTop: "32px",
               }}
            >
               <NovaButton
                  style={{
                     borderRadius: "32px",
                     background: "#F3F5F7",
                     color: "#1B1B48",
                  }}
                  onClick={handleClose}
               >
                  <TranslatedText text="buttons.cancel" />
               </NovaButton>
               <NovaButton
                  // disabled={
                  //    (!firstProfile.name || !firstProfile.bornYear)
                  // }
                  style={{
                     borderRadius: "32px",
                  }}
                  onClick={() => onSaveClick()}
               >
                  <TranslatedText text="buttons.save" />
               </NovaButton>
            </Box>
         </Box>
      </Modal>
   );
};

export default EditModal;
