import axios from "axios";

const getToken = () => {
   const userFromStorage: any = localStorage.getItem("user");
   const user = JSON.parse(userFromStorage);
   if (
      !user &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/sign-up"
   ) {
      window.location.href = "/login";
   }
   const token = user ? user.jwtToken : "";
   return token;
};

const removeUserFromStorage = () => {
   localStorage.removeItem("user");
   window.location.href = "/login";
};

const axiosInstance = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
   config.headers.Authorization = `Bearer ${getToken()}`;
   return config;
});

axiosInstance.interceptors.response.use(
   (response) => response,
   (error) => {
      if (
         error.response &&
         error.response?.data &&
         error.response?.data?.title === "USER_NOT_FOUND"
      ) {
         removeUserFromStorage();
      }
      return Promise.reject(error);
   },
);

const withAuth = (method: any) => {
   return (url: string, data?: any, config = {}) => {
      return method(url, data, { ...config });
   };
};

export default {
   get: withAuth(axiosInstance.get),
   post: withAuth(axiosInstance.post),
   put: withAuth(axiosInstance.put),
   delete: withAuth(axiosInstance.delete),
};
