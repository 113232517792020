import { KeyboardBackspace } from "@mui/icons-material";
import {
   Box,
   Chip,
   Container,
   Divider,
   FormControl,
   Grid,
   MenuItem,
   Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { changeOrderStatus, getSingleOrder } from "./async-actions";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import NovaTitle from "../../components/shared/NovaTitle";
import { useSelector } from "react-redux";
import { OrdersSelectors } from "./selectors";
import medallion from "../../assets/medallion.jpg";
import { ORDER_STATUS } from "../../components/orders/table";
import LoaderOverlay from "../../components/LoaderOverlay";
import TranslatedText from "../../components/translated-text";

export const PAYMENT_METHODS = ["Card", "Affiliate", "Voucher"];
export const PAYMENT_STATUS = ["New", "Processing", "Success", "Failed", "Canceled"];

const SingleOrder = () => {
   const dispatch = useAppDispatch();
   const { orderId } = useParams();
   const order = useSelector(OrdersSelectors.getSingleOrder);
   const [isLoading, setIsLoading] = useState(false);
   const loadingStart = () => {
      setIsLoading(true);
   };

   const loadingCompleted = () => {
      setIsLoading(false);
   };

   const [orderStatus, setOrderStatus] = useState(0);
   const handleOrderStatusChange = (event: any) => {
      setOrderStatus(event.target.value);
      dispatch(changeOrderStatus(orderId, event.target.value));
   };
   useEffect(() => {
      dispatch(getSingleOrder(orderId, loadingStart, loadingCompleted));
   }, []);

   useEffect(() => {
      if (order) {
         setOrderStatus(order.orderStatus);
      }
   }, [order]);

   if (!order) return null;
   return (
      <Container
         sx={{
            padding: "32px 0 120px 0",
         }}
      >
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between",
            }}
         >
            <Link
               to="../orders"
               style={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "8px",
                  textDecoration: "none",
                  color: "#1B1B48",
                  borderRadius: "32px",
                  background: "#fff",
                  padding: "12px 24px",
                  boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.05)",
               }}
            >
               <KeyboardBackspace />
               Back to Orders
            </Link>
         </Box>
         <Box
            sx={{
               marginTop: "24px",
            }}
         >
            <LoaderOverlay isLoading={isLoading}>
               <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                     <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <NovaText type={NovaTextTypes.MediumNormal}>
                           Order #{orderId}
                        </NovaText>
                        <Chip
                           label={ORDER_STATUS[order.orderStatus]}
                           sx={{
                              height: "28px",
                           }}
                        />
                     </Box>
                     <NovaText
                        type={NovaTextTypes.MediumNormal}
                        style={{ margin: 0, fontWeight: "900" }}
                     >
                        Address
                     </NovaText>
                     <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
                        {order.address}, {order.city}, {order.postalCode}
                     </NovaText>
                     <NovaText
                        type={NovaTextTypes.MediumNormal}
                        style={{ margin: "16px 0 0 0", fontWeight: "900" }}
                     >
                        Phone Number
                     </NovaText>
                     <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
                        {order.phoneNumber}
                     </NovaText>
                     <NovaText
                        type={NovaTextTypes.MediumNormal}
                        style={{ margin: "16px 0 0 0", fontWeight: "900" }}
                     >
                        Customer
                     </NovaText>
                     <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
                        {order.customerName}
                     </NovaText>

                     <NovaText
                        type={NovaTextTypes.MediumNormal}
                        style={{ margin: "16px 0 0 0", fontWeight: "900" }}
                     >
                        Payment
                     </NovaText>
                     <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
                        {PAYMENT_METHODS[order.paymentType]}
                     </NovaText>
                     <NovaText
                        type={NovaTextTypes.MediumNormal}
                        style={{ margin: "16px 0 0 0", fontWeight: "900" }}
                     >
                        Payment Status
                     </NovaText>
                     <Chip
                        label={PAYMENT_STATUS[order.paymentStatus]}
                        sx={{
                           marginTop: "8px",
                           height: "28px",
                        }}
                     />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                     <NovaText
                        type={NovaTextTypes.MediumNormal}
                        style={{ margin: "16px 0 0 0" }}
                     >
                        Change order status
                     </NovaText>
                     <FormControl sx={{ minWidth: 250 }} size="small">
                        <Select value={orderStatus} onChange={handleOrderStatusChange}>
                           {/* <MenuItem value={0}>Select an option</MenuItem> */}
                           {ORDER_STATUS.map((status: any, index: number) => {
                              return (
                                 <MenuItem value={index} key={index}>
                                    {status}
                                 </MenuItem>
                              );
                           })}
                        </Select>
                     </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                     <Box
                        sx={{
                           width: "100%",
                           position: "sticky",
                           top: "20px",
                        }}
                     >
                        <NovaText type={NovaTextTypes.XLNormal}>Order Summary</NovaText>
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "16px",
                           }}
                        >
                           {order.items.map((item: any, index: number) => {
                              return (
                                 <Box
                                    key={index}
                                    sx={{
                                       display: "flex",
                                       alignItems: "start",
                                       gap: "16px",
                                    }}
                                 >
                                    <Box
                                       sx={{
                                          background: `url(${medallion})`,
                                          backgroundSize: "cover",
                                          backgroundPosition: "center center",
                                          backgroundRepeat: "no-repeat",
                                          width: "100px",
                                          height: "100px",
                                          borderRadius: "12px",
                                       }}
                                    />
                                    <Box>
                                       <NovaTitle
                                          variant="h4"
                                          style={{
                                             margin: 0,
                                             lineHeight: "24px",
                                          }}
                                       >
                                          {item.name}
                                       </NovaTitle>
                                       <NovaText
                                          type={NovaTextTypes.BaseNormal}
                                          style={{
                                             margin: 0,
                                             lineHeight: "24px",
                                          }}
                                       >
                                          <TranslatedText text="single-product.metal-medallion" />
                                       </NovaText>
                                       <NovaText
                                          type={NovaTextTypes.BaseNormal}
                                          style={{
                                             margin: 0,
                                          }}
                                       >
                                          Quantity: {item.quantity}
                                       </NovaText>
                                       <div
                                          style={{
                                             display: "flex",
                                             alignItems: "center",
                                             marginTop: "16px",
                                          }}
                                       >
                                          <div
                                             style={{
                                                height: "2px",
                                                width: "30px",
                                                background: "#CAA45D",
                                                display: "inline-block",
                                             }}
                                          />
                                          <NovaText
                                             type={NovaTextTypes.LGBold}
                                             style={{
                                                margin: "0 0 0 10px",
                                                color: "#CAA45D",
                                             }}
                                          >
                                             {item.price * item.quantity} BGN
                                          </NovaText>
                                       </div>
                                    </Box>
                                 </Box>
                              );
                           })}
                        </Box>
                        <Divider sx={{ marginTop: "16px" }} />
                        <Box sx={{ marginTop: "8px" }}>
                           <Box
                              sx={{
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "space-between",
                              }}
                           >
                              <NovaText
                                 type={NovaTextTypes.MediumLight}
                                 style={{ margin: 0 }}
                              >
                                 Products price
                              </NovaText>
                              <NovaText
                                 type={NovaTextTypes.MediumLight}
                                 style={{ margin: 0 }}
                              >
                                 {order.amount}&nbsp;{order.currency}
                              </NovaText>
                           </Box>
                           <Box
                              sx={{
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "space-between",
                              }}
                           >
                              <NovaText
                                 type={NovaTextTypes.MediumLight}
                                 style={{ margin: 0 }}
                              >
                                 Delivery price
                              </NovaText>
                              <NovaText
                                 type={NovaTextTypes.MediumLight}
                                 style={{ margin: 0 }}
                              >
                                 {order.deliveryPrice}&nbsp;{order.currency}
                              </NovaText>
                           </Box>
                           <Divider sx={{ marginTop: "8px" }} />
                           <Box
                              sx={{
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "space-between",
                              }}
                           >
                              <NovaText
                                 type={NovaTextTypes.MediumLight}
                                 style={{ margin: "8px 0 0 0", fontWeight: "900" }}
                              >
                                 Total
                              </NovaText>
                              <NovaText
                                 type={NovaTextTypes.MediumLight}
                                 style={{ margin: "8px 0 0 0", fontWeight: "900" }}
                              >
                                 {order.totalPrice} {order.currency} (VAT included)
                              </NovaText>
                           </Box>
                        </Box>
                     </Box>
                  </Grid>
               </Grid>
            </LoaderOverlay>
         </Box>
      </Container>
   );
};

export default SingleOrder;
