import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { getPayments } from "./async-actions";
import { useSelector } from "react-redux";
import { PaymentsSelectors } from "./selectors";
import PaymentsTable from "../../components/payments/table";
import { columns } from "./data";

const Payments = () => {
   const dispatch = useAppDispatch();
   const payments = useSelector(PaymentsSelectors.getPaymentsList);
   const [selectedRowsCount, setSelectedRowsCount] = useState(0);
   const [packagesCount, setPackagesCount] = useState(0);

   const handleSelectedRowsCount = (selectedCount: any) => {
      setSelectedRowsCount(selectedCount);
   };

   useEffect(() => {
      dispatch(getPayments());
   }, []);
   return (
      <Container
         sx={{
            padding: "32px 0 120px 0",
         }}
      >
         <Box>
            <PaymentsTable
               rows={payments}
               columns={columns}
               rowsSelectedCallback={handleSelectedRowsCount}
            />
         </Box>
      </Container>
   );
};

export default Payments;
