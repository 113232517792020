import { Box, Container, Divider, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { CartSelectors } from "../products/clients/selectors";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import { Link, Navigate } from "react-router-dom";
import {
   CalendarMonthOutlined,
   KeyboardBackspace,
   LocalShippingOutlined,
} from "@mui/icons-material";
import CheckoutProductCard from "../../components/products/CheckoutProductCard";
import NovaLink from "../../components/shared/NovaLink";
import TranslatedText from "../../components/translated-text";

const Cart = () => {
   const cart = useSelector(CartSelectors.getOrder);
   const totalPrice = useSelector(CartSelectors.getCardProductsTotal);
   const currency = useSelector(CartSelectors.getCardCurrency);

   // TODO: rework if we want to have different screen with button to the shop
   if (cart.length < 1) {
      return <Navigate to="../products" />;
   }

   return (
      <Container
         sx={{
            padding: "60px 20px 120px 20px",
         }}
      >
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between",
            }}
         >
            <Link
               to="../products"
               style={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "8px",
                  textDecoration: "none",
                  color: "#1B1B48",
                  borderRadius: "32px",
                  background: "#fff",
                  padding: "12px 24px",
                  boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.05)",
               }}
            >
               <KeyboardBackspace />
               <TranslatedText text="buttons.continue-shopping" />
            </Link>
         </Box>
         <Grid
            container
            spacing={5}
            sx={{
               marginTop: "1px",
            }}
         >
            <Grid item xs={12} sm={7}>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     gap: "16px",
                     background: "#fff",
                     padding: "16px 24px",
                     borderRadius: "12px",
                  }}
               >
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                     }}
                  >
                     {cart.map((order: any, index: number) => {
                        return (
                           <Box key={index}>
                              <CheckoutProductCard order={order} index={index} />
                              {cart.length !== index + 1 && (
                                 <Divider sx={{ marginTop: "12px" }} />
                              )}
                           </Box>
                        );
                     })}
                  </Box>
               </Box>
            </Grid>

            <Grid item xs={12} sm={5}>
               <Box
                  sx={{
                     background: "#fff",
                     padding: "16px 24px",
                     borderRadius: "12px",
                  }}
               >
                  <NovaText
                     type={NovaTextTypes.XLNormal}
                     style={{
                        marginTop: 0,
                     }}
                  >
                     <TranslatedText text="buttons.cart" />
                  </NovaText>
                  <Divider sx={{ marginTop: "16px" }} />
                  <Box sx={{ marginTop: "8px" }}>
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "space-between",
                        }}
                     >
                        <NovaText type={NovaTextTypes.MediumLight} style={{ margin: 0 }}>
                           <TranslatedText text="cart.products-price" />
                        </NovaText>
                        <NovaText type={NovaTextTypes.MediumLight} style={{ margin: 0 }}>
                           {totalPrice} {currency}
                        </NovaText>
                     </Box>
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "space-between",
                        }}
                     >
                        <NovaText type={NovaTextTypes.MediumLight} style={{ margin: 0 }}>
                           <TranslatedText text="cart.delivery-price" />
                        </NovaText>
                        <NovaText type={NovaTextTypes.MediumLight} style={{ margin: 0 }}>
                           0 {currency}
                        </NovaText>
                     </Box>
                     <Divider sx={{ marginTop: "8px" }} />
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "space-between",
                        }}
                     >
                        <NovaText
                           type={NovaTextTypes.MediumLight}
                           style={{ margin: "8px 0 0 0", fontWeight: "900" }}
                        >
                           Total
                        </NovaText>
                        <NovaText
                           type={NovaTextTypes.MediumLight}
                           style={{ margin: "8px 0 0 0", fontWeight: "900" }}
                        >
                           {totalPrice} {currency} <TranslatedText text="cart.vat" />
                        </NovaText>
                     </Box>
                  </Box>
                  <NovaLink
                     to="../checkout"
                     style={{
                        marginTop: "24px",
                        borderRadius: "32px",
                        width: "100%",
                        boxSizing: "border-box",
                        background: "#1B1B48",
                        color: "#CAA45D",
                        textDecoration: "none",
                        padding: "10.5px 46px",
                        display: "block",
                        textAlign: "center",
                     }}
                  >
                     <TranslatedText text="buttons.checkout" />
                  </NovaLink>
                  <Box
                     sx={{
                        marginTop: "24px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                     }}
                  >
                     <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <CalendarMonthOutlined />
                        <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
                           <TranslatedText text="cart.delivery-time" /> Mon - Fri
                        </NovaText>
                     </Box>
                     <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <LocalShippingOutlined />
                        <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
                           <TranslatedText
                              text="cart.free-delivery"
                              props={{
                                 name: "Еконт",
                              }}
                           />
                        </NovaText>
                     </Box>
                  </Box>
               </Box>
            </Grid>
         </Grid>
      </Container>
   );
};
export default Cart;
