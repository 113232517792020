import {
   Box,
   InputBaseComponentProps,
   TextField,
   TextFieldVariants,
} from "@mui/material";
import NovaText, { NovaTextTypes } from "./NovaText";

const NovaTextInput = ({
   type,
   placeholder,
   label,
   variant,
   value,
   multiline = false,
   rows = undefined,
   maxRows = undefined,
   fullwidth,
   disabled = false,
   inputProps,
   InputProps,
   style,
   onClick,
   onChange,
   padding,
}: {
   type?: string;
   placeholder?: any;
   label?: any;
   variant?: TextFieldVariants;
   value: string;
   multiline?: boolean;
   rows?: number | undefined;
   maxRows?: number | undefined;
   fullwidth?: boolean;
   disabled?: boolean;
   inputProps?: InputBaseComponentProps;
   InputProps?: any;
   style?: React.CSSProperties;
   onClick?: (event: any) => void;
   onChange?: (event: any) => void;
   padding?: string;
}) => {
   return (
      <Box
         sx={{
            width: fullwidth ? "100%" : "auto",
            ...style,
         }}
      >
         {label && (
            <NovaText
               type={NovaTextTypes.BaseNormal}
               style={{
                  margin: 0,
               }}
            >
               {label}
            </NovaText>
         )}
         <TextField
            disabled={disabled}
            rows={rows}
            maxRows={maxRows}
            multiline={multiline}
            value={value}
            type={type ? type : "text"}
            placeholder={placeholder}
            variant={variant || "outlined"}
            sx={{
               width: "100%",
               marginTop: "8px",
               backgroundColor: "#ffffff",
               ".MuiInputBase-input": {
                  padding: padding ?? "4px 11px",
                  color: "#000",
                  fontSize: "14px",
                  lineHeight: "27px",
                  fontWeight: 400,
                  minHeight: "32px",
               },
            }}
            inputProps={inputProps}
            InputProps={InputProps}
            onClick={onClick}
            onChange={onChange}
         />

         {inputProps?.maxLength && (
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "4px",
               }}
            >
               <NovaText
                  type={NovaTextTypes.BaseNormal}
                  style={{
                     width: "fit-content",
                     color:
                        (value?.length ?? 0) > inputProps?.maxLength ? "red" : "#1b1b45",
                     backgroundColor: "#ffffff",
                     borderRadius: "999px",
                     margin: 0,
                     padding: "0px 8px",
                  }}
               >
                  <span
                     style={{
                        color: "#1b1b4580",
                     }}
                  >
                     {value?.length ?? 0}
                  </span>{" "}
                  / {inputProps?.maxLength}
               </NovaText>
            </Box>
         )}
      </Box>
   );
};

export default NovaTextInput;
