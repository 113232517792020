import { useCallback, useEffect, useMemo, useState } from "react";
import {
   Box,
   Chip,
   List,
   ListItem,
   ListItemIcon,
   ListItemText,
   Switch,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

import { useLanguage } from "../../store/hooks";

import NovaTitle from "../shared/NovaTitle";
import NovaButton from "../shared/NovaButton";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import TranslatedText from "../translated-text";

const SubscriptionPlanCard = ({
   setSelectedPlan,
   selectedPlan,
   readOnly,
   page,
   ...plan
}: any) => {
   const language = useLanguage();

   const [planData, setPlanData] = useState<any>(plan);
   const [isYearly, setIsYearly] = useState<boolean>(true);

   const onChangeMonthlyYearlySwitch = useCallback(() => {
      setIsYearly((oldValue) => {
         setPlanData(oldValue ? plan.monthly : plan);

         if (selectedPlan?.type === 0 || selectedPlan?.type === 1)
            setSelectedPlan?.(oldValue ? plan.monthly.id : plan.id);

         return !oldValue;
      });
   }, [plan, selectedPlan, setSelectedPlan]);

   const currency = useMemo(
      () => (language.language === "bg" ? "bgn" : "eur"),
      [language.language],
   );
   const price = useMemo(
      () => planData.prices.find((price: any) => price.currency === currency),
      [planData, currency],
   );

   const isSelected = selectedPlan?.id === planData.id;
   const unavailable =
      (!page?.subscription && planData.name === "Free") ||
      page?.subscription?.subscriptionPlan?.type === planData.type;

   useEffect(() => {
      if (!selectedPlan) return;

      if (
         (plan.type === 0 || plan.type === 1) &&
         (selectedPlan.type === 0 || selectedPlan.type === 1)
      ) {
         setIsYearly(selectedPlan.type === 1);
         setPlanData(selectedPlan);
      }
   }, [selectedPlan, plan]);

   useEffect(() => {
      if (
         unavailable &&
         selectedPlan?.type === page?.subscription?.subscriptionPlan?.type
      )
         setSelectedPlan?.(null);
   }, [unavailable, selectedPlan, page, setSelectedPlan]);

   if (!planData.name || !planData.data) return null;

   return (
      <Box
         sx={{
            width: "100%",
            minHeight: {
               sm: "100%",
            },
            boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.1)",
            borderRadius: "24px",
            padding: "24px 20px",
            border: planData.data.mostPopular ? "#E9DDC8" : "none",
            background: planData.data.mostPopular ? "#FAF8F4" : "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
         }}
      >
         {!readOnly && plan.type === 1 ? (
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  marginBottom: "16px",
               }}
            >
               <NovaText
                  type={NovaTextTypes.BaseNormal}
                  style={{
                     margin: 0,
                  }}
               >
                  Monthly
               </NovaText>
               <Switch
                  sx={{
                     display: "flex",
                     height: "35px",
                     padding: 0,
                     "& .MuiSwitch-switchBase": {
                        color: "#CAA45D",
                        "& + .MuiSwitch-track": {
                           opacity: 1,
                           border: "1px solid #CAA45D",
                           background: "#fff",
                        },
                        "&.Mui-checked": {
                           color: "#CAA45D",
                           "& + .MuiSwitch-track": {
                              opacity: 1,
                              border: "1px solid #CAA45D",
                              background: "#fff",
                           },
                        },
                     },
                     "& .MuiSwitch-track": {
                        borderRadius: 20 / 2,
                        backgroundColor: "#E9E9EA",
                        opacity: 1,
                     },
                     "& .MuiSwitch-thumb": {
                        boxShadow: "none",
                     },
                     ".MuiButtonBase-root": {
                        height: "35px",
                     },
                     ".MuiButtonBase-root.Mui-checked": {
                        padding: "8px 12px",
                     },
                  }}
                  checked={isYearly}
                  value={isYearly}
                  onChange={onChangeMonthlyYearlySwitch}
               />
               <NovaText
                  type={NovaTextTypes.BaseNormal}
                  style={{
                     margin: 0,
                  }}
               >
                  Yearly
               </NovaText>
            </Box>
         ) : (
            <Box
               sx={{
                  height: "51px",
               }}
            />
         )}

         {planData.data.mostPopular ? (
            <Chip
               label={<TranslatedText text="plans.most-popular" />}
               sx={{
                  height: "36px",
                  marginTop: "-36px",
                  background: "#CAA45D",
                  fontSize: "16px",
                  color: "#1B1B48",
               }}
            />
         ) : (
            <Box
               sx={{
                  height: "27px",
               }}
            />
         )}

         <NovaTitle
            variant="h3"
            style={{
               margin: planData.data.mostPopular ? "24px 0 0 0" : 0,
               textAlign: "center",
            }}
         >
            <TranslatedText
               text={`plans.${planData?.name.toLowerCase().replace(/\s/g, "")}`}
            />
         </NovaTitle>
         <NovaTitle
            variant="h3"
            style={{
               margin: "32px 0 0 0",
               textAlign: "center",
               color: "#CAA45D",
            }}
         >
            {price ? `${price.amount / 100} ${currency.toUpperCase()}` : "--"}
         </NovaTitle>

         {!readOnly && (
            <NovaButton
               style={{
                  borderRadius: "32px",
                  marginTop: "24px",
                  border: isSelected ? "1px solid #1B1B48" : "1px solid #CAA45D",
                  background: isSelected ? "#1B1B48" : "#fff",
                  ...(!unavailable
                     ? {
                          "&:hover": {
                             background: isSelected && "#1B1B48",
                             border: isSelected
                                ? "1px solid #1B1B48"
                                : "1px solid #CAA45D",
                          },
                       }
                     : {
                          opacity: 0.5,
                          cursor: "not-allowed",
                          "&:hover": {
                             background: "transparent",
                             border: isSelected
                                ? "1px solid #1B1B48"
                                : "1px solid #CAA45D",
                          },
                       }),
               }}
               disableRipple={unavailable}
               onClick={!unavailable ? () => setSelectedPlan?.(planData.id) : undefined}
            >
               <TranslatedText text="buttons.select-plan" />
            </NovaButton>
         )}

         <Box
            sx={{
               width: "100%",
            }}
         >
            <List
               sx={{
                  width: "100%",
                  maxWidth: 360,
                  marginTop: "24px",
                  padding: 0,
               }}
               component="nav"
            >
               {planData.data.features.map((feature: any, index: number) => (
                  <ListItem
                     sx={{
                        padding: "0 8px",
                     }}
                     key={index}
                  >
                     <ListItemIcon sx={{ minWidth: "40px" }}>
                        <CheckCircle
                           sx={{
                              color:
                                 planData.type === 2 &&
                                 planData.name.toLowerCase() === "lifetime" &&
                                 index === 0
                                    ? "#CAA45D"
                                    : "#1B1B48",
                           }}
                        />
                     </ListItemIcon>

                     <ListItemText
                        primary={feature[language.language]}
                        sx={{
                           color:
                              planData.type === 2 &&
                              planData.name.toLowerCase() === "lifetime" &&
                              index === 0
                                 ? "#CAA45D"
                                 : "#1B1B48",
                           span: {
                              fontWeight:
                                 planData.type === 2 &&
                                 planData.name.toLowerCase() === "lifetime" &&
                                 index === 0
                                    ? "900"
                                    : "500",
                           },
                        }}
                     />
                  </ListItem>
               ))}
            </List>
         </Box>
      </Box>
   );
};

export default SubscriptionPlanCard;
