import { Box, Grid } from "@mui/material";
import Plan from "./plan";
import { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { getSubscriptionPlans } from "../../pages/subscriptions/async-actions";
import { useSelector } from "react-redux";
import { SubscriptionsSelectors } from "../../pages/subscriptions/selectors";

const Plans = () => {
   const dispatch = useAppDispatch();
   const plans = useSelector(SubscriptionsSelectors.getSubscriptionsList);

   useEffect(() => {
      dispatch(getSubscriptionPlans());
   }, []);

   if (!plans || plans.length < 1) return null;

   return (
      <Box>
         <Grid container spacing={3}>
            {plans.map((plan: any, index: number) => {
               return (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                     <Plan plan={plan} />
                  </Grid>
               );
            })}
         </Grid>
      </Box>
   );
};

export default Plans;
