import * as Immutable from "seamless-immutable";
import { GET_MEMORIAL_PAGE } from "./types";

const initialState = Immutable.from<any>(null);

export default function MemorialsReducer(state = initialState, action: any) {
   const { type, payload } = action;

   switch (type) {
      case GET_MEMORIAL_PAGE: {
         return (state = payload);
      }
      default:
         return state;
   }
}
