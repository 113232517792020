import dayjs from "dayjs";
import { memo, useCallback, useMemo, useState } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { getMonthName } from "../../utils/functions";

import { ArrowLeft, ArrowRight } from "../../components/shared/icons/arrows";
import NovaTitle from "../../components/shared/NovaTitle";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import NovaButton from "../../components/shared/NovaButton";

type GalleryProps = {
   items: any[];
   selectedItemId?: number;
   handleClose?: () => void;
};

function Gallery({ items, selectedItemId, handleClose }: GalleryProps) {
   const [activeItem, setActiveItem] = useState<number>(selectedItemId || 0);

   const onClickPrevious = useCallback(() => {
      setActiveItem((oldValue) => {
         if (oldValue === 0) return items.length - 1;
         return oldValue - 1;
      });
   }, [items]);
   const onClickNext = useCallback(() => {
      setActiveItem((oldValue) => {
         if (oldValue === items.length - 1) return 0;
         return oldValue + 1;
      });
   }, [items]);

   const dateString = useMemo<string | undefined>(() => {
      return items[activeItem].date
         ? dayjs(items[activeItem].date).format("DD/MM/YYYY")
         : "";
   }, [items, activeItem]);

   return (
      <Modal open={true} onClose={handleClose}>
         <Box
            sx={{
               position: "absolute",
               top: "50%",
               left: "50%",
               transform: "translate(-50%, -50%)",
               bgcolor: "background.paper",
               boxShadow: 24,
               borderRadius: "24px",
               display: "flex",
               alignItems: "baseline",
               width: {
                  xs: "calc(100% - 20px)",
                  md: "700px",
               },
               border: "1px solid #E9DDC8",
               outline: "none",
            }}
         >
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  width: "100%",
                  position: "relative",
                  padding: "24px",
               }}
            >
               <Box
                  sx={{
                     width: "100%",
                     display: "flex",
                     justifyContent: "flex-end",
                  }}
               >
                  <IconButton onClick={handleClose}>
                     <CloseIcon />
                  </IconButton>
               </Box>

               <Box
                  sx={{
                     display: "flex",
                     width: "100%",
                     flexDirection: "row",
                     alignItems: "center",
                     justifyContent: "center",
                     gap: "10px",
                     marginBottom: "16px",
                  }}
               >
                  <NovaButton
                     icon={<ArrowLeft />}
                     style={{
                        minWidth: "0px",
                        backgroundColor: "transparent",
                        border: "solid 1px #d9d9d9",
                        borderRadius: "999px",
                        padding: "8px",
                        flexShrink: 0,
                     }}
                     onClick={onClickPrevious}
                  />

                  <Box
                     sx={{
                        display: "flex",
                        width: "100%",
                        height: {
                           xs: "200px",
                           sm: "300px",
                           md: "500px",
                        },
                        justifyContent: "center",
                        userSelect: "none",
                     }}
                  >
                     <img
                        src={items[activeItem].photoUrl}
                        width={500}
                        height={500}
                        style={{
                           width: "auto",
                           maxWidth: "100%",
                           height: "auto",
                           maxHeight: "100%",
                           borderRadius: "6px",
                           objectFit: "contain",
                        }}
                        alt=""
                     />
                  </Box>

                  <NovaButton
                     icon={<ArrowRight />}
                     style={{
                        minWidth: "0px",
                        backgroundColor: "transparent",
                        border: "solid 1px #d9d9d9",
                        borderRadius: "999px",
                        padding: "8px",
                        flexShrink: 0,
                     }}
                     onClick={onClickNext}
                  />
               </Box>

               <Box
                  sx={{
                     display: "flex",
                     width: "100%",
                     flexDirection: "row",
                     alignItems: "center",
                     justifyContent: "center",
                     marginBottom: "30px",
                  }}
               >
                  {items.map((_, index) => (
                     <Box
                        sx={{
                           width: "10px",
                           height: "10px",
                           borderRadius: "999px",
                           backgroundColor: index === activeItem ? "#1B1B48" : "#E9DDC8",
                           margin: "0 4px",
                           cursor: "pointer",
                        }}
                        onClick={() => setActiveItem(index)}
                        key={index}
                     />
                  ))}
               </Box>

               {items[activeItem].title && (
                  <NovaTitle
                     variant="h4"
                     style={{
                        margin: 0,
                        marginBottom: "0px",
                     }}
                  >
                     {items[activeItem].title}
                  </NovaTitle>
               )}
               {dateString && (
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        fontWeight: 300,
                        margin: 0,
                     }}
                  >
                     {dateString}
                  </NovaText>
               )}
            </Box>
         </Box>
      </Modal>
   );
}

export default memo(Gallery);
