export const ArrowLeft: React.FC<{
   color?: string;
   strokeWidth?: string;
}> = ({ color, strokeWidth }: any) => {
   return (
      <svg
         width="22"
         height="21"
         viewBox="0 0 22 21"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M12.4688 19.3594L11.4375 20.3906C10.9688 20.8125 10.2656 20.8125 9.84375 20.3906L0.703126 11.2969C0.281251 10.8281 0.281251 10.125 0.703126 9.70312L9.84375 0.562499C10.2656 0.140624 10.9688 0.140624 11.4375 0.562499L12.4688 1.59375C12.8906 2.0625 12.8906 2.76562 12.4688 3.23437L6.79688 8.625L20.25 8.625C20.9063 8.625 21.375 9.09375 21.375 9.75L21.375 11.25C21.375 11.8594 20.9063 12.375 20.25 12.375L6.79688 12.375L12.4688 17.7187C12.8906 18.1875 12.9375 18.8906 12.4688 19.3594Z"
            fill="#1B1B48"
         />
      </svg>
   );
};

export const ArrowRight: React.FC<{
   color?: string;
   strokeWidth?: string;
}> = ({ color, strokeWidth }: any) => {
   return (
      <svg
         width="22"
         height="21"
         viewBox="0 0 22 21"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M9.53125 1.64062L10.5625 0.609375C11.0312 0.1875 11.7344 0.1875 12.1562 0.609375L21.2969 9.70312C21.7188 10.1719 21.7188 10.875 21.2969 11.2969L12.1562 20.4375C11.7344 20.8594 11.0312 20.8594 10.5625 20.4375L9.53125 19.4062C9.10938 18.9375 9.10938 18.2344 9.53125 17.7656L15.2031 12.375H1.75C1.09375 12.375 0.625 11.9062 0.625 11.25V9.75C0.625 9.14062 1.09375 8.625 1.75 8.625H15.2031L9.53125 3.28125C9.10938 2.8125 9.0625 2.10938 9.53125 1.64062Z"
            fill="#1B1B48"
         />
      </svg>
   );
};
