import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, FormControl, IconButton, MenuItem, Modal, Select } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useAppDispatch } from "../../store/hooks";
import { getSingleOrder } from "../../pages/orders/async-actions";
import { OrdersSelectors } from "../../pages/orders/selectors";
import { DetailsModalFlower } from "../../components/shared/icons/details-modal-flower";
import LoaderOverlay from "../../components/LoaderOverlay";
import { AffiliatesSelectors } from "./selectors";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import { SubscriptionsSelectors } from "../subscriptions/selectors";
import { getSubscriptionPlans } from "../subscriptions/async-actions";
import NovaTextInput from "../../components/shared/NovaTextInput";
import NovaButton from "../../components/shared/NovaButton";
import { editVoucher } from "./async-actions";

const EditModal = ({ voucher, onClose }: any) => {
   const dispatch = useAppDispatch();
   const [isLoading, setIsLoading] = useState(false);
   const singleVoucherFromState = useSelector((state) =>
      AffiliatesSelectors.getSingleVoucher(state, voucher),
   );
   const loadingStart = () => {
      setIsLoading(true);
   };
   const loadingCompleted = () => {
      setIsLoading(false);
   };

   const agencies = useSelector(AffiliatesSelectors.getAffiliateUsers);
   const [agency, setAgency] = useState("");
   const [selectedPlan, setSelectedPlan] = useState("");
   const [commissionForAffiliate, setCommissionForAffiliate] = useState("");
   const [voucherCode, setVoucherCode] = useState("");
   const affiliatePlans = useSelector(
      SubscriptionsSelectors.getAffiliatesSubscriptionsList,
   );

   const handleAgencyChange = (event: any) => {
      setAgency(event.target.value);
   };

   const handleSelectedPlan = (event: any) => {
      setSelectedPlan(event.target.value);
   };

   useEffect(() => {
      if (!affiliatePlans) {
         dispatch(getSubscriptionPlans());
      }
   }, [affiliatePlans]);

   useEffect(() => {
      if (singleVoucherFromState) {
         const filteredAgency = agencies.find((agency: any) => {
            return agency.fullName === singleVoucherFromState?.generatedForUserFullName;
         })?.id;
         setSelectedPlan(singleVoucherFromState?.subscriptionPlan.id);
         setCommissionForAffiliate(singleVoucherFromState?.commissionForAffiliate);
         setVoucherCode(singleVoucherFromState?.code || "");
         setAgency(filteredAgency);
      }
   }, [singleVoucherFromState]);

   const handleAgencyCommissionChange = (event: any) => {
      setCommissionForAffiliate(event.target.value);
   };

   const handleVoucherCodeChange = (event: any) => {
      setVoucherCode(event.target.value);
   };

   const handleClose = () => {
      loadingCompleted();
      onClose();
   };

   const handleSaveEdit = () => {
      const form = {
         id: singleVoucherFromState.id,
         subscriptionPlanId: selectedPlan,
         commissionForAffiliate: Number(commissionForAffiliate),
         code: voucherCode,
         generatedForUserId: agency,
      };

      dispatch(editVoucher(form, loadingStart, handleClose));
   };
   return (
      <Modal open={true} onClose={onClose}>
         <Box
            sx={{
               position: "absolute",
               top: "50%",
               left: "50%",
               transform: "translate(-50%, -50%)",
               bgcolor: "background.paper",
               boxShadow: 24,
               borderRadius: "24px",
               display: "flex",
               alignItems: "baseline",
               minWidth: {
                  xs: "calc(100% - 20px)",
                  md: "578px",
               },
               minHeight: {
                  md: "578px",
               },
               border: "1px solid #E9DDC8",
               outline: "none",
            }}
         >
            <LoaderOverlay isLoading={isLoading}>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "start",
                     width: "100%",
                     position: "relative",
                     padding: "24px",
                  }}
               >
                  <Box
                     sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        zIndex: 10,
                     }}
                  >
                     <IconButton onClick={onClose}>
                        <Close />
                     </IconButton>
                  </Box>
                  <Box sx={{ zIndex: 10, width: "100%", height: "100%" }}>
                     едит {singleVoucherFromState.id}
                     <Box>
                        <NovaText
                           type={NovaTextTypes.MediumNormal}
                           style={{
                              marginBottom: "6px",
                           }}
                        >
                           Select agency
                        </NovaText>
                        <FormControl sx={{ minWidth: 500 }} size="small">
                           <Select
                              value={agency}
                              onChange={handleAgencyChange}
                              sx={{
                                 background: "#fff",
                              }}
                           >
                              {agencies.map((agency: any, index: number) => {
                                 return (
                                    <MenuItem value={agency.id} key={index}>
                                       {agency.fullName}
                                    </MenuItem>
                                 );
                              })}
                           </Select>
                        </FormControl>
                     </Box>
                     <Box
                        sx={{
                           width: "500px",
                           marginTop: "16px",
                        }}
                     >
                        <NovaTextInput
                           label="Voucher code"
                           value={voucherCode}
                           fullwidth
                           onChange={(event) => handleVoucherCodeChange(event)}
                        />
                     </Box>
                     <Box>
                        <NovaText
                           type={NovaTextTypes.MediumNormal}
                           style={{
                              marginBottom: "6px",
                           }}
                        >
                           Subscription plan
                        </NovaText>
                        <FormControl sx={{ minWidth: 500 }} size="small">
                           <Select
                              value={selectedPlan}
                              onChange={handleSelectedPlan}
                              sx={{
                                 background: "#fff",
                              }}
                           >
                              {affiliatePlans?.map((plan: any, index: number) => {
                                 return (
                                    <MenuItem value={plan.id} key={index}>
                                       {plan.name}
                                    </MenuItem>
                                 );
                              })}
                           </Select>
                        </FormControl>
                     </Box>
                     <Box
                        sx={{
                           width: "500px",
                           marginTop: "16px",
                        }}
                     >
                        <NovaTextInput
                           label="Commission for the agency"
                           value={commissionForAffiliate}
                           fullwidth
                           onChange={(event) => handleAgencyCommissionChange(event)}
                        />
                     </Box>
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: {
                              xs: "column",
                              sm: "row",
                           },
                           alignItems: "center",
                           gap: "16px",
                           marginTop: "20px",
                        }}
                     >
                        <NovaButton
                           style={{
                              width: "100%",
                              boxSizing: "border-box",
                              borderRadius: "34px",
                           }}
                           onClick={handleSaveEdit}
                        >
                           Save
                        </NovaButton>
                     </Box>
                  </Box>

                  <Box
                     sx={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        zIndex: 1,
                     }}
                  >
                     <DetailsModalFlower />
                  </Box>
               </Box>
            </LoaderOverlay>
         </Box>
      </Modal>
   );
};

export default EditModal;
