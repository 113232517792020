import { Box } from "@mui/material";
import { memo } from "react";
import NovaText, { NovaTextTypes } from "./shared/NovaText";

type LoaderProps = {
   /** @default "Loading" */
   text?: string;
   /** @default 40 */
   size?: number;
};

function Loader({ text = "Loading", size = 40 }: LoaderProps) {
   const color = "#151623";

   return (
      <Box
         sx={{
            display: "flex",
            width: "fit-content",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
         }}
      >
         <Box
            sx={{
               position: "relative",
               width: `${size}px`,
               height: `${size}px`,
               backgroundColor: color,
               margin: `${size / 2}px 0px`,
               transform: "rotate(45deg)",
               animation: "pump 0.8s infinite",

               ":before": {
                  content: `""`,
                  position: "absolute",
                  backgroundColor: color,
                  width: `${size}px`,
                  height: `${size}px`,
                  top: 0,
                  left: -size / 2,
                  borderRadius: "999px",
               },
               ":after": {
                  content: `""`,
                  position: "absolute",
                  backgroundColor: color,
                  width: `${size}px`,
                  height: `${size}px`,
                  top: -size / 2,
                  left: 0,
                  borderRadius: "999px",
               },

               "@keyframes pump": {
                  "0%": {
                     transform: "rotate(45deg) scale(1)",
                  },
                  "50%": {
                     transform: "rotate(45deg) scale(1.15)",
                  },
                  "100%": {
                     transform: "rotate(45deg) scale(1)",
                  },
               },
            }}
         />

         <NovaText
            type={NovaTextTypes.BaseNormal}
            style={{
               textAlign: "center",
               margin: 0,
            }}
         >
            {text}
         </NovaText>
      </Box>
   );
}

export default memo(Loader);
