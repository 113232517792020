import { useState, MouseEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import { Button } from "@mui/material";
import { ChevronLeft, ChevronRight, Download, Edit } from "@mui/icons-material";
import { useAppDispatch } from "../../store/hooks";
import NovaButton from "../../components/shared/NovaButton";
import EditModal from "./edit-modal";
import { generateVoucherQR, payVoucher } from "./async-actions";
import { AffiliatesSelectors } from "./selectors";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
   if (b[orderBy] < a[orderBy]) {
      return -1;
   }
   if (b[orderBy] > a[orderBy]) {
      return 1;
   }
   return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
   order: Order,
   orderBy: Key,
): (
   a: { [key in Key]: number | string },
   b: { [key in Key]: number | string },
) => number {
   return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
   const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
   stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
         return order;
      }
      return a[1] - b[1];
   });
   return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
   columns: any;
   numSelected: number;
   onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
   onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
   order: Order;
   orderBy: string;
   rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
   const {
      columns,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
   } = props;
   const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
   };

   return (
      <TableHead
         sx={{
            background: "rgba(0, 0, 0, 6%)",
         }}
      >
         <TableRow>
            {columns.map((column: any) => (
               <TableCell
                  key={column.id}
                  align="center"
                  padding={column.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === column.dateCreated ? order : false}
               >
                  <TableSortLabel
                     active={orderBy === column.id}
                     direction={orderBy === column.id ? order : "asc"}
                     onClick={createSortHandler(column.id)}
                  >
                     {column.label}
                     {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                           {order === "desc" ? "sorted descending" : "sorted ascending"}
                        </Box>
                     ) : null}
                  </TableSortLabel>
               </TableCell>
            ))}
         </TableRow>
      </TableHead>
   );
}

interface EnhancedTableToolbarProps {
   numSelected: number;
}

const VouchersTable = ({
   columns,
   rows,
}: {
   hasActions?: boolean;
   columns: any;
   rows: any;
}) => {
   const dispatch = useAppDispatch();
   const qr = useSelector(AffiliatesSelectors.getAffiliateQR);

   const [order, setOrder] = useState<Order>("asc");
   const [orderBy, setOrderBy] = useState("id");
   const [selected, setSelected] = useState<readonly number[]>([]);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(12);

   const [anchorEl, setAnchorEl] = useState<{
      [key: number]: HTMLElement | null;
   }>({});
   const open = Boolean(anchorEl);
   const handleRowSettingsClick = (event: MouseEvent<HTMLElement>, id: any) => {
      event.stopPropagation();
      setAnchorEl((prevState) => ({
         ...prevState,
         [id]: event.currentTarget,
      }));
   };

   const handleRowSettingClose = (id: any) => {
      setAnchorEl((prevState) => ({
         ...prevState,
         [id]: null,
      }));
   };

   const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
   };

   const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
         const newSelected = rows.map((n: any) => n.id);
         setSelected(newSelected);
         return;
      }
      setSelected([]);
   };

   const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected: readonly number[] = [];

      if (selectedIndex === -1) {
         newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
         newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
         );
      }

      setSelected(newSelected);
   };

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   const isSelected = (id: number) => selected.indexOf(id) !== -1;

   // Avoid a layout jump when reaching the last page with empty rows.
   const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

   // const visibleRows = useMemo(
   //   () =>
   //     stableSort(rows, getComparator(order, orderBy)).slice(
   //       page * rowsPerPage,
   //       page * rowsPerPage + rowsPerPage
   //     ),
   //   [order, orderBy, page, rowsPerPage]
   // );

   const handleFirstPageButtonClick = () => {
      setPage(0);
   };

   const handleBackButtonClick = () => {
      setPage((prevPage) => prevPage - 1);
   };

   const handleNextButtonClick = () => {
      setPage((prevPage) => prevPage + 1);
   };

   const handleLastPageButtonClick = () => {
      setPage(Math.max(0, Math.ceil(rows.length / rowsPerPage) - 1));
   };

   const totalPages = Math.ceil(rows.length / rowsPerPage);
   let startPage = Math.max(0, page - 2);
   let endPage = Math.min(startPage + 5, totalPages - 1);
   if (endPage - startPage < 5) {
      startPage = Math.max(0, endPage - 5);
   }

   const navigate = useNavigate();
   const handleRowClick = (event: any, id: number) => {
      //   return navigate(`../product/${id}`);
   };

   const handlePayClick = (id: string) => {
      dispatch(payVoucher(id));
   };
   const handleDownloadQR = (id: string) => {
      dispatch(generateVoucherQR(id, ""));
   };
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [selectedVoucherToEdit, setSelectedVoucherToEdit] = useState(false);
   const handleEdit = (voucher: any) => {
      setSelectedVoucherToEdit(voucher);
      setIsModalOpen(true);
   };

   const handleCloseEditModal = () => {
      setIsModalOpen(false);
   };

   useEffect(() => {
      if (qr) {
         const blob = new Blob([qr], { type: "image/svg+xml" });
         const link = document.createElement("a");
         link.download = "image.svg";
         link.href = URL.createObjectURL(blob);
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
      }
   }, [qr]);

   return (
      <Box sx={{ width: "100%" }}>
         <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
            <TableContainer>
               <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                  <EnhancedTableHead
                     columns={columns}
                     numSelected={selected.length}
                     order={order}
                     orderBy={orderBy}
                     onSelectAllClick={handleSelectAllClick}
                     onRequestSort={handleRequestSort}
                     rowCount={rows.length}
                  />
                  <TableBody>
                     {rows.map((row: any, index: any) => {
                        const isItemSelected = isSelected(row.id);
                        const rowKeys = [
                           "code",
                           "memorialPageId",
                           "subscriptionPlan",
                           "priceForAffiliate",
                           "generatedForUserFullName",
                           "commissionForAffiliate",
                           "used",
                           "payed",
                        ];

                        return (
                           <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                              sx={{ cursor: "pointer" }}
                           >
                              {rowKeys.map((key, index) => {
                                 if (key === "memorialPageId") {
                                    return (
                                       <TableCell
                                          align="left"
                                          key={index}
                                          onClick={(event) =>
                                             handleRowClick(event, row.id)
                                          }
                                       >
                                          <Link
                                             to={`https://novamemorial.com/${row[key]}`}
                                             target="_blank"
                                          >
                                             {row[key]}
                                          </Link>
                                       </TableCell>
                                    );
                                 }
                                 if (key === "subscriptionPlan") {
                                    return (
                                       <TableCell
                                          align="left"
                                          key={index}
                                          onClick={(event) =>
                                             handleRowClick(event, row.id)
                                          }
                                       >
                                          {row?.subscriptionPlan?.name}
                                       </TableCell>
                                    );
                                 }
                                 if (key === "priceForAffiliate") {
                                    return (
                                       <TableCell
                                          align="left"
                                          key={index}
                                          onClick={(event) =>
                                             handleRowClick(event, row.id)
                                          }
                                       >
                                          {row?.subscriptionPlan?.priceForAffiliate}
                                       </TableCell>
                                    );
                                 }

                                 if (key === "used") {
                                    return (
                                       <TableCell
                                          align="left"
                                          key={index}
                                          onClick={(event) =>
                                             handleRowClick(event, row.id)
                                          }
                                       >
                                          {row[key] === false ? "Not used yet" : "Used"}
                                       </TableCell>
                                    );
                                 }

                                 if (key === "payed") {
                                    return (
                                       <TableCell
                                          align="left"
                                          key={index}
                                          onClick={(event) =>
                                             handleRowClick(event, row.id)
                                          }
                                       >
                                          {row[key] === false ? "Not payed yet" : "Payed"}
                                       </TableCell>
                                    );
                                 }

                                 if (key === "commissionForAffiliate") {
                                    return (
                                       <TableCell
                                          align="left"
                                          key={index}
                                          onClick={(event) =>
                                             handleRowClick(event, row.id)
                                          }
                                       >
                                          {row[key]}&nbsp;BGN
                                       </TableCell>
                                    );
                                 }
                                 if (key === "pageId") {
                                    return (
                                       <TableCell
                                          align="left"
                                          key={index}
                                          onClick={(event) =>
                                             handleRowClick(event, row.id)
                                          }
                                       >
                                          <Link
                                             to={`https://novamemorial.com/${row[key]}`}
                                             target="_blank"
                                          >
                                             {row[key]}
                                          </Link>
                                       </TableCell>
                                    );
                                 }
                                 return (
                                    <TableCell
                                       align="left"
                                       key={index}
                                       onClick={(event) => handleRowClick(event, row.id)}
                                    >
                                       {row[key]}
                                    </TableCell>
                                 );
                              })}
                              <TableCell align="right">
                                 <IconButton
                                    onClick={() => handleDownloadQR(row.memorialPageId)}
                                 >
                                    <Download
                                       sx={{
                                          fill: "#CAA45D",
                                       }}
                                    />
                                 </IconButton>
                              </TableCell>
                              <TableCell align="right">
                                 <Box
                                    sx={{
                                       display: "flex",
                                       alignItems: "center",
                                       gap: "8px",
                                    }}
                                 >
                                    <IconButton onClick={() => handleEdit(row.id)}>
                                       <Edit
                                          sx={{
                                             fill: "#CAA45D",
                                          }}
                                       />
                                    </IconButton>
                                    {!row.payed && (
                                       <NovaButton
                                          style={{
                                             borderRadius: "32px",
                                             padding: "4px 8px",
                                          }}
                                          onClick={() => handlePayClick(row.id)}
                                       >
                                          Pay
                                       </NovaButton>
                                    )}
                                 </Box>
                              </TableCell>
                           </TableRow>
                        );
                     })}
                     {emptyRows > 0 && (
                        <TableRow
                           style={{
                              height: 53 * emptyRows,
                           }}
                        >
                           <TableCell colSpan={6} />
                        </TableRow>
                     )}
                  </TableBody>
               </Table>
            </TableContainer>
            <Box
               sx={{
                  width: "100%",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "16px 0",
                  gap: "8px",
               }}
            >
               <IconButton
                  sx={{
                     minWidth: "32px",
                     width: "32px",
                     height: "32px",
                  }}
                  disabled={page === 0}
                  onClick={handleBackButtonClick}
               >
                  <ChevronLeft />
               </IconButton>
               {Array.from(Array(endPage - startPage + 1).keys()).map((pageNumber) => (
                  <Button
                     key={startPage + pageNumber}
                     onClick={() => handleChangePage(null, startPage + pageNumber)}
                     aria-label={`page ${startPage + pageNumber + 1}`}
                     disabled={startPage + pageNumber === page}
                     sx={{
                        minWidth: "32px",
                        height: "32px",
                        padding: "0",
                        color: "rgba(0, 0, 0, 88%)",
                        ...(startPage + pageNumber === page && {
                           color: `#000 !important`,
                           border: `1px solid #000`,
                        }),
                     }}
                  >
                     {startPage + pageNumber + 1}
                  </Button>
               ))}
               <IconButton
                  sx={{
                     minWidth: "32px",
                     width: "32px",
                     height: "32px",
                  }}
                  disabled={page >= totalPages - 1}
                  onClick={handleNextButtonClick}
               >
                  <ChevronRight />
               </IconButton>
            </Box>
            {isModalOpen && (
               <EditModal
                  voucher={selectedVoucherToEdit}
                  onClose={handleCloseEditModal}
               />
            )}
         </Paper>
      </Box>
   );
};

export default VouchersTable;
