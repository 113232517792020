export const MenuDots: React.FC<{
   color?: string;
   strokeWidth?: string;
}> = ({ color, strokeWidth }: any) => {
   return (
      <svg
         style={{
            display: "block",
         }}
         width="16"
         height="5"
         viewBox="0 0 16 5"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M10.25 2.5C10.25 1.875 10.0312 1.34375 9.59375 0.90625C9.15625 0.46875 8.625 0.25 8 0.25C7.375 0.25 6.84375 0.46875 6.40625 0.90625C5.96875 1.34375 5.75 1.875 5.75 2.5C5.75 3.125 5.96875 3.65625 6.40625 4.09375C6.84375 4.53125 7.375 4.75 8 4.75C8.625 4.75 9.15625 4.53125 9.59375 4.09375C10.0312 3.65625 10.25 3.125 10.25 2.5ZM13.5 0.25C14.125 0.25 14.6562 0.46875 15.0938 0.90625C15.5312 1.34375 15.75 1.875 15.75 2.5C15.75 3.125 15.5312 3.65625 15.0938 4.09375C14.6562 4.53125 14.125 4.75 13.5 4.75C12.875 4.75 12.3438 4.53125 11.9062 4.09375C11.4688 3.65625 11.25 3.125 11.25 2.5C11.25 1.875 11.4688 1.34375 11.9062 0.90625C12.3438 0.46875 12.875 0.25 13.5 0.25ZM2.5 0.25C3.125 0.25 3.65625 0.46875 4.09375 0.90625C4.53125 1.34375 4.75 1.875 4.75 2.5C4.75 3.125 4.53125 3.65625 4.09375 4.09375C3.65625 4.53125 3.125 4.75 2.5 4.75C1.875 4.75 1.34375 4.53125 0.90625 4.09375C0.46875 3.65625 0.25 3.125 0.25 2.5C0.25 1.875 0.46875 1.34375 0.90625 0.90625C1.34375 0.46875 1.875 0.25 2.5 0.25Z"
            fill="#1B1B48"
         />
      </svg>
   );
};
