import { Button } from "@mui/material";

const NovaButton = ({
   startIcon,
   children,
   disabled,
   font,
   padding,
   background,
   color,
   border,
   style,
   icon,
   disableRipple,
   onClick,
}: any) => {
   return (
      <Button
         disabled={disabled}
         startIcon={startIcon && startIcon}
         sx={{
            background:
               background && !disabled ? background : disabled ? "#F3F5F7" : "#1B1B48",
            color: color && !disabled ? color : disabled ? "#CAD1E2" : "#CAA45D",
            fontSize: font ? font : "16px",
            fontWeight: 400,
            lineHeight: "27px",
            padding: padding ? padding : "10.5px 46px",
            display: "inline-flex",
            alignItems: "center",
            boxSizing: "content-box",
            border: border ? border : "none",
            ":hover": {
               // border: "1px solid #F3F5F7",
               border: "none",
            },
            ...style,
         }}
         variant="outlined"
         disableRipple={disableRipple}
         onClick={onClick}
      >
         {icon}
         {children}
      </Button>
   );
};

export default NovaButton;
