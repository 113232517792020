export const EditIcon: React.FC<{
   color?: string;
   strokeWidth?: string;
}> = ({ color, strokeWidth }: any) => {
   return (
      <svg
         style={{
            display: "block",
            marginRight: "8px",
         }}
         width="15"
         height="15"
         viewBox="0 0 15 15"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M8.95703 3.29297L12.457 6.79297L4.85547 14.3945L1.73828 14.75C1.51953 14.7773 1.32812 14.7227 1.19141 14.5586C1.02734 14.4219 0.972656 14.2305 1 14.0117L1.35547 10.8945L8.95703 3.29297ZM14.6172 2.77344L12.9766 1.13281C12.7031 0.886719 12.4023 0.75 12.0469 0.75C11.6641 0.75 11.3633 0.886719 11.1172 1.13281L9.55859 2.69141L13.0586 6.19141L14.6172 4.63281C14.8633 4.38672 15 4.08594 15 3.70312C15 3.34766 14.8633 3.04688 14.6172 2.77344Z"
            fill="#1B1B48"
         />
      </svg>
   );
};
