import { Link } from "react-router-dom";

const NovaLink = ({ to, style, children }: any) => {
   return (
      <Link to={to} style={{ ...style }}>
         {children}
      </Link>
   );
};

export default NovaLink;
