import api from "./withAuth";

const getVouchers = async () => {
   return await api.get("vouchers");
};

const createVoucher = async (
   commissionForAffiliate: number,
   subscriptionPlanId: number,
) => {
   return await api.post("vouchers/create", {
      commissionForAffiliate,
      subscriptionPlanId,
   });
};

const useVoucher = async (code: string) => {
   return await api.post(`vouchers/${code}/use`);
};

const editVoucher = async ({
   id,
   generatedForUserId,
   subscriptionPlanId,
   commissionForAffiliate,
   payed,
   code,
}: {
   id: number;
   generatedForUserId: number;
   subscriptionPlanId: number;
   commissionForAffiliate: number;
   payed: boolean;
   code: string;
}) => {
   return await api.put("vouchers", {
      id,
      generatedForUserId,
      subscriptionPlanId,
      commissionForAffiliate,
      payed,
      code,
   });
};

const sellVoucher = async (id: number) => {
   return await api.post(`vouchers/${id}/sell`);
};

const getAffiliateVouchers = async () => {
   return await api.get("users/vouchers/affiliate");
};

const getAffiliateUsers = async () => {
   return await api.get("users/getUsers/affiliate");
};

const payVoucher = async (id: any) => {
   return await api.post(`vouchers/${id}/pay`);
};

export const affiliatesAPI = {
   getVouchers,
   createVoucher,
   useVoucher,
   editVoucher,
   sellVoucher,
   getAffiliateVouchers,
   getAffiliateUsers,
   payVoucher,
};
