import { Box } from "@mui/material";
import { memo } from "react";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";

type Children = string | number | undefined | React.JSX.Element | Children[];

type InfoItemProps = {
   icons?: Children;
   aboveTitle?: string;
   title?: string;
   listItems?: any;
};

const InfoItem = memo(function InfoItem({ icons, listItems, title }: InfoItemProps) {
   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
         }}
      >
         <Box
            sx={{
               display: "flex",
               width: "50px",
               height: "50px",
               backgroundColor: "#CAA45D",
               borderRadius: "999px",
               alignItems: "center",
               justifyContent: "center",
               flexShrink: 0,
            }}
         >
            {icons}
         </Box>

         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
            }}
         >
            {title && (
               <NovaText
                  type={NovaTextTypes.XLNormal}
                  style={{
                     margin: 0,
                     color: "#23262F",
                  }}
               >
                  {title}
               </NovaText>
            )}
            {listItems?.length && (
               <ul
                  style={{
                     paddingLeft: "20px",
                  }}
               >
                  {listItems.map((item: string) => (
                     <li key={item}>
                        <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
                           {item}
                        </NovaText>
                     </li>
                  ))}
               </ul>
            )}
         </Box>
      </Box>
   );
});

export default InfoItem;
