import { Link } from "react-router-dom";
import { Box } from "@mui/material";

import NovaText, { NovaTextTypes } from "../../../components/shared/NovaText";
import MarkdownRenderer from "../../../components/md-editor/markdown-renderer";

const ProductCard = (product: any) => {
   return (
      <Box
         sx={{
            borderRadius: "16px",
            overflow: "hidden",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
            background: "#fff",
         }}
      >
         <Box
            sx={{
               height: "200px",
               background: `url(${product?.photos?.[0]?.photoUrl})`,
               backgroundSize: "cover",
               backgroundPosition: "center center",
            }}
         />

         <Link
            to={`../product/${product.id}`}
            style={{
               textDecoration: "none",
               color: "#000",
            }}
         >
            <Box
               sx={{
                  padding: "18px 16px",
                  background: "#fff",
                  borderBottomLeftRadius: "16px",
                  borderBottomRightRadius: "16px",
               }}
            >
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "space-between",
                  }}
               >
                  <NovaText
                     type={NovaTextTypes.XLNormal}
                     style={{
                        margin: 0,
                        fontFamily: "Cormorant Infant, serif",
                        fontWeight: "bold",
                        display: "-webkit-box",
                        maxWidth: "100%",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                     }}
                  >
                     {product.name}
                  </NovaText>

                  <Box
                     sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "100%",
                        border: "3px solid #fff",
                        background: product.color,
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,.3)",
                        flex: "0 0 auto",
                     }}
                  />
               </Box>

               <MarkdownRenderer markdownText={product.description} planeText>
                  {(parsedText) => (
                     <NovaText
                        type={NovaTextTypes.BaseNormal}
                        style={{
                           margin: "6px 0 0 0",
                           display: "-webkit-box",
                           maxWidth: "100%",
                           WebkitLineClamp: "2",
                           WebkitBoxOrient: "vertical",
                           overflow: "hidden",
                        }}
                     >
                        {parsedText}
                     </NovaText>
                  )}
               </MarkdownRenderer>

               <div
                  style={{
                     display: "flex",
                     alignItems: "center",
                     marginTop: "8px",
                  }}
               >
                  <div
                     style={{
                        height: "2px",
                        width: "30px",
                        background: "#CAA45D",
                        display: "inline-block",
                     }}
                  />
                  <NovaText
                     type={NovaTextTypes.LGBold}
                     style={{
                        margin: "0 0 0 10px",
                        color: "#CAA45D",
                     }}
                  >
                     {product.price} BGN
                  </NovaText>
               </div>
            </Box>
         </Link>
      </Box>
   );
};

export default ProductCard;
