import { Box, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UpgradeStep from "./upgrade-step";
import PlansStep from "./plans-step";
import UpgradeImageBackground from "../../assets/upgrade-image-background.png";
import ConfirmStep from "./confirm-step";
import { useAppDispatch } from "../../store/hooks";
import {
   getCards,
   getPublicSubscriptionPlans,
} from "../../pages/subscriptions/async-actions";
import { SubscriptionsSelectors } from "../../pages/subscriptions/selectors";
import { LoginSelectors } from "../../pages/Login/selectors";
import FinishSuccessfulStep from "./finish-successful-step";
import FinishProblemStep from "./finish-problem-step";

export enum STEPS {
   UPGRADE,
   PLANS,
   CONFIRM,
   FINISH_SUCCESSFUL,
   FINISH_PROBLEM,
}

const UpgradeModal = ({
   page,
   open,
   onClose,
}: {
   page: any;
   open: boolean;
   onClose: () => void;
}) => {
   const dispatch = useAppDispatch();
   const [step, setStep] = useState(STEPS.UPGRADE);
   const [selectedPlan, setSelectedPlan] = useState<any>(null);

   const plans = useSelector(SubscriptionsSelectors.getSubscriptionsList);
   const user = useSelector(LoginSelectors.getUser);
   const cards = useSelector(SubscriptionsSelectors.getCards);

   const handleChangeStep = (step: any) => {
      setStep(step);
   };

   const resetActiveStep = () => {
      setStep(STEPS.UPGRADE);
      setSelectedPlan(null);
   };

   const handlePlanSelect = (id: any) => {
      const planFromState = plans.find((p: any) => p.id === id);
      setSelectedPlan(planFromState);
   };

   useEffect(() => {
      dispatch(getPublicSubscriptionPlans());
      dispatch(getCards());
   }, []);

   if (!page || !plans) return null;

   return (
      <Modal
         sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            outline: "none",
         }}
         open={open}
         onClose={() => {
            resetActiveStep();
            onClose();
         }}
      >
         <Box
            sx={{
               position: "absolute",
               top: "50%",
               left: "50%",
               transform: "translate(-50%, -50%)",
               width: "1024px",
               maxWidth: "calc(100% - 40px)",
               bgcolor: "background.paper",
               borderRadius: "24px",
               boxShadow: 24,
               padding: "24px 17px",
               backgroundImage: `url(${UpgradeImageBackground})`,
               backgroundSize: "contain",
               backgroundRepeat: "no-repeat",
               backgroundPosition: "center center",
               outline: "none",
               maxHeight: "100vh",
               overflow: {
                  xs: "hidden",
                  sm: "scroll",
               },
            }}
         >
            {step === STEPS.UPGRADE && (
               <UpgradeStep
                  data={{
                     name: page?.fullName,
                  }}
                  onClose={() => {
                     resetActiveStep();
                     onClose();
                  }}
                  onStepChange={handleChangeStep}
               />
            )}
            {step === STEPS.PLANS && (
               <PlansStep
                  page={page}
                  plans={plans}
                  selectedPlan={selectedPlan}
                  onPlanSelect={handlePlanSelect}
                  onStepChange={handleChangeStep}
               />
            )}
            {step === STEPS.CONFIRM && (
               <ConfirmStep
                  user={user}
                  data={{
                     pageId: page?.id,
                  }}
                  cards={cards}
                  selectedPlan={selectedPlan}
                  onStepChange={handleChangeStep}
               />
            )}
            {step === STEPS.FINISH_SUCCESSFUL && (
               <FinishSuccessfulStep
                  data={{
                     pageId: page?.id,
                  }}
               />
            )}
            {step === STEPS.FINISH_PROBLEM && (
               <FinishProblemStep onStepChange={handleChangeStep} />
            )}
         </Box>
      </Modal>
   );
};

export default UpgradeModal;
