import * as Immutable from "seamless-immutable";
import { GET_ALL_PAYMENTS_SUCCESS } from "./types";

const initialState = Immutable.from<any>({
   list: [],
   single: null,
});

export default function PaymentsReducer(state = initialState, action: any) {
   const { type, payload } = action;
   switch (type) {
      case GET_ALL_PAYMENTS_SUCCESS: {
         return state.setIn(["list"], payload);
      }

      default:
         return state;
   }
}
