import { memo, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
   Box,
   ClickAwayListener,
   IconButton,
   Menu,
   MenuItem,
   Tooltip,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useAppDispatch } from "../../store/hooks";
import { getMonthName } from "../../utils/functions";
import { requestAccess } from "../../pages/single-memorial-page/async-actions";

import NovaText, { NovaTextTypes } from "../shared/NovaText";
import NovaTitle from "../shared/NovaTitle";
import ShareButtons from "./ShareButtons";
import { EditIcon } from "../shared/icons/edit";
import { ReportIcon } from "../shared/icons/report";
import { MenuDots } from "../shared/icons/menu-dots";
import NovaButton from "../shared/NovaButton";
import { useSelector } from "react-redux";
import { SingleMemorialPageSelectors } from "../../pages/single-memorial-page/selectors";
import NovaTextInput from "../shared/NovaTextInput";
import { OpenInNew } from "@mui/icons-material";
import TranslatedText from "../translated-text";
import { useTranslation } from "react-i18next";

type PersonalMemorialHeaderProps = {
   account: any;
   isMine: boolean;
   pageId: number;
};

function PersonalMemorialHeader({
   account,
   isMine,
   pageId,
}: PersonalMemorialHeaderProps) {
   const { t } = useTranslation();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const [linkCopied, setLinkCopied] = useState(false);
   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

   const pageData = useSelector(SingleMemorialPageSelectors.getSinglePage);

   const externalShareLink = decodeURIComponent(
      `https://novamemorial.${pageData.language}/${pageData.publicID}`,
   );

   const onClickEdit = useCallback(
      () => navigate(`../memorial-page/edit/${pageId}`),
      [navigate, pageId],
   );
   const onClickRequestAccess = useCallback(
      () => dispatch(requestAccess(pageId)),
      [pageId, dispatch],
   );
   const onClickReportProfile = useCallback(() => {}, []);

   const dateString = useMemo<string>(() => {
      if (!account) return "";

      const birthDay = new Date(account.birthDay);
      const deathDay = account.deathDay ? new Date(account.deathDay) : undefined;

      return `${t(
         `personal-memorial-page.dates.months.${birthDay.getMonth()}`,
      )} ${birthDay.getDate()}, ${birthDay.getFullYear()}${
         deathDay
            ? ` - ${t(
                 `personal-memorial-page.dates.months.${deathDay.getMonth()}`,
              )} ${deathDay.getDate()}, ${deathDay.getFullYear()}`
            : ""
      }`;
   }, [account, t]);

   const onClickLinkShare = useCallback(() => {
      setLinkCopied(true);
      navigator.clipboard.writeText(externalShareLink);
      setTimeout(() => {
         setLinkCopied(false);
      }, 3000);
   }, [externalShareLink]);

   const handleTooltipClose = () => {
      setLinkCopied(false);
   };

   const onViewButtonClick = useCallback(() => {
      window?.open(`${externalShareLink}`, "_blank");
   }, [externalShareLink]);

   return (
      <>
         <Box
            sx={{
               display: "flex",
               flexDirection: {
                  xs: "column",
                  md: "row",
               },
               alignItems: "baseline",
               gap: "20px",
            }}
         >
            <Box
               sx={{
                  width: {
                     xs: "100%",
                     md: "200px",
                  },
                  height: {
                     xs: "240px",
                     md: "200px",
                  },
                  backgroundColor: "#F5F5F5",
                  borderRadius: "16px",
                  marginRight: {
                     xs: "0",
                     lg: "60px",
                  },
                  flexShrink: 0,
                  overflow: "hidden",
                  alignSelf: "flex-start",
               }}
            >
               {account?.profilePhotoUrl ? (
                  <img
                     src={account.profilePhotoUrl}
                     alt="Profile"
                     width={200}
                     height={200}
                     style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                     }}
                  />
               ) : (
                  <Box
                     sx={{
                        width: "200px",
                        height: "200px",
                        borderRadius: "16px",
                     }}
                  />
               )}
            </Box>

            <Box
               sx={{
                  width: "100%",
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  order: {
                     xs: 2,
                     md: 1,
                  },
               }}
            >
               {account?.deathDay && (
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        color: "#777E90",
                        margin: 0,
                     }}
                  >
                     <TranslatedText text="personal-memorial-page.person-name-pretext" />
                  </NovaText>
               )}
               <NovaTitle
                  variant="h4"
                  style={{
                     margin: 0,
                  }}
               >
                  {account?.fullName}
               </NovaTitle>
               <NovaText
                  type={NovaTextTypes.MediumLight}
                  style={{
                     color: "#1F1F1F",
                     margin: 0,
                  }}
               >
                  {dateString}
               </NovaText>
               <NovaText
                  type={NovaTextTypes.BaseNormal}
                  style={{
                     color: "#CAA45D",
                     margin: 0,
                  }}
               >
                  {account?.city}
               </NovaText>

               <Box
                  sx={{
                     marginBottom: "12px",
                  }}
               >
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        margin: 0,
                     }}
                  >
                     {account?.briefTribute}
                  </NovaText>
               </Box>

               <ShareButtons />
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "baseline",
                     justifyContent: "flex-start",
                  }}
               >
                  <NovaTextInput
                     value={externalShareLink}
                     disabled
                     style={{
                        width: "100%",
                        maxWidth: "300px",
                        marginBottom: "16px",
                     }}
                  />
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                     }}
                  >
                     <NovaButton
                        style={{
                           padding: "4px 12px",
                           borderRadius: "32px",
                           border: "1px solid #CAA45D",
                           background: "transparent",
                           ":hover": {
                              border: "1px solid #CAA45D",
                           },
                        }}
                        onClick={onViewButtonClick}
                        icon={
                           <OpenInNew
                              sx={{
                                 fill: "#CAA45D",
                                 fontSize: "16px",
                              }}
                           />
                        }
                     >
                        <NovaText
                           type={NovaTextTypes.BaseNormal}
                           style={{ margin: "0 0 0 4px", fontWeight: "500" }}
                        >
                           <TranslatedText text="personal-memorial-page.view" />
                        </NovaText>
                     </NovaButton>
                     <ClickAwayListener onClickAway={handleTooltipClose}>
                        <Tooltip
                           PopperProps={{
                              disablePortal: true,
                           }}
                           onClose={handleTooltipClose}
                           open={linkCopied}
                           disableFocusListener
                           disableHoverListener
                           disableTouchListener
                           title={
                              <TranslatedText text="personal-memorial-page.link-copied" />
                           }
                        >
                           <Box>
                              <NovaButton
                                 style={{
                                    padding: "4px 12px",
                                    borderRadius: "32px",
                                 }}
                                 onClick={onClickLinkShare}
                                 icon={
                                    <ContentCopyIcon
                                       sx={{ fill: "#CAA45D", fontSize: "16px" }}
                                    />
                                 }
                              >
                                 <NovaText
                                    type={NovaTextTypes.BaseNormal}
                                    style={{ margin: "0 0 0 4px", fontWeight: "500" }}
                                 >
                                    <TranslatedText text="personal-memorial-page.copy-link" />
                                 </NovaText>
                              </NovaButton>
                           </Box>
                        </Tooltip>
                     </ClickAwayListener>
                  </Box>
               </Box>
            </Box>

            <Box
               sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: 0,
                  order: {
                     xs: 1,
                     md: 2,
                  },
               }}
            >
               <IconButton
                  sx={{
                     alignSelf: "flex-start",
                     border: "1px solid #CAA45D",
                     borderRadius: "999px",
                     padding: "12px 16px",
                  }}
                  aria-controls={anchorEl ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={anchorEl ? "true" : undefined}
                  onClick={(event) => setAnchorEl(event.currentTarget)}
               >
                  <MenuDots />
               </IconButton>
            </Box>
         </Box>

         <Menu
            anchorEl={anchorEl}
            open={anchorEl !== null}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
               elevation: 0,
               sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                     width: 32,
                     height: 32,
                     ml: -0.5,
                     mr: 1,
                  },
                  "&::before": {
                     content: '""',
                     display: "block",
                     position: "absolute",
                     top: 0,
                     right: 19,
                     width: 10,
                     height: 10,
                     bgcolor: "background.paper",
                     transform: "translateY(-50%) rotate(45deg)",
                     zIndex: 0,
                  },
               },
            }}
            MenuListProps={{
               sx: {
                  minWidth: "200px",
               },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
         >
            {isMine ? (
               <MenuItem onClick={onClickEdit}>
                  <EditIcon />
                  <TranslatedText text="buttons.edit" />
               </MenuItem>
            ) : (
               <>
                  <MenuItem onClick={onClickRequestAccess}>
                     <EditIcon />
                     <TranslatedText text="buttons.request-access" />
                  </MenuItem>
                  <MenuItem onClick={onClickReportProfile}>
                     <ReportIcon />
                     <TranslatedText text="personal-memorial-page.report-profile" />
                  </MenuItem>
               </>
            )}
         </Menu>
      </>
   );
}

export default memo(PersonalMemorialHeader);
