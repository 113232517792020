import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { featuredMemorials } from "../../constants/app";

import NovaButton from "../shared/NovaButton";
import TranslatedText from "../translated-text";
import NovaText, { NovaTextTypes } from "../shared/NovaText";

const CreateMemorialCard = ({ onClick }: { onClick: () => void }) => {
   const navigate = useNavigate();

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            minHeight: "310px",
            border: "1px dashed #CAA45D",
            padding: "0 34px",
            borderRadius: "16px",
         }}
      >
         <NovaButton
            style={{
               background: "#1B1B48",
               color: "#CAA45D",
               textDecoration: "none",
               padding: "8px 10px",
               textTransform: "none",
               width: "100%",
               borderRadius: "32px",
               boxSizing: "content-box",
               textAlign: "center",
            }}
            onClick={onClick}
         >
            <TranslatedText text="buttons.create-memorial" />
         </NovaButton>
         <NovaButton
            style={{
               padding: "8px 10px",
               textTransform: "none",
               width: "100%",
               borderRadius: "32px",
               background: "#fff",
               border: "1px solid #CAA45D",
               ":hover": {
                  border: "1px solid #CAA45D",
               },
            }}
            onClick={() =>
               navigate(
                  `/memorial-page/${
                     featuredMemorials[
                        Math.floor(Math.random() * featuredMemorials.length)
                     ]
                  }`,
               )
            }
         >
            <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
               <TranslatedText text="buttons.see-example" />
            </NovaText>
         </NovaButton>
      </Box>
   );
};

export default CreateMemorialCard;
