import { useNavigate } from "react-router-dom";

import SuccessfullyFinishedScreen from "../SuccessfullyFinishedScreen";

const FinishSuccessfulStep = ({ data }: { data: any }) => {
   const navigate = useNavigate();

   return (
      <SuccessfullyFinishedScreen
         title="Purchase Completed."
         text={
            <>
               Your Page is published and its
               <br /> ready to be viewed here:
            </>
         }
         buttonText="View webpage"
         onClickButton={() => navigate(`../memorial-page/${data.pageId}`)}
      />
   );
};

export default FinishSuccessfulStep;
