import { createSelector } from "@reduxjs/toolkit";

const getOrders = (state: any) => state.orders;
const getOrdersList = createSelector(getOrders, (orders) => orders && orders.list);

const getSingleOrder = createSelector(getOrders, (orders) => orders && orders.single);

export const OrdersSelectors = {
   getOrdersList,
   getSingleOrder,
};
