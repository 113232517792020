import { Box, Grid } from "@mui/material";
import Agency from "./agency";
import { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import dayjs from "dayjs";

export const agencies = [
   {
      id: 0,
      name: "Живот и здраве",
      plan: "lifetime 180lv",
      commission: "50",
      totalVouchers: 50,
      usedVouchers: 12,
      currency: "BGN",
      vouchers: [
         {
            id: 0,
            commission: 50,
            currency: "BGN",
            code: "12m1k2e",
            pageId: "12312412",
            dateCreated: dayjs().format("DD-MM-YYYY"),
            usageStatus: "used",
            paymentStatus: "pending payment",
            plan: "lifetime 180lv",
            totalAmount: 180,
            netAmount: 180 - 50,
         },
         {
            id: 1,
            commission: 50,
            currency: "BGN",
            code: "12m1k2e",
            pageId: "12312412",
            dateCreated: dayjs().format("DD-MM-YYYY"),
            usageStatus: "used",
            paymentStatus: "paid",
            plan: "lifetime 180lv",
            totalAmount: 180,
            netAmount: 180 - 50,
         },
         {
            id: 2,
            commission: 50,
            currency: "BGN",
            code: "12m1k2e",
            pageId: "12312412",
            dateCreated: dayjs().format("DD-MM-YYYY"),
            usageStatus: "used",
            paymentStatus: "pending payment",
            plan: "lifetime 180lv",
            totalAmount: 180,
            netAmount: 180 - 50,
         },
         {
            id: 3,
            commission: 50,
            currency: "BGN",
            code: "12m1k2e",
            pageId: "12312412",
            dateCreated: dayjs().format("DD-MM-YYYY"),
            usageStatus: "used",
            paymentStatus: "pending payment",
            plan: "lifetime 180lv",
            totalAmount: 180,
            netAmount: 180 - 50,
         },
         {
            id: 4,
            commission: 50,
            currency: "BGN",
            code: "12m1k2e",
            pageId: "12312412",
            dateCreated: dayjs().format("DD-MM-YYYY"),
            usageStatus: "used",
            paymentStatus: "pending payment",
            plan: "lifetime 180lv",
            totalAmount: 180,
            netAmount: 180 - 50,
         },
         {
            id: 5,
            commission: 50,
            currency: "BGN",
            code: "12m1k2e",
            pageId: "12312412",
            dateCreated: dayjs().format("DD-MM-YYYY"),
            usageStatus: "used",
            paymentStatus: "pending payment",
            plan: "lifetime 180lv",
            totalAmount: 180,
            netAmount: 180 - 50,
         },
      ],
   },
   {
      id: 1,
      name: "Живот и здраве 1",
      plan: "lifetime 180lv",
      commission: "50",
      totalVouchers: 50,
      usedVouchers: 12,
      currency: "BGN",
      vouchers: [
         {
            id: 0,
            commission: 50,
            currency: "BGN",
            code: "12m1k2e",
            pageId: "12312412",
            dateCreated: dayjs().format("DD-MM-YYYY"),
            usageStatus: "used",
            paymentStatus: "pending payment",
            totalAmount: 180,
            netAmount: 180 - 50,
         },
      ],
   },
   {
      id: 2,
      name: "Живот и здраве",
      plan: "lifetime 180lv",
      commission: "50",
      totalVouchers: 50,
      usedVouchers: 12,
      currency: "BGN",
      vouchers: [
         {
            id: 0,
            commission: 50,
            currency: "BGN",
            code: "12m1k2e",
            pageId: "12312412",
            dateCreated: dayjs().format("DD-MM-YYYY"),
            usageStatus: "used",
            paymentStatus: "pending payment",
            totalAmount: 180,
            netAmount: 180 - 50,
         },
      ],
   },
   {
      id: 3,
      name: "Живот и здраве 1",
      plan: "lifetime 180lv",
      commission: "50",
      totalVouchers: 50,
      usedVouchers: 12,
      currency: "BGN",
      vouchers: [
         {
            id: 0,
            commission: 50,
            currency: "BGN",
            code: "12m1k2e",
            pageId: "12312412",
            dateCreated: dayjs().format("DD-MM-YYYY"),
            usageStatus: "used",
            paymentStatus: "pending payment",
            totalAmount: 180,
            netAmount: 180 - 50,
         },
      ],
   },
];

const Agencies = () => {
   const dispatch = useAppDispatch();
   //    const agencies = useSelector(SubscriptionsSelectors.getSubscriptionsList);

   useEffect(() => {}, []);

   if (!agencies || agencies.length < 1) return null;

   return (
      <Box
         sx={{
            width: "100%",
         }}
      >
         <Grid container spacing={3}>
            {agencies.map((agency: any, index: number) => {
               return (
                  <Grid item xs={12} sm={4} key={index}>
                     <Agency agency={agency} />
                  </Grid>
               );
            })}
         </Grid>
      </Box>
   );
};

export default Agencies;
