import * as Immutable from "seamless-immutable";
import {
   ADD_TO_CART,
   ADD_DELIVERY_INFO,
   REMOVE_FROM_CART,
   CHANGE_ORDER_QUANTITY,
   ADD_CURRENCY,
} from "./types";

const initialState = Immutable.from<any>({
   order: [],
   currency: null,
   deliveryInfo: null,
});

export default function CartReducer(state = initialState, action: any) {
   const { type, payload } = action;
   switch (type) {
      case ADD_TO_CART: {
         return state.setIn(["order"], [...state.order, payload]);
      }
      case REMOVE_FROM_CART: {
         const filtered = state.order.filter((o: any, i: any) => {
            if (i !== payload) {
               return o;
            }
         });
         return state.setIn(["order"], filtered);
      }

      case ADD_CURRENCY: {
         return state.setIn(["currency"], payload);
      }

      case ADD_DELIVERY_INFO: {
         return state.setIn(["deliveryInfo"], payload);
      }

      case CHANGE_ORDER_QUANTITY: {
         const updatedOrder = [...state.order];
         const product = updatedOrder[payload.index].product;
         const singleProductPrice = product?.price;
         updatedOrder[payload.index] = {
            ...updatedOrder[payload.index],
            quantity: payload.quantity,
            totalPrice:
               payload.type === "increase"
                  ? updatedOrder[payload.index].totalPrice + singleProductPrice
                  : updatedOrder[payload.index].totalPrice - singleProductPrice,
         };
         return state.setIn(["order"], updatedOrder);
      }
      default:
         return state;
   }
}
