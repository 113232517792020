import { createSelector } from "@reduxjs/toolkit";
import { roles } from "../../components/PrivateRoutes";

const getLogin = (state: any) => state.login;

const getUser = createSelector(getLogin, (login) => login && login.user);
const getUserRole = createSelector(getUser, (login) => login && login.role);
const isUserAdmin = createSelector(getUser, (user) => user.role === roles.ADMIN);
const isAgency = createSelector(getUser, (user) => user.role === roles.AFFILIATE);

const isUserClient = createSelector(getUser, (user) => user.role === roles.CLIENT);
export const LoginSelectors = {
   getUser,
   getUserRole,
   isUserAdmin,
   isAgency,
   isUserClient,
};
