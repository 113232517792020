import { AlertsActions } from "../../components/alerts/actions";
import { AffiliatesActions } from "./actions";

export const createVoucher =
   (commissionForAffiliate: number, subscriptionPlanId: number, navigate: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.affiliatesAPI
         .createVoucher(commissionForAffiliate, subscriptionPlanId)
         .catch((error: any) => {})
         .then((res: any) => {
            if (res && res.data) {
               return navigate("../vouchers");
            }
         });
   };

export const getVouchers =
   () =>
   (dispatch: any, _: any, { api }: any) => {
      api.affiliatesAPI
         .getVouchers()
         .catch((error: any) => {})
         .then((res: any) => {
            if (res && res.data) {
               dispatch(AffiliatesActions.setAffiliateVouchers(res.data));
            }
         });
   };

export const sellVoucher =
   (id: number) =>
   (dispatch: any, _: any, { api }: any) => {
      api.affiliatesAPI
         .sellVoucher(id)
         .catch((error: any) => {
            dispatch(
               AlertsActions.createAlert({
                  type: "error",
                  message: error.response?.data?.title ?? "An error occurred",
               }),
            );
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(AffiliatesActions.updateVoucher({ id: id, sold: true }));
            }
         });
   };

export const getAffiliateUsers =
   () =>
   (dispatch: any, _: any, { api }: any) => {
      api.affiliatesAPI
         .getAffiliateUsers()
         .catch((error: any) => {})
         .then((res: any) => {
            if (res.data) {
               dispatch(AffiliatesActions.setAffiliateUsers(res.data));
            }
         });
   };

export const editVoucher =
   (form: any, onLoadingStart: () => void, onSuccessCallback: () => void) =>
   (dispatch: any, _: any, { api }: any) => {
      onLoadingStart();
      api.affiliatesAPI
         .editVoucher(form)
         .catch((error: any) => {})
         .then((res: any) => {
            if (res.data) {
               onSuccessCallback();
               dispatch(getVouchers());
            }
         });
   };

export const generateVoucherQR =
   (pageId: any, name: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.memorialAPI
         .generateQR(pageId, name)
         .catch((error: any) => {
            dispatch(AffiliatesActions.generateVoucherQRfail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(AffiliatesActions.generateVoucherQRsuccess(res.data));
            }
         });
   };

export const payVoucher =
   (id: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.affiliatesAPI
         .payVoucher(id)
         .catch((error: any) => {
            dispatch(AffiliatesActions.generateVoucherQRfail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(AffiliatesActions.generateVoucherQRsuccess(res.data));
            }
         });
   };
