import { Box, IconButton } from "@mui/material";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import NovaButton from "../shared/NovaButton";
import { OrderType } from "./OrdersContainer";
import TranslatedText from "../translated-text";

const Order = ({ order, type, onDetailsClick }: any) => {
   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: {
               xs: "column",
               md: "row",
            },
            borderRadius: "16px",
            padding: "24px",
            border: "1px solid #E9DDC8",
            alignItems: "flex-start",
         }}
      >
         <Box
            sx={{
               display: "flex",
               flexDirection: {
                  xs: "row-reverse",
                  md: "column",
               },
               justifyContent: "space-between",
               width: "100%",
            }}
         >
            <NovaText
               type={NovaTextTypes.BaseNormal}
               style={{
                  margin: 0,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "200px",
               }}
            >
               {order.customerName}
            </NovaText>
            <NovaText
               type={NovaTextTypes.BaseNormal}
               style={{ color: "#777E90", margin: 0 }}
            >
               {order.amount}
               {order.currency}
            </NovaText>
         </Box>

         <Box
            sx={{
               display: "flex",
               flexDirection: {
                  xs: "row-reverse",
                  md: "column",
               },
               justifyContent: "space-between",
               width: "100%",
            }}
         >
            <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
               {order.orderItemsCount}
            </NovaText>
            <NovaText
               type={NovaTextTypes.BaseNormal}
               style={{ color: "#777E90", margin: 0 }}
            >
               <TranslatedText text="home.orders.quantity" />
            </NovaText>
         </Box>

         <Box
            sx={{
               display: "flex",
               flexDirection: {
                  xs: "row-reverse",
                  md: "column",
               },
               justifyContent: "space-between",
               width: "100%",
            }}
         >
            <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
               <TranslatedText text={`home.orders.statuses.${order?.status}`} />
            </NovaText>
            <NovaText
               type={NovaTextTypes.BaseNormal}
               style={{ color: "#777E90", margin: 0 }}
            >
               <TranslatedText text="home.orders.status" />
            </NovaText>
         </Box>

         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               gap: "12px",
               flex: 1,
               justifyContent: "flex-end",
               marginTop: {
                  xs: "16px",
                  md: 0,
               },
            }}
         >
            {type === OrderType.INCOMPLETE ? (
               <>
                  <NovaButton style={{ borderRadius: "32px", padding: "8px 18px" }}>
                     <TranslatedText text="buttons.complete" />
                  </NovaButton>
                  <IconButton
                     sx={{
                        border: "1px solid #CAA45D",
                        width: "72px",
                        borderRadius: "32px",
                     }}
                  >
                     <DeleteOutlineIcon />
                  </IconButton>
               </>
            ) : (
               <NovaButton
                  style={{
                     borderRadius: "32px",
                     padding: "8px 18px",
                     border: "1px solid #CAA45D",
                     background: "#fff",
                     ":hover": {
                        border: "1px solid #CAA45D",
                     },
                  }}
                  onClick={() => onDetailsClick(order.id)}
               >
                  <TranslatedText text="buttons.details" />
               </NovaButton>
            )}
         </Box>
      </Box>
   );
};

export default Order;
