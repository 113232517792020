import { ProductsActions } from "./actions";

export const getProducts =
   () =>
   (dispatch: any, _: any, { api }: any) => {
      api.productsAPI
         .getProducts()
         .catch((error: any) => {
            dispatch(ProductsActions.getProductsFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(ProductsActions.getProducts(res.data));
            }
         });
   };

export const getProduct =
   (id: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.productsAPI
         .getProduct(id)
         .catch((error: any) => {
            dispatch(ProductsActions.getSingleProductFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(ProductsActions.getSingleProductSuccess(res.data));
            }
         });
   };

export const createProduct =
   (product: any, navigate: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.productsAPI
         .createProduct(product)
         .catch((error: any) => {
            dispatch(ProductsActions.getProductsFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               // dispatch(ProductsActions.getProducts());
               return navigate("../products");
            }
         });
   };

export const deleteProduct =
   (id: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.productsAPI
         .deleteProduct(id)
         .catch((error: any) => {
            dispatch(ProductsActions.deleteProductFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(ProductsActions.deleteProductSuccess(id));
            }
         });
   };

export const setProductAvailability =
   (id: any, status: boolean) =>
   (dispatch: any, _: any, { api }: any) => {
      api.productsAPI
         .setProductAvailability(id, status)
         .catch((error: any) => {
            dispatch(ProductsActions.setProductAvailabilityFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(ProductsActions.setProductAvailabilitySuccess({ id, status }));
            }
         });
   };

export const editProduct =
   (changes: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.productsAPI
         .editProduct(changes)
         .catch((error: any) => {
            dispatch(ProductsActions.getProductsFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               // TODO causes rerendering
               dispatch(getProduct(changes.id));
            }
         });
   };

export const editProductPhoto =
   (changes: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.productsAPI
         .editProductPhoto({
            id: changes.id,
            description: changes.description,
            isMain: changes.isMain ?? false,
         })
         .catch((error: any) => {
            dispatch(ProductsActions.getProductsFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               // TODO causes rerendering
               dispatch(getProduct(changes.productId));
            }
         });
   };

export const addProductPhoto =
   (changes: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.productsAPI
         .addProductPhoto({
            id: changes.productId,
            description: changes.description,
            photoBase64: changes.photoBase64,
         })
         .catch((error: any) => {
            dispatch(ProductsActions.getProductsFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               // TODO causes rerendering
               dispatch(getProduct(changes.productId));
            }
         });
   };

export const deleteProductPhoto =
   (changes: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.productsAPI
         .deleteProductPhoto(changes.photoId)
         .catch((error: any) => {
            dispatch(ProductsActions.getProductsFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               // TODO causes rerendering
               dispatch(getProduct(changes.productId));
            }
         });
   };

export const generateQR =
   (pageId: any, name: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.memorialAPI
         .generateQR(pageId, name)
         .catch((error: any) => {
            dispatch(ProductsActions.getSingleQRFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(ProductsActions.getSingleQRSuccess(res.data));
            }
         });
   };
