import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { InputAdornment } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import Tree from "../../components/tree/tree";
import NovaTextInput from "../../components/shared/NovaTextInput";
import { limitations } from "./limitations";
import { useSelector } from "react-redux";
import { HomeSelectors } from "../home/selectors";
import MemorialLimitationsInfoBar from "../../components/MemorialLimitationsInfoBar";

const FamilyTree = ({ data, onUpdate }: any) => {
   const { t } = useTranslation();
   const editMemorialPageData = useSelector(HomeSelectors.getEditedMemorialPage);

   const [searchValue, setSearchValue] = useState<string>("");

   const handleSearch = (event: any) => {
      setSearchValue(event.target.value);
   };

   const maxTreeNodesLength =
      limitations[
         editMemorialPageData?.subscription
            ? editMemorialPageData.subscription.subscriptionPlan.type
            : "free"
      ]?.treeNodesLength;

   return (
      <Box>
         <MemorialLimitationsInfoBar
            itemName={t("create-memorial-page.family-tree.item-name")}
            limit={maxTreeNodesLength}
         />

         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               marginBottom: "24px",
            }}
         >
            <NovaTextInput
               label={t("create-memorial-page.family-tree.search")}
               placeholder={t("create-memorial-page.family-tree.search-placeholder")}
               value={searchValue}
               InputProps={{
                  startAdornment: (
                     <InputAdornment position="start">
                        <SearchOutlined />
                     </InputAdornment>
                  ),
               }}
               onChange={handleSearch}
            />
         </Box>

         <Tree
            data={data}
            isEditable
            canAddMore={maxTreeNodesLength ? data.length < maxTreeNodesLength : true}
            searchTerm={searchValue}
            onUpdate={onUpdate}
         />
      </Box>
   );
};

export default FamilyTree;
