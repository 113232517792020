import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import { KeyboardBackspace } from "@mui/icons-material";
import { Box, Container, FormControlLabel, Grid, Switch } from "@mui/material";

import {
   addProductPhoto,
   deleteProductPhoto,
   editProduct,
   editProductPhoto,
   getProduct,
} from "./async-actions";
import { ProductsSelectors } from "./selectors";
import { useAppDispatch } from "../../store/hooks";

import { EditIcon } from "../../components/shared/icons/edit";
import { UploadIcon } from "../../components/shared/icons/upload-icon";
import NovaTextInput from "../../components/shared/NovaTextInput";
import NovaButton from "../../components/shared/NovaButton";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import MarkdownEditor from "../../components/md-editor/markdown-editor";
import PhotoEditModal from "../../components/products/photo-edit-modal";
import Loader from "../../components/Loader";
import TranslatedText from "../../components/translated-text";

const SingleProduct = () => {
   const { productId } = useParams();
   const dispatch = useAppDispatch();
   const product = useSelector(ProductsSelectors.getSingleProduct);
   const [form, setForm] = useState<any>();
   const [selectedImageIndex, setSelectedImageIndex] = useState<number | undefined>(
      undefined,
   );
   const [imagesScrollX, setImagesScrollX] = useState<number>(0);

   const imagesScrollHolderRef = useRef<HTMLDivElement>(null);

   const onFieldChange = (event: any, field: string) => {
      if (field === "price" || field === "stockQuantity") {
         return setForm({
            ...form,
            [field]: isNaN(event.target.value)
               ? form[field]
               : parseInt(event.target.value),
         });
      }

      if (field === "description") {
         return setForm({
            ...form,
            [field]: event,
         });
      }
      return setForm({ ...form, [field]: event.target.value });
   };

   const onSwitchChange = (event: any) => {
      setForm({ ...form, isActive: event.target.checked });
   };

   useEffect(() => {
      dispatch(getProduct(productId));
   }, []);

   useEffect(() => {
      setForm(product);
   }, [product]);

   const canSave = useMemo(
      () => !product || !form || JSON.stringify(product) === JSON.stringify(form),
      [product, form],
   );

   const handleEditSave = () => {
      dispatch(editProduct(form));
   };

   const handleEditPhotoSave = (newPhotoData: any) => {
      dispatch(
         editProductPhoto({
            productId,
            ...newPhotoData,
         }),
      );
      setSelectedImageIndex(undefined);
   };

   const handleDeletePhoto = (photoId: number) => {
      dispatch(
         deleteProductPhoto({
            productId,
            photoId,
         }),
      );
      setSelectedImageIndex(undefined);
   };

   return (
      <Container
         sx={{
            padding: "60px 0 120px 0",
         }}
      >
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between",
            }}
         >
            <Link
               to="../products"
               style={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "8px",
                  textDecoration: "none",
                  color: "#1B1B48",
                  borderRadius: "32px",
                  background: "#fff",
                  padding: "12px 24px",
                  boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.05)",
               }}
            >
               <KeyboardBackspace />
               Back to Products
            </Link>

            <NovaButton
               disabled={canSave}
               style={{ borderRadius: "32px" }}
               onClick={handleEditSave}
            >
               <TranslatedText text="buttons.save" />
            </NovaButton>
         </Box>
         {product && form ? (
            <>
               <Box
                  sx={{
                     marginTop: "24px",
                  }}
               >
                  <Grid container spacing={3}>
                     <Grid item xs={12} sm={6} md={4}>
                        <Box>
                           <Box
                              sx={{
                                 border: "2px solid #CAD1E2",
                                 borderRadius: "32px",
                                 overflow: "hidden",
                              }}
                           >
                              <Dropzone
                                 onDrop={(acceptedFiles) => {
                                    acceptedFiles.map((file, index) => {
                                       const reader = new FileReader();
                                       reader.readAsDataURL(file);

                                       reader.onload = (event: any) => {
                                          dispatch(
                                             addProductPhoto({
                                                productId,
                                                description: "",
                                                photoBase64: event.target.result,
                                             }),
                                          );
                                       };

                                       reader.onerror = (error) =>
                                          console.error("Error reading file:", error);
                                    });
                                 }}
                              >
                                 {({ getRootProps, getInputProps }) => (
                                    <Box
                                       sx={{
                                          maxWidth: "100%",
                                          width: "100%",
                                          height: "400px",
                                          // height: "100%",
                                          borderRadius: "16px",
                                          cursor: "pointer",
                                       }}
                                    >
                                       <div
                                          {...getRootProps()}
                                          style={{
                                             height: "100%",
                                          }}
                                       >
                                          <input {...getInputProps()} />
                                          <div
                                             style={{
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                             }}
                                          >
                                             {form.photos.length > 0 && (
                                                <Box
                                                   sx={{
                                                      width: "100%",
                                                      height: "100%",
                                                      position: "relative",
                                                      "&:hover > .edit-button": {
                                                         display: "inline-flex",
                                                         background: "#fff",
                                                      },
                                                   }}
                                                >
                                                   <Box
                                                      sx={{
                                                         background: `url(${
                                                            form.photos.find(
                                                               (photo: any) =>
                                                                  photo.isMain,
                                                            )?.photoUrl
                                                         })`,
                                                         backgroundSize: "cover",
                                                         backgroundRepeat: "no-repeat",
                                                         backgroundPosition:
                                                            "center center",
                                                         width: "100%",
                                                         height: "100%",
                                                      }}
                                                   />
                                                   <Box
                                                      className="edit-button"
                                                      sx={{
                                                         position: "absolute",
                                                         top: "50%",
                                                         left: "50%",
                                                         transform:
                                                            "translate(-50%, -50%)",
                                                         background:
                                                            "rgba(255, 255, 255, .8)",
                                                         display: "none",
                                                         alignItems: "center",
                                                         justifyContent: "center",
                                                         border: "1px solid #E9DDC8",
                                                         borderRadius: "50%",
                                                         width: "50px",
                                                         height: "50px",
                                                         color: "#1B1B48",
                                                      }}
                                                   >
                                                      <EditIcon />
                                                   </Box>
                                                </Box>
                                             )}
                                             {form.photos.length < 1 && (
                                                <Box
                                                   sx={{
                                                      display: "inline-flex",
                                                      alignItems: "center",
                                                      gap: "4px",
                                                      border: "1px solid #E9DDC8",
                                                      borderRadius: "32px",
                                                      padding: "10px 24px",
                                                   }}
                                                >
                                                   <UploadIcon />
                                                   Upload
                                                </Box>
                                             )}
                                          </div>
                                       </div>
                                    </Box>
                                 )}
                              </Dropzone>
                           </Box>
                           <Box
                              sx={{
                                 position: "relative",
                              }}
                           >
                              <Box
                                 sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "16px 0",
                                    gap: "8px",
                                    overflowX: "auto",
                                 }}
                                 onScroll={(event) =>
                                    setImagesScrollX(event.currentTarget.scrollLeft)
                                 }
                                 ref={imagesScrollHolderRef}
                              >
                                 {form.photos.length > 0
                                    ? form.photos.map((photo: any, index: number) => (
                                         <Box
                                            key={index}
                                            sx={{
                                               flex: "0 0 25%",
                                               borderRadius: "12px",
                                               padding: "6px",
                                               height: "85px",
                                               background: `url(${photo.photoUrl})`,
                                               backgroundSize: "cover",
                                               backgroundRepeat: "no-repeat",
                                               backgroundPosition: "center center",
                                               border: photo.isMain
                                                  ? "4px solid #CAA45D"
                                                  : undefined,
                                            }}
                                            onClick={() => setSelectedImageIndex(index)}
                                         />
                                      ))
                                    : Array(4)
                                         .fill(null)
                                         .map((_, index) => (
                                            <Box
                                               sx={{
                                                  flex: 1,
                                                  borderRadius: "12px",
                                                  padding: "6px",
                                                  height: "85px",
                                                  border: "2px dashed #CAD1E2",
                                               }}
                                               key={index}
                                            />
                                         ))}
                              </Box>

                              <Box
                                 sx={{
                                    position: "absolute",
                                    width: "20px",
                                    height: "100%",
                                    top: "0px",
                                    left: "0px",
                                    backgroundImage:
                                       "linear-gradient(to right, #f3f5f7, #f3f5f700)",
                                    opacity: imagesScrollX / 20,
                                    zIndex: 1,
                                 }}
                              />
                              <Box
                                 sx={{
                                    position: "absolute",
                                    width: "20px",
                                    height: "100%",
                                    top: "0px",
                                    right: "0px",
                                    backgroundImage:
                                       "linear-gradient(to left, #f3f5f7, #f3f5f700)",
                                    opacity:
                                       ((imagesScrollHolderRef.current?.scrollWidth ??
                                          0) -
                                          ((imagesScrollHolderRef.current?.clientWidth ??
                                             0) +
                                             imagesScrollX)) /
                                       20,
                                    zIndex: 1,
                                 }}
                              />
                           </Box>
                        </Box>
                     </Grid>
                     <Grid item xs={12} sm={6} md={4}>
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "16px",
                           }}
                        >
                           <NovaTextInput
                              value={form.name}
                              label="Product Name"
                              placeholder="Full Name"
                              onChange={(event) => onFieldChange(event, "name")}
                           />
                           <Box>
                              <NovaText
                                 type={NovaTextTypes.BaseNormal}
                                 style={{
                                    margin: 0,
                                 }}
                              >
                                 Product Description
                              </NovaText>
                              <MarkdownEditor
                                 value={form.description}
                                 placeholder="Long description, used for the product..."
                                 onChange={(event) => onFieldChange(event, "description")}
                              />
                           </Box>
                        </Box>
                     </Grid>
                     <Grid item xs={12} sm={6} md={4}>
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "16px",
                           }}
                        >
                           <NovaTextInput
                              value={form.stockQuantity}
                              label="Product Quantity"
                              placeholder="Type here"
                              type="number"
                              onChange={(event) => onFieldChange(event, "stockQuantity")}
                           />
                           <NovaTextInput
                              value={form.price}
                              label="Product Price"
                              placeholder="Type here"
                              type="number"
                              onChange={(event) => onFieldChange(event, "price")}
                           />
                           <FormControlLabel
                              value="start"
                              control={
                                 <Switch
                                    checked={form.isActive}
                                    onChange={(event) => onSwitchChange(event)}
                                    inputProps={{ "aria-label": "controlled" }}
                                    sx={{
                                       height: "35px",
                                       padding: 0,
                                       display: "flex",
                                       marginLeft: "16px",
                                       "& .MuiSwitch-switchBase": {
                                          "&.Mui-checked": {
                                             color: "#CAA45D",
                                             "& + .MuiSwitch-track": {
                                                opacity: 1,
                                                border: "1px solid #CAA45D",
                                                background: "#fff",
                                             },
                                          },
                                       },
                                       "& .MuiSwitch-track": {
                                          borderRadius: 20 / 2,
                                          backgroundColor: "#E9E9EA",
                                          opacity: 1,
                                       },
                                       "& .MuiSwitch-thumb": {
                                          boxShadow: "none",
                                       },
                                       ".MuiButtonBase-root": {
                                          height: "35px",
                                       },
                                       ".MuiButtonBase-root.Mui-checked": {
                                          padding: "8px 12px",
                                       },
                                    }}
                                 />
                              }
                              label="Is Product active"
                              labelPlacement="start"
                           />
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
            </>
         ) : (
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "center",
               }}
            >
               <Loader />
            </Box>
         )}

         {selectedImageIndex !== undefined && (
            <PhotoEditModal
               isOpened={selectedImageIndex !== undefined}
               photoId={form.photos[selectedImageIndex].id}
               photoIndex={selectedImageIndex}
               photoUrl={form.photos[selectedImageIndex].photoUrl}
               photoDescription={form.photos[selectedImageIndex].description}
               photoIsMain={form.photos[selectedImageIndex].isMain}
               onClose={() => setSelectedImageIndex(undefined)}
               onSave={handleEditPhotoSave}
               onDelete={handleDeletePhoto}
            />
         )}
      </Container>
   );
};

export default SingleProduct;
