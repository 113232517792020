import api from "./withAuth";

const userLogin = async (email: string, password: string) => {
   return await api.post("users/login", { email, password });
};

const userSignUp = async (
   fullName: string,
   email: string,
   password: string,
   marketingAccepted: boolean,
) => {
   return await api.post("users/create", {
      fullName,
      email,
      password,
      marketingAccepted,
   });
};

const affiliateSignUp = async (fullName: string, email: string) => {
   return await api.post("users/create/affiliate", {
      fullName,
      email,
   });
};

const saveProfilePhoto = async (profilePhotoBase64: string) => {
   return await api.put("users/edit/profilePhoto", {
      profilePhotoBase64,
   });
};

const getUser = async () => {
   return await api.get("users/getUser");
};

const getUsers = async () => {
   return await api.get("users/getUsers");
};

const changePassword = async (oldPassword: string, newPassword: string) => {
   return await api.post("users/changePassword", {
      oldPassword,
      newPassword,
   });
};

const userEdit = async (fullName: string) => {
   return await api.put("users/edit", {
      fullName,
   });
};

const deleteAccount = async () => {
   return await api.delete("users");
};

const emailVerification = async (email: string) => {
   return await api.post("users/emailverification", {
      email,
   });
};

const recoverPassword = async (email: string, code: string, password: string) => {
   return await api.post("users/passwordRecovery", {
      email,
      code,
      password,
   });
};

export const usersAPI = {
   userLogin,
   userSignUp,
   affiliateSignUp,
   saveProfilePhoto,
   getUser,
   getUsers,
   changePassword,
   userEdit,
   deleteAccount,
   emailVerification,
   recoverPassword,
};
