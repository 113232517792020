import { useState } from "react";
import { useSelector } from "react-redux";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { LoginSelectors } from "../../pages/Login/selectors";
import { useAppDispatch } from "../../store/hooks";
import { createPaymentMethod } from "../../pages/subscriptions/async-actions";
import NovaButton from "../shared/NovaButton";
import Loader from "../Loader";
import { Box } from "@mui/material";
import TranslatedText from "../translated-text";

const PaymentForm = () => {
   const dispatch = useAppDispatch();
   const user = useSelector(LoginSelectors.getUser);
   const stripe = useStripe() as any;
   const elements = useElements();
   const [error, setError] = useState(null);
   const [paymentMethod, setPaymentMethod] = useState<any>(null);
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const priceId = "";

   const handleSubmit = async (event: any) => {
      event.preventDefault();

      if (!stripe || !elements) {
         return;
      }

      setIsLoading(true);

      const cardElement = elements.getElement(CardElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
         type: "card",
         card: cardElement,
         billing_details: {
            name: user.fullName,
            email: user.email,
         },
      });

      if (error) {
         console.error(error);
         setIsLoading(false);
      } else {
         dispatch(
            createPaymentMethod(
               user.email,
               user.fullName,
               paymentMethod.id,
               priceId,
               () => {
                  setIsLoading(false);
               },
               () => {
                  setIsLoading(false);
               },
            ),
         );
      }
   };

   return (
      <form onSubmit={handleSubmit}>
         <CardElement
            options={{
               hidePostalCode: true,
            }}
         />
         <Box
            sx={{
               display: "flex",
               flexDirection: "row",
               alignItems: "center",
               gap: "10px",
               marginTop: "16px",
            }}
         >
            <NovaButton
               onClick={handleSubmit}
               disabled={!stripe || isLoading}
               style={{
                  borderRadius: "24px",
                  padding: "6px 16px",
                  fontSize: "14px",
               }}
            >
               <TranslatedText text="buttons.create-payment-method" />
            </NovaButton>
            {isLoading && (
               <Box>
                  <Loader size={20} />
               </Box>
            )}
         </Box>
         {error && <div>{error}</div>}
         {paymentMethod && (
            <div>
               <TranslatedText text="profile.payment-methods.payment-method-created" />
               &nbsp;{paymentMethod.id}
            </div>
         )}
      </form>
   );
};

export default PaymentForm;
