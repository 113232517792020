import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import {
   Avatar,
   Box,
   Container,
   Divider,
   FormControl,
   Grid,
   IconButton,
   MenuItem,
   Select,
   Tab,
   Tabs,
} from "@mui/material";
import NovaTitle from "../../components/shared/NovaTitle";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import { CardType } from "../../components/home/MemorialCardContainer";
import MemorialCardContainer from "../../components/home/MemorialCardContainer";
import RequestsContainer from "../../components/home/RequestsContainer";
import OrdersContainer from "../../components/home/OrdersContainer";
import { ProfileImageChange } from "../../components/shared/icons/profile-image-change";
import {
   getMemorialPages,
   getMyPagesRequests,
   getMyRequests,
   saveProfilePhoto,
} from "./async-actions";
import { useAppDispatch } from "../../store/hooks";
import { HomeSelectors } from "./selectors";
import UpgradeModal from "../../components/upgrade-modal";
import { LoginSelectors } from "../Login/selectors";
import TranslatedText from "../../components/translated-text";

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

export enum RequestsType {
   MY_PAGES_REQUESTS,
   MY_REQUESTS,
}

const HomeClient = () => {
   const dispatch = useAppDispatch();
   const user = useSelector(LoginSelectors.getUser);

   const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
   const [value, setValue] = useState(0);
   const [selectedPage, setSelectedPage] = useState(null);

   const pages = useSelector(HomeSelectors.getMemorialPages);
   const myRequests = useSelector(HomeSelectors.getMyRequests);
   const myPagesRequests = useSelector(HomeSelectors.getMyPagesRequests);

   const handleUpgradeModalOpen = (pageId: number) => {
      const selected = pages.find((p: any) => p.id === pageId);
      setSelectedPage(selected);
      setIsUpgradeModalOpen(true);
   };

   const handleUpgradeModalClose = () => {
      dispatch(getMemorialPages());
      setIsUpgradeModalOpen(false);
   };

   const [requestsType, setRequestsType] = useState(0);

   const handleRequestsTypeChange = (event: any) => {
      setRequestsType(event.target.value);
   };

   useEffect(() => {
      dispatch(getMemorialPages());
      dispatch(getMyRequests());
      dispatch(getMyPagesRequests());
   }, []);

   const Panel = (props: TabPanelProps) => {
      const { children, value, index, ...other } = props;
      return (
         <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
         >
            {value === index && <Box>{children}</Box>}
         </div>
      );
   };

   function a11yProps(index: number) {
      return {
         id: `simple-tab-${index}`,
         "aria-controls": `simple-tabpanel-${index}`,
      };
   }

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   const handleProfileImageSave = (profilePhotoBase64: string | ArrayBuffer | null) => {
      dispatch(saveProfilePhoto(profilePhotoBase64));
   };
   return (
      <Box sx={{ paddingBottom: "80px" }}>
         <Box
            sx={{
               background: "#fff",
               height: "136px",
            }}
         />

         <Container>
            <Dropzone
               accept={{
                  "image/png": [".png"],
                  "image/jpeg": [".jpg"],
               }}
               onDrop={(acceptedFiles) => {
                  if (acceptedFiles.length > 0) {
                     const reader = new FileReader();
                     reader.onload = (e) => {
                        const imageDataUrl = reader.result;
                        handleProfileImageSave(imageDataUrl);
                     };
                     reader.readAsDataURL(acceptedFiles[0]);
                  }
               }}
            >
               {({ getRootProps, getInputProps }) => (
                  <Box
                     sx={{
                        width: "153px",
                        height: "153px",
                        borderRadius: "100%",
                        border: "3px solid #fff",
                        marginTop: "-76px",
                        marginBottom: {
                           xs: "24px",
                           md: "0",
                        },
                        position: "relative",
                     }}
                  >
                     <Avatar src={user?.photoUrl} sx={{ width: "100%", height: "100%" }}>
                        {user?.fullName?.[0]}
                     </Avatar>
                     <div
                        {...getRootProps()}
                        style={{
                           height: "100%",
                        }}
                     >
                        <input {...getInputProps()} />

                        <IconButton
                           sx={{
                              position: "absolute",
                              bottom: 0,
                              right: 0,
                              width: "32px",
                              height: "32px",
                              border: "3px solid #fff",
                              background: "#1B1B48",
                              ":hover": {
                                 backgroundColor: "#CAA45D",
                              },
                              ":hover svg path": {
                                 fill: "#1B1B48",
                              },
                           }}
                        >
                           <ProfileImageChange />
                        </IconButton>
                     </div>
                  </Box>
               )}
            </Dropzone>

            <Box
               sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "24px",
               }}
            >
               <Box
                  sx={{
                     display: {
                        xs: "none",
                        md: "block",
                     },
                     width: "153px",
                     flexShrink: 0,
                  }}
               >
                  <NovaText
                     type={NovaTextTypes.XLNormal}
                     style={{
                        fontFamily: "Cormorant Infant, serif",
                        fontWeight: "bold",
                        margin: "16px 0 0 0",
                     }}
                  >
                     {user?.fullname}
                  </NovaText>
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        fontFamily: "Cormorant Infant, serif",
                        fontWeight: "bold",
                        margin: "0 0 0 0",
                     }}
                  >
                     {user?.email}
                  </NovaText>
               </Box>

               <Box
                  sx={{
                     width: "100%",
                  }}
               >
                  <Box
                     sx={{
                        background: "#fff",
                        borderRadius: "16px",
                        padding: "40px 24px",
                        position: "relative",
                     }}
                  >
                     <NovaTitle
                        variant="h2"
                        style={{
                           textAlign: "center",
                           margin: 0,
                           color: "#1B1B48",
                        }}
                     >
                        <TranslatedText text="home.welcome" />
                     </NovaTitle>

                     <NovaText
                        type={NovaTextTypes.BaseNormal}
                        style={{
                           color: "#777E90",
                           maxWidth: "554px",
                           textAlign: "center",
                           margin: "20px auto 0 auto",
                        }}
                     >
                        <TranslatedText text="home.thank-you" />
                     </NovaText>

                     <Divider
                        sx={{
                           margin: "24px auto",
                           maxWidth: "430px",
                           background: "rgba(228,203,141, 30%)",
                        }}
                     />

                     <NovaText
                        type={NovaTextTypes.BaseNormal}
                        style={{
                           color: "#1B1B48",
                           textAlign: "center",
                        }}
                     >
                        <TranslatedText text="home.headline" />
                     </NovaText>
                  </Box>

                  <Box
                     sx={{
                        background: "#fff",
                        borderRadius: "16px",
                        marginTop: "40px",
                        padding: "40px",
                     }}
                  >
                     <Tabs
                        value={value}
                        sx={{
                           ".MuiTabs-flexContainer": {
                              overflowX: "auto",
                           },
                           "& .MuiButtonBase-root.Mui-selected": {
                              color: "#fff",
                              background: "#1B1B48",
                              borderRadius: "32px",
                           },
                           "& .MuiTabs-indicator": {
                              display: "none",
                           },
                        }}
                        onChange={handleChange}
                     >
                        <Tab
                           label={<TranslatedText text="home.tabs.published" />}
                           {...a11yProps(0)}
                           sx={{ textTransform: "none" }}
                        />
                        <Tab
                           label={<TranslatedText text="home.tabs.access" />}
                           {...a11yProps(1)}
                           sx={{ textTransform: "none" }}
                        />
                        <Tab
                           label={<TranslatedText text="home.tabs.orders" />}
                           {...a11yProps(2)}
                           sx={{ textTransform: "none" }}
                        />
                     </Tabs>

                     <Divider
                        sx={{
                           background: "rgba(228,203,141, 30%)",
                           margin: "24px 0",
                        }}
                     />

                     <Box>
                        <Panel value={value} index={0}>
                           <MemorialCardContainer
                              type={CardType.PUBLISHED}
                              items={pages}
                              onUpgrade={handleUpgradeModalOpen}
                           />
                        </Panel>

                        <Panel value={value} index={1}>
                           <FormControl size="small">
                              <Select
                                 value={requestsType}
                                 onChange={handleRequestsTypeChange}
                              >
                                 <MenuItem value={0}>
                                    <TranslatedText text="buttons.requests-to-my-pages" />
                                 </MenuItem>
                                 <MenuItem value={1}>
                                    <TranslatedText text="buttons.my-requests" />
                                 </MenuItem>
                              </Select>
                           </FormControl>

                           <Box sx={{ mt: 4 }}>
                              {requestsType === 0 ? (
                                 <RequestsContainer
                                    items={myPagesRequests}
                                    type={RequestsType.MY_PAGES_REQUESTS}
                                 />
                              ) : (
                                 <RequestsContainer
                                    items={myRequests}
                                    type={RequestsType.MY_REQUESTS}
                                 />
                              )}
                           </Box>
                        </Panel>

                        <Panel value={value} index={2}>
                           <OrdersContainer />
                        </Panel>
                     </Box>
                  </Box>
               </Box>
            </Box>

            <UpgradeModal
               page={selectedPage}
               open={isUpgradeModalOpen}
               onClose={handleUpgradeModalClose}
            />
         </Container>
      </Box>
   );
};

export default HomeClient;
