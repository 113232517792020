import { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useAppDispatch } from "../../store/hooks";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import TranslatedText from "../../components/translated-text";
import { DetailsModalFlower } from "../../components/shared/icons/details-modal-flower";
import NovaTextInput from "../../components/shared/NovaTextInput";
import NovaButton from "../../components/shared/NovaButton";
import { emailVerification, recoverPassword } from "./async-actions";
import { checkEmailIsValid } from "../../utils/functions";
import NovaPasswordInput from "../../components/shared/NovaPasswordInput";

const ForgotPasswordModal = ({ handleClose }: { handleClose?: () => void }) => {
   const dispatch = useAppDispatch();
   const { t } = useTranslation();
   const [email, setEmail] = useState<string>("");
   const [code, setCode] = useState<string>("");
   const [password, setPassword] = useState<string>("");
   const [confirmPassword, setConfirmPassword] = useState<string>("");
   const [sentSuccess, setSentSuccess] = useState(false);

   const onSentSuccess = () => {
      setSentSuccess(true);
   };

   const onRecoverSuccess = () => {
      if (handleClose) {
         handleClose();
      }
   };

   const onSendConfirm = () => {
      dispatch(emailVerification(email, onSentSuccess));
   };

   const onRecoveryConfirm = () => {
      dispatch(recoverPassword(email, code, password, onRecoverSuccess));
   };

   return (
      <Modal open={true} onClose={handleClose}>
         <Box
            sx={{
               position: "absolute",
               top: "50%",
               left: "50%",
               width: {
                  xs: "calc(100% - 20px)",
                  sm: "unset",
               },
               transform: "translate(-50%, -50%)",
               bgcolor: "background.paper",
               boxShadow: 24,
               borderRadius: "24px",
               display: "flex",
               alignItems: "baseline",
               border: "1px solid #E9DDC8",
               outline: "none",
               overflow: "hidden",
            }}
         >
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  width: "100%",
                  position: "relative",
                  padding: "24px",
               }}
            >
               <Box
                  sx={{
                     width: "100%",
                     display: "flex",
                     flexDirection: "column",
                     zIndex: 10,
                  }}
               >
                  <Box>
                     <NovaText
                        type={NovaTextTypes.XLNormal}
                        style={{ margin: "0 0 8px 0" }}
                     >
                        <TranslatedText text="auth-flow.forgot-password.title" />
                     </NovaText>
                  </Box>

                  {sentSuccess ? (
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           gap: "16px",
                           marginTop: "32px",
                        }}
                     >
                        <NovaTextInput
                           fullwidth
                           value={email}
                           label={t("auth-flow.forgot-password.email")}
                           placeholder={t("auth-flow.forgot-password.email-placeholder")}
                           onChange={(event) => setEmail(event.target.value)}
                        />
                        {email && !checkEmailIsValid(email) && (
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "red", margin: 0 }}
                           >
                              <TranslatedText text="checkout.error.email" />
                           </NovaText>
                        )}

                        <NovaTextInput
                           fullwidth
                           value={code}
                           label={t("auth-flow.forgot-password.code")}
                           placeholder={t("auth-flow.forgot-password.code-placeholder")}
                           onChange={(event) => setCode(event.target.value)}
                        />

                        <NovaPasswordInput
                           label={t("auth-flow.forgot-password.password")}
                           placeholder={t(
                              "auth-flow.forgot-password.password-placeholder",
                           )}
                           value={password}
                           onChange={(event) => setPassword(event.target.value)}
                        />
                        {password && password.length < 6 && (
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "red", margin: 0 }}
                           >
                              <TranslatedText
                                 text="messages.register-validation.password"
                                 props={{
                                    count: 6,
                                 }}
                              />
                           </NovaText>
                        )}

                        <NovaPasswordInput
                           label={t("auth-flow.forgot-password.confirm-password")}
                           placeholder={t(
                              "auth-flow.forgot-password.confirm-password-placeholder",
                           )}
                           value={confirmPassword}
                           onChange={(event) => setConfirmPassword(event.target.value)}
                        />
                        {confirmPassword && password !== confirmPassword && (
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "red", margin: 0 }}
                           >
                              <TranslatedText text="messages.register-validation.confirm-password" />
                           </NovaText>
                        )}
                     </Box>
                  ) : (
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           gap: "16px",
                           marginTop: "32px",
                        }}
                     >
                        <NovaTextInput
                           fullwidth
                           value={email}
                           label={t("auth-flow.forgot-password.email")}
                           placeholder={t("auth-flow.forgot-password.email-placeholder")}
                           onChange={(event) => setEmail(event.target.value)}
                        />
                        {email && !checkEmailIsValid(email) && (
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "red", margin: 0 }}
                           >
                              <TranslatedText text="checkout.error.email" />
                           </NovaText>
                        )}
                     </Box>
                  )}
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "10px",
                        marginTop: "32px",
                     }}
                  >
                     {sentSuccess ? (
                        <NovaButton
                           disabled={
                              !email ||
                              !checkEmailIsValid(email) ||
                              (!code && code.length < 5) ||
                              password !== confirmPassword
                           }
                           style={{
                              backgroundColor: "transparent",
                              border: "1px solid #CAA45D",
                              ":hover": {
                                 border: "1px solid #CAA45D",
                              },
                              borderRadius: "32px",
                           }}
                           onClick={onRecoveryConfirm}
                        >
                           <TranslatedText text="buttons.confirm" />
                        </NovaButton>
                     ) : (
                        <NovaButton
                           disabled={!email || !checkEmailIsValid(email)}
                           style={{
                              backgroundColor: "transparent",
                              border: "1px solid #CAA45D",
                              ":hover": {
                                 border: "1px solid #CAA45D",
                              },
                              borderRadius: "32px",
                           }}
                           onClick={onSendConfirm}
                        >
                           <TranslatedText text="buttons.confirm" />
                        </NovaButton>
                     )}
                     <NovaButton
                        style={{
                           borderRadius: "32px",
                        }}
                        onClick={handleClose}
                     >
                        <TranslatedText text="buttons.cancel" />
                     </NovaButton>
                  </Box>
               </Box>

               <Box
                  sx={{
                     position: "absolute",
                     top: "0",
                     right: "0",
                     zIndex: 1,
                  }}
               >
                  <DetailsModalFlower />
               </Box>
            </Box>
         </Box>
      </Modal>
   );
};

export default ForgotPasswordModal;
<Box
   sx={{
      position: "absolute",
      top: "0",
      right: "0",
      zIndex: 1,
   }}
>
   <DetailsModalFlower />
</Box>;
