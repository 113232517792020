import { isAxiosError } from "axios";
import { AlertsActions } from "../../components/alerts/actions";
import { LoginActions } from "./actions";

const LOGGED_IN_HOME = "/";

const errorTitles = {
   USER_NOT_FOUND: "User not found",
   INCORRECT_PASSWORD: "Incorrect password",
};

export const userLogin =
   (email: string, password: string, navigate: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.usersAPI
         .userLogin(email, password)
         .catch((error: any) => {
            if (!isAxiosError(error)) return;

            dispatch(LoginActions.loginFail());

            const errorTitle: keyof typeof errorTitles = error.response?.data.title;

            dispatch(
               AlertsActions.createAlert({
                  type: "error",
                  message: errorTitles[errorTitle] ?? "An error occurred",
               }),
            );
         })
         .then((res: any) => {
            if (res && res.data) {
               localStorage.setItem("user", JSON.stringify(res.data));
               dispatch(LoginActions.loginSuccess(res.data));
               return navigate(LOGGED_IN_HOME);
            }
         });
   };

export const userLogout = (navigate: any) => (dispatch: any) => {
   dispatch(LoginActions.logoutSuccess());
   localStorage.removeItem("user");
   return navigate("/");
};

export const getUser =
   () =>
   (dispatch: any, _: any, { api }: any) => {
      api.usersAPI
         .getUser()
         .catch((error: any) => {
            // const { response } = error;
            // dispatch(LoginActions.loginFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               const user: any = JSON.parse(localStorage.getItem("user") as any);
               if (user) {
                  const { photoUrl } = res.data;
                  const updatedUser = {
                     ...user,
                     photoUrl,
                  };
                  localStorage.setItem("user", JSON.stringify(updatedUser));
                  dispatch(LoginActions.updateUser(updatedUser));
               }
            }
         });
   };

export const emailVerification =
   (email: string, onSuccess: () => void) =>
   (dispatch: any, _: any, { api }: any) => {
      api.usersAPI
         .emailVerification(email)
         .catch((error: any) => {
            dispatch(
               AlertsActions.createAlert({
                  type: "error",
                  message: error.response?.data?.title ?? "An error occurred",
               }),
            );
         })
         .then((res: any) => {
            if (res && res.data) {
               onSuccess();
            }
         });
   };

export const recoverPassword =
   (email: string, code: string, password: string, onRecoverSuccess: () => void) =>
   (dispatch: any, _: any, { api }: any) => {
      api.usersAPI
         .recoverPassword(email, code, password)
         .catch((error: any) => {
            dispatch(
               AlertsActions.createAlert({
                  type: "error",
                  message: error.response?.data?.title ?? "An error occurred",
               }),
            );
         })
         .then((res: any) => {
            if (res && res.data) {
               onRecoverSuccess();
            }
         });
   };

export const getUsers =
   () =>
   (dispatch: any, _: any, { api }: any) => {
      api.usersAPI
         .getUsers()
         .catch((error: any) => {
            dispatch(
               AlertsActions.createAlert({
                  type: "error",
                  message: error.response?.data?.title ?? "An error occurred",
               }),
            );
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(LoginActions.getUsers(res.data));
            }
         });
   };
