import { memo, useCallback, useState } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import emailjs from "@emailjs/browser";

import NovaText, { NovaTextTypes } from "./shared/NovaText";
import NovaTextInput from "./shared/NovaTextInput";
import NovaButton from "./shared/NovaButton";
import ConfirmationModal from "./ConfirmationModal";

import upgradeImageBackground from "../assets/upgrade-image-background.png";
import Loader from "./Loader";

type ContactSupportModalProps = {
   /** @default false */
   opened?: boolean;
   onClose?: () => void;
};

function ContactSupportModal({ opened = false, onClose }: ContactSupportModalProps) {
   const [confirmCloseIsOpened, setConfirmCloseIsOpened] = useState<boolean>(false);
   const [isLoading, setIsLoading] = useState<boolean>(false);

   const [name, setName] = useState<string>("");
   const [email, setEmail] = useState<string>("");
   const [message, setMessage] = useState<string>("");

   const [errorMessage, setErrorMessage] = useState<string>("");
   const [successMessage, setSuccessMessage] = useState<string>("");

   const onClickSend = useCallback(async () => {
      const apiKay = process.env.REACT_APP_EMAILJS_API_KEY;
      const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
      const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;

      setSuccessMessage("");
      setErrorMessage("");

      if (!serviceId || !templateId) {
         setErrorMessage("There was a problem with the email service.");
         return;
      }

      setIsLoading(true);

      try {
         const response = await emailjs.send(
            serviceId,
            templateId,
            {
               name,
               email,
               message,
            },
            {
               publicKey: apiKay,
            },
         );

         setSuccessMessage("The email was sent successfully.");

         setName("");
         setEmail("");
         setMessage("");
      } catch (error) {
         setErrorMessage("There was a problem with the email service.");
      } finally {
         setIsLoading(false);
      }
   }, [name, email, message]);

   return (
      <>
         <Modal open={opened}>
            <Box
               sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  borderRadius: "24px",
                  display: "flex",
                  alignItems: "baseline",
                  width: 400,
                  border: "1px solid #E9DDC8",
                  outline: "none",
                  overflow: "hidden",
               }}
            >
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "start",
                     width: "100%",
                     position: "relative",
                     padding: "24px",
                  }}
               >
                  <Box
                     sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        zIndex: 10,
                     }}
                  >
                     <Box
                        sx={{
                           width: "100%",
                           display: "flex",
                           justifyContent: "flex-end",
                           zIndex: 10,
                        }}
                     >
                        <IconButton
                           onClick={() =>
                              (name || email || message) && !successMessage
                                 ? setConfirmCloseIsOpened(true)
                                 : onClose?.()
                           }
                        >
                           <Close />
                        </IconButton>
                     </Box>

                     <NovaText
                        type={NovaTextTypes.XLNormal}
                        style={{ margin: "0 0 20px 0" }}
                     >
                        Contact Support
                     </NovaText>

                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           gap: "16px",
                        }}
                     >
                        <NovaTextInput
                           value={name}
                           label="Name"
                           placeholder="Name"
                           onChange={(event) => setName(event.target.value)}
                        />
                        <NovaTextInput
                           value={email}
                           label="Email"
                           placeholder="Email"
                           onChange={(event) => setEmail(event.target.value)}
                        />
                        <NovaTextInput
                           value={message}
                           label="Message"
                           placeholder="Message"
                           multiline
                           maxRows={4}
                           onChange={(event) => setMessage(event.target.value)}
                        />
                     </Box>

                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "flex-end",
                           gap: "20px",
                           marginTop: "24px",
                        }}
                     >
                        {isLoading && <Loader size={20} />}
                        <NovaButton
                           disabled={!name || !email || !message || isLoading}
                           style={{
                              borderRadius: "32px",
                              borderWidth: "1px",
                           }}
                           onClick={onClickSend}
                        >
                           Send
                        </NovaButton>
                     </Box>

                     {errorMessage && (
                        <NovaText
                           type={NovaTextTypes.BaseNormal}
                           style={{
                              textAlign: "right",
                              color: "red",
                              margin: "20px 0 0 0",
                           }}
                        >
                           {errorMessage}
                        </NovaText>
                     )}
                     {successMessage && (
                        <NovaText
                           type={NovaTextTypes.BaseNormal}
                           style={{
                              textAlign: "right",
                              color: "green",
                              margin: "20px 0 0 0",
                           }}
                        >
                           {successMessage}
                        </NovaText>
                     )}
                  </Box>
               </Box>

               <Box
                  sx={{
                     position: "absolute",
                  }}
               >
                  <img src={upgradeImageBackground} width={600} alt="Stars" />
               </Box>
            </Box>
         </Modal>

         <ConfirmationModal
            isOpened={confirmCloseIsOpened}
            onConfirmCallback={() => {
               setConfirmCloseIsOpened(false);
               onClose?.();
            }}
            onCancelCallback={() => setConfirmCloseIsOpened(false)}
         />
      </>
   );
}

export default memo(ContactSupportModal);
