import { useState } from "react";
import {
   Box,
   Grid,
   FormControl,
   FormControlLabel,
   Radio,
   RadioGroup,
} from "@mui/material";
import NovaButton from "../shared/NovaButton";
import { STEPS } from ".";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import NovaTitle from "../shared/NovaTitle";
import SubscriptionPlanCard from "../subscriptions/subscription-plan-card";
import AddPaymentMethod from "../subscriptions/add-payment-method";
import TranslatedText from "../translated-text";
import { AddOutlined, CloseOutlined, LockOutlined } from "@mui/icons-material";
import { useAppDispatch } from "../../store/hooks";
import { createClientSubscription } from "../../pages/subscriptions/async-actions";
import LoaderOverlay from "../LoaderOverlay";

const ConfirmStep = ({
   user,
   data,
   cards,
   selectedPlan,
   onStepChange,
}: {
   user: any;
   data: any;
   cards: any;
   selectedPlan: any;
   onStepChange: (step: any) => void;
}) => {
   const dispatch = useAppDispatch();
   const [selectedCard, setSelectedCard] = useState((cards && cards[0]?.id) || "");

   const [addAnotherPaymentMethod, setAddAnotherPaymentMethod] = useState(false);
   const [isLoading, setIsLoading] = useState<boolean>(false);

   const handleCreateSubscription = () => {
      setIsLoading(true);
      dispatch(
         createClientSubscription(
            selectedPlan.type,
            selectedPlan.id,
            data.pageId,
            selectedPlan.prices[0].priceId,
            selectedCard,
            () => onStepChange(STEPS.FINISH_SUCCESSFUL),
            () => onStepChange(STEPS.FINISH_PROBLEM),
         ),
      );
   };

   const handleChange = (event: any) => {
      setSelectedCard(event.target.value);
   };

   const handleAddAnotherPaymentMethod = () => {
      setAddAnotherPaymentMethod(true);
   };

   const handleAddAnotherPaymentMethodClose = () => {
      setAddAnotherPaymentMethod(false);
   };

   return (
      <Box>
         <LoaderOverlay isLoading={isLoading}>
            <Grid container spacing={5}>
               <Grid item xs={12}>
                  <NovaTitle
                     variant="h3"
                     style={{
                        textAlign: "center",
                        margin: "12px 0 0 0",
                     }}
                  >
                     <TranslatedText
                        text="plans.review-order-title"
                        props={{
                           name: user?.fullName,
                        }}
                     />
                  </NovaTitle>
               </Grid>
               {selectedPlan.name.toLowerCase() !== "lifetime" && (
                  <Grid item xs={12} sm={7}>
                     <NovaText
                        type={NovaTextTypes.XLNormal}
                        style={{
                           margin: "0 0 16px 0",
                        }}
                     >
                        <TranslatedText text="plans.select-payment-method" />
                     </NovaText>
                     <NovaButton
                        onClick={handleAddAnotherPaymentMethod}
                        style={{
                           borderRadius: "24px",
                           padding: "6px 12px",
                           fontSize: "12px",
                        }}
                     >
                        <AddOutlined fontSize="small" />
                        <TranslatedText text="plans.add-payment-method" />
                     </NovaButton>
                     <Box
                        sx={{
                           maxHeight: "200px",
                           overflow: "auto",
                           marginTop: "16px",
                        }}
                     >
                        <FormControl sx={{ width: "100%" }}>
                           <RadioGroup
                              value={selectedCard}
                              onChange={handleChange}
                              sx={{
                                 display: "flex",
                                 flexDirection: "column",
                                 gap: "8px",
                                 width: "100%",
                              }}
                           >
                              {cards?.map((card: any, index: number) => {
                                 return (
                                    <FormControlLabel
                                       key={index}
                                       value={card.id}
                                       control={<Radio />}
                                       sx={{
                                          width: "100%",
                                          ".MuiTypography-root": {
                                             width: "100%",
                                          },
                                       }}
                                       label={
                                          <Box
                                             sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "100%",
                                             }}
                                          >
                                             <Box
                                                sx={{
                                                   display: "flex",
                                                   alignItems: "center",
                                                   gap: "8px",
                                                }}
                                             >
                                                <NovaText
                                                   type={NovaTextTypes.MediumLight}
                                                   style={{
                                                      margin: 0,
                                                      fontWeight: "700",
                                                   }}
                                                >
                                                   ***{card.last4}
                                                </NovaText>
                                                {/* {card.isDefault && (
                                                   <Chip
                                                      label={
                                                         <TranslatedText text="plans.default" />
                                                      }
                                                      sx={{
                                                         height: "24px",
                                                         background: "#1B1B48",
                                                         color: "#CAA45D",
                                                      }}
                                                   />
                                                )} */}
                                             </Box>
                                             <NovaText
                                                type={NovaTextTypes.BaseNormal}
                                                style={{
                                                   margin: 0,
                                                   fontSize: "12px",
                                                }}
                                             >
                                                (
                                                <TranslatedText
                                                   text="plans.expires-on"
                                                   props={{
                                                      date: `${card.expMonth}/${card.expYear}`,
                                                   }}
                                                />
                                                )
                                             </NovaText>
                                          </Box>
                                       }
                                    />
                                 );
                              })}
                           </RadioGroup>
                        </FormControl>
                     </Box>
                     {!cards && (
                        <NovaText
                           type={NovaTextTypes.MediumLight}
                           style={{
                              margin: 0,
                           }}
                        >
                           <TranslatedText
                              text="plans.no-payment-method"
                              props={{
                                 name: user?.fullName,
                              }}
                           />
                        </NovaText>
                     )}
                     {!cards ||
                        (addAnotherPaymentMethod && (
                           <Box
                              sx={{
                                 padding: "16px 16px 24px 16px",
                                 background: "#F3F5F7",
                                 borderRadius: "24px",
                                 marginTop: "24px",
                              }}
                           >
                              <Box
                                 sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "end",
                                    marginBottom: "24px",
                                 }}
                              >
                                 <NovaButton
                                    startIcon={<CloseOutlined />}
                                    style={{
                                       borderRadius: "24px",
                                       padding: "6px 16px",
                                       fontSize: "12px",
                                    }}
                                    onClick={handleAddAnotherPaymentMethodClose}
                                 >
                                    Close
                                 </NovaButton>
                              </Box>
                              <AddPaymentMethod />
                           </Box>
                        ))}
                  </Grid>
               )}
               <Grid item xs={12} sm={5}>
                  <Box>
                     <NovaText
                        type={NovaTextTypes.XLNormal}
                        style={{
                           margin: "0 0 16px 0",
                        }}
                     >
                        <TranslatedText text="plans.selected-plan" />
                     </NovaText>
                     <SubscriptionPlanCard {...selectedPlan} readOnly />
                  </Box>
               </Grid>
            </Grid>

            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                  marginTop: "32px",
                  width: "100%",
                  justifyContent: "space-between",
               }}
            >
               <Box
                  sx={{
                     display: "inline-flex",
                     alignItems: "center",
                     background: "#d9ead3",
                     padding: "0 10px",
                     borderRadius: "6px",
                     gap: "8px",
                  }}
               >
                  <LockOutlined />
                  <NovaText type={NovaTextTypes.BaseNormal}>
                     <TranslatedText text="messages.stripe-message" />
                  </NovaText>
               </Box>
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     gap: "16px",
                  }}
               >
                  <NovaButton
                     style={{
                        borderRadius: "32px",
                        border: "1px solid #CAA45D",
                        background: "#fff",
                        color: "#1B1B48",
                     }}
                     onClick={() => onStepChange(STEPS.PLANS)}
                  >
                     <TranslatedText text="buttons.back" />
                  </NovaButton>
                  <NovaButton
                     disabled={
                        !selectedCard &&
                        selectedPlan.type !== 2 &&
                        selectedPlan.name !== "Free"
                     }
                     style={{ borderRadius: "32px" }}
                     onClick={handleCreateSubscription}
                  >
                     <TranslatedText text="buttons.subscribe" />
                  </NovaButton>
               </Box>
            </Box>
         </LoaderOverlay>
      </Box>
   );
};

export default ConfirmStep;
