import { getUser } from "../Login/async-actions";
import { MemorialsActions } from "./actions";

export const getMemorialPages =
   () =>
   (dispatch: any, _: any, { api }: any) => {
      api.memorialAPI
         .getMemorialPages()
         .catch((error: any) => {
            // const { response } = error;
            // dispatch(LoginActions.loginFail());
         })
         .then((res: any) => {
            const { data } = res;
            if (data) {
               dispatch(MemorialsActions.getMemorials(data));
            }
         });
   };

export const getMemorialPageById =
   (id: number, navigate?: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.memorialAPI
         .getPage(id)
         .catch((error: any) => {
            // const { response } = error;
            // dispatch(LoginActions.loginFail());
         })
         .then((res: any) => {
            if (res?.data) {
               dispatch(MemorialsActions.getEditedMemorial(res.data));
            } else {
               navigate("../dashboard");
            }
         });
   };

export const deleteMemorialPages =
   (id: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.memorialAPI
         .deleteMemorialPage(id)
         .catch((error: any) => {
            // const { response } = error;
            // dispatch(LoginActions.loginFail());
         })
         .then((res: any) => {
            const { data } = res;
            if (data) {
               dispatch(MemorialsActions.updateMemorials(id));
            }
         });
   };

export const getMyRequests =
   () =>
   (dispatch: any, _: any, { api }: any) => {
      api.memorialAPI
         .getMyRequests()
         .catch((error: any) => {
            // const { response } = error;
            // dispatch(LoginActions.loginFail());
         })
         .then((res: any) => {
            const { data } = res;
            if (data) {
               dispatch(MemorialsActions.getMyRequests(data));
            }
         });
   };

export const getMyPagesRequests =
   () =>
   (dispatch: any, _: any, { api }: any) => {
      api.memorialAPI
         .getMyPagesRequests()
         .catch((error: any) => {
            // const { response } = error;
            // dispatch(LoginActions.loginFail());
         })
         .then((res: any) => {
            const { data } = res;
            if (data) {
               // dispatch(MemorialsActions.getPageRequests(data));
               dispatch(MemorialsActions.getMyPagesRequests(data));
            }
         });
   };

export const approveRequest =
   (requestId: any, pageId: any) =>
   (dispatch: any, _: any, { api }: any) => {
      // TODO remove hardcoded access level
      api.memorialAPI
         .approveRequest(requestId, 2)
         .catch((error: any) => {
            dispatch(MemorialsActions.rejectPageRequestFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(MemorialsActions.approvePageRequestSuccess(res.data));
            }
         });
   };

export const rejectRequest =
   (requestId: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.memorialAPI
         .rejectRequest(requestId)
         .catch((error: any) => {
            // const { response } = error;
            dispatch(MemorialsActions.rejectPageRequestFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(MemorialsActions.rejectPageRequestSuccess(requestId));
            }
         });
   };

export const saveProfilePhoto =
   (profilePhotoBase64: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.usersAPI
         .saveProfilePhoto(profilePhotoBase64)
         .catch((error: any) => {
            // const { response } = error;
            // dispatch(MemorialsActions.rejectPageRequestFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(getUser());
            }
         });
   };
