import { SubscriptionsActions } from "./actions";

export const createSubscriptionPlan =
   (subscription: any, navigate: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.subscriptionsAPI
         .createSubscriptionPlan(subscription)
         .catch((error: any) => {
            dispatch(SubscriptionsActions.createSubscriptionPlanFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(SubscriptionsActions.createSubscriptionPlanSuccess(res.data));
               return navigate("../subscriptions");
            }
         });
   };

export const createPaymentMethod =
   (
      email: string,
      name: string,
      paymentMethodId: string,
      priceId: string,
      onSuccess?: () => void,
      onFail?: () => void,
   ) =>
   (dispatch: any, _: any, { api }: any) => {
      api.subscriptionsAPI
         .createPaymentMethod(email, name, paymentMethodId, priceId)
         .catch((error: any) => {
            dispatch(SubscriptionsActions.createCardFail());
            onFail?.();
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(SubscriptionsActions.createCardSuccess(res.data));
               onSuccess?.();
            }
         });
   };

export const getSubscriptionPlans =
   () =>
   (dispatch: any, _: any, { api }: any) => {
      api.subscriptionsAPI
         .getSubscriptionPlans()
         .catch((error: any) => {
            dispatch(SubscriptionsActions.getAllSubscriptionPlansFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               const plans = res.data.map((plan: any) => ({
                  ...plan,
                  data: JSON.parse(plan.data),
               }));

               const sortedPlans = plans.sort((a: any, b: any) =>
                  a.data.order > b.data.order ? 1 : b.data.order > a.data.order ? -1 : 0,
               );
               dispatch(SubscriptionsActions.getAllSubscriptionPlansSuccess(sortedPlans));
            }
         });
   };

export const getPublicSubscriptionPlans =
   () =>
   (dispatch: any, _: any, { api }: any) => {
      api.subscriptionsAPI
         .getActiveAndVisiblePlans()
         .catch((error: any) => {
            dispatch(SubscriptionsActions.getAllSubscriptionPlansFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               const plans = res.data.map((plan: any) => ({
                  ...plan,
                  data: JSON.parse(plan.data),
               }));
               const sortedPlans = plans.sort((a: any, b: any) =>
                  a.data.order > b.data.order ? 1 : b.data.order > a.data.order ? -1 : 0,
               );
               dispatch(SubscriptionsActions.getAllSubscriptionPlansSuccess(sortedPlans));
            }
         });
   };

export const setPlanVisibility =
   (id: number, visibility: boolean) =>
   (dispatch: any, _: any, { api }: any) => {
      api.subscriptionsAPI
         .setPlanVisibility(id, visibility)
         .catch((error: any) => {
            dispatch(SubscriptionsActions.changeSubscriptionPlanVisibilityFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(
                  SubscriptionsActions.changeSubscriptionPlanVisibilitySuccess({
                     id,
                     visibility,
                  }),
               );
            }
         });
   };

export const setPlanActivity =
   (id: number, isActive: boolean) =>
   (dispatch: any, _: any, { api }: any) => {
      api.subscriptionsAPI
         .setPlanActivity(id, isActive)
         .catch((error: any) => {
            dispatch(SubscriptionsActions.changeSubscriptionPlanActivityFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(
                  SubscriptionsActions.changeSubscriptionPlanActivitySuccess({
                     id,
                     isActive,
                  }),
               );
            }
         });
   };

export const editSubscriptionPlan =
   (subscription: any, navigate: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.subscriptionsAPI
         .editSubscriptionPlan(subscription.id, subscription)
         .catch((error: any) => {
            dispatch(SubscriptionsActions.editSubscriptionPlanFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               const plan = {
                  ...res.data,
                  data: JSON.parse(res.data.data),
               };
               dispatch(SubscriptionsActions.editSubscriptionPlanSuccess(plan));
               return navigate("../subscriptions");
            }
         });
   };

export const createClientSubscription =
   (
      type: number,
      subscriptionPlanId: any,
      memorialPageId: any,
      priceId: any,
      paymentMethodId: string,
      onSuccess: any,
      onFail: any,
   ) =>
   (dispatch: any, _: any, { api }: any) => {
      api.subscriptionsAPI
         .createClientSubscription(
            subscriptionPlanId,
            memorialPageId,
            priceId,
            paymentMethodId,
         )
         .catch((error: any) => {
            dispatch(SubscriptionsActions.createSubscriptionFail());
            onFail();
         })
         .then((res: any) => {
            if (res && res.data) {
               if (type !== 2) {
                  onSuccess();
                  dispatch(SubscriptionsActions.createSubscriptionSuccess(res.data));
               } else {
                  window.location.href = res.data.paymentUrl;
               }
            }
         });
   };

export const getCards =
   () =>
   (dispatch: any, _: any, { api }: any) => {
      api.subscriptionsAPI
         .getCards()
         .catch((error: any) => {
            dispatch(SubscriptionsActions.getCardsFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(SubscriptionsActions.getCardsSuccess(res.data));
            }
         });
   };

export const deleteCard =
   (paymentMethodId: string) =>
   (dispatch: any, _: any, { api }: any) => {
      api.subscriptionsAPI
         .deleteCard(paymentMethodId)
         .catch((error: any) => {
            dispatch(SubscriptionsActions.deleteCardFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(SubscriptionsActions.deleteCardSuccess(paymentMethodId));
            }
         });
   };

export const getSubscribers =
   () =>
   (dispatch: any, _: any, { api }: any) => {
      api.subscriptionsAPI
         .getSubscribers()
         .catch((error: any) => {
            dispatch(SubscriptionsActions.getSubscribersFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(SubscriptionsActions.getSubscribersSuccess(res.data));
            }
         });
   };
