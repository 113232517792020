import api from "./withAuth";

// admin
const getSubscriptionPlans = async () => {
   return await api.get("subscriptionplans/all");
};

const getActiveAndVisiblePlans = async () => {
   return await api.get("subscriptionplans/all/public");
};

const createSubscriptionPlan = async (subscription: any) => {
   return await api.post("subscriptionplans/create", {
      ...subscription,
   });
};

const editSubscriptionPlan = async (id: number, changes: any) => {
   return await api.put(`subscriptionplans/${id}`, {
      ...changes,
   });
};

const setPlanVisibility = async (id: number, visibility: boolean) => {
   return await api.put(`subscriptionplans/${id}/setvisibility/${visibility}`);
};

const setPlanActivity = async (id: number, isActive: boolean) => {
   return await api.put(`subscriptionplans/${id}/setactivity/${isActive}`);
};

// client
const createClientSubscription = async (
   subscriptionPlanId: any,
   memorialPageId: any,
   priceId: any,
   paymentMethodId: string,
) => {
   return await api.post("subscriptions/create", {
      subscriptionPlanId,
      memorialPageId,
      priceId,
      paymentMethodId,
   });
};

const createPaymentMethod = async (
   email: string,
   name: string,
   paymentMethodId: string,
) => {
   return await api.post("users/stripe/addcard", {
      email,
      name,
      paymentMethodId,
   });
};

const getCards = async () => {
   return await api.get("users/stripe/cards");
};

const deleteCard = async (paymentMethodId: any) => {
   return await api.delete(`users/stripe/cards/${paymentMethodId}`);
};

const getSubscribers = async () => {
   return await api.get("subscriptions/all");
};

export const subscriptionsAPI = {
   getSubscriptionPlans,
   getActiveAndVisiblePlans,
   createSubscriptionPlan,
   editSubscriptionPlan,
   setPlanVisibility,
   setPlanActivity,
   createClientSubscription,
   createPaymentMethod,
   getCards,
   deleteCard,
   getSubscribers,
};
