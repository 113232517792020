import { useNavigate, useParams } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import SuccessfullyFinishedScreen from "../../components/SuccessfullyFinishedScreen";

const PaymentSuccess = () => {
   const { orderId, memorialPageId } = useParams();
   const navigate = useNavigate();

   const pageType = orderId ? "Order" : "Subscription";

   return (
      <Container>
         <Box
            sx={{
               width: "100%",
               marginTop: "80px",
               background: "#fff",
               border: "1px solid #E9DDC8",
               position: "relative",
               overflow: "hidden",
               borderRadius: "32px",
            }}
         >
            <SuccessfullyFinishedScreen
               title="Purchase Completed"
               text={
                  <>
                     Your {pageType} <b>#{orderId || memorialPageId}</b> was successfully
                     created!
                  </>
               }
               buttonText={
                  <>
                     Dashboard&nbsp;
                     <ArrowForward />
                  </>
               }
               onClickButton={() => navigate("../dashboard")}
            />
         </Box>
      </Container>
   );
};

export default PaymentSuccess;
