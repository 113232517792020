import { CREATE, REMOVE } from "./types";
import { Alert } from "./reducer";

const createAlert = (payload: Omit<Alert, "id">) => ({
   type: CREATE,
   payload: {
      id: Math.random(),
      ...payload,
   },
});

const removeAlert = (payload: { alertId: number }) => ({
   type: REMOVE,
   payload,
});

export const AlertsActions = {
   createAlert,
   removeAlert,
};
