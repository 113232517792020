import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";

import { Language, resources } from "../translations/i18n";

import { AppDispatch, RootState } from "./index";
import { useCallback, useEffect, useMemo, useState } from "react";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export const useLanguage = (): {
   language: Language;
   changeLanguage: (language: Language) => void;
   domainLanguage: string;
} => {
   const [language, setLanguage] = useState<Language>(i18next.language as Language);

   const changeLanguage = useCallback((language: Language) => {
      i18next.changeLanguage(language, (error) => {
         if (error)
            console.log("Something went wrong with the loading of the language!", error);
      });
      setLanguage(language);
   }, []);
   const domainToLanguage = useCallback((domain: string): Language => {
      switch (domain) {
         case "bg":
            return "bg";

         default:
            return "en";
      }
   }, []);

   const domainLanguage = useMemo<string>(() => {
      const domain = window.location.host.split(".").at(-1);

      if (domain?.includes("localhost")) return "com";
      return domain ?? "com";
   }, []);

   useEffect(() => {
      if (!Object.keys(resources).includes(language))
         changeLanguage(domainToLanguage(domainLanguage));
   }, [language, changeLanguage, domainToLanguage, domainLanguage]);

   return {
      language,
      changeLanguage,
      domainLanguage,
   };
};

export const useMediaQuery = () => {
   const [xs, setXs] = useState<boolean>(false);
   const [sm, setSm] = useState<boolean>(false);
   const [md, setMd] = useState<boolean>(false);
   const [lg, setLg] = useState<boolean>(false);
   const [xl, setXl] = useState<boolean>(false);

   useEffect(() => {
      const xsMedia = window?.matchMedia("(max-width: 0px)");
      const smMedia = window?.matchMedia("(max-width: 600px)");
      const mdMedia = window?.matchMedia("(max-width: 900px)");
      const lgMedia = window?.matchMedia("(max-width: 1200px)");
      const xlMedia = window?.matchMedia("(max-width: 1536px)");

      const onResize = () => {
         setXs(xsMedia?.matches ?? false);
         setSm(smMedia?.matches ?? false);
         setMd(mdMedia?.matches ?? false);
         setLg(lgMedia?.matches ?? false);
         setXl(xlMedia?.matches ?? false);
      };

      window?.addEventListener("resize", onResize);
      onResize();

      return () => {
         window?.removeEventListener("resize", onResize);
      };
   }, []);

   return {
      /** 0px */
      xs,
      /** 600px */
      sm,
      /** 900px */
      md,
      /** 1200px */
      lg,
      /** 1536px */
      xl,
   };
};
