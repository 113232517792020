import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, IconButton, Modal } from "@mui/material";
import LoaderOverlay from "../LoaderOverlay";
import { useAppDispatch } from "../../store/hooks";
import { getSingleOrder } from "../../pages/orders/async-actions";
import { OrdersSelectors } from "../../pages/orders/selectors";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import { DetailsModalFlower } from "../shared/icons/details-modal-flower";
import { Close } from "@mui/icons-material";
import ProductCarousel from "./products-carousel";
import TranslatedText from "../translated-text";

const DetailsModal = ({ orderId, onClose }: any) => {
   const dispatch = useAppDispatch();
   const [isLoading, setIsLoading] = useState(false);
   const singleOrder = useSelector(OrdersSelectors.getSingleOrder);
   const loadingStart = () => {
      setIsLoading(true);
   };
   const loadingCompleted = () => {
      setIsLoading(false);
   };
   useEffect(() => {
      if (!singleOrder || singleOrder.id !== orderId) {
         dispatch(getSingleOrder(orderId, loadingStart, loadingCompleted));
      }
   }, [singleOrder]);

   return (
      <Modal open={true} onClose={onClose}>
         <Box
            sx={{
               position: "absolute",
               top: "50%",
               left: "50%",
               transform: "translate(-50%, -50%)",
               bgcolor: "background.paper",
               boxShadow: 24,
               borderRadius: "24px",
               display: "flex",
               alignItems: "baseline",
               minWidth: {
                  xs: "calc(100% - 20px)",
                  md: "578px",
               },
               border: "1px solid #E9DDC8",
               outline: "none",
            }}
         >
            <LoaderOverlay isLoading={isLoading}>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "start",
                     width: "100%",
                     position: "relative",
                     padding: "24px",
                  }}
               >
                  <Box
                     sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        zIndex: 10,
                     }}
                  >
                     <IconButton onClick={onClose}>
                        <Close />
                     </IconButton>
                  </Box>

                  <Box sx={{ zIndex: 10, width: "100%", height: "100%" }}>
                     <ProductCarousel
                        items={singleOrder?.items}
                        singleOrder={singleOrder}
                        orderId={orderId}
                     />
                     <Box sx={{ width: "100%", marginTop: "16px" }}>
                        <NovaText
                           type={NovaTextTypes.XLNormal}
                           style={{ margin: "0 0 8px 0" }}
                        >
                           <TranslatedText text="home.orders.details-panel.order-details" />
                        </NovaText>
                        <Box
                           sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              flex: 1,
                              borderBottom: "1px solid #E9DDC8",
                              padding: "4px 0",
                           }}
                        >
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "#777E90", margin: 0 }}
                           >
                              <TranslatedText text="home.orders.details-panel.customer-name" />
                           </NovaText>
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{
                                 margin: 0,
                                 whiteSpace: "nowrap",
                                 overflow: "hidden",
                                 textOverflow: "ellipsis",
                                 // maxWidth: "200px",
                              }}
                           >
                              {singleOrder?.customerName}
                           </NovaText>
                        </Box>

                        <Box
                           sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              flex: 1,
                              borderBottom: "1px solid #E9DDC8",
                              marginTop: "8px",
                           }}
                        >
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "#777E90", margin: 0 }}
                           >
                              <TranslatedText text="home.orders.details-panel.order-status" />
                           </NovaText>
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{
                                 margin: 0,
                                 whiteSpace: "nowrap",
                                 overflow: "hidden",
                                 textOverflow: "ellipsis",
                                 // maxWidth: "200px",
                              }}
                           >
                              <TranslatedText
                                 text={`home.orders.statuses.${singleOrder?.orderStatus}`}
                              />
                           </NovaText>
                        </Box>

                        <Box
                           sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              flex: 1,
                              borderBottom: "1px solid #E9DDC8",
                              marginTop: "8px",
                           }}
                        >
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "#777E90", margin: 0 }}
                           >
                              <TranslatedText text="home.orders.details-panel.order-amount" />
                           </NovaText>
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{
                                 margin: 0,
                                 whiteSpace: "nowrap",
                                 overflow: "hidden",
                                 textOverflow: "ellipsis",
                                 // maxWidth: "200px",
                              }}
                           >
                              {singleOrder?.amount}&nbsp;{singleOrder?.currency}
                           </NovaText>
                        </Box>

                        <Box
                           sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              flex: 1,
                              borderBottom: "1px solid #E9DDC8",
                              marginTop: "8px",
                           }}
                        >
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "#777E90", margin: 0 }}
                           >
                              <TranslatedText text="home.orders.details-panel.payment-type" />
                           </NovaText>
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{
                                 margin: 0,
                                 whiteSpace: "nowrap",
                                 overflow: "hidden",
                                 textOverflow: "ellipsis",
                                 // maxWidth: "200px",
                              }}
                           >
                              <TranslatedText
                                 text={`home.orders.payment-methods.${singleOrder?.paymentType}`}
                              />
                              &nbsp;(
                              <TranslatedText
                                 text={`home.orders.payment-status.${singleOrder?.paymentStatus}`}
                              />
                              )
                           </NovaText>
                        </Box>
                     </Box>
                  </Box>

                  <Box
                     sx={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        zIndex: 1,
                     }}
                  >
                     <DetailsModalFlower />
                  </Box>
               </Box>
            </LoaderOverlay>
         </Box>
      </Modal>
   );
};

export default DetailsModal;
