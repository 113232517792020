import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, Grid } from "@mui/material";
import { Box, styled } from "@mui/system";
import { AddCircleOutline } from "@mui/icons-material";

import { useAppDispatch } from "../../store/hooks";
import { uploadFile, deleteFile } from "./async-actions";
import { HomeSelectors } from "../home/selectors";

import { limitations } from "./limitations";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import MarkdownEditor from "../../components/md-editor/markdown-editor";
import MemorialLimitationsInfoBar from "../../components/MemorialLimitationsInfoBar";
import FilesList from "./FilesList";
import TranslatedText from "../../components/translated-text";

const VisuallyHiddenInput = styled("input")({
   clip: "rect(0 0 0 0)",
   clipPath: "inset(50%)",
   height: 1,
   overflow: "hidden",
   position: "absolute",
   bottom: 0,
   left: 0,
   whiteSpace: "nowrap",
   width: 1,
});

const Biography = ({
   biography,
   onChange,
   onChangeFormData,
}: {
   biography: any;
   onChange: (changes: any, field: any) => void;
   onChangeFormData: Dispatch<SetStateAction<any[]>>;
}) => {
   const { t } = useTranslation();
   const isInEditMode = useSelector(HomeSelectors.getPageIsInEditMode);
   const pageId = useSelector(HomeSelectors.getEditPageId);
   const editMemorialPageData = useSelector(HomeSelectors.getEditedMemorialPage);

   const maxBiographyLength =
      limitations[
         editMemorialPageData?.subscription
            ? editMemorialPageData.subscription.subscriptionPlan.type
            : "free"
      ]?.biographyLength;

   const maxBiographyFilesLength =
      limitations[
         editMemorialPageData?.subscription
            ? editMemorialPageData.subscription.subscriptionPlan.type
            : "free"
      ]?.biographyFilesLength;

   const dispatch = useAppDispatch();
   const [isFileUploading, setIsFileUploading] = useState(false);
   const uploadStartCallback = () => {
      setIsFileUploading(true);
   };
   const uploadSuccessCallback = () => {
      setIsFileUploading(false);
   };
   const handleRemoveFile = (fileId: number) => {
      if (isInEditMode) dispatch(deleteFile(fileId));

      onChangeFormData([]); //? If limitations change to more that one this needs to be changed
      onChange?.(
         biography?.files?.filter((file: any) => file.id !== fileId),
         "files",
      );
   };
   const handleFileChange = async (event: any) => {
      if (biography?.files?.length >= maxBiographyFilesLength) return;

      const file = event.target.files[0];

      if (file) {
         const formData = new FormData();
         formData.append("file", file);

         if (isInEditMode) {
            dispatch(
               uploadFile(pageId, formData, uploadStartCallback, uploadSuccessCallback),
            );
         } else {
            onChangeFormData((oldValue) => [...oldValue, formData]);
            onChange(
               [
                  ...biography?.files,
                  {
                     id: Math.random(),
                     name: file.name,
                  },
               ],
               "files",
            );
         }
      }
   };

   return (
      <>
         <MemorialLimitationsInfoBar
            itemName={t("create-memorial-page.biography.item-name")}
            sentence={t("create-memorial-page.biography.plan-validation-message", {
               maxBiographyLength,
               maxBiographyFilesLength,
            })}
            limit={maxBiographyLength}
         />

         <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
               <Box>
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        margin: 0,
                     }}
                  >
                     <TranslatedText text="create-memorial-page.biography.title" />
                  </NovaText>
                  <MarkdownEditor
                     value={biography.biography}
                     placeholder={t(
                        "create-memorial-page.biography.biography-placeholder",
                     )}
                     maxSymbols={maxBiographyLength}
                     onChange={(value) => onChange(value, "biography")}
                  />
               </Box>
               <Box sx={{ marginTop: "16px" }}>
                  <Box
                     sx={{
                        marginTop: "18px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                     }}
                  >
                     <Button
                        component="label"
                        role={undefined}
                        variant="text"
                        tabIndex={-1}
                        startIcon={
                           isFileUploading ? (
                              <CircularProgress size="20px" />
                           ) : (
                              <AddCircleOutline />
                           )
                        }
                        sx={{
                           color: "#1B1B48",
                        }}
                     >
                        <TranslatedText text="create-memorial-page.biography.upload-other-files" />
                        <VisuallyHiddenInput
                           type="file"
                           accept="application/pdf"
                           onChange={handleFileChange}
                        />
                     </Button>

                     <FilesList
                        files={biography?.files}
                        handleRemoveFile={handleRemoveFile}
                     />
                  </Box>
               </Box>
            </Grid>
         </Grid>
      </>
   );
};

export default Biography;
