import { memo, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import {
   BoldItalicUnderlineToggles,
   CodeToggle,
   ListsToggle,
   MDXEditor,
   UndoRedo,
   listsPlugin,
   quotePlugin,
   linkPlugin,
   toolbarPlugin,
   Separator,
   CreateLink,
   linkDialogPlugin,
   MDXEditorMethods,
} from "@mdxeditor/editor";

import "@mdxeditor/editor/style.css";
import NovaText, { NovaTextTypes } from "../shared/NovaText";

type MarkdownEditorProps = {
   value?: string;
   placeholder?: string | undefined;
   readOnly?: boolean;
   /** @default none */
   maxSymbols?: number;
   onChange?: (value: string) => void;
};

function MarkdownEditor({
   value,
   placeholder,
   readOnly,
   maxSymbols,
   onChange,
}: MarkdownEditorProps) {
   const ref = useRef<MDXEditorMethods>(null);

   useEffect(() => {
      ref.current?.setMarkdown(value ?? "");
   }, [value]);

   return (
      <Box
         sx={{
            ".mdxeditor-root-contenteditable": !readOnly
               ? {
                    minHeight: "150px",
                    maxHeight: "200px",
                    backgroundColor: "#ffffff",
                    border: "1px solid #d1d1d160",
                    borderRadius: "5px",
                    marginTop: "4px",
                    overflowY: "auto",
                 }
               : {},
            ".mdxeditor-root-contenteditable > div": {
               padding: readOnly ? "0px" : "8px 12px",
            },
            ".mdxeditor-root-contenteditable > div > p": {
               marginBlock: "10px",
            },
         }}
      >
         <MDXEditor
            readOnly={readOnly}
            markdown={value ?? ""}
            placeholder={placeholder}
            plugins={[
               ...(!readOnly
                  ? [
                       toolbarPlugin({
                          toolbarContents: () => (
                             <>
                                <BoldItalicUnderlineToggles />
                                <Separator />
                                <ListsToggle options={["bullet", "number"]} />
                                <Separator />

                                <CodeToggle />
                                <CreateLink />

                                <Box
                                   sx={{
                                      marginLeft: "auto",
                                   }}
                                >
                                   <UndoRedo />
                                </Box>
                             </>
                          ),
                       }),
                    ]
                  : []),
               listsPlugin(),
               quotePlugin(),
               linkPlugin(),
               linkDialogPlugin(),
            ]}
            ref={ref}
            onChange={onChange}
         />

         {maxSymbols && (
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "4px",
               }}
            >
               <NovaText
                  type={NovaTextTypes.BaseNormal}
                  style={{
                     width: "fit-content",
                     color: (value?.length ?? 0) > maxSymbols ? "red" : "#1b1b45",
                     backgroundColor: "#ffffff",
                     borderRadius: "999px",
                     margin: 0,
                     padding: "0px 8px",
                  }}
               >
                  <span
                     style={{
                        color: "#1b1b4580",
                     }}
                  >
                     {value?.length ?? 0}
                  </span>{" "}
                  / {maxSymbols}
               </NovaText>
            </Box>
         )}
      </Box>
   );
}

export default memo(MarkdownEditor);
