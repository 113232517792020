export const rows: Array<any> = [
   {
      id: 0,
      name: "namename",
      description: "descriptiondescription",
      isActive: true,
      stockQuantity: 10,
      price: 4,
      dateCreated: "10-06-2024",
      dateModified: "10-09-2024",
   },
   {
      id: 1,
      name: "namename 1",
      description: "descriptiondescription",
      isActive: false,
      stockQuantity: 15,
      price: 41,
      dateCreated: "10-06-2024",
      dateModified: "10-09-2024",
   },
   {
      id: 2,
      name: "namename 2",
      description: "descriptiondescription",
      isActive: true,
      stockQuantity: 10,
      price: 4,
      dateCreated: "10-06-2024",
      dateModified: "10-09-2024",
   },
   {
      id: 3,
      name: "namename 3",
      description: "descriptiondescription",
      isActive: false,
      stockQuantity: 15,
      price: 41,
      dateCreated: "10-06-2024",
      dateModified: "10-09-2024",
   },
   {
      id: 4,
      name: "namename 4",
      description: "descriptiondescription",
      isActive: true,
      stockQuantity: 10,
      price: 4,
      dateCreated: "10-06-2024",
      dateModified: "10-09-2024",
   },
   {
      id: 5,
      name: "namename 5",
      description: "descriptiondescription",
      isActive: false,
      stockQuantity: 15,
      price: 41,
      dateCreated: "10-06-2024",
      dateModified: "10-09-2024",
   },
   {
      id: 6,
      name: "namename 6",
      description: "descriptiondescription",
      isActive: true,
      stockQuantity: 10,
      price: 4,
      dateCreated: "10-06-2024",
      dateModified: "10-09-2024",
   },
   {
      id: 7,
      name: "namename 7",
      description: "descriptiondescription",
      isActive: false,
      stockQuantity: 15,
      price: 41,
      dateCreated: "10-06-2024",
      dateModified: "10-09-2024",
   },
];

export const columns: readonly any[] = [
   {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
   },
   {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
   },
   {
      id: "isActive",
      numeric: false,
      disablePadding: false,
      label: "Active",
   },
   {
      id: "stockQuantity",
      numeric: false,
      disablePadding: false,
      label: "Quantity",
   },
   {
      id: "price",
      numeric: false,
      disablePadding: false,
      label: "Price",
   },
   // {
   //   id: "dateCreated",
   //   numeric: false,
   //   disablePadding: false,
   //   label: "Created on",
   // },
   // {
   //   id: "dateModified",
   //   numeric: false,
   //   disablePadding: false,
   //   label: "Modified on",
   // },
   {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "",
   },
];
