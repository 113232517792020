import axios from "axios";
import api from "./withAuth";

const getEkontCities = async () => {
   return await axios.post(
      "https://ee.econt.com/services/Nomenclatures/NomenclaturesService.getCities.json",
      {
         countryCode: "BGR",
      },
   );
};

const getEkontOffices = async (cityID: string) => {
   return await axios.post(
      "https://ee.econt.com/services/Nomenclatures/NomenclaturesService.getOffices.json",
      {
         countryCode: "BGR",
         showCargReceptions: 1,
         showLC: 1,
         cityID,
      },
   );
};

export const ekontAPI = {
   getEkontCities,
   getEkontOffices,
};
