const NovaTitle = ({
   variant,
   style,
   children,
}: {
   variant: string;
   style?: any;
   children: any;
}) => {
   if (variant === "h1") {
      return (
         <h1
            style={{
               fontFamily: "Cormorant Infant",
               fontSize: "100px",
               fontWeight: 700,
               lineHeight: "80px",
               color: style?.color ? style.color : "#000",
               ...style,
            }}
         >
            {children}
         </h1>
      );
   }
   if (variant === "h2") {
      return (
         <h2
            style={{
               fontFamily: "Cormorant Infant",
               fontSize: "48px",
               fontWeight: 700,
               lineHeight: "46px",
               color: style?.color ? style.color : "#000",
               ...style,
            }}
         >
            {children}
         </h2>
      );
   }
   if (variant === "h3") {
      return (
         <h3
            style={{
               fontFamily: "Cormorant Infant",
               fontSize: "40px",
               fontWeight: 700,
               lineHeight: "38px",
               color: style?.color ? style.color : "#000",
               ...style,
            }}
         >
            {children}
         </h3>
      );
   }
   if (variant === "h4") {
      return (
         <h4
            style={{
               fontFamily: "Cormorant Infant",
               fontSize: "24px",
               fontWeight: 700,
               lineHeight: "46px",
               color: style?.color ? style.color : "#000",
               ...style,
            }}
         >
            {children}
         </h4>
      );
   }
   if (variant === "h5") {
      return (
         <h5
            style={{
               fontFamily: "Montserrat, sans-serif",
               fontSize: "28px",
               fontWeight: 700,
               lineHeight: "46px",
               color: style?.color ? style.color : "#000",
               ...style,
            }}
         >
            {children}
         </h5>
      );
   }
   return <h1>add variant</h1>;
};

export default NovaTitle;
