export const columns: readonly any[] = [
   {
      id: "userFullName",
      numeric: false,
      disablePadding: false,
      label: "User",
   },
   {
      id: "planName",
      numeric: false,
      disablePadding: false,
      label: "Duration",
   },
   {
      id: "startDate",
      numeric: false,
      disablePadding: false,
      label: "Start date",
   },
   {
      id: "endDate",
      numeric: false,
      disablePadding: false,
      label: "End date",
   },
   {
      id: "isActive",
      numeric: false,
      disablePadding: false,
      label: "Status",
   },
];
