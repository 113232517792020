import { Box } from "@mui/material";
import { memo } from "react";
import Loader from "./Loader";

type LoaderOverlayProps = {
   /** @default false */
   isLoading?: boolean;
   /** @default "Loading" */
   loadingText?: string;
   children?: React.ReactNode;
};

function LoaderOverlay({ isLoading, loadingText, children }: LoaderOverlayProps) {
   return (
      <Box
         sx={{
            position: "relative",
            width: "100%",
         }}
      >
         <Box
            sx={{
               opacity: isLoading ? 0.5 : 1,
               pointerEvents: isLoading ? "none" : "auto",
            }}
         >
            {children}
         </Box>
         <Box
            sx={{
               display: "flex",
               position: "absolute",
               width: "100%",
               height: "100%",
               top: 0,
               left: 0,
               opacity: isLoading ? 1 : 0,
               pointerEvents: isLoading ? "auto" : "none",
               alignItems: "center",
               justifyContent: "center",
            }}
         >
            <Loader text={loadingText} />
         </Box>
      </Box>
   );
}

export default memo(LoaderOverlay);
