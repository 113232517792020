import { PaymentsActions } from "./actions";

export const getPayments =
   () =>
   (dispatch: any, _: any, { api }: any) => {
      api.paymentsAPI
         .getPayments()
         .catch((error: any) => {
            dispatch(PaymentsActions.getPaymentsFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(PaymentsActions.getPaymentsSuccess(res.data));
            }
         });
   };
