import * as Immutable from "seamless-immutable";
import {
   GET_SUBSCRIPTION_PLANS_SUCCESS,
   CREATE_SUBSCRIPTION_PLAN_SUCCESS,
   CHANGE_SUBSCRIPTION_PLAN_VISIBILITY_SUCCESS,
   CHANGE_SUBSCRIPTION_PLAN_ACTIVITY_SUCCESS,
   GET_CARDS_SUCCESS,
   DELETE_CARD_SUCCESS,
   CREATE_CARD_SUCCESS,
   GET_SUBSCRIBERS_SUCCESS,
   EDIT_SUBSCRIPTION_PLAN_SUCCESS,
} from "./types";

const initialState = Immutable.from<any>({
   list: null,
   cards: null,
   subscribers: null,
});

export default function SubscriptionsReducer(state = initialState, action: any) {
   const { type, payload } = action;
   switch (type) {
      case GET_SUBSCRIPTION_PLANS_SUCCESS: {
         return state.setIn(["list"], payload);
      }
      case CREATE_SUBSCRIPTION_PLAN_SUCCESS: {
         const { list } = state;
         return state.setIn(["list", { ...list, ...payload }]);
      }

      case CHANGE_SUBSCRIPTION_PLAN_VISIBILITY_SUCCESS: {
         const { list } = state;

         const updatedList = list.map((item: any) => {
            if (item.id === payload.id) {
               return {
                  ...item,
                  isVisible: payload.visibility,
               };
            }
            return item;
         });

         return state.setIn(["list"], updatedList);
      }

      case CHANGE_SUBSCRIPTION_PLAN_ACTIVITY_SUCCESS: {
         const { list } = state;

         const updatedList = list.map((item: any) => {
            if (item.id === payload.id) {
               return {
                  ...item,
                  isActive: payload.isActive,
               };
            }
            return item;
         });

         return state.setIn(["list"], updatedList);
      }

      case CREATE_CARD_SUCCESS: {
         const { cards } = state;
         const updatedCards = [...cards, { ...payload }];
         return state.setIn(["cards"], updatedCards);
      }

      case GET_CARDS_SUCCESS: {
         return state.setIn(["cards"], payload);
      }

      case DELETE_CARD_SUCCESS: {
         const { cards } = state;
         const filtered = cards.filter((card: any, i: any) => {
            if (card.id !== payload) {
               return card;
            }
         });
         return state.setIn(["cards"], filtered);
      }

      case GET_SUBSCRIBERS_SUCCESS: {
         return state.setIn(["subscribers"], payload);
      }

      case EDIT_SUBSCRIPTION_PLAN_SUCCESS: {
         const { list } = state;

         const updatedList = list.map((item: any) => {
            if (item.id === payload.id) {
               return {
                  ...payload,
               };
            }
            return item;
         });

         return state.setIn(["list"], updatedList);
      }

      default:
         return state;
   }
}
