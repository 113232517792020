import { useState } from "react";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import TranslatedText from "../translated-text";

const ProductCarousel = ({ items, singleOrder, orderId }: any) => {
   const [currentIndex, setCurrentIndex] = useState(0);

   const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
   };

   const handlePrev = () => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
   };

   if (!items || !singleOrder || !orderId) return null;

   return (
      <div
         style={{
            margin: "auto",
            position: "relative",
         }}
      >
         <NovaText type={NovaTextTypes.XLNormal} style={{ margin: "0 0 8px 0" }}>
            <TranslatedText text="home.orders.details-panel.product-details" />
         </NovaText>
         <Grid container>
            <Grid item xs={6}>
               <Box sx={{ overflow: "hidden", width: "100%", height: "100%" }}>
                  <div
                     style={{
                        width: `${100 * items.length}%`,
                        display: "flex",
                        transition: "transform 0.3s ease-in-out",
                        transform: `translateX(-${
                           (currentIndex * 100) / items?.length
                        }%)`,
                     }}
                  >
                     {items.map((item: any) => (
                        <div
                           key={item.id}
                           style={{
                              flex: "1 0 auto",
                              width: `${100 / items?.length}%`,
                              padding: "8px",
                           }}
                        >
                           <Box
                              key={item.id}
                              sx={{
                                 background: `url(${item.productMainPhotoUrl})`,
                                 backgroundSize: "cover",
                                 backgroundPosition: "center center",
                                 backgroundRepeat: "no-repeat",
                                 // width: "262px",
                                 paddingBottom: "100%",
                                 borderRadius: "8px",
                              }}
                           />
                        </div>
                     ))}
                  </div>
               </Box>
            </Grid>
            <Grid item xs={6}>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     justifyContent: "space-between",
                     height: "100%",
                     padding: "0 16px",
                  }}
               >
                  <Box>
                     <Box
                        sx={{
                           width: "100%",
                           display: "flex",
                           flexDirection: "column",
                           flex: 1,
                           borderBottom: "1px solid #E9DDC8",
                           marginTop: "8px",
                        }}
                     >
                        <NovaText
                           type={NovaTextTypes.BaseNormal}
                           style={{ color: "#777E90", margin: 0 }}
                        >
                           <TranslatedText text="home.orders.details-panel.item-name" />
                        </NovaText>
                        <NovaText
                           type={NovaTextTypes.BaseNormal}
                           style={{
                              margin: 0,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                           }}
                        >
                           {items[currentIndex]?.name}
                        </NovaText>
                     </Box>

                     <Box
                        sx={{
                           width: "100%",
                           display: "flex",
                           flexDirection: "column",
                           flex: 1,
                           borderBottom: "1px solid #E9DDC8",
                           marginTop: "8px",
                        }}
                     >
                        <NovaText
                           type={NovaTextTypes.BaseNormal}
                           style={{ color: "#777E90", margin: 0 }}
                        >
                           <TranslatedText text="home.orders.details-panel.product-size" />
                        </NovaText>
                        <NovaText
                           type={NovaTextTypes.BaseNormal}
                           style={{
                              margin: 0,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                           }}
                        >
                           {items[currentIndex]?.productSizeName}
                        </NovaText>
                     </Box>

                     <Box
                        sx={{
                           width: "100%",
                           display: "flex",
                           flexDirection: "column",
                           flex: 1,
                           borderBottom: "1px solid #E9DDC8",
                           marginTop: "8px",
                        }}
                     >
                        <NovaText
                           type={NovaTextTypes.BaseNormal}
                           style={{ color: "#777E90", margin: 0 }}
                        >
                           <TranslatedText text="home.orders.details-panel.price" />
                        </NovaText>
                        <NovaText
                           type={NovaTextTypes.BaseNormal}
                           style={{
                              margin: 0,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                           }}
                        >
                           {(items[currentIndex]?.price +
                              items[currentIndex]?.productSizePrice) *
                              items[currentIndex]?.quantity}
                           &nbsp;
                           {singleOrder?.currency}
                        </NovaText>
                     </Box>
                  </Box>
                  <Box
                     sx={{
                        display: "flex",
                        marginTop: "8px",
                        gap: "8px",
                     }}
                  >
                     <IconButton
                        onClick={handlePrev}
                        sx={{
                           background: "#1B1B48",
                        }}
                     >
                        <ArrowBack
                           sx={{
                              color: "#CAA45D",
                           }}
                        />
                     </IconButton>
                     <Box
                        sx={{
                           display: "inline-flex",
                           alignItems: "center",
                           justifyContent: "center",
                           minWidth: "60px",
                           width: "fit-content",
                        }}
                     >
                        <NovaText type={NovaTextTypes.MediumNormal} style={{ margin: 0 }}>
                           {currentIndex}&nbsp;
                           <TranslatedText text="home.orders.details-panel.of" />
                           &nbsp;
                           {items?.length - 1}
                        </NovaText>
                     </Box>
                     <IconButton
                        onClick={handleNext}
                        sx={{
                           background: "#1B1B48",
                        }}
                     >
                        <ArrowForward
                           sx={{
                              color: "#CAA45D",
                           }}
                        />
                     </IconButton>
                  </Box>
               </Box>
            </Grid>
         </Grid>
      </div>
   );
};

export default ProductCarousel;
