import { createSelector } from "@reduxjs/toolkit";

const getCart = (state: any) => state.cart;
const getOrder = createSelector(getCart, (cart) => cart.order);
const getCardTotalPrice = createSelector(getOrder, (order) => {
   return order.totalPrice;
});

const getCardCurrency = createSelector(getCart, (cart) => cart.currency);
const getCardProductsTotal = createSelector(getOrder, (order) => {
   return order.reduce((sum: number, order: any) => sum + order.totalPrice, 0);
});
// TODO add to products/clients/Product.tsx
const getLastOrder = createSelector(getOrder, (cart) => {
   if (cart) {
      const lastItemIndex = cart.length - 1;
      return cart[lastItemIndex];
   }
});

export const CartSelectors = {
   getOrder,
   getCardTotalPrice,
   getCardCurrency,
   getLastOrder,
   getCardProductsTotal,
};
