import { getMemorialPageById, getMemorialPages } from "../home/async-actions";
import { MemorialPageActions } from "./actions";

export const createMemorialPage =
   (memorial: any, onSuccess?: (data: any) => void, onError?: () => void) =>
   (dispatch: any, _: any, { api }: any) => {
      api.memorialAPI
         .createMemorialPage({
            ...memorial,
            files: undefined,
         })
         .catch((error: any) => {
            console.error(error?.response?.data);
            onError?.();
         })
         .then((res: any) => {
            if (!res) return;

            if (res.data) {
               if (memorial.biographyFilesFormData.length)
                  dispatch(
                     uploadFile(
                        res.data.id,
                        memorial.biographyFilesFormData[0], //? If limitations change to more that one this needs to be changed
                        () => {
                           onError?.();
                        },
                        () => {
                           onSuccess?.(res.data);
                        },
                     ),
                  );
               else onSuccess?.(res.data);
            }
         });
   };

export const editMemorialPage =
   (memorial: any, navigate: any, onError?: () => void) =>
   (dispatch: any, _: any, { api }: any) => {
      api.memorialAPI
         .editMemorialPage(memorial)
         .catch((error: any) => {
            console.error(error?.response?.data);
            onError?.();
         })
         .then((res: any) => {
            if (!res) return;

            if (res.data) {
               return navigate("/");
            }
         });
   };

export const changeVisibilityMemorialPage =
   (memorialPageId: number, visible: boolean) =>
   (dispatch: any, _: any, { api }: any) => {
      api.memorialAPI
         .changeVisibilityMemorialPage(memorialPageId, visible)
         .catch((error: any) => {
            console.error(error?.response?.data);
         })
         .then((res: any) => {
            if (!res) return;

            if (res.data) dispatch(getMemorialPages());
         });
   };

export const uploadFile =
   (
      pageId: any,
      file: any,
      uploadStartCallback: () => void,
      uploadSuccessCallback: () => void,
   ) =>
   (dispatch: any, _: any, { api }: any) => {
      uploadStartCallback();
      api.memorialAPI
         .uploadFile(pageId, file)
         .catch((error: any) => {
            console.error(error?.response?.data);
            dispatch(MemorialPageActions.uploadFileFail);
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(MemorialPageActions.uploadFileSuccess);
               dispatch(getMemorialPageById(pageId));
               uploadSuccessCallback();
            }
         });
   };

export const deleteFile =
   (fileId: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.memorialAPI
         .deleteFile(fileId)
         .catch((error: any) => {
            console.error(error?.response?.data);
            dispatch(MemorialPageActions.deleteFileFail);
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(MemorialPageActions.deleteFileSuccess);
            }
         });
   };
