import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT_SUCCESS, GET_USER, GET_USERS } from "./types";

const loginSuccess = (payload: any) => ({
   type: LOGIN_SUCCESS,
   payload,
});

const loginFail = () => ({
   type: LOGIN_FAIL,
});

const logoutSuccess = () => ({
   type: LOGOUT_SUCCESS,
});

const updateUser = (payload: any) => ({
   type: GET_USER,
   payload,
});

const getUsers = (payload: any) => ({
   type: GET_USERS,
   payload,
});

export const LoginActions = {
   loginSuccess,
   loginFail,
   logoutSuccess,
   updateUser,
   getUsers,
};
