import { createSelector } from "@reduxjs/toolkit";

const getSinglePage = (state: any) => state.singleMemorial;
const getSingleMemorialPagePublicId = createSelector(
   getSinglePage,
   (page) => page?.publicID,
);

export const SingleMemorialPageSelectors = {
   getSinglePage,
   getSingleMemorialPagePublicId,
};
