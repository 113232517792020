import {
   Ref,
   forwardRef,
   memo,
   useCallback,
   useEffect,
   useImperativeHandle,
   useMemo,
   useRef,
   useState,
} from "react";

import { useSpaceNavigationGestures } from "./hooks";

import ReactFamilyTreePair from "./pair-item";
import {
   ReactFamilyTreeClickPlusData,
   ReactFamilyTreeColor,
   ReactFamilyTreeItemData,
   ReactFamilyTreeItemId,
   ReactFamilyTreeRef,
} from "./types";

import { v4 as uuidv4 } from "uuid";
import ReactFamilyTreeItem from "./tree-item";
import TranslatedText from "../translated-text";

type ReactFamilyTreeProps = {
   data: ReactFamilyTreeItemData[];
   /** @default 100% */
   width?: number | `${number}%`;
   /** @default 100% */
   height?: number | `${number}%`;
   /** @default 0.2 */
   minZoom?: number;
   /** @default 10 */
   maxZoom?: number;
   /** @default 40 */
   verticalGap?: number;
   /** @default 40 */
   horizontalGap?: number;
   /** @default "#aaaaaa" */
   linkColor?: ReactFamilyTreeColor;
   /** @default 2 */
   linkWidth?: number;
   /** @default "#f8f8f8" */
   backgroundColor?: ReactFamilyTreeColor;
   /** @default false */
   disabled?: boolean;
   searchKeyword?: string;
   /** @default false */
   haveCollapseExpandButton?: boolean;
   /** @default false */
   inEditMode?: boolean;
   /** @default true */
   canAddMore?: boolean;
   renderItem: (item: any, dataId: number) => JSX.Element;
   onClickPlus?: (plusData: ReactFamilyTreeClickPlusData) => void;
   onDataUpdate: (data: any) => void;
};

function ReactFamilyTreeRefFunction(
   {
      data,
      width,
      height,
      minZoom = 0.2,
      maxZoom = 10,
      verticalGap = 40,
      horizontalGap = 40,
      linkColor = "#aaaaaa",
      linkWidth = 2,
      backgroundColor = "#f8f8f8",
      disabled,
      searchKeyword,
      haveCollapseExpandButton,
      inEditMode,
      canAddMore = true,
      renderItem,
      onClickPlus,
      onDataUpdate,
   }: ReactFamilyTreeProps,
   ref: Ref<ReactFamilyTreeRef>,
) {
   const componentRef = useRef<HTMLDivElement>(null);
   const spaceNavigationGestures = useSpaceNavigationGestures(componentRef, {
      minZoom,
      maxZoom,
   });

   const [dataToUse, setDataToUse] = useState<ReactFamilyTreeItemData[]>(data);
   const [renderedNotes, setRenderedNotes] = useState<ReactFamilyTreeItemId[]>(
      data.map((item) => item.id),
   );

   const [initialItemIndex, setInitialItemIndex] = useState<ReactFamilyTreeItemId>(0);

   const [folds, setFolds] = useState<ReactFamilyTreeItemId[]>([]);

   const onClickMinus = useCallback(
      (dataId: ReactFamilyTreeItemId) => {
         const scale = spaceNavigationGestures.scale;
         spaceNavigationGestures.setScale(1);

         let removedItemIndex: number = 0;
         setDataToUse((oldValue) => {
            const thisItem = oldValue.find((item) => item.id === dataId);

            const partners = thisItem?.partnersIds
               ? [dataId, ...thisItem.partnersIds]
               : [dataId];
            const saveOtherPartners = oldValue.find(
               (item) => (item.parentsIds?.length ?? 0) > 0,
            )
               ? partners.some((partnerId) => {
                    const thisPartner = oldValue.find((item) => item.id === partnerId);

                    if ((thisPartner?.parentsIds?.length ?? 0) > 0) return true;
                    return false;
                 })
               : true;

            return oldValue
               .filter((item, index) => {
                  if (item.id === dataId) removedItemIndex = index;

                  if (saveOtherPartners) return item.id !== dataId;
                  else return !partners.includes(item.id);
               })
               .map((item) => {
                  let thisItem: ReactFamilyTreeItemData = { ...item };

                  if (thisItem.partnersIds?.length) {
                     thisItem = {
                        ...thisItem,
                        partnersIds: thisItem.partnersIds.filter(
                           (partnerId) => partnerId !== dataId,
                        ),
                     } as ReactFamilyTreeItemData;
                  }
                  if (thisItem.parentsIds?.length) {
                     thisItem = {
                        ...thisItem,
                        parentsIds: [...partners]
                           .sort()
                           .join()
                           .includes([...thisItem.parentsIds].sort().join())
                           ? thisItem.parentsIds.filter(
                                (parentId) => !partners.includes(parentId),
                             )
                           : thisItem.parentsIds,
                     } as ReactFamilyTreeItemData;
                  }

                  return thisItem;
               });
         });

         if (initialItemIndex >= removedItemIndex)
            setInitialItemIndex((oldValue) => Math.max(oldValue - 1, 0));

         setTimeout(() => {
            spaceNavigationGestures.setScale(scale);
         }, 10);
      },
      [spaceNavigationGestures],
   );
   const onChangeConnectionType = useCallback(
      (partnerId: ReactFamilyTreeItemId[], divorced: boolean) => {
         setDataToUse((oldValue) =>
            oldValue.map((value) =>
               partnerId.includes(value.id)
                  ? {
                       ...value,
                       childLinkIcon: divorced ? "heart" : "heartBroken",
                       childLinkIconColor: divorced ? "red" : "purple",
                    }
                  : value,
            ),
         );
      },
      [],
   );

   // TODO REVIEW
   useEffect(() => {
      onDataUpdate?.(dataToUse);
   }, [dataToUse]);

   const onClickInitialPlus = useCallback(() => {
      onClickPlus?.({
         type: "initial",
      });
   }, [onClickPlus]);

   const reactFamilyTreeStyle = useMemo<React.CSSProperties>(
      () => ({
         width,
         height,
         backgroundColor: backgroundColor,
      }),
      [width, height, backgroundColor],
   );

   useEffect(() => {
      setTimeout(() => {
         setDataToUse((oldValue) =>
            oldValue.filter((item) => renderedNotes.includes(item.id)),
         );
      }, 1 * 1000);
   }, [renderedNotes]);

   useImperativeHandle(
      ref,
      (): ReactFamilyTreeRef => {
         return {
            data: dataToUse,
            setData: setDataToUse,
            reset: () => spaceNavigationGestures.reset(),
            addDataItem: (item, plusData, childLink) => {
               const scale = spaceNavigationGestures.scale;
               spaceNavigationGestures.setScale(1);

               const firstPersonId = uuidv4();
               const secondPersonId = uuidv4();

               setDataToUse((oldValue: any) => {
                  const newDataToAdd = [
                     {
                        id: firstPersonId,
                        childLinkIcon: childLink?.icon,
                        childLinkIconColor: childLink?.iconColor,
                        ...(plusData?.type === "partner"
                           ? {
                                partnersIds: [plusData.partnerId],
                             }
                           : plusData?.type === "child"
                           ? {
                                parentsIds: plusData?.parentIds,
                             }
                           : plusData?.type === "parents"
                           ? { partnersIds: [secondPersonId] }
                           : {}),
                        ...item[0],
                     },
                     ...(plusData.type === "parents"
                        ? [
                             {
                                id: secondPersonId,
                                partnersIds: [firstPersonId],
                                ...item[1],
                             } as ReactFamilyTreeItemData,
                          ]
                        : []),
                  ];

                  return [
                     ...(plusData?.type === "parents" ? newDataToAdd : []),
                     //* Adding the new partner's id to the other partner's data
                     ...(plusData?.type === "partner"
                        ? oldValue.map((value: any) =>
                             value.id === plusData.partnerId
                                ? ({
                                     ...value,
                                     partnersIds: [
                                        ...(value.partnersIds ?? []),
                                        firstPersonId,
                                     ],
                                  } as ReactFamilyTreeItemData)
                                : value,
                          )
                        : plusData?.type === "parents"
                        ? oldValue.map((value: any) =>
                             value.id === plusData.childId
                                ? ({
                                     ...value,
                                     parentsIds: [firstPersonId, secondPersonId],
                                  } as ReactFamilyTreeItemData)
                                : value,
                          )
                        : oldValue),
                     //* Adding the new item data to the array
                     ...(plusData?.type !== "parents" ? newDataToAdd : []),
                  ];
               });

               setTimeout(() => {
                  spaceNavigationGestures.setScale(scale);
               }, 10);
            },
            setPosition: spaceNavigationGestures.setPosition,
            setScale: (getNewScale) => {
               spaceNavigationGestures.setScale(
                  getNewScale(spaceNavigationGestures.scale),
               );
            },
            treeComponent: spaceNavigationGestures.componentRef.current ?? undefined,
         };
      },
      [dataToUse, spaceNavigationGestures],
   );

   return (
      <div
         className={`reactFamilyTree${disabled ? " isDisabled" : ""}`}
         style={reactFamilyTreeStyle}
         onMouseDown={spaceNavigationGestures.onMouseDown}
         onMouseMove={spaceNavigationGestures.onMouseMove}
         onMouseUp={spaceNavigationGestures.onMouseUp}
         onMouseLeave={spaceNavigationGestures.onMouseUp}
         onTouchStart={spaceNavigationGestures.onTouchStart}
         onTouchMove={spaceNavigationGestures.onTouchMove}
         onTouchEnd={spaceNavigationGestures.onMouseUp}
         onTouchCancel={spaceNavigationGestures.onMouseUp}
         ref={spaceNavigationGestures.componentHolderRef}
         key={JSON.stringify(dataToUse)}
      >
         <div
            className="reactFamilyTreeGraph"
            style={spaceNavigationGestures.style}
            ref={spaceNavigationGestures.componentRef}
         >
            {dataToUse[initialItemIndex]?.partnersIds?.length ? (
               <ReactFamilyTreePair
                  treeData={dataToUse}
                  partnersIds={dataToUse[initialItemIndex].partnersIds ?? []}
                  verticalGap={verticalGap}
                  horizontalGap={horizontalGap}
                  linkColor={linkColor}
                  linkWidth={linkWidth}
                  backgroundColor={backgroundColor}
                  folds={folds}
                  setFolds={setFolds}
                  haveCollapseExpandButton={haveCollapseExpandButton}
                  searchKeyword={searchKeyword}
                  inEditMode={inEditMode}
                  canAddMore={canAddMore}
                  spaceNavigationGestures={spaceNavigationGestures}
                  renderItem={renderItem}
                  onClickMinus={onClickMinus}
                  onClickPlus={onClickPlus}
                  onChangeConnectionType={onChangeConnectionType}
                  setRenderedNotes={setRenderedNotes}
               />
            ) : dataToUse[initialItemIndex] ? (
               <ReactFamilyTreeItem
                  dataId={dataToUse[initialItemIndex].id}
                  item={dataToUse[initialItemIndex]}
                  linkColor={linkColor}
                  linkWidth={linkWidth}
                  verticalGap={verticalGap}
                  searchKeyword={searchKeyword}
                  inEditMode={inEditMode}
                  withParentPlus={inEditMode && canAddMore}
                  withRightPartnerPlus={canAddMore}
                  renderItem={renderItem}
                  onClickMinus={onClickMinus}
                  onClickPlus={onClickPlus}
                  setRenderedNotes={setRenderedNotes}
               />
            ) : inEditMode && canAddMore ? (
               <div className="emptyTreeData">
                  <p className="">
                     <TranslatedText text="create-memorial-page.family-tree.tree.start" />
                  </p>
                  <div className="itemPlusMinusIcon" onClick={onClickInitialPlus}>
                     <svg viewBox="0 0 448 512">
                        <path d="M416 208H272V64c0-17.7-14.3-32-32-32h-32c-17.7 0-32 14.3-32 32v144H32c-17.7 0-32 14.3-32 32v32c0 17.7 14.3 32 32 32h144v144c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V304h144c17.7 0 32-14.3 32-32v-32c0-17.7-14.3-32-32-32z" />
                     </svg>
                  </div>
               </div>
            ) : undefined}
         </div>
      </div>
   );
}

const ReactFamilyTree = forwardRef(ReactFamilyTreeRefFunction) as (
   props: ReactFamilyTreeProps & {
      ref?: Ref<ReactFamilyTreeRef>;
   },
) => JSX.Element;

export default memo(ReactFamilyTree) as typeof ReactFamilyTree;
