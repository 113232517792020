import { EkontActions } from "./actions";

export const getEkontCities =
   () =>
   (dispatch: any, _: any, { api }: any) => {
      api.ekontAPI
         .getEkontCities()
         .catch((error: any) => {
            console.log(error);
         })
         .then((res: any) => {
            if (res && res.data) {
               const filtered = res.data.cities.filter(
                  (city: any) => city.expressCityDeliveries,
               );
               const sorted = filtered.sort((a: any, b: any) =>
                  a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
               );
               dispatch(EkontActions.getCities(sorted));
            }
         });
   };

export const getEkontOffices =
   (cityID: string) =>
   (dispatch: any, _: any, { api }: any) => {
      api.ekontAPI
         .getEkontOffices(cityID)
         .catch((error: any) => {
            console.log(error);
         })
         .then((res: any) => {
            if (res && res.data) {
               // filter mobile post stations and automatic post stations from results
               const filtered = res.data.offices.filter(
                  (office: any) => !office.isMPS && !office.isAPS,
               );
               const sorted = filtered.sort((a: any, b: any) =>
                  a.address.street > b.address.street
                     ? 1
                     : b.address.street > a.address.street
                     ? -1
                     : 0,
               );
               dispatch(EkontActions.getOffices(sorted));
            }
         });
   };

export const createOrder =
   (order: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.ordersAPI
         .createOrder(order)
         .catch((error: any) => {
            console.log(error);
         })
         .then((res: any) => {
            if (res && res.data) {
               window.location.href = res.data.paymentUrl;
            }
         });
   };
