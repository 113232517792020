import { createSelector } from "@reduxjs/toolkit";

const getMemorials = (state: any) => state.memorials;
const getMemorialPages = createSelector(getMemorials, (memorials) => memorials.pages);
const getEditedMemorialPage = createSelector(
   getMemorials,
   (memorials) => memorials.editedMemorial,
);

const getPageIsInEditMode = createSelector(
   getMemorials,
   (memorials) => memorials.editedMemorial,
);

const getEditPageId = createSelector(
   getMemorials,
   (memorials) => memorials.editedMemorial?.id,
);

const getMemorialRequests = createSelector(
   getMemorials,
   (memorials) => memorials.requests,
);

const getMyRequests = createSelector(
   getMemorialRequests,
   (requests) => requests && requests.myRequests,
);

const getMyPagesRequests = createSelector(
   getMemorialRequests,
   (requests) => requests && requests.myPagesRequests,
);

const getMemorialPageById = createSelector(
   [getMemorialPages, (_: any, pageId: any) => pageId],
   (pages, pageId) => {
      if (pageId && pages && pages.length > 0) {
         return pages.find((subscription: any) => subscription.id === Number(pageId));
      }
      return null;
   },
);

const getMemorialPageType = createSelector(
   getMemorials,
   (memorials) => memorials && memorials.memorialPageType,
);

export const HomeSelectors = {
   getMemorialPages,
   getEditedMemorialPage,
   getPageIsInEditMode,
   getEditPageId,
   getMemorialRequests,
   getMyRequests,
   getMyPagesRequests,
   getMemorialPageById,
   getMemorialPageType,
};
