import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";

const Layout = () => {
   return (
      <Box
         sx={{
            background: "#F3F5F7",
            minHeight: "100svh",
         }}
      >
         <Header />
         <Box
            sx={{
               minHeight: "calc(100svh - 82px - 80px)", // 82px is the height of the header, 80px is the height of the footer
            }}
         >
            <Outlet />
         </Box>
         <Footer />
      </Box>
   );
};

export default Layout;
