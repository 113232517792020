import { memo } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { HomeSelectors } from "../pages/home/selectors";

import Section from "./memorial-page/section";
import NovaText, { NovaTextTypes } from "./shared/NovaText";
import TranslatedText from "./translated-text";

type MemorialLimitationsInfoBarProps = {
   itemName?: string;
   limit?: number;
   /** @default "Current subscription plan limits the number of <itemName>. You can add up to <limit>." */
   sentence?: string;
};

function MemorialLimitationsInfoBar({
   itemName,
   limit,
   sentence,
}: MemorialLimitationsInfoBarProps) {
   const editMemorialPageData = useSelector(HomeSelectors.getEditedMemorialPage);

   if (!limit) return null;

   return (
      <Box
         sx={{
            marginBottom: "20px",
         }}
      >
         <Section
            backgroundColor="#E4CB8Da0"
            paddingBlock="10px"
            paddingInline="20px"
            borderRadius="20px"
         >
            <Box
               sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
               }}
            >
               <InfoOutlined
                  sx={{
                     color: "#1b1b45",
                     fontSize: "26px",
                  }}
               />
               <NovaText
                  type={NovaTextTypes.BaseNormal}
                  style={{
                     flex: 1,
                     fontWeight: "500",
                     color: "#1b1b45",
                     margin: 0,
                  }}
               >
                  {sentence ?? (
                     <TranslatedText
                        text="messages.memorial-limitations-info"
                        props={{
                           itemName,
                           limit,
                        }}
                     />
                  )}
                  {!editMemorialPageData && (
                     <>
                        &nbsp;
                        <TranslatedText
                           text="messages.after-save"
                           props={{
                              itemName,
                           }}
                        />
                     </>
                  )}
               </NovaText>
            </Box>
         </Section>
      </Box>
   );
}

export default memo(MemorialLimitationsInfoBar);
