import api from "./withAuth";

const createMemorialPage = async (memorialPage: any) => {
   return await api.post("memorialPages/create", memorialPage);
};

const editMemorialPage = async (memorialPage: any) => {
   return await api.put("memorialPages/edit", memorialPage);
};

const changeVisibilityMemorialPage = async (memorialPageId: number, visible: boolean) => {
   return await api.put(`memorialPages/${memorialPageId}/setvisibility`, {
      isPublic: visible,
   });
};

const getMemorialPages = async () => {
   return await api.get("memorialPages/user");
};

const deleteMemorialPage = async (id: number) => {
   return await api.delete(`memorialPages/${id}`);
};

const getMyRequests = async () => {
   return await api.get("memorialpages/access/user/requests");
};

const getMyPagesRequests = async () => {
   return await api.get("memorialpages/access/requests");
};

const getPublicPage = async (pageId: any) => {
   return await api.get(`memorialPages/public/${pageId}`);
};

const getPage = async (id: any) => {
   return await api.get(`memorialPages/${id}`);
};

const requestPageAccess = async (pageId: any) => {
   return await api.post(`memorialpages/${pageId}/access/request`);
};

const approveRequest = async (requestId: any, pageId: any) => {
   return await api.post(`memorialpages/request/${requestId}/approve/${pageId}`);
};

const rejectRequest = async (requestId: any) => {
   return await api.post(`memorialpages/request/${requestId}/reject`);
};

const generateQR = async (pageId: any, name: any) => {
   return await api.get(`memorialPages/${pageId}/qr/${name}`);
};

const uploadFile = async (pageId: any, file: any) => {
   return await api.post(`memorialPages/${pageId}/files`, file);
};

const deleteFile = async (fileId: any) => {
   return await api.delete(`memorialPages/files/${fileId}`);
};

export const memorialAPI = {
   createMemorialPage,
   editMemorialPage,
   changeVisibilityMemorialPage,
   getMemorialPages,
   deleteMemorialPage,
   getMyRequests,
   getMyPagesRequests,
   getPublicPage,
   getPage,
   requestPageAccess,
   approveRequest,
   rejectRequest,
   generateQR,
   uploadFile,
   deleteFile,
};
