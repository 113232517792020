import * as Immutable from "seamless-immutable";
import {
   APPROVE_ACCESS_SUCCESS,
   GET_MEMORIALS,
   GET_MY_REQUESTS,
   GET_MY_PAGES_REQUESTS,
   REJECT_ACCESS_SUCCESS,
   UPDATE_MEMORIALS,
   GET_EDITED_MEMORIAL,
   SET_MEMORIAL_PAGE_TYPE,
} from "./types";

const initialState = Immutable.from<any>({
   pages: null,
   editedMemorial: null,
   requests: null,
   memorialPageType: null,
});

export default function MemorialsReducer(state = initialState, action: any) {
   const { type, payload } = action;

   switch (type) {
      case GET_MEMORIALS: {
         return state.setIn(["pages"], payload);
      }
      case GET_EDITED_MEMORIAL: {
         return state.setIn(["editedMemorial"], payload);
      }
      case UPDATE_MEMORIALS: {
         const { pages } = state;
         const filtered = pages.filter((s: any) => s.id !== payload);
         return state.setIn(["pages"], filtered);
      }
      case GET_MY_REQUESTS: {
         return state.setIn(["requests", "myRequests"], payload);
      }
      case GET_MY_PAGES_REQUESTS: {
         return state.setIn(["requests", "myPagesRequests"], payload);
      }
      case APPROVE_ACCESS_SUCCESS: {
         // TODO
         return state;
      }
      case REJECT_ACCESS_SUCCESS: {
         const { myPagesRequests } = state.requests;
         const filtered = myPagesRequests.filter(
            (request: any) => request.id !== payload,
         );
         return state.setIn(["requests", "myPagesRequests"], filtered);
      }
      case SET_MEMORIAL_PAGE_TYPE: {
         return state.setIn(["memorialPageType"], payload);
      }
      default:
         return state;
   }
}
