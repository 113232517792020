import * as Immutable from "seamless-immutable";
import { GET_CITIES, GET_OFFICES } from "./types";

const initialState = Immutable.from<any>({
   cities: null,
   offices: null,
});

export default function EkontReducer(state = initialState, action: any) {
   const { type, payload } = action;
   switch (type) {
      case GET_CITIES: {
         return state.setIn(["cities"], payload);
      }
      case GET_OFFICES: {
         return state.setIn(["offices"], payload);
      }
      default:
         return state;
   }
}
