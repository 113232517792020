import { Navigate, Outlet } from "react-router-dom";
import { LoginActions } from "../pages/Login/actions";
import { useAppDispatch } from "../store/hooks";
import { getUser } from "../pages/Login/async-actions";
import { useEffect } from "react";

type Props = {
   allowedRoles: any;
   redirectPath?: string;
   children?: JSX.Element;
};

export enum roles {
   ADMIN,
   CLIENT,
   AFFILIATE,
}

const PrivateRoutes = ({ allowedRoles, redirectPath = "/login", children }: Props) => {
   const dispatch = useAppDispatch();
   const user: any = JSON.parse(localStorage.getItem("user") as any);

   useEffect(() => {
      if (!user || !allowedRoles.includes(user?.role)) return;

      dispatch(LoginActions.loginSuccess(user));
   }, [user]);

   useEffect(() => {
      dispatch(getUser());
   }, []);
   if (!user || !allowedRoles.includes(user?.role))
      return <Navigate to={redirectPath} replace />;

   return children ? children : <Outlet />;
};

export default PrivateRoutes;
