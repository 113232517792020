import { useSelector } from "react-redux";
import { LoginSelectors } from "../Login/selectors";
import { roles } from "../../components/PrivateRoutes";
import HomeClient from "./home-client";
import HomeAffiliate from "./home-affiliate";

const Home = () => {
   const userRole = useSelector(LoginSelectors.getUserRole);

   if (!userRole) return null;

   return (
      <>
         {userRole === roles.CLIENT && <HomeClient />}
         {userRole === roles.AFFILIATE && <HomeAffiliate />}
      </>
   );
};

export default Home;
