import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Container } from "@mui/material";
import { useAppDispatch } from "../../store/hooks";
import { getSubscribers } from "../../pages/subscriptions/async-actions";
import { SubscriptionsSelectors } from "../../pages/subscriptions/selectors";
import SubscribersTable from "./table";
import { columns } from "../../pages/subscriptions/data";
const Subscribers = () => {
   const dispatch = useAppDispatch();
   const subscribers = useSelector(SubscriptionsSelectors.getSubscribers);
   const [selectedRowsCount, setSelectedRowsCount] = useState(0);
   const [packagesCount, setPackagesCount] = useState(0);
   const handleSelectedRowsCount = (selectedCount: any) => {
      setSelectedRowsCount(selectedCount);
   };
   useEffect(() => {
      dispatch(getSubscribers());
   }, []);

   return (
      <Container
         sx={{
            padding: "32px 0 120px 0",
         }}
      >
         <Box>
            <SubscribersTable
               rows={subscribers}
               columns={columns}
               rowsSelectedCallback={handleSelectedRowsCount}
            />
         </Box>
      </Container>
   );
};

export default Subscribers;
