import api from "./withAuth";

const getOrders = async () => {
   return await api.get("orders");
};

const getSingleOrder = async (id: any) => {
   return await api.get(`orders/${id}`);
};

const changeOrderStatus = async (orderId: any, statusId: any) => {
   return await api.put(`orders/${orderId}/edit/${statusId}`);
};

const createOrder = async (order: any) => {
   return await api.post(`orders/create`, {
      ...order,
   });
};

export const ordersAPI = {
   getOrders,
   getSingleOrder,
   changeOrderStatus,
   createOrder,
};
