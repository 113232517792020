import { OrdersActions } from "./actions";

export const getOrders =
   () =>
   (dispatch: any, _: any, { api }: any) => {
      api.ordersAPI
         .getOrders()
         .catch((error: any) => {
            dispatch(OrdersActions.getOrdersFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(OrdersActions.getOrdersSuccess(res.data));
            }
         });
   };

export const getSingleOrder =
   (id: any, startCallback?: any, completeCallback?: any) =>
   (dispatch: any, _: any, { api }: any) => {
      startCallback();
      api.ordersAPI
         .getSingleOrder(id)
         .catch((error: any) => {
            dispatch(OrdersActions.getSingleOrdersFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(OrdersActions.getSingleOrderSuccess(res.data));
               completeCallback();
            }
         });
   };

export const changeOrderStatus =
   (orderId: any, statusId: any) =>
   (dispatch: any, _: any, { api }: any) => {
      api.ordersAPI
         .changeOrderStatus(orderId, statusId)
         .catch((error: any) => {
            dispatch(OrdersActions.changeStatusFail());
         })
         .then((res: any) => {
            if (res && res.data) {
               dispatch(OrdersActions.changeStatusSuccess(statusId));
            }
         });
   };
