export const ReportIcon: React.FC<{
   color?: string;
   strokeWidth?: string;
}> = ({ color, strokeWidth }: any) => {
   return (
      <svg
         style={{
            display: "block",
            marginRight: "8px",
         }}
         width="14"
         height="15"
         viewBox="0 0 14 15"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M0.875 0.75C1.09375 0.75 1.3125 0.859375 1.47656 1.02344C1.64062 1.1875 1.75 1.40625 1.75 1.625V14.3125C1.75 14.4492 1.69531 14.5586 1.61328 14.6406C1.53125 14.7227 1.42188 14.75 1.3125 14.75H0.4375C0.300781 14.75 0.191406 14.7227 0.109375 14.6406C0.0273438 14.5586 0 14.4492 0 14.3125V1.625C0 1.40625 0.0820312 1.1875 0.246094 1.02344C0.410156 0.859375 0.628906 0.75 0.875 0.75ZM12.6602 0.859375C12.9609 0.695312 13.2617 0.695312 13.5625 0.859375C13.8359 1.02344 14 1.26953 14 1.59766V9.82812C14 9.99219 13.9453 10.1562 13.8633 10.293C13.7539 10.457 13.6445 10.5664 13.5078 10.6211C12.7695 10.9492 12.0859 11.1406 11.4297 11.1953C10.8828 11.2773 10.3359 11.2773 9.78906 11.168C9.43359 11.1133 8.94141 11.0039 8.3125 10.8125C7.62891 10.6484 7.10938 10.5117 6.75391 10.457C6.125 10.375 5.52344 10.375 4.92188 10.457C4.21094 10.5938 3.44531 10.8672 2.625 11.25V1.625C3.52734 1.24219 4.34766 0.96875 5.08594 0.832031C5.6875 0.75 6.26172 0.75 6.80859 0.832031C7.13672 0.886719 7.57422 1.02344 8.14844 1.21484C8.64062 1.40625 9.05078 1.51562 9.32422 1.57031C9.76172 1.65234 10.2539 1.65234 10.7461 1.57031C11.293 1.46094 11.9492 1.24219 12.6602 0.859375Z"
            fill="#1B1B48"
         />
      </svg>
   );
};
