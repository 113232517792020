import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./store/index";
import router from "./router";
import AlertsHolder from "./components/alerts/AlertsHolder";

import "./App.css";

function App() {
   return (
      <Provider store={store}>
         <AlertsHolder />
         <RouterProvider router={router} />
      </Provider>
   );
}

export default App;
