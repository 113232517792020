import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
   Box,
   Chip,
   Container,
   FormControl,
   Grid,
   IconButton,
   MenuItem,
   Select,
   Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AddOutlined, RemoveOutlined } from "@mui/icons-material";

import { getMemorialPages } from "../../home/async-actions";
import { useAppDispatch, useLanguage } from "../../../store/hooks";
import { HomeSelectors } from "../../home/selectors";
import { CartActions } from "./actions";
import { getProduct } from "../async-actions";
import { ProductsSelectors } from "../selectors";

import NovaButton from "../../../components/shared/NovaButton";
import NovaText, { NovaTextTypes } from "../../../components/shared/NovaText";
import MarkdownRenderer from "../../../components/md-editor/markdown-renderer";
import TranslatedText from "../../../components/translated-text";
import PreviewModal from "../../../components/products/qr-preview/modal";
import Gallery from "../../memorial-page/gallery";
import Loader from "../../../components/Loader";

const vertical = "bottom";
const horizontal = "right";

export const Product = () => {
   const dispatch = useAppDispatch();
   const { productId } = useParams();
   const language = useLanguage();

   const pages = useSelector(HomeSelectors.getMemorialPages);
   const product = useSelector(ProductsSelectors.getSingleProduct);

   const [page, setPage] = useState<any>(null);
   const [selectedPage, setSelectedPage] = useState<any>(0);
   const [numberOfMedallions, setNumberOfMedallions] = useState(1);
   const [selectedSize, setSelectedSize] = useState({
      id: "select",
      name: "Select size",
      price: 0,
   });
   const [sizePrice, setSizePrice] = useState(0);
   const [isDisabled, setIsDisabled] = useState(true);
   const [open, setOpen] = useState(false);
   const [qrPreview, openQRpreview] = useState(false);
   const [selectedImageIndex, setSelectedImageIndex] = useState<number | undefined>(
      undefined,
   );
   const [imagesScrollX, setImagesScrollX] = useState<number>(0);

   const imagesScrollHolderRef = useRef<HTMLDivElement>(null);

   const qrCode = useSelector(ProductsSelectors.getSingleProductQR);

   const handlePageChange = (event: any) => {
      handleClose();
      setSelectedPage(event.target.value);
   };

   const handleSelectedPage = (selectedPage: any) => {
      handleClose();
      setPage(selectedPage);
   };

   const handleQRPreviewClose = () => {
      openQRpreview(false);
   };

   const handlePreviewOpen = () => {
      openQRpreview(true);
   };

   const handleAddToCart = () => {
      const findSelectedPage = pages && pages[selectedPage - 1];
      const numberOfFreeProducts = findSelectedPage?.subscription?.freeProducts;
      const hasFreeProducts = numberOfFreeProducts > 0;

      handleClose();
      dispatch(
         CartActions.addToCart({
            totalPrice,
            currency: language.domainLanguage === "bg" ? "BGN" : "EUR",
            quantity: numberOfMedallions,
            productSizeId: selectedSize.id,
            product: { ...product },
            productId: product.id,
            memorialPage: page,
            memorialPageId: page.id,
            qrCode: qrCode,
            freeProductUsed: hasFreeProducts ? true : false,
         }),
      );
      dispatch(CartActions.addCurrency(language.domainLanguage === "bg" ? "BGN" : "EUR"));
      handleClick();
      handleQRPreviewClose();
   };

   const handleSelectedSize = (selectedSize: any) => {
      setSizePrice(selectedSize.price);
      setSelectedSize(selectedSize);
   };

   useEffect(() => {
      if (productId) {
         dispatch(getMemorialPages());
         dispatch(getProduct(productId));
      }
   }, [productId]);

   const [totalPrice, setTotalPrice] = useState(0);

   useEffect(() => {
      const findSelectedPage = pages && pages[selectedPage - 1];
      const numberOfFreeProducts = findSelectedPage?.subscription?.freeProducts;
      const hasFreeProducts = numberOfFreeProducts > 0;

      if (findSelectedPage && hasFreeProducts) {
         if (numberOfMedallions > numberOfFreeProducts) {
            setTotalPrice(
               (numberOfMedallions - numberOfFreeProducts) * (product?.price + sizePrice),
            );
         } else {
            setTotalPrice(0);
         }
      }

      if (!hasFreeProducts) {
         const platesPrice =
            numberOfMedallions > 1
               ? (numberOfMedallions - 1) * (product?.price + sizePrice)
               : 0;
         setTotalPrice(product?.price + sizePrice + platesPrice);
      }
   }, [numberOfMedallions, selectedPage, pages, product, sizePrice]);

   useEffect(() => {
      if (!page || selectedSize.id === "select" || numberOfMedallions < 1) {
         return setIsDisabled(true);
      } else {
         return setIsDisabled(false);
      }
   }, [page, selectedSize, numberOfMedallions]);

   const handleClick = () => {
      setOpen(true);
   };

   const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason && reason === "clickaway") {
         return;
      }

      setOpen(false);
   };

   const handleCountIncrease = () => {
      setNumberOfMedallions((oldValue) => oldValue + 1);
   };

   const handleCountDecrease = () => {
      setNumberOfMedallions((oldValue) => oldValue - 1);
   };

   if (!product)
      return (
         <Box
            sx={{
               display: "flex",
               width: "100%",
               justifyContent: "center",
               marginTop: "20px",
            }}
         >
            <Loader />
         </Box>
      );

   return (
      <>
         <Container
            sx={{
               padding: "60px 20px 120px 20px",
            }}
         >
            <Grid
               container
               spacing={5}
               sx={{
                  marginTop: "1px",
               }}
            >
               <Grid item xs={12} md={6}>
                  <Box
                     sx={{
                        background: `url(${
                           product?.photos.find((photo: any) => photo.isMain)?.photoUrl
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                        height: {
                           xs: "auto",
                           md: "100%",
                        },
                        minHeight: "400px",
                        maxHeight: {
                           xs: "unset",
                           md: "600px",
                        },
                        borderRadius: "32px",
                     }}
                     onClick={() => setSelectedImageIndex(0)}
                  />

                  <Box
                     sx={{
                        position: "relative",
                     }}
                  >
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           padding: "16px 0",
                           gap: "8px",
                           overflowX: "auto",
                        }}
                        onScroll={(event) =>
                           setImagesScrollX(event.currentTarget.scrollLeft)
                        }
                        ref={imagesScrollHolderRef}
                     >
                        {product?.photos?.map((photo: any, index: number) => (
                           <Box
                              key={index}
                              sx={{
                                 flex: "0 0 25%",
                                 borderRadius: "12px",
                                 padding: "6px",
                                 height: "85px",
                                 background: `url(${photo.photoUrl})`,
                                 backgroundSize: "cover",
                                 backgroundRepeat: "no-repeat",
                                 backgroundPosition: "center center",
                              }}
                              onClick={() => setSelectedImageIndex(index)}
                           />
                        ))}
                     </Box>

                     <Box
                        sx={{
                           position: "absolute",
                           width: "20px",
                           height: "100%",
                           top: "0px",
                           left: "0px",
                           backgroundImage:
                              "linear-gradient(to right, #f3f5f7, #f3f5f700)",
                           opacity: imagesScrollX / 20,
                           zIndex: 1,
                        }}
                     />
                     <Box
                        sx={{
                           position: "absolute",
                           width: "20px",
                           height: "100%",
                           top: "0px",
                           right: "0px",
                           backgroundImage:
                              "linear-gradient(to left, #f3f5f7, #f3f5f700)",
                           opacity:
                              ((imagesScrollHolderRef.current?.scrollWidth ?? 0) -
                                 ((imagesScrollHolderRef.current?.clientWidth ?? 0) +
                                    imagesScrollX)) /
                              20,
                           zIndex: 1,
                        }}
                     />
                  </Box>
               </Grid>

               <Grid item xs={12} md={6}>
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        color: "#777E90",
                     }}
                  >
                     <TranslatedText text="single-product.headline" />
                  </NovaText>

                  <NovaText type={NovaTextTypes.XLNormal}>{product.name}</NovaText>

                  <MarkdownRenderer markdownText={product.description}>
                     {(parsedText: any) => (
                        <NovaText type={NovaTextTypes.BaseNormal} renderHTML>
                           {parsedText.__html}
                        </NovaText>
                     )}
                  </MarkdownRenderer>

                  <Box>
                     <NovaText
                        type={NovaTextTypes.BaseNormal}
                        style={{
                           marginBottom: "6px",
                        }}
                     >
                        <TranslatedText text="single-product.select-page" />
                     </NovaText>

                     <FormControl sx={{ minWidth: 250 }} size="small">
                        <Select value={selectedPage} onChange={handlePageChange}>
                           {selectedPage === 0 && (
                              <MenuItem value={0}>Select an option</MenuItem>
                           )}

                           {pages?.map((page: any, index: number) => (
                              <MenuItem
                                 value={index + 1}
                                 onClick={() => handleSelectedPage(page)}
                                 key={index}
                              >
                                 <Box
                                    sx={{
                                       display: "flex",
                                       alignItems: "center",
                                       width: "100%",
                                    }}
                                 >
                                    <NovaText
                                       type={NovaTextTypes.BaseNormal}
                                       style={{ flex: 1, width: "100%", margin: 0 }}
                                    >
                                       {page.fullName}
                                    </NovaText>
                                 </Box>
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     {page?.subscription?.freeProducts > 0 && (
                        <Box
                           sx={{
                              background: "#CAA45D",
                              padding: "4px 16px",
                              borderRadius: "8px",
                              marginTop: "12px",
                              width: "fit-content",
                           }}
                        >
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ margin: 0 }}
                           >
                              You have {page?.subscription?.freeProducts} free products
                              for this page
                           </NovaText>
                        </Box>
                     )}
                  </Box>

                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        marginBottom: "6px",
                     }}
                  >
                     <TranslatedText text="single-product.size" />
                  </NovaText>

                  <FormControl sx={{ minWidth: 250 }} size="small">
                     <Select value={selectedSize.id}>
                        {selectedSize.id === "select" && (
                           <MenuItem
                              value={"select"}
                              onClick={() =>
                                 handleSelectedSize({
                                    id: "select",
                                    price: 0,
                                    name: "Select size",
                                 })
                              }
                           >
                              Select size
                           </MenuItem>
                        )}

                        {product?.sizes?.map((size: any, index: number) => (
                           <MenuItem
                              value={size.id}
                              onClick={() => handleSelectedSize(size)}
                              key={index}
                           >
                              {size.name}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>

                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        marginBottom: "6px",
                     }}
                  >
                     <TranslatedText text="single-product.select-count" />
                  </NovaText>
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                     }}
                  >
                     <IconButton
                        disabled={numberOfMedallions === 1}
                        onClick={handleCountDecrease}
                        sx={{
                           "&:disabled > svg.MuiSvgIcon-root": {
                              color: "#ccc",
                           },
                        }}
                     >
                        <RemoveOutlined />
                     </IconButton>
                     <Box sx={{ margin: "0 7px" }}>{numberOfMedallions}</Box>
                     <IconButton onClick={handleCountIncrease}>
                        <AddOutlined sx={{ color: "#000" }} />
                     </IconButton>
                  </Box>
                  <div
                     style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "24px",
                     }}
                  >
                     <div
                        style={{
                           height: "2px",
                           width: "30px",
                           background: "#CAA45D",
                           display: "inline-block",
                        }}
                     />
                     <NovaText
                        type={NovaTextTypes.XLNormal}
                        style={{
                           margin: "0 0 0 10px",
                           color: "#CAA45D",
                           fontWeight: "900",
                        }}
                     >
                        {totalPrice}
                        &nbsp;
                        <span style={{ fontWeight: "700", fontSize: "18px" }}>
                           {language.domainLanguage === "bg" ? "BGN" : "EUR"}
                        </span>
                     </NovaText>
                  </div>
                  <NovaButton
                     disabled={isDisabled}
                     style={{
                        marginTop: "24px",
                        borderRadius: "32px",
                     }}
                     onClick={handlePreviewOpen}
                  >
                     <TranslatedText text="buttons.preview-and-order" />
                  </NovaButton>
               </Grid>
            </Grid>

            <Snackbar
               anchorOrigin={{ vertical, horizontal }}
               open={open}
               onClose={handleClose}
               sx={{
                  width: "100%",
                  maxWidth: "350px",
                  background: "rgb(237, 247, 237)",
                  borderRadius: "12px",
                  padding: "12px",
                  border: "1px solid #d4e1b8",
               }}
            >
               <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
                  <Box
                     sx={{
                        width: "100%",
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "16px",
                     }}
                  >
                     <Box
                        sx={{
                           flex: "0 0 auto",
                           background: `url(${product.photos[0].photoUrl})`,
                           backgroundSize: "cover",
                           backgroundPosition: "center center",
                           backgroundRepeat: "no-repeat",
                           width: "50px",
                           height: "50px",
                           borderRadius: "8px",
                        }}
                     />
                     <Box
                        sx={{
                           flex: 1,
                        }}
                     >
                        <NovaText
                           type={NovaTextTypes.MediumNormal}
                           style={{
                              margin: 0,
                              fontWeight: "600",
                              lineHeight: "20px",
                           }}
                        >
                           {numberOfMedallions} x {product.name}
                        </NovaText>
                        <NovaText
                           type={NovaTextTypes.BaseNormal}
                           style={{
                              margin: "6px 0 0 0",
                              lineHeight: "14px",
                           }}
                        >
                           <TranslatedText text="single-product.metal-medallion" />
                        </NovaText>
                        <div
                           style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "16px",
                           }}
                        >
                           <div
                              style={{
                                 height: "2px",
                                 width: "18px",
                                 background: "#CAA45D",
                                 display: "inline-block",
                              }}
                           />
                           <NovaText
                              type={NovaTextTypes.MediumNormal}
                              style={{
                                 margin: "0 0 0 10px",
                                 color: "#CAA45D",
                                 fontWeight: "900",
                              }}
                           >
                              {totalPrice}&nbsp;
                              <span style={{ fontWeight: "700", fontSize: "12px" }}>
                                 {language.domainLanguage === "bg" ? "BGN" : "EUR"}
                              </span>
                           </NovaText>
                        </div>
                     </Box>
                     <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                     >
                        <CloseIcon fontSize="small" />
                     </IconButton>
                  </Box>
                  <Box sx={{ marginTop: "12px", display: "flex" }}>
                     <Link
                        to="../cart"
                        style={{
                           flex: 1,
                           fontSize: "12px",
                           lineHeight: "14px",
                           borderRadius: "32px",
                           padding: "10px 18px",
                           background: "#1B1B48",
                           color: "#CAA45D",
                           textAlign: "center",
                           textDecoration: "none",
                        }}
                     >
                        <TranslatedText text="buttons.go-to-cart" />
                     </Link>
                  </Box>
               </Box>
            </Snackbar>
         </Container>

         {qrPreview && (
            <PreviewModal
               page={page}
               handleOrder={handleAddToCart}
               handleClose={handleQRPreviewClose}
            />
         )}

         {selectedImageIndex !== undefined && (
            <Gallery
               items={product?.photos?.map((photo: any) => ({
                  photoUrl: photo.photoUrl,
               }))}
               selectedItemId={selectedImageIndex}
               handleClose={() => setSelectedImageIndex(undefined)}
            />
         )}
      </>
   );
};

export default Product;
