import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { getProducts } from "./async-actions";
import ProductsTable from "../../components/products/table";
import { columns } from "./data";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProductsSelectors } from "./selectors";
import Loader from "../../components/Loader";

const Products = () => {
   const dispatch = useAppDispatch();

   const products = useSelector(ProductsSelectors.getProductsList);

   const [selectedRowsCount, setSelectedRowsCount] = useState(0);
   const [packagesCount, setPackagesCount] = useState(0);

   const handleSelectedRowsCount = (selectedCount: any) => {
      setSelectedRowsCount(selectedCount);
   };

   useEffect(() => {
      dispatch(getProducts());
   }, []);

   return (
      <Container
         sx={{
            padding: "32px 0 120px 0",
         }}
      >
         <Box
            sx={{
               display: "flex",
               alignItems: "flex-end",
               justifyContent: "end",
               padding: "24px 0",
            }}
         >
            {/* <Box>{selectedRowsCount > 0 && selectedRowsCount}</Box> */}
            <Box>
               <Link
                  to="create"
                  style={{
                     background: "#CAA45D",
                     color: "#fff",
                     padding: "14px 24px",
                     textDecoration: "none",
                     borderRadius: "32px",
                  }}
               >
                  Create
               </Link>
            </Box>
         </Box>
         <Box>
            {products ? (
               <ProductsTable
                  rows={products}
                  columns={columns}
                  rowsSelectedCallback={handleSelectedRowsCount}
               />
            ) : (
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                  }}
               >
                  <Loader />
               </Box>
            )}
         </Box>
      </Container>
   );
};

export default Products;
