import { GET_CITIES, GET_OFFICES } from "./types";

const getCities = (payload: any) => ({
   type: GET_CITIES,
   payload,
});

const getOffices = (payload: any) => ({
   type: GET_OFFICES,
   payload,
});

export const EkontActions = {
   getCities,
   getOffices,
};
