import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { EditIcon } from "../shared/icons/edit";
import { UploadIcon } from "../shared/icons/upload-icon";
import NovaTextInput from "../shared/NovaTextInput";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import { Divider, FormControlLabel, Switch } from "@mui/material";
import NovaButton from "../shared/NovaButton";
import { v4 as uuidv4 } from "uuid";
import TranslatedText from "../translated-text";
import { useTranslation } from "react-i18next";

const style = {
   position: "absolute" as "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   bgcolor: "background.paper",
   boxShadow: 24,
   p: 4,
   outline: "none",
   borderRadius: "24px",
};

const AddModal = ({ data, handleSave, handleClose }: any) => {
   const { t } = useTranslation();
   const initialPerson = {
      id: null,
      Base64: null,
      partnersIds: null,
      childLinkIcon: null,
      childLinkIconColor: null,
      name: "",
      gender: "male",
      bornYear: null,
      deathYear: null,
      pageId: null,
      isAlive: true,
      divorced: false,
      unknown: false,
   } as any;
   const [firstProfile, setFirstProfile] = useState<any>({
      id: null,
      Base64: null,
      partnersIds: null,
      childLinkIcon: null,
      childLinkIconColor: null,
      name: "",
      gender: "male",
      bornYear: null,
      deathYear: null,
      pageId: null,
      isAlive: true,
      divorced: false,
      unknown: false,
   });
   const [secondProfile, setSecondProfile] = useState<any>({
      id: null,
      Base64: null,
      partnersIds: null,
      childLinkIcon: null,
      childLinkIconColor: null,
      name: "",
      gender: "female",
      bornYear: null,
      deathYear: null,
      pageId: null,
      isAlive: true,
      unknown: false,
   });

   const resetForms = () => {
      setFirstProfile({ ...initialPerson });
      setSecondProfile({ ...initialPerson, gender: "female" });
   };
   const onFormChange = (value: any, field: any) => {
      if (field === "bornYear") {
         return setFirstProfile({
            ...firstProfile,
            bornYear: dayjs(value).format("YYYY-MM-DD"),
         });
      }

      if (field === "deathYear") {
         return setFirstProfile({
            ...firstProfile,
            deathYear: dayjs(value).format("YYYY-MM-DD"),
         });
      }
      setFirstProfile({ ...firstProfile, [field]: value });
   };

   const onSecondProfileFormChange = (value: any, field: any) => {
      if (field === "bornYear") {
         return setSecondProfile({
            ...secondProfile,
            bornYear: dayjs(value).format("YYYY-MM-DD"),
         });
      }

      if (field === "deathYear") {
         return setSecondProfile({
            ...secondProfile,
            deathYear: dayjs(value).format("YYYY-MM-DD"),
         });
      }
      return setSecondProfile({ ...secondProfile, [field]: value });
   };

   const onSaveClick = (firstPerson: any, secondPerson: any) => {
      if (data?.type === "parents") {
         handleSave([
            {
               ...(firstPerson.unknown
                  ? {
                       ...firstPerson,
                       name: "Unknown",
                    }
                  : firstPerson),
               id: uuidv4(),
            },
            {
               ...(secondPerson.unknown
                  ? {
                       ...secondPerson,
                       name: "Unknown",
                    }
                  : secondPerson),
               id: uuidv4(),
            },
         ]);
      } else {
         handleSave([{ ...firstPerson, id: uuidv4() }]);
      }
      resetForms();
   };

   return (
      <Modal open={true} onClose={handleClose}>
         <Box sx={style}>
            <NovaText type={NovaTextTypes.XLNormal} style={{ margin: 0 }}>
               <TranslatedText text="create-memorial-page.family-tree.tree.add-type" />
               &nbsp;
               <TranslatedText
                  text={`create-memorial-page.family-tree.tree.types.${data?.type}`}
               />
            </NovaText>
            <Box
               sx={{
                  display: "flex",
                  gap: "48px",
               }}
            >
               <Box>
                  {data?.type === "parents" && (
                     <Box sx={{ display: "flex", marginTop: "24px" }}>
                        <FormControlLabel
                           value="start"
                           control={
                              <Switch
                                 checked={firstProfile.unknown}
                                 onChange={(event) =>
                                    onFormChange(event.target.checked, "unknown")
                                 }
                                 inputProps={{ "aria-label": "controlled" }}
                                 sx={{
                                    height: "35px",
                                    padding: 0,
                                    display: "flex",
                                    marginLeft: "16px",
                                    "& .MuiSwitch-switchBase": {
                                       "&.Mui-checked": {
                                          color: "#CAA45D",
                                          "& + .MuiSwitch-track": {
                                             opacity: 1,
                                             border: "1px solid #CAA45D",
                                             background: "#fff",
                                          },
                                       },
                                    },
                                    "& .MuiSwitch-track": {
                                       borderRadius: 20 / 2,
                                       backgroundColor: "#E9E9EA",
                                       opacity: 1,
                                    },
                                    "& .MuiSwitch-thumb": {
                                       boxShadow: "none",
                                    },
                                    ".MuiButtonBase-root": {
                                       height: "35px",
                                    },
                                    ".MuiButtonBase-root.Mui-checked": {
                                       padding: "8px 12px",
                                    },
                                 }}
                              />
                           }
                           label="Unknown"
                           labelPlacement="start"
                        />
                     </Box>
                  )}
                  <Box
                     sx={{
                        display: "flex",
                        position: "relative",
                        width: "100%",
                        gap: "24px",
                        marginTop: data?.type === "parents" ? "10px" : "24px",
                        opacity: firstProfile.unknown ? 0.4 : 1,
                        pointerEvents: firstProfile.unknown ? "none" : "all",
                     }}
                  >
                     <Box
                        sx={{
                           height: "158px",
                        }}
                     >
                        <Dropzone
                           accept={{
                              "image/png": [".png"],
                              "image/jpeg": [".jpg"],
                           }}
                           onDrop={(acceptedFiles) => {
                              if (acceptedFiles.length > 0) {
                                 const reader = new FileReader();
                                 reader.onload = (e) => {
                                    const imageDataUrl = reader.result;
                                    onFormChange(imageDataUrl, "Base64");
                                 };
                                 reader.readAsDataURL(acceptedFiles[0]);
                              }
                           }}
                        >
                           {({ getRootProps, getInputProps }) => (
                              <Box
                                 sx={{
                                    maxWidth: "100%",
                                    width: "158px",
                                    maxHeight: "158px",
                                    height: "100%",
                                    border: "2px dashed #E9DDC8",
                                    borderRadius: "16px",
                                    cursor: "pointer",
                                    overflow: "hidden",
                                 }}
                              >
                                 <div
                                    {...getRootProps()}
                                    style={{
                                       height: "100%",
                                    }}
                                 >
                                    <input {...getInputProps()} />
                                    <div
                                       style={{
                                          height: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                       }}
                                    >
                                       {firstProfile.Base64 && (
                                          <Box
                                             sx={{
                                                position: "relative",
                                                "&:hover > .edit-button": {
                                                   display: "inline-flex",
                                                   background: "#fff",
                                                },
                                             }}
                                          >
                                             <Box
                                                sx={{
                                                   background: `url(${firstProfile.Base64})`,
                                                   backgroundSize: "cover",
                                                   backgroundRepeat: "no-repeat",
                                                   backgroundPosition: "center center",
                                                   width: "158px",
                                                   height: "158px",
                                                }}
                                             />
                                             <Box
                                                className="edit-button"
                                                sx={{
                                                   position: "absolute",
                                                   top: "50%",
                                                   left: "50%",
                                                   transform: "translate(-50%, -50%)",
                                                   background: "rgba(255, 255, 255, .8)",
                                                   display: "none",
                                                   alignItems: "center",
                                                   justifyContent: "center",
                                                   border: "1px solid #E9DDC8",
                                                   borderRadius: "50%",
                                                   width: "50px",
                                                   height: "50px",
                                                   color: "#1B1B48",
                                                }}
                                             >
                                                <EditIcon />
                                             </Box>
                                          </Box>
                                       )}
                                       {!firstProfile.Base64 && (
                                          <Box
                                             sx={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                                gap: "4px",
                                                border: "1px solid #E9DDC8",
                                                borderRadius: "32px",
                                                padding: "10px 24px",
                                             }}
                                          >
                                             <UploadIcon />
                                             <TranslatedText text="buttons.upload" />
                                          </Box>
                                       )}
                                    </div>
                                 </div>
                              </Box>
                           )}
                        </Dropzone>
                     </Box>
                     <Box>
                        <NovaTextInput
                           label={t("create-memorial-page.family-tree.tree.name")}
                           placeholder={t("create-memorial-page.family-tree.tree.name")}
                           value={firstProfile.name}
                           fullwidth
                           onChange={(event) => {
                              onFormChange(event.target.value, "name");
                           }}
                        />
                        <Box
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "16px",
                              marginTop: "16px",
                           }}
                        >
                           <Box
                              sx={{
                                 display: "flex",
                                 flexDirection: " column",
                              }}
                           >
                              <NovaText
                                 type={NovaTextTypes.BaseNormal}
                                 style={{
                                    margin: 0,
                                 }}
                              >
                                 <TranslatedText text="create-memorial-page.family-tree.tree.birth-date" />
                              </NovaText>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                 <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                       value={
                                          firstProfile.bornYear &&
                                          dayjs(firstProfile.bornYear)
                                       }
                                       slotProps={{ textField: { size: "small" } }}
                                       openTo="year"
                                       views={["year", "month", "day"]}
                                       minDate={dayjs("01-01-1000")}
                                       maxDate={dayjs()}
                                       onChange={(event) =>
                                          onFormChange(event, "bornYear")
                                       }
                                    />
                                 </DemoContainer>
                              </LocalizationProvider>
                           </Box>

                           {!firstProfile.isAlive && (
                              <Box
                                 sx={{
                                    display: "flex",
                                    flexDirection: " column",
                                 }}
                              >
                                 <NovaText
                                    type={NovaTextTypes.BaseNormal}
                                    style={{
                                       margin: 0,
                                    }}
                                 >
                                    <TranslatedText text="create-memorial-page.family-tree.tree.dead-date" />
                                 </NovaText>
                                 <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                       <DatePicker
                                          value={
                                             firstProfile.deathYear &&
                                             dayjs(firstProfile.deathYear)
                                          }
                                          slotProps={{ textField: { size: "small" } }}
                                          openTo="year"
                                          views={["year", "month", "day"]}
                                          minDate={dayjs("01-01-1000")}
                                          maxDate={dayjs()}
                                          onChange={(event) =>
                                             onFormChange(event, "deathYear")
                                          }
                                       />
                                    </DemoContainer>
                                 </LocalizationProvider>
                              </Box>
                           )}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "end", mt: "10px" }}>
                           <FormControlLabel
                              value="start"
                              control={
                                 <Switch
                                    checked={firstProfile.isAlive}
                                    onChange={(event) =>
                                       onFormChange(event.target.checked, "isAlive")
                                    }
                                    inputProps={{ "aria-label": "controlled" }}
                                    sx={{
                                       height: "35px",
                                       padding: 0,
                                       display: "flex",
                                       marginLeft: "16px",
                                       "& .MuiSwitch-switchBase": {
                                          "&.Mui-checked": {
                                             color: "#CAA45D",
                                             "& + .MuiSwitch-track": {
                                                opacity: 1,
                                                border: "1px solid #CAA45D",
                                                background: "#fff",
                                             },
                                          },
                                       },
                                       "& .MuiSwitch-track": {
                                          borderRadius: 20 / 2,
                                          backgroundColor: "#E9E9EA",
                                          opacity: 1,
                                       },
                                       "& .MuiSwitch-thumb": {
                                          boxShadow: "none",
                                       },
                                       ".MuiButtonBase-root": {
                                          height: "35px",
                                       },
                                       ".MuiButtonBase-root.Mui-checked": {
                                          padding: "8px 12px",
                                       },
                                    }}
                                 />
                              }
                              label={t(
                                 "create-memorial-page.family-tree.tree.person-is-alive",
                              )}
                              labelPlacement="start"
                           />
                        </Box>
                        {data?.type === "partner" && (
                           <Box
                              sx={{ display: "flex", justifyContent: "end", mt: "10px" }}
                           >
                              <FormControlLabel
                                 value="start"
                                 control={
                                    <Switch
                                       checked={firstProfile.divorced}
                                       onChange={(event) =>
                                          onFormChange(event.target.checked, "divorced")
                                       }
                                       inputProps={{ "aria-label": "controlled" }}
                                       sx={{
                                          height: "35px",
                                          padding: 0,
                                          display: "flex",
                                          marginLeft: "16px",
                                          "& .MuiSwitch-switchBase": {
                                             "&.Mui-checked": {
                                                color: "#CAA45D",
                                                "& + .MuiSwitch-track": {
                                                   opacity: 1,
                                                   border: "1px solid #CAA45D",
                                                   background: "#fff",
                                                },
                                             },
                                          },
                                          "& .MuiSwitch-track": {
                                             borderRadius: 20 / 2,
                                             backgroundColor: "#E9E9EA",
                                             opacity: 1,
                                          },
                                          "& .MuiSwitch-thumb": {
                                             boxShadow: "none",
                                          },
                                          ".MuiButtonBase-root": {
                                             height: "35px",
                                          },
                                          ".MuiButtonBase-root.Mui-checked": {
                                             padding: "8px 12px",
                                          },
                                       }}
                                    />
                                 }
                                 label={t(
                                    "create-memorial-page.family-tree.tree.divorced",
                                 )}
                                 labelPlacement="start"
                              />
                           </Box>
                        )}
                        <Box>
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{
                                 margin: 0,
                              }}
                           >
                              <TranslatedText text="create-memorial-page.family-tree.tree.gender.title" />
                           </NovaText>
                           <Box
                              className="chipSelector"
                              sx={{
                                 marginTop: "8px",
                                 display: "flex",
                                 gap: "8px",
                              }}
                           >
                              <div
                                 className={`chip${
                                    firstProfile.gender === "male" ? " active" : ""
                                 }`}
                                 onClick={() => onFormChange("male", "gender")}
                              >
                                 <TranslatedText text="create-memorial-page.family-tree.tree.gender.male" />
                              </div>
                              <div
                                 className={`chip${
                                    firstProfile.gender === "female" ? " active" : ""
                                 }`}
                                 onClick={() => onFormChange("female", "gender")}
                              >
                                 <TranslatedText text="create-memorial-page.family-tree.tree.gender.female" />
                              </div>
                           </Box>
                        </Box>
                     </Box>
                  </Box>
               </Box>
               {data?.type === "parents" && (
                  <>
                     <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                           borderStyle: "dashed",
                           borderColor: "#CAA45D",
                        }}
                     />
                     <Box>
                        <Box sx={{ display: "flex", marginTop: "24px" }}>
                           <FormControlLabel
                              value="start"
                              control={
                                 <Switch
                                    checked={secondProfile.unknown}
                                    onChange={(event) =>
                                       onSecondProfileFormChange(
                                          event.target.checked,
                                          "unknown",
                                       )
                                    }
                                    inputProps={{ "aria-label": "controlled" }}
                                    sx={{
                                       height: "35px",
                                       padding: 0,
                                       display: "flex",
                                       marginLeft: "16px",
                                       "& .MuiSwitch-switchBase": {
                                          "&.Mui-checked": {
                                             color: "#CAA45D",
                                             "& + .MuiSwitch-track": {
                                                opacity: 1,
                                                border: "1px solid #CAA45D",
                                                background: "#fff",
                                             },
                                          },
                                       },
                                       "& .MuiSwitch-track": {
                                          borderRadius: 20 / 2,
                                          backgroundColor: "#E9E9EA",
                                          opacity: 1,
                                       },
                                       "& .MuiSwitch-thumb": {
                                          boxShadow: "none",
                                       },
                                       ".MuiButtonBase-root": {
                                          height: "35px",
                                       },
                                       ".MuiButtonBase-root.Mui-checked": {
                                          padding: "8px 12px",
                                       },
                                    }}
                                 />
                              }
                              label="Unknown"
                              labelPlacement="start"
                           />
                        </Box>
                        <Box
                           sx={{
                              display: "flex",
                              position: "relative",
                              width: "100%",
                              gap: "24px",
                              marginTop: " 10px",
                              opacity: secondProfile.unknown ? 0.4 : 1,
                              pointerEvents: secondProfile.unknown ? "none" : "all",
                           }}
                        >
                           <Box
                              sx={{
                                 height: "158px",
                              }}
                           >
                              <Dropzone
                                 accept={{
                                    "image/png": [".png"],
                                    "image/jpeg": [".jpg"],
                                 }}
                                 onDrop={(acceptedFiles) => {
                                    if (acceptedFiles.length > 0) {
                                       const reader = new FileReader();
                                       reader.onload = (e) => {
                                          const imageDataUrl = reader.result;
                                          onSecondProfileFormChange(
                                             imageDataUrl,
                                             "Base64",
                                          );
                                       };
                                       reader.readAsDataURL(acceptedFiles[0]);
                                    }
                                 }}
                              >
                                 {({ getRootProps, getInputProps }) => (
                                    <Box
                                       sx={{
                                          maxWidth: "100%",
                                          width: "158px",
                                          maxHeight: "158px",
                                          height: "100%",
                                          border: "2px dashed #E9DDC8",
                                          borderRadius: "16px",
                                          cursor: "pointer",
                                          overflow: "hidden",
                                       }}
                                    >
                                       <div
                                          {...getRootProps()}
                                          style={{
                                             height: "100%",
                                          }}
                                       >
                                          <input {...getInputProps()} />
                                          <div
                                             style={{
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                             }}
                                          >
                                             {secondProfile.Base64 && (
                                                <Box
                                                   sx={{
                                                      position: "relative",
                                                      "&:hover > .edit-button": {
                                                         display: "inline-flex",
                                                         background: "#fff",
                                                      },
                                                   }}
                                                >
                                                   <Box
                                                      sx={{
                                                         background: `url(${secondProfile.Base64})`,
                                                         backgroundSize: "cover",
                                                         backgroundRepeat: "no-repeat",
                                                         backgroundPosition:
                                                            "center center",
                                                         width: "158px",
                                                         height: "158px",
                                                      }}
                                                   />
                                                   <Box
                                                      className="edit-button"
                                                      sx={{
                                                         position: "absolute",
                                                         top: "50%",
                                                         left: "50%",
                                                         transform:
                                                            "translate(-50%, -50%)",
                                                         background:
                                                            "rgba(255, 255, 255, .8)",
                                                         display: "none",
                                                         alignItems: "center",
                                                         justifyContent: "center",
                                                         border: "1px solid #E9DDC8",
                                                         borderRadius: "50%",
                                                         width: "50px",
                                                         height: "50px",
                                                         color: "#1B1B48",
                                                      }}
                                                   >
                                                      <EditIcon />
                                                   </Box>
                                                </Box>
                                             )}
                                             {!secondProfile.Base64 && (
                                                <Box
                                                   sx={{
                                                      display: "inline-flex",
                                                      alignItems: "center",
                                                      gap: "4px",
                                                      border: "1px solid #E9DDC8",
                                                      borderRadius: "32px",
                                                      padding: "10px 24px",
                                                   }}
                                                >
                                                   <UploadIcon />
                                                   <TranslatedText text="buttons.upload" />
                                                </Box>
                                             )}
                                          </div>
                                       </div>
                                    </Box>
                                 )}
                              </Dropzone>
                           </Box>
                           <Box>
                              <NovaTextInput
                                 label="Name"
                                 placeholder="name"
                                 value={secondProfile.name}
                                 fullwidth
                                 onChange={(event) => {
                                    onSecondProfileFormChange(event.target.value, "name");
                                 }}
                              />
                              <Box
                                 sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "16px",
                                    marginTop: "16px",
                                 }}
                              >
                                 <Box
                                    sx={{
                                       display: "flex",
                                       flexDirection: " column",
                                    }}
                                 >
                                    <NovaText
                                       type={NovaTextTypes.BaseNormal}
                                       style={{
                                          margin: 0,
                                       }}
                                    >
                                       <TranslatedText text="create-memorial-page.family-tree.tree.birth-date" />
                                    </NovaText>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                       <DemoContainer components={["DatePicker"]}>
                                          <DatePicker
                                             value={
                                                secondProfile.bornYear &&
                                                dayjs(secondProfile.bornYear)
                                             }
                                             slotProps={{ textField: { size: "small" } }}
                                             openTo="year"
                                             views={["year", "month", "day"]}
                                             minDate={dayjs("01-01-1000")}
                                             maxDate={dayjs()}
                                             onChange={(event) =>
                                                onSecondProfileFormChange(
                                                   event,
                                                   "bornYear",
                                                )
                                             }
                                          />
                                       </DemoContainer>
                                    </LocalizationProvider>
                                 </Box>

                                 {!secondProfile.isAlive && (
                                    <Box
                                       sx={{
                                          display: "flex",
                                          flexDirection: " column",
                                       }}
                                    >
                                       <NovaText
                                          type={NovaTextTypes.BaseNormal}
                                          style={{
                                             margin: 0,
                                          }}
                                       >
                                          <TranslatedText text="create-memorial-page.family-tree.tree.dead-date" />
                                       </NovaText>
                                       <LocalizationProvider dateAdapter={AdapterDayjs}>
                                          <DemoContainer components={["DatePicker"]}>
                                             <DatePicker
                                                value={
                                                   secondProfile.deathYear &&
                                                   dayjs(secondProfile.deathYear)
                                                }
                                                slotProps={{
                                                   textField: { size: "small" },
                                                }}
                                                openTo="year"
                                                views={["year", "month", "day"]}
                                                minDate={dayjs("01-01-1000")}
                                                maxDate={dayjs()}
                                                onChange={(event) =>
                                                   onSecondProfileFormChange(
                                                      event,
                                                      "deathYear",
                                                   )
                                                }
                                             />
                                          </DemoContainer>
                                       </LocalizationProvider>
                                    </Box>
                                 )}
                              </Box>
                              <Box
                                 sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    mt: "10px",
                                 }}
                              >
                                 <FormControlLabel
                                    value="start"
                                    control={
                                       <Switch
                                          checked={secondProfile.isAlive}
                                          onChange={(event) =>
                                             onSecondProfileFormChange(
                                                event.target.checked,
                                                "isAlive",
                                             )
                                          }
                                          inputProps={{ "aria-label": "controlled" }}
                                          sx={{
                                             height: "35px",
                                             padding: 0,
                                             display: "flex",
                                             marginLeft: "16px",
                                             "& .MuiSwitch-switchBase": {
                                                "&.Mui-checked": {
                                                   color: "#CAA45D",
                                                   "& + .MuiSwitch-track": {
                                                      opacity: 1,
                                                      border: "1px solid #CAA45D",
                                                      background: "#fff",
                                                   },
                                                },
                                             },
                                             "& .MuiSwitch-track": {
                                                borderRadius: 20 / 2,
                                                backgroundColor: "#E9E9EA",
                                                opacity: 1,
                                             },
                                             "& .MuiSwitch-thumb": {
                                                boxShadow: "none",
                                             },
                                             ".MuiButtonBase-root": {
                                                height: "35px",
                                             },
                                             ".MuiButtonBase-root.Mui-checked": {
                                                padding: "8px 12px",
                                             },
                                          }}
                                       />
                                    }
                                    label={t(
                                       "create-memorial-page.family-tree.tree.person-is-alive",
                                    )}
                                    labelPlacement="start"
                                 />
                              </Box>
                              <Box>
                                 <NovaText
                                    type={NovaTextTypes.BaseNormal}
                                    style={{
                                       margin: 0,
                                    }}
                                 >
                                    <TranslatedText text="create-memorial-page.family-tree.tree.gender.title" />
                                 </NovaText>
                                 <Box
                                    className="chipSelector"
                                    sx={{
                                       marginTop: "8px",
                                       display: "flex",
                                       gap: "8px",
                                    }}
                                 >
                                    <div
                                       className={`chip${
                                          secondProfile.gender === "male" ? " active" : ""
                                       }`}
                                       onClick={() =>
                                          onSecondProfileFormChange("male", "gender")
                                       }
                                    >
                                       <TranslatedText text="create-memorial-page.family-tree.tree.gender.male" />
                                    </div>
                                    <div
                                       className={`chip${
                                          secondProfile.gender === "female"
                                             ? " active"
                                             : ""
                                       }`}
                                       onClick={() =>
                                          onSecondProfileFormChange("female", "gender")
                                       }
                                    >
                                       <TranslatedText text="create-memorial-page.family-tree.tree.gender.female" />
                                    </div>
                                 </Box>
                              </Box>
                           </Box>
                        </Box>
                     </Box>
                  </>
               )}
            </Box>
            {data?.type === "parents" && (
               <Box sx={{ display: "flex", mt: "10px" }}>
                  <FormControlLabel
                     value="start"
                     control={
                        <Switch
                           checked={firstProfile.divorced}
                           onChange={(event) =>
                              onFormChange(event.target.checked, "divorced")
                           }
                           inputProps={{ "aria-label": "controlled" }}
                           sx={{
                              height: "35px",
                              padding: 0,
                              display: "flex",
                              marginLeft: "16px",
                              "& .MuiSwitch-switchBase": {
                                 "&.Mui-checked": {
                                    color: "#CAA45D",
                                    "& + .MuiSwitch-track": {
                                       opacity: 1,
                                       border: "1px solid #CAA45D",
                                       background: "#fff",
                                    },
                                 },
                              },
                              "& .MuiSwitch-track": {
                                 borderRadius: 20 / 2,
                                 backgroundColor: "#E9E9EA",
                                 opacity: 1,
                              },
                              "& .MuiSwitch-thumb": {
                                 boxShadow: "none",
                              },
                              ".MuiButtonBase-root": {
                                 height: "35px",
                              },
                              ".MuiButtonBase-root.Mui-checked": {
                                 padding: "8px 12px",
                              },
                           }}
                        />
                     }
                     label="Divorced"
                     labelPlacement="start"
                  />
               </Box>
            )}
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginTop: "32px",
               }}
            >
               <NovaButton
                  style={{
                     borderRadius: "32px",
                     background: "#F3F5F7",
                     color: "#1B1B48",
                  }}
                  onClick={handleClose}
               >
                  <TranslatedText text="buttons.cancel" />
               </NovaButton>
               <NovaButton
                  disabled={
                     (!firstProfile.unknown &&
                        (!firstProfile.name || !firstProfile.bornYear)) ||
                     (data?.type === "parents" && !secondProfile.unknown
                        ? !secondProfile.name || !secondProfile.bornYear
                        : false)
                  }
                  style={{
                     borderRadius: "32px",
                  }}
                  onClick={() => onSaveClick(firstProfile, secondProfile)}
               >
                  <TranslatedText text="buttons.add" />
               </NovaButton>
            </Box>
         </Box>
      </Modal>
   );
};

export default AddModal;
