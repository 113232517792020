import { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Box, ClickAwayListener, IconButton, Tooltip } from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FacebookIcon, TwitterIcon } from "../shared/icons/socials";
import { SingleMemorialPageSelectors } from "../../pages/single-memorial-page/selectors";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import NovaButton from "../shared/NovaButton";
import TranslatedText from "../translated-text";

type ShareButtonsProps = {
   color?: string;
   scrolled?: boolean;
};

function ShareButtons({ color = "#CAA45D", scrolled = false }: ShareButtonsProps) {
   const pagePublicId = useSelector(
      SingleMemorialPageSelectors.getSingleMemorialPagePublicId,
   );
   const [linkCopied, setLinkCopied] = useState(false);
   const shareLink = (
      window?.location.origin.includes("localhost")
         ? "https://webdemo.novamemorial.com" + window?.location.pathname
         : window?.location.origin
   )
      ?.replaceAll(":", "%3A")
      .replaceAll("/", "%2F");

   const externalShareLink = decodeURIComponent(
      `https://novamemorial.com/${pagePublicId}`,
   );

   const onClickFacebookShare = useCallback(() => {
      window?.open(
         `https://www.facebook.com/sharer/sharer.php?u=${shareLink}&amp;src=sdkpreparse`,
         "_blank",
      );
   }, [shareLink]);
   const onClickTweeterShare = useCallback(() => {
      window?.open(`https://twitter.com/intent/tweet?url=${shareLink}`, "_blank");
   }, [shareLink]);

   const onClickLinkShare = useCallback(() => {
      setLinkCopied(true);
      navigator.clipboard.writeText(externalShareLink);
      setTimeout(() => {
         setLinkCopied(false);
      }, 3000);
   }, [externalShareLink]);

   const handleTooltipClose = () => {
      setLinkCopied(false);
   };

   return (
      <Box
         sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
         }}
      >
         <IconButton onClick={onClickFacebookShare}>
            <FacebookIcon color={color} />
         </IconButton>
         <IconButton onClick={onClickTweeterShare}>
            <TwitterIcon color={color} />
         </IconButton>
         {scrolled && (
            <ClickAwayListener onClickAway={handleTooltipClose}>
               <Tooltip
                  PopperProps={{
                     disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={linkCopied}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Link copied!"
                  placement="right"
               >
                  <Box>
                     <NovaButton
                        style={{
                           backgroundColor: "transparent",
                           padding: "4px",
                        }}
                        onClick={onClickLinkShare}
                        icon={<ContentCopyIcon sx={{ fill: color, fontSize: "16px" }} />}
                     >
                        <NovaText
                           type={NovaTextTypes.BaseNormal}
                           style={{ margin: "0 0 0 4px", fontWeight: "500" }}
                        >
                           <TranslatedText text="personal-memorial-page.copy-link" />
                        </NovaText>
                     </NovaButton>
                  </Box>
               </Tooltip>
            </ClickAwayListener>
         )}
      </Box>
   );
}

export default memo(ShareButtons);
