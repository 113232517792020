import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Container, Grid } from "@mui/material";

import { getProducts } from "../async-actions";
import { useAppDispatch } from "../../../store/hooks";
import { ProductsSelectors } from "../selectors";

import ProductCard from "./ProductCard";
import Loader from "../../../components/Loader";

const ClientProducts = () => {
   const dispatch = useAppDispatch();

   const products = useSelector(ProductsSelectors.getProductsList);

   useEffect(() => {
      dispatch(getProducts());
   }, []);

   return (
      <Container
         sx={{
            padding: "80px 20px",
         }}
      >
         <Grid container spacing={3}>
            {products?.length ? (
               products.map((product: any, index: number) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                     <ProductCard {...product} />
                  </Grid>
               ))
            ) : (
               <Box
                  sx={{
                     display: "flex",
                     width: "100%",
                     justifyContent: "center",
                  }}
               >
                  <Loader />
               </Box>
            )}
         </Grid>
      </Container>
   );
};

export default ClientProducts;
