import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Box } from "@mui/material";

import { userSignUp } from "./async-actions";
import { useAppDispatch, useLanguage } from "../../store/hooks";
import { checkEmailIsValid } from "../../utils/functions";

import CreateNewAccount from "./CreateNewAccount";

const SignUp = () => {
   useLanguage();

   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const [account, setAccount] = useState({
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      terms: false,
      marketingAccepted: false,
   });
   const [isFormValid, setIsFormValid] = useState(false);

   const onAccountChange = ({ field, value }: any) => {
      setAccount({
         ...account,
         [field]: value,
      });
   };

   const handleSubmit = async () => {
      dispatch(
         userSignUp(
            account.name,
            account.email,
            account.password,
            account.marketingAccepted,
            navigate,
         ),
      );
   };

   useEffect(() => {
      if (
         account.name.length >= 3 &&
         account.email &&
         checkEmailIsValid(account.email) &&
         account.password.length >= 6 &&
         account.confirm_password &&
         account.password === account.confirm_password &&
         account.terms
      ) {
         return setIsFormValid(true);
      }
      return setIsFormValid(false);
   }, [account]);

   useEffect(() => {
      const keyDownHandler = (event: any) => {
         if (event.key === "Enter") {
            event.preventDefault();
            handleSubmit();
         }
      };

      document.addEventListener("keydown", keyDownHandler);

      return () => {
         document.removeEventListener("keydown", keyDownHandler);
      };
   }, []);

   return (
      <Box
         style={{
            height: "100%",
         }}
      >
         <CreateNewAccount
            account={account}
            isFormValid={isFormValid}
            onSubmit={handleSubmit}
            onAccountChange={onAccountChange}
         />
      </Box>
   );
};

export default SignUp;
