import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Box, Container } from "@mui/system";
import { AddCircleOutline } from "@mui/icons-material";
import Tribute from "./Tribute";
import NovaButton from "../../components/shared/NovaButton";
import { EmptyMemoryIcon } from "../../components/shared/icons/empty-memory-icon";
import { NovaModal } from "../../components/modal";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import { limitations } from "./limitations";
import { useSelector } from "react-redux";
import { HomeSelectors } from "../home/selectors";
import MemorialLimitationsInfoBar from "../../components/MemorialLimitationsInfoBar";
import TranslatedText from "../../components/translated-text";

const Tributes = ({
   generalInformation,
   memories,
   onChange,
}: {
   generalInformation: any;
   memories: any;
   onChange: (changes: any) => void;
}) => {
   const { t } = useTranslation();
   const editMemorialPageData = useSelector(HomeSelectors.getEditedMemorialPage);

   const [memoryModalOpened, setMemoryModalOpened] = useState(false);
   const handleAddMemory = () => {
      setMemoryModalOpened(!memoryModalOpened);
   };

   const handleMemoryModalClosed = () => {
      setMemoryModalOpened(!memoryModalOpened);
   };

   const handleSave = (form: any) => {
      onChange((prevMemories: any) => {
         const updated = [
            ...prevMemories,
            {
               ...form,
            },
         ];
         return updated;
      });
      handleMemoryModalClosed();
   };

   const handleDelete = (id: any) => {
      const newItems = [...memories.slice(0, id), ...memories.slice(id + 1)];

      onChange(newItems);
   };

   const maxMemoriesLength =
      limitations[
         editMemorialPageData?.subscription
            ? editMemorialPageData.subscription.subscriptionPlan.type
            : "free"
      ]?.memoriesLength;

   return (
      <Container>
         <MemorialLimitationsInfoBar
            itemName={t("create-memorial-page.memories.item-name")}
            limit={maxMemoriesLength}
         />

         {memories && memories.length > 0 ? (
            <Grid container spacing={5}>
               <Grid item xs={12}>
                  <NovaButton
                     startIcon={<AddCircleOutline />}
                     background="#fff"
                     color="#1B1B48"
                     style={{
                        border: "1px solid #1B1B48",
                        borderRadius: "32px",
                        marginBottom: "24px",
                     }}
                     onClick={handleAddMemory}
                     disabled={memories.length >= maxMemoriesLength}
                  >
                     <TranslatedText text="buttons.add-memory" />
                  </NovaButton>
               </Grid>

               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     gap: "20px",
                     width: "100%",
                  }}
               >
                  {memories.map((memory: any, index: number) => (
                     <Tribute
                        image={memory.photoUrl ?? memory.photoBase64}
                        name={memory.name}
                        description={memory.description}
                        birthDay={memory.date}
                        inEditMode
                        onClickRemove={() => handleDelete(index)}
                        key={index}
                     />
                  ))}
               </Box>
            </Grid>
         ) : (
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
               }}
            >
               <NovaButton
                  startIcon={<AddCircleOutline />}
                  background="#fff"
                  color="#1B1B48"
                  onClick={handleAddMemory}
               >
                  <TranslatedText text="buttons.add-memory" />
               </NovaButton>
               <Box
                  sx={{
                     marginTop: "24px",
                  }}
               >
                  <EmptyMemoryIcon />
               </Box>
               <NovaText
                  type={NovaTextTypes.BaseNormal}
                  style={{
                     textAlign: "center",
                     maxWidth: "800px",
                     marginTop: "24px",
                  }}
               >
                  <TranslatedText text="create-memorial-page.memories.message" />
               </NovaText>
            </Box>
         )}

         {memoryModalOpened && (
            <NovaModal
               generalInformation={generalInformation}
               onSave={handleSave}
               handleClose={handleMemoryModalClosed}
            />
         )}
      </Container>
   );
};

export default Tributes;
