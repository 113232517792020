import { createSelector } from "@reduxjs/toolkit";

const getProducts = (state: any) => state.products;
const getProductsList = createSelector(
   getProducts,
   (products) => products && products.list,
);

const getSingleProduct = createSelector(
   getProducts,
   (products) => products && products.single,
);

const getSingleProductQR = createSelector(
   getSingleProduct,
   (product) => product && product.qr,
);

export const ProductsSelectors = {
   getProductsList,
   getSingleProduct,
   getSingleProductQR,
};
