import { Box, Grid, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import NovaButton from "../shared/NovaButton";
import { STEPS } from ".";
import NovaTitle from "../shared/NovaTitle";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import { CheckCircle } from "@mui/icons-material";
import UpgradeImage from "../../assets/upgrade-image.png";
import TranslatedText from "../translated-text";
import { useTranslation } from "react-i18next";

const UpgradeStep = ({
   data,
   onClose,
   onStepChange,
}: {
   data: any;
   onClose: () => void;
   onStepChange: (step: any) => void;
}) => {
   const { t } = useTranslation();

   const list = [
      t("plans.initial.list.0"),
      t("plans.initial.list.1"),
      t("plans.initial.list.2"),
      t("plans.initial.list.3"),
      t("plans.initial.list.4"),
   ];

   return (
      <Grid container spacing={1}>
         <Grid item xs={0} sm={6}>
            <Box
               sx={{
                  width: "100%",
                  height: "100%",
                  background: `url(${UpgradeImage})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
               }}
            />
         </Grid>
         <Grid item xs={12} sm={6}>
            <NovaTitle variant="h3">
               <TranslatedText text="plans.initial.title1" /> "
               <span style={{ color: "#CAA45D" }}>{data.name}</span>"{" "}
               <TranslatedText text="plans.initial.title2" />
            </NovaTitle>
            <NovaText type={NovaTextTypes.BaseNormal}>
               <TranslatedText text="plans.initial.content1" />
            </NovaText>
            <NovaText type={NovaTextTypes.BaseNormal}>
               <TranslatedText text="plans.initial.content2" />
            </NovaText>
            <List>
               {list.map((item: any, index: number) => {
                  return (
                     <ListItem disablePadding key={index}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                           <ListItemIcon
                              sx={{
                                 minWidth: 0,
                              }}
                           >
                              <CheckCircle sx={{ color: "#1B1B48" }} />
                           </ListItemIcon>
                           <ListItemText primary={item} />
                        </Box>
                     </ListItem>
                  );
               })}
            </List>
            <NovaButton
               style={{
                  width: "100%",
                  boxSizing: "border-box",
                  borderRadius: "24px",
                  marginTop: "18px",
               }}
               onClick={() => onStepChange(STEPS.PLANS)}
            >
               <TranslatedText text="buttons.upgrade-plan" />
            </NovaButton>
            <NovaButton
               style={{
                  width: "100%",
                  boxSizing: "border-box",
                  marginTop: "18px",
                  background: "#fff",
                  borderRadius: "24px",
                  border: "1px solid #CAA45D",
                  "&:hover": {
                     border: "1px solid #CAA45D",
                  },
               }}
               onClick={onClose}
            >
               <TranslatedText text="buttons.without-upgrade" />
            </NovaButton>
         </Grid>
      </Grid>
   );
};

export default UpgradeStep;
