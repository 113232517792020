export const ProfileImageChange: React.FC<{
   color?: string;
   strokeWidth?: string;
}> = ({ color, strokeWidth }: any) => {
   return (
      <svg
         width="16"
         height="14"
         viewBox="0 0 16 14"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M10.125 1H6.03125C5.90625 1 5.8125 1.03125 5.75 1.09375C5.65625 1.15625 5.59375 1.25 5.5625 1.3125L4.9375 3H1.5C1.34375 3 1.21875 3.0625 1.125 3.15625C1.03125 3.25 1 3.375 1 3.5V12.5C1 12.6562 1.03125 12.7812 1.125 12.875C1.21875 12.9688 1.34375 13 1.5 13H14.5C14.625 13 14.75 12.9688 14.8438 12.875C14.9375 12.7812 15 12.6562 15 12.5V3.5C15 3.375 14.9375 3.25 14.8438 3.15625C14.75 3.0625 14.625 3 14.5 3H11.0625L10.375 1.15625C10.3125 1.0625 10.25 1 10.125 1ZM10.125 0C10.375 0 10.625 0.09375 10.8438 0.21875C11.0312 0.375 11.1875 0.5625 11.3125 0.8125L11.75 2H14.5C14.9062 2 15.25 2.15625 15.5625 2.4375C15.8438 2.75 16 3.09375 16 3.5V12.5C16 12.9375 15.8438 13.2812 15.5625 13.5625C15.25 13.875 14.9062 14 14.5 14H1.5C1.0625 14 0.71875 13.875 0.4375 13.5625C0.125 13.2812 0 12.9375 0 12.5V3.5C0 3.09375 0.125 2.75 0.4375 2.4375C0.71875 2.15625 1.0625 2 1.5 2H4.25L4.625 0.96875C4.75 0.6875 4.9375 0.46875 5.1875 0.28125C5.4375 0.09375 5.71875 0 6.03125 0H10.125ZM8 11.75C8.6875 11.75 9.3125 11.5938 9.875 11.25C10.4375 10.9375 10.9062 10.4688 11.25 9.90625C11.5625 9.34375 11.75 8.6875 11.75 8C11.75 7.3125 11.5625 6.6875 11.25 6.125C10.9062 5.5625 10.4375 5.09375 9.875 4.75C9.3125 4.4375 8.6875 4.25 8 4.25C7.3125 4.25 6.65625 4.4375 6.09375 4.75C5.53125 5.09375 5.0625 5.5625 4.75 6.125C4.40625 6.6875 4.25 7.3125 4.25 8C4.25 8.6875 4.40625 9.34375 4.75 9.90625C5.0625 10.4688 5.53125 10.9375 6.09375 11.25C6.65625 11.5938 7.3125 11.75 8 11.75ZM8 5.25C8.75 5.25 9.375 5.53125 9.9375 6.0625C10.4688 6.625 10.75 7.25 10.75 8C10.75 8.75 10.4688 9.40625 9.9375 9.9375C9.375 10.5 8.75 10.75 8 10.75C7.25 10.75 6.59375 10.5 6.0625 9.9375C5.5 9.40625 5.25 8.75 5.25 8C5.25 7.25 5.5 6.625 6.0625 6.0625C6.59375 5.53125 7.25 5.25 8 5.25Z"
            fill="#FEFDFC"
         />
      </svg>
   );
};
