import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { CloseOutlined } from "@mui/icons-material";
import { useAppDispatch } from "../../../store/hooks";
import { generateQR } from "../../../pages/products/async-actions";
import NovaButton from "../../shared/NovaButton";
import { ProductsSelectors } from "../../../pages/products/selectors";
import {
   FormControl,
   FormControlLabel,
   Grid,
   IconButton,
   Radio,
   RadioGroup,
} from "@mui/material";
import NovaTextInput from "../../shared/NovaTextInput";
import NovaText, { NovaTextTypes } from "../../shared/NovaText";
import TranslatedText from "../../translated-text";

export default function PreviewModal({
   page,
   qr,
   handleClose,
   handleOrder,
   readOnly = false,
}: {
   page?: any;
   qr?: any;
   handleClose: any;
   handleOrder?: any;
   readOnly?: boolean;
}) {
   const dispatch = useAppDispatch();
   const qrCode = useSelector(ProductsSelectors.getSingleProductQR);
   const [selectedValue, setSelectedValue] = useState((page && page.fullName) || "");
   const [customName, setCustomName] = useState("");

   useEffect(() => {
      if (page && !readOnly) {
         dispatch(generateQR(page.id, selectedValue));
      }
   }, [page, selectedValue]);

   const handleChange = (event: any) => {
      setSelectedValue(event.target.value);
   };

   const handleCustomNameChange = (event: any) => {
      setCustomName(event.target.value);
      setSelectedValue(event.target.value);
   };

   const handleTextFieldClick = () => {
      setSelectedValue(customName);
   };

   return (
      <div>
         <Modal open={true}>
            <Box
               sx={{
                  position: "absolute" as "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: {
                     xs: "80%",
                     sm: "75%",
                     md: "70%",
                     lg: readOnly ? "40%" : "60%",
                     xl: readOnly ? "30%" : "80%",
                  },
                  bgcolor: "background.paper",
                  borderRadius: "24px",
                  boxShadow: 24,
                  p: 4,
                  outline: "none",
                  maxHeight: "100vh",
                  overflow: "scroll",
               }}
            >
               <IconButton onClick={handleClose}>
                  <CloseOutlined />
               </IconButton>
               <Grid container spacing={2}>
                  <Grid item xs={12} sm={readOnly ? 12 : 6}>
                     <Box
                        dangerouslySetInnerHTML={{
                           __html: readOnly ? qr : qrCode || "",
                        }}
                        sx={{
                           mt: 2,
                           textAlign: "center",
                           width: "100%",
                           "& svg": {
                              width: "100%",
                              height: "auto",
                              maxWidth: "100%",
                              maxHeight: "100%",
                           },
                        }}
                     />
                  </Grid>
                  {!readOnly && (
                     <Grid item xs={12} sm={6}>
                        <Box>
                           <FormControl>
                              <RadioGroup
                                 value={selectedValue}
                                 onChange={handleChange}
                                 sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                 }}
                              >
                                 <FormControlLabel
                                    value={page.fullName}
                                    control={<Radio />}
                                    label={
                                       <Box>
                                          <NovaText
                                             type={NovaTextTypes.MediumLight}
                                             style={{ margin: 0 }}
                                          >
                                             <TranslatedText text="qr-modal.use-page-fullname" />
                                          </NovaText>
                                          <NovaText
                                             type={NovaTextTypes.BaseNormal}
                                             style={{
                                                margin: 0,
                                                fontSize: "12px",
                                                fontWeight: "700",
                                             }}
                                          >
                                             ({page.fullName})
                                          </NovaText>
                                       </Box>
                                    }
                                 />
                                 <FormControlLabel
                                    value={page.nickName}
                                    control={<Radio />}
                                    label={
                                       <Box>
                                          <NovaText
                                             type={NovaTextTypes.MediumLight}
                                             style={{ margin: 0 }}
                                          >
                                             <TranslatedText text="qr-modal.use-page-nickname" />
                                          </NovaText>
                                          <NovaText
                                             type={NovaTextTypes.BaseNormal}
                                             style={{
                                                margin: 0,
                                                fontSize: "12px",
                                                fontWeight: "700",
                                             }}
                                          >
                                             ({page.nickName})
                                          </NovaText>
                                       </Box>
                                    }
                                 />
                                 <FormControlLabel
                                    value={customName}
                                    control={<Radio />}
                                    label={
                                       <Box>
                                          <NovaText
                                             type={NovaTextTypes.MediumLight}
                                             style={{ margin: 0 }}
                                          >
                                             <TranslatedText text="qr-modal.use-custom-name" />
                                          </NovaText>
                                          <NovaTextInput
                                             value={customName}
                                             onClick={handleTextFieldClick}
                                             onChange={handleCustomNameChange}
                                             placeholder="Your custom name"
                                          />
                                       </Box>
                                    }
                                 />
                              </RadioGroup>
                           </FormControl>
                        </Box>
                     </Grid>
                  )}
               </Grid>

               {!readOnly && (
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: {
                           xs: "column",
                           sm: "row",
                        },
                        alignItems: "center",
                        gap: "16px",
                        marginTop: "20px",
                     }}
                  >
                     <NovaButton
                        style={{
                           width: "100%",
                           boxSizing: "border-box",
                           borderRadius: "34px",
                        }}
                        onClick={handleOrder}
                     >
                        <TranslatedText text="buttons.add-to-cart" />
                     </NovaButton>
                     <NovaButton
                        style={{
                           width: "100%",
                           boxSizing: "border-box",
                           borderRadius: "34px",
                        }}
                        background="#fff"
                        onClick={handleClose}
                     >
                        <TranslatedText text="buttons.back" />
                     </NovaButton>
                  </Box>
               )}
            </Box>
         </Modal>
      </div>
   );
}
