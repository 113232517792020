import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Autocomplete, Box, Container, TextField } from "@mui/material";
import VouchersTable from "./vouchers-table";
import { columns } from "./columns";
import { useAppDispatch } from "../../store/hooks";
import { getAffiliateUsers, getVouchers } from "./async-actions";
import { AffiliatesSelectors } from "./selectors";

const Vouchers = () => {
   const dispatch = useAppDispatch();
   const affiliatesUsers = useSelector(AffiliatesSelectors.getAffiliateUsers);
   const vouchers = useSelector(AffiliatesSelectors.getVouchers);
   const [filteredVouchers, setFilteredVouchers] = useState([]);
   const [selectedUser, setSelectedUser] = useState({
      id: 0,
      fullName: "All",
   });

   useEffect(() => {
      dispatch(getAffiliateUsers());
      dispatch(getVouchers());
   }, []);

   const onUserChange = (user: any) => {
      setSelectedUser(user);
   };

   useEffect(() => {
      if (vouchers && vouchers.length > 0) {
         if (!selectedUser || selectedUser?.fullName === "All") {
            setFilteredVouchers(vouchers);
         } else {
            const filtered = vouchers.filter(
               (voucher: any) =>
                  voucher.generatedForUserFullName === selectedUser?.fullName,
            );
            setFilteredVouchers(filtered);
         }
      }
   }, [vouchers, selectedUser]);

   if (!vouchers || !affiliatesUsers) return null;
   return (
      <Container
         sx={{
            padding: "32px 0 120px 0",
         }}
      >
         <Box
            sx={{
               display: "flex",
               alignItems: "flex-end",
               justifyContent: "end",
               padding: "24px 0",
            }}
         >
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
               }}
            >
               <Autocomplete
                  options={[
                     {
                        id: 0,
                        fullName: "All",
                     },
                     ...affiliatesUsers,
                  ]}
                  getOptionLabel={(option: any) => option?.fullName}
                  renderOption={(props, option) => (
                     <Box component="li" {...props}>
                        {option?.fullName}
                     </Box>
                  )}
                  renderInput={(params) => (
                     <TextField
                        {...params}
                        sx={{
                           ".MuiInputBase-root": {
                              width: "200px",
                              padding: "8.5px 14px",
                              ".MuiInputBase-input": {
                                 padding: 0,
                              },
                              backgroundColor: "#ffffff",
                           },
                        }}
                        placeholder="Select user"
                        inputProps={{
                           ...params.inputProps,
                           autoComplete: "new-password",
                        }}
                     />
                  )}
                  value={selectedUser}
                  onChange={(_, newValue) => onUserChange(newValue)}
               />
               <Link
                  to="../vouchers/create-voucher"
                  style={{
                     background: "#CAA45D",
                     color: "#fff",
                     padding: "14px 24px",
                     textDecoration: "none",
                     borderRadius: "32px",
                  }}
               >
                  Create
               </Link>
            </Box>
         </Box>
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between",
               padding: "24px 0",
            }}
         >
            <VouchersTable rows={filteredVouchers} columns={columns} />
         </Box>
      </Container>
   );
};

export default Vouchers;
