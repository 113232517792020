import { useTranslation } from "react-i18next";
import { LocalShippingOutlined, LockOutlined } from "@mui/icons-material";
import {
   Autocomplete,
   Box,
   Checkbox,
   CheckboxProps,
   CircularProgress,
   Container,
   Divider,
   FormControl,
   FormControlLabel,
   FormGroup,
   Grid,
   Radio,
   RadioGroup,
   TextField,
} from "@mui/material";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import NovaTextInput from "../../components/shared/NovaTextInput";
import { countries } from "./countries";
import { useEffect, useState } from "react";
import NovaButton from "../../components/shared/NovaButton";
import { useSelector } from "react-redux";
import { CartSelectors } from "../products/clients/selectors";
import NovaTitle from "../../components/shared/NovaTitle";
import { createOrder, getEkontCities, getEkontOffices } from "./async-actions";
import { useAppDispatch } from "../../store/hooks";
import { EkontSelectors } from "./selectors";
import TranslatedText from "../../components/translated-text";
import { checkEmailIsValid } from "../../utils/functions";

const Checkout = () => {
   const dispatch = useAppDispatch();
   const cart = useSelector(CartSelectors.getOrder);

   const totalPrice = useSelector(CartSelectors.getCardProductsTotal);
   const currency = useSelector(CartSelectors.getCardCurrency);

   const ekontCities = useSelector(EkontSelectors.getEkontCities);
   const ekontOffices = useSelector(EkontSelectors.getEkontOffices);

   const [form, setForm] = useState({
      email: "",
      name: "",
      surname: "",
      phoneNumber: "",
      address: "",
      postalCode: "",
      city: "",
      country: null,
      orderItems: null,
      paymentMethodType: 0,
      terms: false,
      marketing: false,
   } as any);

   const [deliveryType, setDeliveryType] = useState("toAddress"); //? toAddress, ekont, speedy
   const { t } = useTranslation();

   const onFieldChange = (event: any, field: string) => {
      if (field === "country") {
         const filteredCountry: any = countries.find(
            (country: any) => country.code === event?.code,
         );

         return setForm({ ...form, [field]: filteredCountry?.code });
      }

      return setForm({ ...form, [field]: event.target.value });
   };

   const onTermsChange: CheckboxProps["onChange"] = (event) => {
      setForm({ ...form, terms: event.target.checked });
   };

   const onMarketingChange: CheckboxProps["onChange"] = (event) => {
      setForm({ ...form, marketing: event.target.checked });
   };

   const handleDeliveryTypeChange = (event: any) => {
      setDeliveryType(event.target.value);
      if (event.target.value === "ekont" && !ekontCities) {
         dispatch(getEkontCities());
      }
   };

   const [selectedCity, setSelectedCity] = useState(null) as any;
   const [selectedOffice, setSelectedOffice] = useState(null) as any;

   const handleCitySelect = (selectedCity: any) => {
      setSelectedCity(selectedCity);
      if (selectedCity && selectedCity.id) {
         dispatch(getEkontOffices(selectedCity.id));
      }
   };

   const handleOfficeSelect = (selectedOffice: any) => {
      setSelectedOffice(selectedOffice);
   };

   const handleCreateOrder = () => {
      dispatch(createOrder(form));
   };

   useEffect(() => {
      setSelectedOffice(null);
   }, [selectedCity]);

   useEffect(() => {
      if (cart.length > 0) {
         setForm({ ...form, orderItems: [...cart] });
      }
   }, [cart]);

   return (
      <Container
         sx={{
            padding: "16px 20px 64px 20px",
         }}
      >
         <Grid
            container
            spacing={5}
            sx={{
               marginTop: "1px",
            }}
         >
            <Grid item xs={12} sm={6}>
               {/* <iframe
            title="Ekont Office Locator"
            allow="geolocation;"
            src="https://staging.officelocator.econt.com?shopUrl=https://example.staging.officelocator.econt.com&city=Sofia&address=ul. rezbarska 5&officeType=office&lang=bg"
            style={{ width: "100%", height: "90vh", borderWidth: 0 }}
          ></iframe> */}
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     gap: "16px",
                     background: "#fff",
                     padding: "24px",
                     borderRadius: "24px",
                  }}
               >
                  <NovaText type={NovaTextTypes.XLNormal}>
                     1. <TranslatedText text="checkout.delivery-data" />
                  </NovaText>
                  <Box sx={{ display: "flex", alignItems: "flex-start", gap: "16px" }}>
                     <Box
                        sx={{
                           display: "flex",
                           width: "100%",
                           flexDirection: "column",
                           gap: "4px",
                        }}
                     >
                        <NovaTextInput
                           label={<TranslatedText text="checkout.form.your-name" />}
                           placeholder={t("checkout.form.your-name")}
                           value={form.name}
                           fullwidth
                           onChange={(event) => {
                              onFieldChange(event, "name");
                           }}
                        />
                        {form.name && form.name.length < 3 && (
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "red", margin: 0 }}
                           >
                              <TranslatedText text="checkout.error.your-name" />
                           </NovaText>
                        )}
                     </Box>
                     <Box
                        sx={{
                           display: "flex",
                           width: "100%",
                           flexDirection: "column",
                           gap: "4px",
                        }}
                     >
                        <NovaTextInput
                           label={<TranslatedText text="checkout.form.your-surname" />}
                           placeholder={t("checkout.form.your-surname")}
                           value={form.surname}
                           fullwidth
                           onChange={(event) => {
                              onFieldChange(event, "surname");
                           }}
                        />
                        {form.surname && form.surname.length < 3 && (
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "red", margin: 0 }}
                           >
                              <TranslatedText text="checkout.error.your-surname" />
                           </NovaText>
                        )}
                     </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "flex-start", gap: "16px" }}>
                     <Box
                        sx={{
                           display: "flex",
                           width: "100%",
                           flexDirection: "column",
                           gap: "4px",
                        }}
                     >
                        <NovaTextInput
                           type="email"
                           label={<TranslatedText text="checkout.form.email" />}
                           placeholder={t("checkout.form.email")}
                           value={form.email}
                           fullwidth
                           onChange={(event) => {
                              onFieldChange(event, "email");
                           }}
                        />
                        {form.email && !checkEmailIsValid(form.email) && (
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "red", margin: 0 }}
                           >
                              <TranslatedText text="checkout.error.email" />
                           </NovaText>
                        )}
                     </Box>
                     <Box
                        sx={{
                           display: "flex",
                           width: "100%",
                           flexDirection: "column",
                           gap: "4px",
                        }}
                     >
                        <NovaTextInput
                           label={<TranslatedText text="checkout.form.phone-number" />}
                           placeholder={t("checkout.form.phone-number")}
                           value={form.phoneNumber}
                           type="number"
                           fullwidth
                           onChange={(event) => {
                              onFieldChange(event, "phoneNumber");
                           }}
                        />
                        {form.phoneNumber && form.phoneNumber.length < 6 && (
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "red", margin: 0 }}
                           >
                              <TranslatedText text="checkout.error.phone-number" />
                           </NovaText>
                        )}
                     </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "flex-start", gap: "16px" }}>
                     <Box
                        sx={{
                           display: "flex",
                           width: "100%",
                           flexDirection: "column",
                           gap: "4px",
                        }}
                     >
                        <NovaTextInput
                           label={<TranslatedText text="checkout.form.address" />}
                           placeholder={t("checkout.form.address")}
                           value={form.address}
                           fullwidth
                           onChange={(event) => {
                              onFieldChange(event, "address");
                           }}
                        />
                        {form.address && form.address.length < 3 && (
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "red", margin: 0 }}
                           >
                              <TranslatedText text="checkout.error.address" />
                           </NovaText>
                        )}
                     </Box>
                     <Box
                        sx={{
                           display: "flex",
                           width: "200px",
                           flexDirection: "column",
                           gap: "4px",
                        }}
                     >
                        <NovaTextInput
                           label={<TranslatedText text="checkout.form.postal-code" />}
                           placeholder={t("checkout.form.postal-code")}
                           value={form.postalCode}
                           type="number"
                           onChange={(event) => {
                              onFieldChange(event, "postalCode");
                           }}
                        />
                        {form.postalCode && form.postalCode.length < 2 && (
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "red", margin: 0 }}
                           >
                              <TranslatedText text="checkout.error.postal-code" />
                           </NovaText>
                        )}
                     </Box>
                  </Box>

                  <Box
                     sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        gap: "4px",
                     }}
                  >
                     <NovaTextInput
                        label={<TranslatedText text="checkout.form.city" />}
                        placeholder={t("checkout.form.city")}
                        value={form.city}
                        fullwidth
                        onChange={(event) => {
                           onFieldChange(event, "city");
                        }}
                     />
                     {form.city && form.city.length < 3 && (
                        <NovaText
                           type={NovaTextTypes.BaseNormal}
                           color="error"
                           style={{ color: "red", margin: 0 }}
                        >
                           <TranslatedText text="checkout.error.city" />
                        </NovaText>
                     )}
                  </Box>
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        marginTop: "0",
                        marginBottom: "0",
                     }}
                  >
                     <TranslatedText text="checkout.form.country" />
                  </NovaText>
                  <Autocomplete
                     options={countries}
                     renderOption={(props, option) => (
                        <Box
                           component="li"
                           sx={{
                              "& > img": { mr: 2, flexShrink: 0 },
                           }}
                           {...props}
                        >
                           <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                           />
                           {option.label} ({option.code})
                        </Box>
                     )}
                     renderInput={(params) => (
                        <TextField
                           value={form?.country && countries[form?.country]}
                           sx={{
                              ".MuiInputBase-root": {
                                 padding: "8.5px 14px",
                                 ".MuiInputBase-input": {
                                    padding: 0,
                                 },
                                 backgroundColor: "#ffffff",
                              },
                           }}
                           {...params}
                           autoComplete="new-password"
                           placeholder={t("checkout.form.select-country")}
                           inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                           }}
                        />
                     )}
                     onChange={(_: any, newValue: any) =>
                        onFieldChange(newValue, "country")
                     }
                  />
                  <NovaText type={NovaTextTypes.XLNormal}>
                     2. <TranslatedText text="checkout.delivery-type" />
                  </NovaText>
                  <FormControl>
                     <RadioGroup
                        value={deliveryType}
                        name="radio-buttons-group"
                        onChange={handleDeliveryTypeChange}
                     >
                        <Box
                           sx={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                           }}
                        >
                           <FormControlLabel
                              sx={{
                                 flex: "1",
                              }}
                              value="toAddress"
                              control={<Radio />}
                              label={
                                 <TranslatedText text="checkout.delivery-types.courier" />
                              }
                           />
                           <Box
                              sx={{
                                 display: "flex",
                                 alignItems: "center",
                                 gap: "8px",
                              }}
                           >
                              <NovaText type={NovaTextTypes.BaseNormal}>0 BGN</NovaText>
                              <LocalShippingOutlined />
                           </Box>
                        </Box>

                        <Box
                           sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                           }}
                        >
                           <Box
                              sx={{
                                 display: "flex",
                                 alignItems: "center",
                              }}
                           >
                              <FormControlLabel
                                 sx={{
                                    flex: "1",
                                 }}
                                 value="ekont"
                                 control={<Radio />}
                                 label={
                                    <TranslatedText text="checkout.delivery-types.ekont" />
                                 }
                              />
                              <Box
                                 sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                 }}
                              >
                                 <NovaText type={NovaTextTypes.BaseNormal}>
                                    0 BGN
                                 </NovaText>
                                 <LocalShippingOutlined />
                              </Box>
                           </Box>
                           {deliveryType === "ekont" && !ekontCities && (
                              <Box sx={{ display: "flex", justifyContent: "center" }}>
                                 <CircularProgress size={29} />
                              </Box>
                           )}
                           {deliveryType === "ekont" && ekontCities && (
                              <Box
                                 sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "6px",
                                 }}
                              >
                                 <Autocomplete
                                    sx={{
                                       flex: 1,
                                    }}
                                    options={ekontCities.map(
                                       (city: any, index: number) => ({
                                          ...city,
                                          key: `${city.name}-${city.id}-${index}`,
                                       }),
                                    )}
                                    getOptionLabel={(city: any) =>
                                       `${city.name}, ${city.regionName}`
                                    }
                                    renderInput={(params) => (
                                       <TextField
                                          sx={{
                                             ".MuiInputBase-root": {
                                                padding: "8.5px 14px",
                                                ".MuiInputBase-input": {
                                                   padding: 0,
                                                },
                                             },
                                          }}
                                          {...params}
                                          autoComplete="new-password"
                                          placeholder={t("checkout.form.select-city")}
                                          inputProps={{
                                             ...params.inputProps,
                                             autoComplete: "new-password",
                                          }}
                                       />
                                    )}
                                    isOptionEqualToValue={(option: any, value: any) =>
                                       option.id === value.id
                                    }
                                    onChange={(_: any, newValue: string | null) => {
                                       handleCitySelect(newValue);
                                    }}
                                 />

                                 <Autocomplete
                                    sx={{
                                       flex: 1,
                                    }}
                                    disabled={!selectedCity}
                                    options={ekontOffices || []}
                                    // getOptionLabel={(office) => office.address.street || ""}
                                    getOptionLabel={(office: any) =>
                                       `${office.address.fullAddress}`
                                    }
                                    // renderOption={(props: any, option: any) => {
                                    //   return (
                                    //     <Box component="li" {...props}>
                                    //       {option.address.fullAddress}
                                    //     </Box>
                                    //   );
                                    // }}
                                    renderInput={(params) => (
                                       <TextField
                                          sx={{
                                             ".MuiInputBase-root": {
                                                padding: "8.5px 14px",
                                                ".MuiInputBase-input": {
                                                   padding: 0,
                                                },
                                             },
                                          }}
                                          {...params}
                                          autoComplete="new-password"
                                          placeholder={t("checkout.form.select-office")}
                                          inputProps={{
                                             ...params.inputProps,
                                             autoComplete: "new-password",
                                          }}
                                       />
                                    )}
                                    value={selectedOffice}
                                    onChange={(_: any, newValue: string | null) => {
                                       handleOfficeSelect(newValue);
                                    }}
                                 />
                              </Box>
                           )}
                        </Box>
                        {/* <Box
                           sx={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                           }}
                        >
                           <FormControlLabel
                              sx={{
                                 flex: "1",
                              }}
                              value="speedy"
                              control={<Radio />}
                              label={
                                 <TranslatedText text="checkout.delivery-types.speedy" />
                              }
                           />
                           <Box
                              sx={{
                                 display: "flex",
                                 alignItems: "center",
                                 gap: "8px",
                              }}
                           >
                              <NovaText type={NovaTextTypes.BaseNormal}>0 BGN</NovaText>
                              <LocalShippingOutlined />
                           </Box>
                        </Box> */}
                     </RadioGroup>
                  </FormControl>

                  <NovaText type={NovaTextTypes.XLNormal}>
                     3. <TranslatedText text="checkout.payment-type" />
                  </NovaText>
                  <FormControl>
                     <RadioGroup defaultValue="card" name="radio-buttons-group">
                        <FormControlLabel
                           value="card"
                           control={<Radio />}
                           label={<TranslatedText text="checkout.pay-with-card" />}
                        />
                     </RadioGroup>
                  </FormControl>
                  <Box
                     sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        background: "#d9ead3",
                        padding: "0 10px",
                        borderRadius: "6px",
                        gap: "8px",
                     }}
                  >
                     <LockOutlined />
                     <NovaText type={NovaTextTypes.BaseNormal}>
                        <TranslatedText text="checkout.ssl-message" />
                     </NovaText>
                  </Box>
               </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
               <Box
                  sx={{
                     position: "sticky",
                     top: "20px",
                     background: "#fff",
                     padding: "24px",
                     borderRadius: "24px",
                  }}
               >
                  <NovaText type={NovaTextTypes.XLNormal} style={{ margin: 0 }}>
                     <TranslatedText text="checkout.summary" />
                  </NovaText>
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        marginTop: "16px",
                     }}
                  >
                     {cart.map((c: any, index: number) => {
                        return (
                           <Box
                              key={index}
                              sx={{
                                 display: "flex",
                                 alignItems: "start",
                                 gap: "16px",
                              }}
                           >
                              <Box
                                 sx={{
                                    background: `url(${c.product.photos[0].photoUrl})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                    backgroundRepeat: "no-repeat",
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: "12px",
                                 }}
                              />
                              <Box>
                                 <NovaTitle
                                    variant="h4"
                                    style={{
                                       margin: 0,
                                       lineHeight: "24px",
                                    }}
                                 >
                                    {c.quantity} x {c.product.name}
                                 </NovaTitle>
                                 <NovaText
                                    type={NovaTextTypes.BaseNormal}
                                    style={{
                                       margin: 0,
                                       lineHeight: "24px",
                                    }}
                                 >
                                    <TranslatedText text="single-product.metal-medallion" />
                                 </NovaText>
                                 <div
                                    style={{
                                       display: "flex",
                                       alignItems: "center",
                                       marginTop: "16px",
                                    }}
                                 >
                                    <div
                                       style={{
                                          height: "2px",
                                          width: "30px",
                                          background: "#CAA45D",
                                          display: "inline-block",
                                       }}
                                    />
                                    <NovaText
                                       type={NovaTextTypes.LGBold}
                                       style={{
                                          margin: "0 0 0 10px",
                                          color: "#CAA45D",
                                       }}
                                    >
                                       {c.totalPrice} {c.currency}
                                    </NovaText>
                                 </div>
                              </Box>
                           </Box>
                        );
                     })}
                  </Box>
                  <Divider sx={{ marginTop: "16px" }} />
                  <Box sx={{ marginTop: "8px" }}>
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "space-between",
                        }}
                     >
                        <NovaText type={NovaTextTypes.MediumLight} style={{ margin: 0 }}>
                           <TranslatedText text="cart.products-price" />
                        </NovaText>
                        <NovaText type={NovaTextTypes.MediumLight} style={{ margin: 0 }}>
                           {totalPrice} {currency}
                        </NovaText>
                     </Box>
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "space-between",
                        }}
                     >
                        <NovaText type={NovaTextTypes.MediumLight} style={{ margin: 0 }}>
                           <TranslatedText text="cart.delivery-price" />
                        </NovaText>
                        <NovaText type={NovaTextTypes.MediumLight} style={{ margin: 0 }}>
                           0 {currency}
                        </NovaText>
                     </Box>
                     <Divider sx={{ marginTop: "8px" }} />
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "space-between",
                        }}
                     >
                        <NovaText
                           type={NovaTextTypes.MediumLight}
                           style={{ margin: "8px 0 0 0", fontWeight: "900" }}
                        >
                           <TranslatedText text="cart.total" />
                        </NovaText>
                        <NovaText
                           type={NovaTextTypes.MediumLight}
                           style={{ margin: "8px 0 0 0", fontWeight: "900" }}
                        >
                           {totalPrice} {currency} <TranslatedText text="cart.vat" />
                        </NovaText>
                     </Box>
                  </Box>
                  <FormGroup
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        padding: "16px 0",
                     }}
                  >
                     <FormControlLabel
                        checked={form.terms}
                        sx={{
                           display: "flex",
                           alignItems: "flex-start",
                           userSelect: "none",
                           ".MuiButtonBase-root": {
                              paddingTop: "0",
                              paddingBottom: "0",
                           },
                        }}
                        control={<Checkbox onChange={onTermsChange} />}
                        label={<TranslatedText text="agreements.terms" />}
                     />
                     <FormControlLabel
                        checked={form.marketing}
                        sx={{
                           display: "flex",
                           alignItems: "flex-start",
                           userSelect: "none",
                        }}
                        control={<Checkbox onChange={onMarketingChange} />}
                        label={<TranslatedText text="agreements.marketing" />}
                     />
                  </FormGroup>
                  <NovaButton
                     disabled={
                        form.name.length < 3 ||
                        form.surname.length < 3 ||
                        !form.email ||
                        !checkEmailIsValid(form.email) ||
                        form.phoneNumber.length < 6 ||
                        form.address.length < 3 ||
                        form.postalCode.length < 2 ||
                        form.city.length < 3 ||
                        !form.country ||
                        !form.orderItems ||
                        !form.terms ||
                        (deliveryType === "ekont" && (!selectedCity || !selectedOffice))
                     }
                     style={{
                        borderRadius: "32px",
                     }}
                     onClick={handleCreateOrder}
                  >
                     <TranslatedText text="buttons.order-and-pay" />
                  </NovaButton>
               </Box>
            </Grid>
         </Grid>
      </Container>
   );
};

export default Checkout;
