import api from "./withAuth";

const getProducts = async () => {
   return await api.get("products/all");
};

const getProduct = async (id: any) => {
   return await api.get(`products/${id}`);
};

const createProduct = async (product: any) => {
   return await api.post("products/create", {
      ...product,
   });
};

const deleteProduct = async (id: any) => {
   return await api.delete(`products/${id}`);
};

const setProductAvailability = async (id: any, status: boolean) => {
   return await api.put(`products/${id}/setavailability/${status}`);
};

const editProduct = async (changes: any) => {
   return await api.put("products", {
      ...changes,
   });
};

const editProductPhoto = async (changes: any) => {
   return await api.put("products/photo", {
      ...changes,
   });
};

const addProductPhoto = async (changes: any) => {
   return await api.post("products/photos/add", {
      ...changes,
   });
};

const deleteProductPhoto = async (photoId: number) => {
   return await api.delete(`products/photos/${photoId}`);
};

export const productsAPI = {
   getProducts,
   getProduct,
   createProduct,
   deleteProduct,
   setProductAvailability,
   editProduct,
   editProductPhoto,
   addProductPhoto,
   deleteProductPhoto,
};
