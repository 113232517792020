import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import Order from "./Order";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import TranslatedText from "../translated-text";
import { useAppDispatch } from "../../store/hooks";
import { OrdersSelectors } from "../../pages/orders/selectors";
import { getOrders } from "../../pages/orders/async-actions";
import DetailsModal from "./details-modal";
import NovaLink from "../shared/NovaLink";

export enum OrderType {
   INCOMPLETE,
   SUMMARY,
}
const OrdersContainer = () => {
   const dispatch = useAppDispatch();
   const orders = useSelector(OrdersSelectors.getOrdersList);

   useEffect(() => {
      dispatch(getOrders());
   }, []);

   const [isModalOpen, setIsModalOpen] = useState(false);
   const [selectedOrder, setSelectedOrder] = useState<any>(null);

   const handleOpenModal = (orderId: any) => {
      setSelectedOrder(orderId);
      setIsModalOpen(true);
   };

   const handleCloseDetailsModal = () => {
      setIsModalOpen(false);
      setSelectedOrder(null);
   };

   if (!orders || orders.length < 1) {
      return (
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               height: "300px",
            }}
         >
            <NovaText
               type={NovaTextTypes.MediumNormal}
               style={{
                  fontStyle: "italic",
                  textAlign: "center",
               }}
            >
               <TranslatedText html text="home.orders.no-orders" />
               &nbsp;
               <NovaLink
                  to="../products"
                  style={{
                     color: "#CAA45D",
                  }}
               >
                  <TranslatedText html text="home.orders.products" />
               </NovaLink>
               .
            </NovaText>
         </Box>
      );
   }
   return (
      <>
         <Box sx={{ marginTop: "24px", padding: "16px 16px 0 16px" }}>
            <NovaText type={NovaTextTypes.MediumNormal} style={{ margin: "0 0 24px 0" }}>
               <TranslatedText text="home.orders.summary" />
            </NovaText>

            <Grid container spacing={2}>
               {orders.map((order: any, index: any) => {
                  return (
                     <Grid item xs={12} key={index}>
                        <Order
                           order={order}
                           type={OrderType.SUMMARY}
                           onDetailsClick={handleOpenModal}
                        />
                     </Grid>
                  );
               })}
            </Grid>
         </Box>

         {isModalOpen && (
            <DetailsModal orderId={selectedOrder} onClose={handleCloseDetailsModal} />
         )}
      </>
   );
};

export default OrdersContainer;
