import {
   GET_MEMORIAL_PAGE,
   GET_MEMORIAL_PAGE_FAILED,
   GET_MEMORIAL_PAGE_SUCCESS,
} from "./types";

const getMemorialPage = (payload: any) => ({
   type: GET_MEMORIAL_PAGE,
   payload,
});

const getMemorialPageSuccess = () => ({
   type: GET_MEMORIAL_PAGE_SUCCESS,
});
const getMemorialPageFailed = () => ({
   type: GET_MEMORIAL_PAGE_FAILED,
});

export const SingleMemorialPageActions = {
   getMemorialPage,
   getMemorialPageSuccess,
   getMemorialPageFailed,
};
