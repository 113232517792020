import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useMemo, useState, MouseEvent } from "react";

import { Button, Chip, FormControlLabel, Menu, MenuItem, Switch } from "@mui/material";
import { ChevronLeft, ChevronRight, DeleteOutline } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import NovaText, { NovaTextTypes } from "../shared/NovaText";

export const ORDER_STATUS = [
   "Pending",
   "Processing",
   "Shipped",
   "Delivered",
   "Cancelled",
   "Refunded",
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
   if (b[orderBy] < a[orderBy]) {
      return -1;
   }
   if (b[orderBy] > a[orderBy]) {
      return 1;
   }
   return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
   order: Order,
   orderBy: Key,
): (
   a: { [key in Key]: number | string },
   b: { [key in Key]: number | string },
) => number {
   return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
   const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
   stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
         return order;
      }
      return a[1] - b[1];
   });
   return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
   columns: any;
   numSelected: number;
   onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
   onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
   order: Order;
   orderBy: string;
   rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
   const {
      columns,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
   } = props;
   const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
   };

   return (
      <TableHead
         sx={{
            background: "rgba(0, 0, 0, 6%)",
         }}
      >
         <TableRow>
            {columns.map((column: any) => (
               <TableCell
                  key={column.id}
                  align={column.numeric ? "right" : "left"}
                  padding={column.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === column.id ? order : false}
               >
                  <TableSortLabel
                     active={orderBy === column.id}
                     direction={orderBy === column.id ? order : "asc"}
                     onClick={createSortHandler(column.id)}
                  >
                     {column.label}
                     {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                           {order === "desc" ? "sorted descending" : "sorted ascending"}
                        </Box>
                     ) : null}
                  </TableSortLabel>
               </TableCell>
            ))}
            <TableCell />
         </TableRow>
      </TableHead>
   );
}

interface EnhancedTableToolbarProps {
   numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
   const { numSelected } = props;
   return (
      <Toolbar
         sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
               bgcolor: (theme) =>
                  alpha(
                     theme.palette.primary.main,
                     theme.palette.action.activatedOpacity,
                  ),
            }),
         }}
      >
         {numSelected > 0 && (
            <Typography
               sx={{ flex: "1 1 100%" }}
               color="inherit"
               variant="subtitle1"
               component="div"
            >
               {numSelected} selected
            </Typography>
         )}
         {numSelected > 0 && (
            <Tooltip title="Delete">
               <IconButton>
                  <DeleteIcon />
               </IconButton>
            </Tooltip>
         )}
      </Toolbar>
   );
}
const OrdersTable = ({
   hasActions = true,
   columns,
   rows,
   rowsSelectedCallback,
}: {
   hasActions?: boolean;
   columns: any;
   rows: any;
   rowsSelectedCallback: (selectedCount: any) => void;
}) => {
   const dispatch = useAppDispatch();
   const [order, setOrder] = useState<Order>("asc");
   const [orderBy, setOrderBy] = useState("id");
   const [selected, setSelected] = useState<readonly number[]>([]);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(12);

   const [anchorEl, setAnchorEl] = useState<{
      [key: number]: HTMLElement | null;
   }>({});
   const open = Boolean(anchorEl);
   const handleRowSettingsClick = (event: MouseEvent<HTMLElement>, id: any) => {
      event.stopPropagation();
      setAnchorEl((prevState) => ({
         ...prevState,
         [id]: event.currentTarget,
      }));
   };

   const handleRowSettingClose = (event: MouseEvent<HTMLElement>, id: any) => {
      event.stopPropagation();
      setAnchorEl((prevState) => ({
         ...prevState,
         [id]: null,
      }));
   };

   const handleDelete = (event: any, id: any) => {
      handleRowSettingClose(event, id);
      // dispatch(deleteProduct(id));
   };

   const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
   };

   const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
         const newSelected = rows.map((n: any) => n.id);
         setSelected(newSelected);
         return;
      }
      setSelected([]);
   };

   const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected: readonly number[] = [];

      if (selectedIndex === -1) {
         newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
         newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
         );
      }

      setSelected(newSelected);
   };

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   const isSelected = (id: number) => selected.indexOf(id) !== -1;

   // Avoid a layout jump when reaching the last page with empty rows.
   const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

   // const visibleRows = useMemo(
   //   () =>
   //     stableSort(rows, getComparator(order, orderBy)).slice(
   //       page * rowsPerPage,
   //       page * rowsPerPage + rowsPerPage
   //     ),
   //   [order, orderBy, page, rowsPerPage]
   // );

   useEffect(() => {
      rowsSelectedCallback(selected.length);
   }, [selected]);

   const handleFirstPageButtonClick = () => {
      setPage(0);
   };

   const handleBackButtonClick = () => {
      setPage((prevPage) => prevPage - 1);
   };

   const handleNextButtonClick = () => {
      setPage((prevPage) => prevPage + 1);
   };

   const handleLastPageButtonClick = () => {
      setPage(Math.max(0, Math.ceil(rows.length / rowsPerPage) - 1));
   };

   const totalPages = Math.ceil(rows.length / rowsPerPage);
   let startPage = Math.max(0, page - 2);
   let endPage = Math.min(startPage + 5, totalPages - 1);
   if (endPage - startPage < 5) {
      startPage = Math.max(0, endPage - 5);
   }

   const navigate = useNavigate();
   const handleRowClick = (event: any, id: number) => {
      return navigate(`../orders/${id}`);
   };

   const onSwitchChange = (id: any, status: any) => {
      // dispatch(setProductAvailability(id, !status));
   };

   return (
      <Box sx={{ width: "100%" }}>
         <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
            <TableContainer>
               <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                  <EnhancedTableHead
                     columns={columns}
                     numSelected={selected.length}
                     order={order}
                     orderBy={orderBy}
                     onSelectAllClick={handleSelectAllClick}
                     onRequestSort={handleRequestSort}
                     rowCount={rows.length}
                  />
                  <TableBody>
                     {rows.map((row: any, index: any) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const rowKeys = [
                           "status",
                           "address",
                           "city",
                           "phoneNumber",
                           "amount",
                           "orderItemsCount",
                           "actions",
                        ];
                        return (
                           <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                              sx={{ cursor: "pointer" }}
                           >
                              {rowKeys.map((key, index) => {
                                 if (key === "id") return null;
                                 if (key === "status") {
                                    return (
                                       <TableCell
                                          align="left"
                                          key={index}
                                          onClick={(event) =>
                                             handleRowClick(event, row.id)
                                          }
                                       >
                                          <Chip
                                             label={ORDER_STATUS[row[key]]}
                                             sx={{
                                                height: "28px",
                                             }}
                                          />
                                       </TableCell>
                                    );
                                 }
                                 if (key === "amount") {
                                    return (
                                       <TableCell
                                          align="left"
                                          key={index}
                                          onClick={(event) =>
                                             handleRowClick(event, row.id)
                                          }
                                       >
                                          {row[key]}&nbsp;{row.currency}
                                       </TableCell>
                                    );
                                 }
                                 return (
                                    <TableCell
                                       align="left"
                                       key={index}
                                       onClick={(event) => handleRowClick(event, row.id)}
                                    >
                                       {row[key]}
                                    </TableCell>
                                 );
                              })}
                              {hasActions && (
                                 <TableCell align="right">
                                    <IconButton
                                       onClick={(event) => handleDelete(event, row.id)}
                                    >
                                       <DeleteOutline />
                                    </IconButton>
                                 </TableCell>
                              )}
                           </TableRow>
                        );
                     })}
                     {emptyRows > 0 && (
                        <TableRow
                           style={{
                              height: 53 * emptyRows,
                           }}
                        >
                           <TableCell colSpan={6} />
                        </TableRow>
                     )}
                  </TableBody>
               </Table>
            </TableContainer>
            <Box
               sx={{
                  width: "100%",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "16px 0",
                  gap: "8px",
               }}
            >
               <IconButton
                  sx={{
                     minWidth: "32px",
                     width: "32px",
                     height: "32px",
                  }}
                  disabled={page === 0}
                  onClick={handleBackButtonClick}
               >
                  <ChevronLeft />
               </IconButton>
               {Array.from(Array(endPage - startPage + 1).keys()).map((pageNumber) => (
                  <Button
                     key={startPage + pageNumber}
                     onClick={() => handleChangePage(null, startPage + pageNumber)}
                     aria-label={`page ${startPage + pageNumber + 1}`}
                     disabled={startPage + pageNumber === page}
                     sx={{
                        minWidth: "32px",
                        height: "32px",
                        padding: "0",
                        color: "rgba(0, 0, 0, 88%)",
                        ...(startPage + pageNumber === page && {
                           color: `#000 !important`,
                           border: `1px solid #000`,
                        }),
                     }}
                  >
                     {startPage + pageNumber + 1}
                  </Button>
               ))}
               <IconButton
                  sx={{
                     minWidth: "32px",
                     width: "32px",
                     height: "32px",
                  }}
                  disabled={page >= totalPages - 1}
                  onClick={handleNextButtonClick}
               >
                  <ChevronRight />
               </IconButton>
            </Box>
         </Paper>
      </Box>
   );
};

export default OrdersTable;
