import { AddCircleOutline, Close, KeyboardBackspace } from "@mui/icons-material";
import {
   Box,
   Container,
   FormControl,
   FormControlLabel,
   MenuItem,
   Select,
   Switch,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NovaTextInput from "../../components/shared/NovaTextInput";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import NovaButton from "../../components/shared/NovaButton";
import { useAppDispatch } from "../../store/hooks";
import { createSubscriptionPlan } from "./async-actions";

export const PLAN_TYPE = ["Monthly", "Yearly", "Lifetime"];
export const CLIENT_TYPE = ["Affiliate", "Subscriber"];
export const PLAN_ORDER = [0, 1, 2];

const CreatePlan = () => {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const [plan, setPlan] = useState<any>({
      name: "",
      data: {
         mostPopular: false,
         features: [],
         order: null,
         clientType: 0,
      },
      priceForAffiliate: 0,
      type: 0,
      stripeId: "",
   });

   const onFieldChange = (event: any, field: string) => {
      return setPlan({ ...plan, [field]: event.target.value });
   };

   const handlePlanTypeChange = (event: any) => {
      setPlan({ ...plan, type: event.target.value });
   };

   const onDataChange = (event: any, field: string) => {
      if (field === "mostPopular") {
         return setPlan((prevPlan: any) => ({
            ...prevPlan,
            data: {
               ...prevPlan.data,
               [field]: event.target.checked,
            },
         }));
      }
      return setPlan((prevPlan: any) => ({
         ...prevPlan,
         data: {
            ...prevPlan.data,
            [field]: event.target.value,
         },
      }));
   };

   const handleAddFeature = () => {
      const newFeature = { en: "", bg: "" };
      setPlan((prevPlan: any) => ({
         ...prevPlan,
         data: {
            ...prevPlan.data,
            features: [...prevPlan.data.features, newFeature],
         },
      }));
   };

   const handleFeatureChange = (event: any, index: any, lang: any) => {
      setPlan((prevPlan: any) => ({
         ...prevPlan,
         data: {
            ...prevPlan.data,
            features: prevPlan.data.features.map((feature: any, idx: any) =>
               idx === index ? { ...feature, [lang]: event.target.value } : feature,
            ),
         },
      }));
   };

   const handleCreatePlan = () => {
      const { data, ...otherPlanProps } = plan;

      const planForAPI = {
         ...otherPlanProps,
         data: JSON.stringify(data).replace(/"/g, `\"`),
      };

      dispatch(createSubscriptionPlan(planForAPI, navigate));
   };

   const handleFeatureDelete = (index: any) => {
      setPlan((prevPlan: any) => ({
         ...prevPlan,
         data: {
            ...prevPlan.data,
            features: prevPlan.data.features.filter((_: any, idx: any) => idx !== index),
         },
      }));
   };

   return (
      <Container
         sx={{
            padding: "32px 0 120px 0",
         }}
      >
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between",
               padding: "24px 0",
            }}
         >
            <Link
               to="../subscriptions"
               style={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "8px",
                  textDecoration: "none",
                  color: "#1B1B48",
                  borderRadius: "32px",
                  background: "#fff",
                  padding: "12px 24px",
                  boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.05)",
               }}
            >
               <KeyboardBackspace />
               Back to subscriptions
            </Link>
         </Box>
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               gap: "16px",
            }}
         >
            <NovaTextInput
               label="Plan name"
               placeholder="Type your plan name"
               value={plan.name}
               fullwidth
               onChange={(event) => {
                  onFieldChange(event, "name");
               }}
            />
            <Box>
               <NovaText
                  type={NovaTextTypes.BaseNormal}
                  style={{
                     margin: "16px 0 0 0",
                  }}
               >
                  Most popular plan
               </NovaText>
               <FormControlLabel
                  sx={{
                     margin: 0,
                  }}
                  value={plan.data.mostPopular}
                  control={
                     <Switch
                        checked={plan.data.mostPopular}
                        onChange={(event) => onDataChange(event, "mostPopular")}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                           height: "35px",
                           padding: 0,
                           display: "flex",
                           marginLeft: "16px",
                           "&.MuiSwitch-root": {
                              margin: 0,
                           },
                           "& .MuiSwitch-switchBase": {
                              "&.Mui-checked": {
                                 color: "#CAA45D",
                                 "& + .MuiSwitch-track": {
                                    opacity: 1,
                                    border: "1px solid #CAA45D",
                                    background: "#fff",
                                 },
                              },
                           },
                           "& .MuiSwitch-track": {
                              borderRadius: 20 / 2,
                              backgroundColor: "#E9E9EA",
                              opacity: 1,
                           },
                           "& .MuiSwitch-thumb": {
                              boxShadow: "none",
                           },
                           ".MuiButtonBase-root": {
                              height: "35px",
                           },
                           ".MuiButtonBase-root.Mui-checked": {
                              padding: "8px 12px",
                           },
                        }}
                     />
                  }
                  label=""
                  labelPlacement="start"
               />
            </Box>
            <Box>
               <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
                  Select plan order
               </NovaText>
               <FormControl sx={{ minWidth: 250 }} size="small">
                  <Select
                     value={plan.order}
                     onChange={(event) => onDataChange(event, "order")}
                  >
                     {PLAN_ORDER.map((order: any, index: number) => {
                        return (
                           <MenuItem value={index} key={index}>
                              {order}
                           </MenuItem>
                        );
                     })}
                  </Select>
               </FormControl>
            </Box>
            <Box>
               <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
                  Select plan type
               </NovaText>
               <FormControl sx={{ minWidth: 250 }} size="small">
                  <Select value={plan.type} onChange={handlePlanTypeChange}>
                     {PLAN_TYPE.map((type: any, index: number) => {
                        return (
                           <MenuItem value={index} key={index}>
                              {type}
                           </MenuItem>
                        );
                     })}
                  </Select>
               </FormControl>
            </Box>

            <Box>
               <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
                  Client type
               </NovaText>
               <FormControl sx={{ minWidth: 250 }} size="small">
                  <Select
                     value={plan.data.clientType}
                     onChange={(event: any) => onDataChange(event, "clientType")}
                  >
                     {CLIENT_TYPE.map((type: any, index: number) => {
                        return (
                           <MenuItem value={index} key={index}>
                              {type}
                           </MenuItem>
                        );
                     })}
                  </Select>
               </FormControl>
            </Box>
            <NovaTextInput
               label="Stripe ID"
               placeholder="Type your stripe id"
               value={plan.stripeId}
               fullwidth
               onChange={(event) => {
                  onFieldChange(event, "stripeId");
               }}
            />
         </Box>
         <Box sx={{ marginTop: "16px" }}>
            <NovaTextInput
               label="Price"
               placeholder="Type the commission amount"
               value={plan.priceForAffiliate}
               fullwidth
               onChange={(event) => {
                  onFieldChange(event, "priceForAffiliate");
               }}
            />
         </Box>
         <NovaText
            type={NovaTextTypes.BaseNormal}
            style={{
               margin: "16px 0 0 0",
            }}
         >
            Plan features
         </NovaText>

         {plan.data.features.map((feature: any, index: any) => (
            <Box
               sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
               }}
               key={index}
            >
               <Box sx={{ display: "flex", alignItems: "center", flex: 1, gap: "8px" }}>
                  <NovaText type={NovaTextTypes.BaseNormal}>EN</NovaText>
                  <NovaTextInput
                     fullwidth
                     value={feature.en}
                     placeholder="Add feature"
                     onChange={(event) => handleFeatureChange(event, index, "en")}
                  />
               </Box>
               <Box sx={{ display: "flex", alignItems: "center", flex: 1, gap: "8px" }}>
                  <NovaText type={NovaTextTypes.BaseNormal}>BG</NovaText>
                  <NovaTextInput
                     fullwidth
                     value={feature.bg}
                     placeholder="Add feature"
                     onChange={(event) => handleFeatureChange(event, index, "bg")}
                  />
               </Box>
               <Close onClick={() => handleFeatureDelete(index)} />
            </Box>
         ))}
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
            }}
         >
            <NovaButton
               variant="outlined"
               onClick={handleAddFeature}
               startIcon={<AddCircleOutline />}
               style={{ marginTop: "16px" }}
            >
               Add feature
            </NovaButton>

            <NovaButton
               variant="outlined"
               onClick={handleCreatePlan}
               style={{ marginTop: "16px" }}
            >
               Create Plan
            </NovaButton>
         </Box>
      </Container>
   );
};
export default CreatePlan;
