import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Container, Tab, Tabs } from "@mui/material";
import Plans from "../../components/subscriptions/plans";
import Subscribers from "../../components/subscriptions/subscribers";

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

const Subscriptions = () => {
   const [value, setValue] = useState(0);
   const Panel = (props: TabPanelProps) => {
      const { children, value, index, ...other } = props;
      return (
         <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
         >
            {value === index && <Box>{children}</Box>}
         </div>
      );
   };

   const a11yProps = (index: number) => {
      return {
         id: `simple-tab-${index}`,
         "aria-controls": `simple-tabpanel-${index}`,
      };
   };

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };
   return (
      <Container
         sx={{
            padding: "32px 0 120px 0",
         }}
      >
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between",
               padding: "24px 0",
            }}
         >
            <Tabs
               value={value}
               sx={{
                  "& .MuiButtonBase-root.Mui-selected": {
                     color: "#fff",
                     background: "#1B1B48",
                     borderRadius: "32px",
                  },
                  "& .MuiTabs-indicator": {
                     display: "none",
                  },
               }}
               onChange={handleChange}
            >
               <Tab label="Plans" {...a11yProps(0)} sx={{ textTransform: "none" }} />
               <Tab
                  label="Subscribers"
                  {...a11yProps(1)}
                  sx={{ textTransform: "none" }}
               />
            </Tabs>
            <Box>
               <Link
                  to="../create-plan"
                  style={{
                     background: "#CAA45D",
                     color: "#fff",
                     padding: "14px 24px",
                     textDecoration: "none",
                     borderRadius: "32px",
                  }}
               >
                  Create
               </Link>
            </Box>
         </Box>
         <Box>
            <Panel value={value} index={0}>
               <Plans />
            </Panel>
            <Panel value={value} index={1}>
               <Subscribers />
            </Panel>
         </Box>
      </Container>
   );
};

export default Subscriptions;
