export const DetailsModalFlower = () => {
   return (
      <svg
         width="470"
         height="587"
         viewBox="0 0 470 587"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M198.145 -34.3731C205.223 -4.80702 221.554 5.85555 242.015 26.7031C245.875 30.3312 235.976 30.4544 233.897 31.9613C225.334 34.6328 216.018 36.3911 207.929 39.9173C188.614 46.6747 173.659 60.0726 157.992 72.062C156.695 72.9955 156.141 72.4992 155.675 71.3305C153.676 65.6311 151.167 60.0875 149.299 54.3678C147.222 49.9918 145.354 45.5216 143.731 40.9826C141.188 36.7506 139.521 32.0968 137.031 27.8237C131.664 19.7827 124.215 13.3582 117.978 6.00137C115.863 3.79779 113.629 1.6966 111.749 -0.697008C110.1 -3.10787 104.103 -6.0434 104.872 -8.85027C142.992 -20.5564 149.057 -17.7769 180.467 -44.7005C194.656 -57.2363 189.703 -55.1946 198.154 -34.3784L198.145 -34.3731Z"
            fill="#F3F5F7"
         />
         <path
            d="M471.957 363.159C479.105 344.034 474.008 331.969 470.431 312.68C469.642 309.211 475.202 312.856 476.926 312.796C482.698 314.521 488.547 317.036 494.375 318.106C507.669 321.588 521.026 319.735 534.251 318.934C535.323 318.9 535.446 319.385 535.267 320.21C534.242 324.135 533.56 328.164 532.455 332.051C531.971 335.268 531.334 338.459 530.535 341.596C530.364 344.907 529.547 348.125 529.331 351.439C529.304 357.932 531.044 364.305 531.758 370.743C532.109 372.763 532.566 374.772 532.715 376.81C532.729 378.772 534.968 382.657 533.487 383.932C507.861 376.152 505.526 372.329 477.929 375.543C465.323 377.203 468.848 377.924 471.949 363.158L471.957 363.159Z"
            fill="#F3F5F7"
         />
         <path
            d="M471.957 363.159C479.105 344.034 474.008 331.969 470.431 312.68C469.642 309.211 475.202 312.856 476.926 312.796C482.698 314.521 488.547 317.036 494.375 318.106C507.669 321.588 521.026 319.735 534.251 318.934C535.323 318.9 535.446 319.385 535.267 320.21C534.242 324.135 533.56 328.164 532.455 332.051C531.971 335.268 531.334 338.459 530.535 341.596C530.364 344.907 529.547 348.125 529.331 351.439C529.304 357.932 531.044 364.305 531.758 370.743C532.109 372.763 532.566 374.772 532.715 376.81C532.729 378.772 534.968 382.657 533.487 383.932C507.861 376.152 505.526 372.329 477.929 375.543C465.323 377.203 468.848 377.924 471.949 363.158L471.957 363.159Z"
            fill="#F3F5F7"
         />
         <path
            d="M471.957 363.159C479.105 344.034 474.008 331.969 470.431 312.68C469.642 309.211 475.202 312.856 476.926 312.796C482.698 314.521 488.547 317.036 494.375 318.106C507.669 321.588 521.026 319.735 534.251 318.934C535.323 318.9 535.446 319.385 535.267 320.21C534.242 324.135 533.56 328.164 532.455 332.051C531.971 335.268 531.334 338.459 530.535 341.596C530.364 344.907 529.547 348.125 529.331 351.439C529.304 357.932 531.044 364.305 531.758 370.743C532.109 372.763 532.566 374.772 532.715 376.81C532.729 378.772 534.968 382.657 533.487 383.932C507.861 376.152 505.526 372.329 477.929 375.543C465.323 377.203 468.848 377.924 471.949 363.158L471.957 363.159Z"
            fill="#F3F5F7"
         />
         <path
            d="M399.317 337.029C409.298 330.447 411.05 322.98 416.014 312.62C416.837 310.707 418.21 314.35 419.046 314.916C421.182 317.724 423.081 320.932 425.469 323.449C430.558 329.684 437.516 333.416 444.049 337.6C444.568 337.954 444.459 338.226 444.089 338.555C442.25 340.058 440.538 341.729 438.674 343.186C437.334 344.541 435.932 345.831 434.472 347.04C433.248 348.547 431.752 349.788 430.506 351.281C428.253 354.343 426.877 357.959 424.993 361.251C424.462 362.328 423.985 363.436 423.352 364.452C422.682 365.385 422.401 367.996 421.26 368.088C411.821 355.565 412.035 352.95 397.87 344.948C391.333 341.384 392.752 342.941 399.314 337.026L399.317 337.029Z"
            fill="#F3F5F7"
         />
         <path
            d="M399.317 337.029C409.298 330.447 411.05 322.98 416.014 312.62C416.837 310.707 418.21 314.35 419.046 314.916C421.182 317.724 423.081 320.932 425.469 323.449C430.558 329.684 437.516 333.416 444.049 337.6C444.568 337.954 444.459 338.226 444.089 338.555C442.25 340.058 440.538 341.729 438.674 343.186C437.334 344.541 435.932 345.831 434.472 347.04C433.248 348.547 431.752 349.788 430.506 351.281C428.253 354.343 426.877 357.959 424.993 361.251C424.462 362.328 423.985 363.436 423.352 364.452C422.682 365.385 422.401 367.996 421.26 368.088C411.821 355.565 412.035 352.95 397.87 344.948C391.333 341.384 392.752 342.941 399.314 337.026L399.317 337.029Z"
            fill="#F3F5F7"
         />
         <path
            d="M399.317 337.029C409.298 330.447 411.05 322.98 416.014 312.62C416.837 310.707 418.21 314.35 419.046 314.916C421.182 317.724 423.081 320.932 425.469 323.449C430.558 329.684 437.516 333.416 444.049 337.6C444.568 337.954 444.459 338.226 444.089 338.555C442.25 340.058 440.538 341.729 438.674 343.186C437.334 344.541 435.932 345.831 434.472 347.04C433.248 348.547 431.752 349.788 430.506 351.281C428.253 354.343 426.877 357.959 424.993 361.251C424.462 362.328 423.985 363.436 423.352 364.452C422.682 365.385 422.401 367.996 421.26 368.088C411.821 355.565 412.035 352.95 397.87 344.948C391.333 341.384 392.752 342.941 399.314 337.026L399.317 337.029Z"
            fill="#F3F5F7"
         />
         <path
            d="M428.93 288.42C429.889 260.065 417.864 246.466 404.649 222.686C402.081 218.474 410.999 220.786 413.239 219.938C421.589 219.629 430.388 220.327 438.523 219.132C457.538 217.776 474.28 209.374 491.313 202.414C492.709 201.891 493.083 202.473 493.212 203.64C493.597 209.261 494.479 214.867 494.741 220.474C495.523 224.923 496.094 229.405 496.429 233.89C497.665 238.323 498.011 242.921 499.189 247.379C502.019 255.933 507.118 263.541 510.898 271.692C512.251 274.194 513.738 276.633 514.833 279.249C515.717 281.823 520.376 285.935 518.991 288.274C481.868 289.482 477.11 285.494 442.247 302.047C426.406 309.861 431.358 309.235 428.92 288.423L428.93 288.42Z"
            fill="#F3F5F7"
         />
         <path
            d="M428.93 288.42C429.889 260.065 417.864 246.466 404.649 222.686C402.081 218.474 410.999 220.786 413.239 219.938C421.589 219.629 430.388 220.327 438.523 219.132C457.538 217.776 474.28 209.374 491.313 202.414C492.709 201.891 493.083 202.473 493.212 203.64C493.597 209.261 494.479 214.867 494.741 220.474C495.523 224.923 496.094 229.405 496.429 233.89C497.665 238.323 498.011 242.921 499.189 247.379C502.019 255.933 507.118 263.541 510.898 271.692C512.251 274.194 513.738 276.633 514.833 279.249C515.717 281.823 520.376 285.935 518.991 288.274C481.868 289.482 477.11 285.494 442.247 302.047C426.406 309.861 431.358 309.235 428.92 288.423L428.93 288.42Z"
            fill="#F3F5F7"
         />
         <path
            d="M428.93 288.42C429.889 260.065 417.864 246.466 404.649 222.686C402.081 218.474 410.999 220.786 413.239 219.938C421.589 219.629 430.388 220.327 438.523 219.132C457.538 217.776 474.28 209.374 491.313 202.414C492.709 201.891 493.083 202.473 493.212 203.64C493.597 209.261 494.479 214.867 494.741 220.474C495.523 224.923 496.094 229.405 496.429 233.89C497.665 238.323 498.011 242.921 499.189 247.379C502.019 255.933 507.118 263.541 510.898 271.692C512.251 274.194 513.738 276.633 514.833 279.249C515.717 281.823 520.376 285.935 518.991 288.274C481.868 289.482 477.11 285.494 442.247 302.047C426.406 309.861 431.358 309.235 428.92 288.423L428.93 288.42Z"
            fill="#F3F5F7"
         />
      </svg>
   );
};
