import { Box, Container, IconButton } from "@mui/material";
import NovaTitle from "../../components/shared/NovaTitle";
import NovaTextInput from "../../components/shared/NovaTextInput";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import { Close } from "@mui/icons-material";
import NovaButton from "../../components/shared/NovaButton";
import { useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { validateVoucher } from "./async-actions";
import TranslatedText from "../../components/translated-text";

const RegisterVoucher = () => {
   const dispatch = useAppDispatch();
   const [voucher, setVoucher] = useState("");
   const [voucherRegisterMessage, setVoucherRegisterMessage] = useState<any>(null);

   const onVoucherValidate = () => {
      setVoucher("");
      dispatch(validateVoucher(voucher, setVoucherRegisterMessage));
   };

   return (
      <Container
         sx={{
            padding: "80px 10px",
            display: "flex",
            flexDirection: "column",
            gap: "32px",
         }}
      >
         <Box
            sx={{
               background: "#fff",
               borderRadius: "16px",
               padding: "40px 32px",
               position: "relative",
            }}
         >
            <NovaTitle
               variant="h3"
               style={{
                  margin: 0,
                  color: "#1B1B48",
               }}
            >
               <TranslatedText text="profile.register-code.title" />
            </NovaTitle>

            <Box
               sx={{
                  marginTop: "32px",
               }}
            >
               <Box
                  sx={{
                     maxWidth: "300px",
                  }}
               >
                  <NovaTextInput
                     label={<TranslatedText text="profile.register-code.enter-code" />}
                     value={voucher}
                     style={{
                        width: "100%",
                        maxWidth: "300px",
                        marginBottom: "16px",
                     }}
                     onChange={(event) => setVoucher(event.target.value)}
                  />
               </Box>
               {voucherRegisterMessage && (
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "8px 16px",
                        borderRadius: "12px",
                        background:
                           voucherRegisterMessage.status === "success"
                              ? "#1B1B48"
                              : "#e22739",
                        color: "#fff",
                     }}
                  >
                     <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
                        {voucherRegisterMessage.message}
                     </NovaText>
                     <IconButton onClick={() => setVoucherRegisterMessage(null)}>
                        <Close sx={{ color: "#fff" }} />
                     </IconButton>
                  </Box>
               )}
               <NovaButton
                  disabled={!voucher}
                  style={{
                     marginTop: "24px",
                     borderRadius: "32px",
                  }}
                  onClick={onVoucherValidate}
               >
                  <TranslatedText text="buttons.register" />
               </NovaButton>
            </Box>
         </Box>
      </Container>
   );
};

export default RegisterVoucher;
