import { Box, Card, CardContent, CircularProgress } from "@mui/material";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";

const Widget = ({ data }: any) => {
   return (
      <Card sx={{ width: "100%" }}>
         <CardContent>
            <NovaText type={NovaTextTypes.MediumNormal} style={{ margin: 0 }}>
               Orders
            </NovaText>
            <Box
               sx={{
                  position: "relative",
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: "24px",
               }}
            >
               <CircularProgress variant="determinate" value={100} size={"80%"} />
               <Box
                  sx={{
                     top: 0,
                     left: 0,
                     bottom: 0,
                     right: 0,
                     position: "absolute",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     width: "100%",
                  }}
               >
                  <NovaText
                     type={NovaTextTypes.XLNormal}
                     style={{
                        fontSize: "48px",
                        fontWeight: "600",
                     }}
                  >
                     {data || "0"}
                  </NovaText>
               </Box>
            </Box>
         </CardContent>
      </Card>
   );
};

export default Widget;
