import {
   CHANGE_SUBSCRIPTION_PLAN_ACTIVITY_FAIL,
   CHANGE_SUBSCRIPTION_PLAN_ACTIVITY_SUCCESS,
   CHANGE_SUBSCRIPTION_PLAN_VISIBILITY_FAIL,
   CHANGE_SUBSCRIPTION_PLAN_VISIBILITY_SUCCESS,
   CREATE_SUBSCRIPTION_PLAN_FAIL,
   CREATE_SUBSCRIPTION_PLAN_SUCCESS,
   GET_CARDS_FAIL,
   GET_CARDS_SUCCESS,
   DELETE_CARD_FAIL,
   DELETE_CARD_SUCCESS,
   CREATE_CARD_FAIL,
   CREATE_CARD_SUCCESS,
   GET_SUBSCRIPTION_PLANS_FAIL,
   GET_SUBSCRIPTION_PLANS_SUCCESS,
   GET_SUBSCRIBERS_SUCCESS,
   GET_SUBSCRIBERS_FAIL,
   EDIT_SUBSCRIPTION_PLAN_SUCCESS,
   EDIT_SUBSCRIPTION_PLAN_FAIL,
   CREATE_SUBSCRIPTION_SUCCESS,
   CREATE_SUBSCRIPTION_FAIL,
} from "./types";

const createSubscriptionPlanSuccess = (payload: any) => ({
   type: CREATE_SUBSCRIPTION_PLAN_SUCCESS,
   payload,
});

const createSubscriptionPlanFail = () => ({
   type: CREATE_SUBSCRIPTION_PLAN_FAIL,
});

const getAllSubscriptionPlansSuccess = (payload: any) => ({
   type: GET_SUBSCRIPTION_PLANS_SUCCESS,
   payload,
});

const getAllSubscriptionPlansFail = () => ({
   type: GET_SUBSCRIPTION_PLANS_FAIL,
});

const changeSubscriptionPlanVisibilityFail = () => ({
   type: CHANGE_SUBSCRIPTION_PLAN_VISIBILITY_FAIL,
});

const changeSubscriptionPlanVisibilitySuccess = (payload: any) => ({
   type: CHANGE_SUBSCRIPTION_PLAN_VISIBILITY_SUCCESS,
   payload,
});

const changeSubscriptionPlanActivityFail = () => ({
   type: CHANGE_SUBSCRIPTION_PLAN_ACTIVITY_FAIL,
});

const changeSubscriptionPlanActivitySuccess = (payload: any) => ({
   type: CHANGE_SUBSCRIPTION_PLAN_ACTIVITY_SUCCESS,
   payload,
});

// cards
const getCardsSuccess = (payload: any) => ({
   type: GET_CARDS_SUCCESS,
   payload,
});

const getCardsFail = () => ({
   type: GET_CARDS_FAIL,
});

const deleteCardSuccess = (payload: any) => ({
   type: DELETE_CARD_SUCCESS,
   payload,
});

const deleteCardFail = () => ({
   type: DELETE_CARD_FAIL,
});

const createCardSuccess = (payload: any) => ({
   type: CREATE_CARD_SUCCESS,
   payload,
});

const createCardFail = () => ({
   type: CREATE_CARD_FAIL,
});

const getSubscribersSuccess = (payload: any) => ({
   type: GET_SUBSCRIBERS_SUCCESS,
   payload,
});

const getSubscribersFail = () => ({
   type: GET_SUBSCRIBERS_FAIL,
});

const editSubscriptionPlanSuccess = (payload: any) => ({
   type: EDIT_SUBSCRIPTION_PLAN_SUCCESS,
   payload,
});

const editSubscriptionPlanFail = () => ({
   type: EDIT_SUBSCRIPTION_PLAN_FAIL,
});

// subscriptions
const createSubscriptionSuccess = (payload: any) => ({
   type: CREATE_SUBSCRIPTION_SUCCESS,
   payload,
});

const createSubscriptionFail = () => ({
   type: CREATE_SUBSCRIPTION_FAIL,
});

export const SubscriptionsActions = {
   createSubscriptionPlanSuccess,
   createSubscriptionPlanFail,
   getAllSubscriptionPlansSuccess,
   getAllSubscriptionPlansFail,
   changeSubscriptionPlanVisibilityFail,
   changeSubscriptionPlanVisibilitySuccess,
   changeSubscriptionPlanActivityFail,
   changeSubscriptionPlanActivitySuccess,
   getCardsSuccess,
   getCardsFail,
   deleteCardSuccess,
   deleteCardFail,
   createCardSuccess,
   createCardFail,
   getSubscribersSuccess,
   getSubscribersFail,
   editSubscriptionPlanSuccess,
   editSubscriptionPlanFail,
   createSubscriptionSuccess,
   createSubscriptionFail,
};
