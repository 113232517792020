import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { Box, Checkbox, CheckboxProps, FormControlLabel, FormGroup } from "@mui/material";
import LogoDark from "../../components/shared/icons/logo-dark";
import NovaTitle from "../../components/shared/NovaTitle";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import NovaLink from "../../components/shared/NovaLink";
import loginFlowImage from "../../assets/login-flow-image.jpg";
import NovaButton from "../../components/shared/NovaButton";
import NovaTextInput from "../../components/shared/NovaTextInput";
import NovaPasswordInput from "../../components/shared/NovaPasswordInput";
import { checkEmailIsValid } from "../../utils/functions";
import TranslatedText from "../../components/translated-text";

const CreateNewAccount = ({ account, isFormValid, onSubmit, onAccountChange }: any) => {
   const location = useLocation();
   const { name, email, password, confirm_password, terms, marketingAccepted } = account;
   const { t } = useTranslation();
   const onFieldChange = (event: any, field: string) => {
      onAccountChange({ field, value: event.target.value });
   };
   const onTermsChange: CheckboxProps["onChange"] = (event) => {
      onAccountChange({ field: "terms", value: event.target.checked });
   };
   const onMarketingChange: CheckboxProps["onChange"] = (event) => {
      onAccountChange({ field: "marketingAccepted", value: event.target.checked });
   };

   useEffect(() => {
      ReactGA.send({
         hitType: "pageview",
         page: window.location.pathname,
         title: "Registration page",
      });
   }, [location]);

   return (
      <Box
         style={{
            height: "100%",
         }}
      >
         <Box
            sx={{
               width: "100%",
               height: "100%",
               display: "flex",
               flexDirection: "row",
            }}
         >
            <Box
               sx={{
                  width: "100%",
                  background: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
               }}
            >
               <div
                  style={{
                     maxWidth: "450px",
                     width: "100%",
                     paddingInline: "20px",
                  }}
               >
                  <LogoDark />
                  <NovaTitle
                     variant="h2"
                     style={{
                        marginTop: "24px",
                        marginBottom: "0",
                        maxWidth: "304px",
                     }}
                  >
                     <TranslatedText text="auth-flow.register.title" />
                  </NovaTitle>
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        marginTop: "12px",
                        marginBottom: 0,
                     }}
                  >
                     <TranslatedText text="auth-flow.register.already-have-an-account" />
                     &nbsp;
                     <NovaLink
                        to="../login"
                        style={{
                           color: "#CAA45D",
                        }}
                     >
                        <TranslatedText text="buttons.sign-in-here" />
                     </NovaLink>
                  </NovaText>
                  <div
                     style={{
                        padding: "10px",
                     }}
                  >
                     <div>
                        <NovaTextInput
                           value={name}
                           label={t("auth-flow.register.name")}
                           placeholder={t("auth-flow.register.name-placeholder")}
                           onChange={(event) => onFieldChange(event, "name")}
                        />
                        {name && name.length < 3 && (
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "red", margin: 0 }}
                           >
                              <TranslatedText
                                 text="messages.register-validation.name"
                                 props={{
                                    count: 3,
                                 }}
                              />
                           </NovaText>
                        )}
                     </div>
                     <div
                        style={{
                           marginTop: "16px",
                        }}
                     >
                        <NovaTextInput
                           value={email}
                           type="email"
                           label={t("auth-flow.register.email")}
                           placeholder={t("auth-flow.register.email-placeholder")}
                           onChange={(event) => onFieldChange(event, "email")}
                        />
                        {email && !checkEmailIsValid(email) && (
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "red", margin: 0 }}
                           >
                              <TranslatedText text="messages.register-validation.email" />
                           </NovaText>
                        )}
                     </div>
                     <div
                        style={{
                           marginTop: "16px",
                        }}
                     >
                        <NovaPasswordInput
                           label={t("auth-flow.register.password")}
                           placeholder={t("auth-flow.register.password-placeholder")}
                           value={password}
                           onChange={(event) => onFieldChange(event, "password")}
                        />
                        {password && password.length < 6 && (
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "red", margin: 0 }}
                           >
                              <TranslatedText
                                 text="messages.register-validation.password"
                                 props={{
                                    count: 6,
                                 }}
                              />
                           </NovaText>
                        )}
                     </div>
                     <div
                        style={{
                           marginTop: "16px",
                        }}
                     >
                        <NovaPasswordInput
                           label={t("auth-flow.register.confirm-password")}
                           placeholder={t(
                              "auth-flow.register.confirm-password-placeholder",
                           )}
                           value={confirm_password}
                           onChange={(event) => onFieldChange(event, "confirm_password")}
                        />
                        {confirm_password && password !== confirm_password && (
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{ color: "red", margin: 0 }}
                           >
                              <TranslatedText text="messages.register-validation.confirm-password" />
                           </NovaText>
                        )}
                     </div>
                  </div>
                  <div
                     style={{
                        padding: "16px 10px 0 10px",
                     }}
                  >
                     <FormGroup>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={marketingAccepted}
                                 onChange={onMarketingChange}
                                 sx={{
                                    color: "#777E90",
                                    display: "flex",
                                 }}
                              />
                           }
                           label={t("agreements.register.marketing")}
                        />
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={terms}
                                 onChange={(event, checked) =>
                                    onTermsChange(event, checked)
                                 }
                                 sx={{
                                    color: "#777E90",
                                    display: "flex",
                                 }}
                              />
                           }
                           label={t("agreements.register.policy")}
                        />
                     </FormGroup>
                  </div>
                  <div
                     style={{
                        display: "flex",
                        width: "100%",
                        marginTop: "16px",
                     }}
                  >
                     <NovaButton
                        disabled={!isFormValid}
                        style={{
                           width: "100%",
                           borderRadius: "32px",
                        }}
                        onClick={onSubmit}
                     >
                        <TranslatedText text="buttons.create-account" />
                     </NovaButton>
                  </div>
               </div>
            </Box>

            <Box
               sx={{
                  display: {
                     width: "100%",
                     xs: "none",
                     md: "flex",
                  },
               }}
            >
               <div
                  style={{
                     width: "100%",
                     height: "100%",
                     background: `url(${loginFlowImage})`,
                     backgroundRepeat: "no-repeat",
                     backgroundSize: "cover",
                     backgroundPosition: "center",
                     display: "flex",
                     alignItems: "end",
                     justifyContent: "end",
                     paddingRight: "20px",
                     paddingBottom: "23px",
                  }}
               >
                  <LogoDark />
               </div>
            </Box>
         </Box>
      </Box>
   );
};

export default CreateNewAccount;
