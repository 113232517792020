import { Box, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import NovaText, { NovaTextTypes } from "./NovaText";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";

const NovaPasswordInput = ({
   label,
   placeholder,
   value,
   onChange,
}: {
   label?: any;
   placeholder?: any;
   value: string;
   onChange: (event: any) => void;
}) => {
   const [showPassword, setShowPassword] = useState(false);

   const handleClickShowPassword = () => setShowPassword((show) => !show);

   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
   };
   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
         }}
      >
         <NovaText
            type={NovaTextTypes.BaseNormal}
            style={{
               margin: 0,
            }}
         >
            {label ? label : "Password"}
         </NovaText>
         <OutlinedInput
            value={value}
            type={showPassword ? "text" : "password"}
            endAdornment={
               <InputAdornment position="end">
                  <IconButton
                     onClick={handleClickShowPassword}
                     onMouseDown={handleMouseDownPassword}
                     edge="end"
                  >
                     {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
               </InputAdornment>
            }
            placeholder={placeholder ? placeholder : "Password"}
            sx={{
               width: "100%",
               marginTop: "8px",
               ".MuiInputBase-input": {
                  padding: "4px 11px",
                  color: "#000",
                  fontSize: "14px",
                  lineHeight: "27px",
                  fontWeight: 400,
                  minHeight: "32px",
               },
            }}
            onChange={onChange}
         />
      </Box>
   );
};

export default NovaPasswordInput;
