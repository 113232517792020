import {
   DELETE_PRODUCT_FAIL,
   DELETE_PRODUCT_SUCCESS,
   GET_PRODUCTS,
   GET_PRODUCTS_FAIL,
   GET_SINGLE_PRODUCT_FAIL,
   GET_SINGLE_PRODUCT_SUCCESS,
   GET_SINGLE_QR_FAIL,
   GET_SINGLE_QR_SUCCESS,
   SET_PRODUCT_AVAILABILITY_FAIL,
   SET_PRODUCT_AVAILABILITY_SUCCESS,
} from "./types";

const getProducts = (payload: any) => ({
   type: GET_PRODUCTS,
   payload,
});

const getProductsFail = () => ({
   type: GET_PRODUCTS_FAIL,
});

const getSingleProductFail = () => ({
   type: GET_SINGLE_PRODUCT_FAIL,
});

const getSingleProductSuccess = (payload: any) => ({
   type: GET_SINGLE_PRODUCT_SUCCESS,
   payload,
});

const getSingleQRFail = () => ({
   type: GET_SINGLE_QR_FAIL,
});

const getSingleQRSuccess = (payload: any) => ({
   type: GET_SINGLE_QR_SUCCESS,
   payload,
});

const deleteProductFail = () => ({
   type: DELETE_PRODUCT_FAIL,
});

const deleteProductSuccess = (payload: any) => ({
   type: DELETE_PRODUCT_SUCCESS,
   payload,
});

const setProductAvailabilityFail = () => ({
   type: SET_PRODUCT_AVAILABILITY_FAIL,
});

const setProductAvailabilitySuccess = (payload: any) => ({
   type: SET_PRODUCT_AVAILABILITY_SUCCESS,
   payload,
});

export const ProductsActions = {
   getProducts,
   getProductsFail,
   deleteProductFail,
   deleteProductSuccess,
   getSingleProductFail,
   getSingleProductSuccess,
   getSingleQRFail,
   getSingleQRSuccess,
   setProductAvailabilityFail,
   setProductAvailabilitySuccess,
};
