import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import bg from "./locales/bg.json";
import en from "./locales/en.json";

export const resources = {
   bg: {
      translation: bg,
   },
   en: {
      translation: en,
   },
   us: {
      translation: en,
   },
};

export type Language = keyof typeof resources;

i18n
   .use(detector)
   .use(initReactI18next)
   .init({
      resources,
      fallbackLng: "en",
      interpolation: {
         escapeValue: false,
      },
   });
