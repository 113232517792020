const ACTION_TYPE = "SUBSCRIPTIONS";
export const CREATE_SUBSCRIPTION_PLAN_FAIL = `${ACTION_TYPE}/CREATE_SUBSCRIPTION_PLAN_FAIL`;
export const CREATE_SUBSCRIPTION_PLAN_SUCCESS = `${ACTION_TYPE}/CREATE_SUBSCRIPTION_PLAN_SUCCESS`;

export const GET_SUBSCRIPTION_PLANS_FAIL = `${ACTION_TYPE}/GET_SUBSCRIPTION_PLANS_FAIL`;
export const GET_SUBSCRIPTION_PLANS_SUCCESS = `${ACTION_TYPE}/GET_SUBSCRIPTION_PLANS_SUCCESS`;

export const CHANGE_SUBSCRIPTION_PLAN_VISIBILITY_FAIL = `${ACTION_TYPE}/CHANGE_SUBSCRIPTION_PLAN_VISIBILITY_FAIL`;
export const CHANGE_SUBSCRIPTION_PLAN_VISIBILITY_SUCCESS = `${ACTION_TYPE}/CHANGE_SUBSCRIPTION_PLAN_VISIBILITY_SUCCESS`;

export const CHANGE_SUBSCRIPTION_PLAN_ACTIVITY_FAIL = `${ACTION_TYPE}/CHANGE_SUBSCRIPTION_PLAN_ACTIVITY_FAIL`;
export const CHANGE_SUBSCRIPTION_PLAN_ACTIVITY_SUCCESS = `${ACTION_TYPE}/CHANGE_SUBSCRIPTION_PLAN_ACTIVITY_SUCCESS`;

export const EDIT_SUBSCRIPTION_PLAN_FAIL = `${ACTION_TYPE}/EDIT_SUBSCRIPTION_PLAN_FAIL`;
export const EDIT_SUBSCRIPTION_PLAN_SUCCESS = `${ACTION_TYPE}/EDIT_SUBSCRIPTION_PLAN_SUCCESS`;

// cards
export const GET_CARDS_FAIL = `${ACTION_TYPE}/GET_CARDS_FAIL`;
export const GET_CARDS_SUCCESS = `${ACTION_TYPE}/GET_CARDS_SUCCESS`;

export const DELETE_CARD_FAIL = `${ACTION_TYPE}/DELETE_CARD_FAIL`;
export const DELETE_CARD_SUCCESS = `${ACTION_TYPE}/DELETE_CARD_SUCCESS`;

export const CREATE_CARD_FAIL = `${ACTION_TYPE}/CREATE_CARD_FAIL`;
export const CREATE_CARD_SUCCESS = `${ACTION_TYPE}/CREATE_CARD_SUCCESS`;

// subscribers
export const GET_SUBSCRIBERS_FAIL = `${ACTION_TYPE}/GET_SUBSCRIBERS_FAIL`;
export const GET_SUBSCRIBERS_SUCCESS = `${ACTION_TYPE}/GET_SUBSCRIBERS_SUCCESS`;

// subscriptions
export const CREATE_SUBSCRIPTION_FAIL = `${ACTION_TYPE}/CREATE_SUBSCRIPTION_FAIL`;
export const CREATE_SUBSCRIPTION_SUCCESS = `${ACTION_TYPE}/CREATE_SUBSCRIPTION_SUCCESS`;
