import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { AddOutlined, DeleteOutline, RemoveOutlined } from "@mui/icons-material";
import NovaTitle from "../shared/NovaTitle";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import { CartActions } from "../../pages/products/clients/actions";
import { useAppDispatch } from "../../store/hooks";
import TranslatedText from "../translated-text";
import NovaButton from "../shared/NovaButton";
import PreviewModal from "./qr-preview/modal";

const CheckoutProductCard = ({ order, index }: any) => {
   const dispatch = useAppDispatch();
   const [value, setValue] = useState<any>(order.quantity);
   const [qrPreview, openQRpreview] = useState(false);

   const handleCountIncrease = () => {
      setValue(value + 1);

      dispatch(
         CartActions.changeOrderQuantity({
            quantity: value + 1,
            index,
            type: "increase",
         }),
      );
   };

   const handleCountDecrease = () => {
      setValue(value - 1);
      dispatch(
         CartActions.changeOrderQuantity({
            quantity: value - 1,
            index,
            type: "decrease",
         }),
      );
   };

   const handleRemove = () => {
      dispatch(CartActions.removeFromCart(index));
   };

   const handleOpenPreview = () => {
      openQRpreview(true);
   };

   const handleQRPreviewClose = () => {
      openQRpreview(false);
   };

   return (
      <Box
         sx={{
            display: "flex",
            alignItems: "start",
            gap: "16px",
         }}
      >
         <Box
            sx={{
               flex: "0 0 auto",
               background: `url(${order.product.photos[0].photoUrl})`,
               backgroundSize: "cover",
               backgroundPosition: "center center",
               backgroundRepeat: "no-repeat",
               width: "100px",
               height: "100px",
               borderRadius: "12px",
            }}
         />

         <Box sx={{ flex: 1 }}>
            <Box
               sx={{
                  display: "flex",
                  flexDirection: {
                     xs: "column",
                     md: "row",
                  },
                  gap: "16px",
                  justifyContent: "space-between",
               }}
            >
               <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                  <NovaTitle
                     variant="h4"
                     style={{
                        margin: 0,
                        lineHeight: "24px",
                     }}
                  >
                     {order.product.name}
                  </NovaTitle>
                  {/* <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        margin: 0,
                        lineHeight: "24px",
                     }}
                  >
                     Metal medallion
                  </NovaText> */}
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        margin: "12px 0 0 0",
                        lineHeight: "24px",
                     }}
                  >
                     <TranslatedText text="cart.for-page" />
                     &nbsp;
                     <b>
                        <i>{order.memorialPage.fullName}</i>
                     </b>
                  </NovaText>
               </Box>

               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "flex-end",
                     gap: "8px",
                  }}
               >
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                     }}
                  >
                     <NovaButton
                        style={{
                           borderRadius: "24px",
                           padding: " 6px 14px",
                           fontSize: "14px",
                        }}
                        onClick={handleOpenPreview}
                     >
                        <TranslatedText text="qr-modal.preview" />
                     </NovaButton>
                     <IconButton onClick={handleRemove}>
                        <DeleteOutline />
                     </IconButton>
                  </Box>

                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                     }}
                  >
                     <IconButton
                        disabled={value === 1}
                        onClick={handleCountDecrease}
                        sx={{
                           "&:disabled > svg.MuiSvgIcon-root": {
                              color: "#ccc",
                           },
                        }}
                     >
                        <RemoveOutlined />
                     </IconButton>
                     <Box sx={{ margin: "0 7px" }}>{value}</Box>
                     <IconButton onClick={handleCountIncrease}>
                        <AddOutlined sx={{ color: "#000" }} />
                     </IconButton>
                  </Box>
               </Box>
            </Box>

            <div
               style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "12px",
               }}
            >
               <div
                  style={{
                     height: "2px",
                     width: "30px",
                     background: "#CAA45D",
                     display: "inline-block",
                  }}
               />
               <NovaText
                  type={NovaTextTypes.LGBold}
                  style={{
                     margin: "0 0 0 10px",
                     color: "#CAA45D",
                  }}
               >
                  {order.totalPrice}&nbsp;
                  {order.currency}
               </NovaText>
            </div>
         </Box>

         {qrPreview && (
            <PreviewModal qr={order.qrCode} readOnly handleClose={handleQRPreviewClose} />
         )}
      </Box>
   );
};

export default CheckoutProductCard;
