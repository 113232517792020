import { useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { UploadIcon } from "../../components/shared/icons/upload-icon";
import BackgroundImage from "../../components/shared/BackgroundImage";
import NovaTextInput from "../../components/shared/NovaTextInput";
import { HomeSelectors } from "../home/selectors";
import { limitations } from "./limitations";
import MemorialLimitationsInfoBar from "../../components/MemorialLimitationsInfoBar";
import TranslatedText from "../../components/translated-text";

const Photos = ({
   photos,
   onChange,
}: {
   photos: any[];
   onChange: (changes: any) => void;
}) => {
   const { t } = useTranslation();
   const editMemorialPageData = useSelector(HomeSelectors.getEditedMemorialPage);

   const DropzoneRef = useRef<DropzoneRef>(null);
   const editingImageRef = useRef<number | undefined>(undefined);

   const handleEditImage = (selectedImage: any) => {
      editingImageRef.current = selectedImage;
      DropzoneRef.current?.open();
   };

   const handleRemoveImage = (removeIndex: number) => {
      onChange(photos.filter((_: any, index: number) => index !== removeIndex));
   };

   const onFieldChange = (fieldIndex: number, field: string, value: string) => {
      onChange(
         photos.map((photo, index) =>
            index === fieldIndex
               ? {
                    ...photo,
                    [field]: value,
                 }
               : photo,
         ),
      );
   };

   return (
      <>
         <MemorialLimitationsInfoBar
            itemName={t("create-memorial-page.photos.item-name")}
            limit={
               limitations[
                  editMemorialPageData?.subscription
                     ? editMemorialPageData.subscription.subscriptionPlan.type
                     : "free"
               ]?.photosLength
            }
         />

         <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={12 / 5}>
               <Dropzone
                  multiple
                  accept={{
                     "image/png": [".png"],
                     "image/jpeg": [".jpg"],
                  }}
                  onDrop={(acceptedFiles: any) => {
                     acceptedFiles.forEach((file: any) => {
                        const reader = new FileReader();

                        reader.onload = (event: any) => {
                           if (editingImageRef.current !== undefined) {
                              onChange((prevPhotos: any) =>
                                 prevPhotos.map((photo: any, index: number) =>
                                    index === editingImageRef.current
                                       ? {
                                            ...photo,
                                            base64: event.target.result,
                                            id: undefined,
                                         }
                                       : photo,
                                 ),
                              );

                              editingImageRef.current = undefined;
                           } else {
                              onChange((prevPhotos: any) => [
                                 ...prevPhotos,
                                 {
                                    title: "",
                                    location: "",
                                    date: undefined,
                                    base64: event.target.result,
                                 },
                              ]);
                           }
                        };
                        reader.readAsDataURL(file);
                     });
                  }}
                  ref={DropzoneRef}
               >
                  {({ getRootProps, getInputProps }) => (
                     <Box
                        sx={{
                           maxWidth: "100%",
                           width: "180px",
                           maxHeight: "180px",
                           height: "100%",
                           border: "2px dashed #E9DDC8",
                           borderRadius: "16px",
                           cursor: "pointer",
                        }}
                     >
                        <div
                           {...getRootProps()}
                           style={{
                              height: "100%",
                           }}
                        >
                           <input {...getInputProps()} />
                           <div
                              style={{
                                 width: "180px",
                                 height: "180px",
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                              }}
                           >
                              <Box
                                 sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    gap: "4px",
                                    border: "1px solid #E9DDC8",
                                    borderRadius: "32px",
                                    padding: "10px 24px",
                                 }}
                              >
                                 <UploadIcon />
                                 <TranslatedText text="buttons.upload" />
                              </Box>
                           </div>
                        </div>
                     </Box>
                  )}
               </Dropzone>
            </Grid>
            {photos.map((photo: any, index: any) => {
               return (
                  <Grid item xs={12} md={6} lg={12 / 5} key={index}>
                     <Box
                        sx={{
                           maxWidth: "100%",
                           width: "180px",
                           minHeight: "180px",
                           position: "relative",
                           marginBottom: "16px",
                        }}
                     >
                        <BackgroundImage image={photo.base64} />

                        <Box
                           sx={{
                              display: "flex",
                              position: "absolute",
                              bottom: 8,
                              right: 8,
                              flexDirection: "row",
                              gap: "6px",
                           }}
                        >
                           <Box
                              onClick={() => handleEditImage(index)}
                              sx={{
                                 display: "flex",
                                 width: "30px",
                                 height: "30px",
                                 backgroundColor: "#1b1b45",
                                 border: "2px solid #ffffff",
                                 borderRadius: "999px",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 cursor: "pointer",
                              }}
                           >
                              <EditIcon
                                 sx={{
                                    fontSize: "14px",
                                    color: "#ffffff",
                                 }}
                              />
                           </Box>

                           <Box
                              onClick={() => handleRemoveImage(index)}
                              sx={{
                                 display: "flex",
                                 width: "30px",
                                 height: "30px",
                                 backgroundColor: "#1b1b45",
                                 border: "2px solid #ffffff",
                                 borderRadius: "999px",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 cursor: "pointer",
                              }}
                           >
                              <DeleteOutlineIcon
                                 sx={{
                                    fontSize: "16px",
                                    color: "#ffffff",
                                 }}
                              />
                           </Box>
                        </Box>
                     </Box>
                     <NovaTextInput
                        value={photo.title}
                        placeholder={t("create-memorial-page.photos.photo-title")}
                        onChange={(event) =>
                           onFieldChange(index, "title", event.target.value)
                        }
                        InputProps={{
                           startAdornment: (
                              <DriveFileRenameOutlineIcon
                                 sx={{
                                    fontSize: "medium",
                                    fill: "#0000008a",
                                 }}
                              />
                           ),
                        }}
                        padding="0px 11px"
                     />
                     <NovaTextInput
                        value={photo.location}
                        placeholder={t("create-memorial-page.photos.location")}
                        onChange={(event) =>
                           onFieldChange(index, "location", event.target.value)
                        }
                        InputProps={{
                           startAdornment: (
                              <LocationOnIcon
                                 sx={{
                                    fontSize: "medium",
                                    fill: "#0000008a",
                                 }}
                              />
                           ),
                        }}
                        style={{
                           marginBottom: "8px",
                        }}
                        padding="0px 11px"
                     />
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box
                           sx={{
                              ".MuiTextField-root": {
                                 width: "100%",
                                 backgroundColor: "#fff",
                              },
                           }}
                        >
                           <DatePicker
                              slotProps={{
                                 textField: {
                                    size: "small",
                                    sx: {
                                       input: {
                                          minHeight: "32px",
                                          fontSize: "14px",
                                          padding: "0px",
                                       },
                                    },
                                 },
                                 field: {
                                    clearable: false,
                                 },
                                 inputAdornment: {
                                    sx: {
                                       ".MuiButtonBase-root": {
                                          padding: "4px",
                                          marginLeft: "-4px",
                                       },
                                       svg: {
                                          width: "16px",
                                          height: "16px",
                                       },
                                    },
                                    position: "start",
                                 },
                              }}
                              openTo="year"
                              views={["year", "month", "day"]}
                              minDate={dayjs("01-01-1000")}
                              maxDate={dayjs()}
                              value={photo.date && dayjs(photo.date)}
                              onChange={(event: any) =>
                                 onFieldChange(
                                    index,
                                    "date",
                                    dayjs(event).format("YYYY-MM-DD"),
                                 )
                              }
                           />
                        </Box>
                     </LocalizationProvider>
                  </Grid>
               );
            })}
         </Grid>
      </>
   );
};

export default Photos;
