import { marked } from "marked";

const MarkdownRenderer = ({
   markdownText,
   planeText,
   children,
}: {
   markdownText: string;
   planeText?: boolean;
   children: (text: string) => JSX.Element;
}) => {
   const getMarkdownText = () => {
      const rawMarkup = marked(markdownText);
      const planeTextValue = (rawMarkup as any).replace(/<[^>]*>/g, "");
      return planeText ? planeTextValue : ({ __html: rawMarkup } as any);
   };

   return children(getMarkdownText());
};

export default MarkdownRenderer;
