export const affiliateColumns: readonly any[] = [
   {
      id: "code",
      numeric: false,
      disablePadding: false,
      label: "Code",
   },

   {
      id: "commissionForAffiliate",
      numeric: false,
      disablePadding: false,
      label: "Commission",
   },
   {
      id: "used",
      numeric: false,
      disablePadding: false,
      label: "Used",
   },
];

export const requestsColumns: readonly any[] = [
   {
      id: "amount",
      numeric: false,
      disablePadding: false,
      label: "Amount",
   },
   {
      id: "address",
      numeric: false,
      disablePadding: false,
      label: "Address",
   },
   {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
   },
   {
      id: "createdOn",
      numeric: false,
      disablePadding: false,
      label: "Created On",
   },
];
