export const columns: readonly any[] = [
   {
      id: "code",
      numeric: false,
      disablePadding: false,
      label: "Code",
   },
   {
      id: "memorialPageId",
      numeric: false,
      disablePadding: false,
      label: "Page",
   },
   {
      id: "subscriptionPlan",
      numeric: false,
      disablePadding: false,
      label: "Subscription plan",
   },
   {
      id: "priceForAffiliate",
      numeric: false,
      disablePadding: false,
      label: "Net Amount",
   },
   {
      id: "generatedForUserFullName",
      numeric: false,
      disablePadding: false,
      label: "For user",
   },

   {
      id: "commissionForAffiliate",
      numeric: false,
      disablePadding: false,
      label: "Commission",
   },
   {
      id: "used",
      numeric: false,
      disablePadding: false,
      label: "Used",
   },
   {
      id: "payed",
      numeric: false,
      disablePadding: false,
      label: "Payment status",
   },
   {
      id: "download",
      numeric: false,
      disablePadding: false,
      label: "QR",
   },
   {
      id: "pay",
      numeric: false,
      disablePadding: false,
      label: "",
   },
];
