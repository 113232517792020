import { Box, Grid } from "@mui/material";
import Request from "./Request";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import TranslatedText from "../translated-text";

const RequestsContainer = ({ items, type }: any) => {
   if (!items || items.length < 1) {
      return (
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               height: "300px",
            }}
         >
            <NovaText
               type={NovaTextTypes.MediumNormal}
               style={{
                  fontStyle: "italic",
                  textAlign: "center",
               }}
            >
               <TranslatedText text="home.requests.no-requests" />
            </NovaText>
         </Box>
      );
   }
   return (
      <Grid container spacing={2}>
         {items.map((item: any, index: any) => {
            return (
               <Grid item xs={12} key={index}>
                  <Request item={item} type={type} />
               </Grid>
            );
         })}
      </Grid>
   );
};

export default RequestsContainer;
