const ACTION_TYPE = "MEMORIALS";
export const GET_MEMORIALS = `${ACTION_TYPE}/GET_MEMORIALS`;
export const GET_EDITED_MEMORIAL = `${ACTION_TYPE}/GET_EDITED_MEMORIAL`;
export const UPDATE_MEMORIALS = `${ACTION_TYPE}/UPDATE_MEMORIALS`;
export const GET_MY_REQUESTS = `${ACTION_TYPE}/GET_MY_REQUESTS`;
export const GET_MY_PAGES_REQUESTS = `${ACTION_TYPE}/GET_MY_PAGES_REQUESTS`;
export const APPROVE_ACCESS_SUCCESS = `${ACTION_TYPE}/APPROVE_ACCESS_SUCCESS`;
export const APPROVE_ACCESS_FAIL = `${ACTION_TYPE}/APPROVE_ACCESS_FAIL`;
export const REJECT_ACCESS_SUCCESS = `${ACTION_TYPE}/REJECT_ACCESS_SUCCESS`;
export const REJECT_ACCESS_FAIL = `${ACTION_TYPE}/REJECT_ACCESS_FAIL`;

export const SET_MEMORIAL_PAGE_TYPE = `${ACTION_TYPE}/SET_MEMORIAL_PAGE_TYPE`;
