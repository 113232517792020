import { isAxiosError } from "axios";
import { SignUpActions } from "./actions";
import { AlertsActions } from "../../components/alerts/actions";

const LOGGED_IN_HOME = "/";

export const userSignUp =
   (
      fullName: string,
      email: string,
      password: string,
      marketingAccepted: boolean,
      navigate: any,
   ) =>
   (dispatch: any, _: any, { api }: any) => {
      api.usersAPI
         .userSignUp(fullName, email, password, marketingAccepted)
         .catch((error: any) => {
            if (!isAxiosError(error)) return;

            dispatch(SignUpActions.registrationFail());

            dispatch(
               AlertsActions.createAlert({
                  type: "error",
                  message: error.response?.data?.title ?? "An error occurred",
               }),
            );
         })
         .then((res: any) => {
            if (!res) return;

            const { data } = res;
            if (data) {
               localStorage.setItem("user", JSON.stringify(data));
               dispatch(SignUpActions.registrationSuccess(res.data));
               return navigate(LOGGED_IN_HOME);
            }
         });
   };

export const affiliateSignUp =
   (fullName: string, email: string, website: string, phone: string) =>
   (dispatch: any, _: any, { api }: any) => {
      api.usersAPI
         .affiliateSignUp(fullName, email, website, phone)
         .catch((error: any) => {
            if (!isAxiosError(error)) return;

            dispatch(SignUpActions.affiliateRegistrationFail());

            dispatch(
               AlertsActions.createAlert({
                  type: "error",
                  message: error.response?.data?.title ?? "An error occurred",
               }),
            );
         })
         .then((res: any) => {
            if (!res) return;
            const { data } = res;
            if (data) {
               dispatch(SignUpActions.affiliateRegistrationSuccess(res.data));
            }
         });
   };
