import { configureStore, combineReducers } from "@reduxjs/toolkit";
import api from "../api/index";
import UsersReducer from "../pages/Login/reducer";
import MemorialsReducer from "../pages/home/reducer";
import SingleMemorialPageReducer from "../pages/single-memorial-page/reducer";
import CartReducer from "../pages/products/clients/reducer";
import ProductsReducer from "../pages/products/reducer";
import OrdersReducer from "../pages/orders/reducer";
import EkontReducer from "../pages/checkout/reducer";
import PaymentsReducer from "../pages/payments/reducer";
import SubscriptionsReducer from "../pages/subscriptions/reducer";
import AlertReducer from "../components/alerts/reducer";
import AffiliatesReducer from "../pages/vouchers/reducer";

const rootReducer = combineReducers({
   login: UsersReducer,
   memorials: MemorialsReducer,
   singleMemorial: SingleMemorialPageReducer,
   cart: CartReducer,
   products: ProductsReducer,
   orders: OrdersReducer,
   ekont: EkontReducer,
   payments: PaymentsReducer,
   subscriptions: SubscriptionsReducer,
   alerts: AlertReducer,
   affiliates: AffiliatesReducer,
});

export const store = configureStore({
   reducer: rootReducer,
   middleware: (getDefaultMiddleware: any) =>
      getDefaultMiddleware({
         thunk: {
            extraArgument: {
               api,
            },
         },
      }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
