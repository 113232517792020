const LogoDark: React.FC<{
   /** @default 129 */
   width?: number;
}> = ({ width = 129 }) => {
   return (
      <svg
         width={width}
         height={width / 3.225}
         viewBox="0 0 129 40"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M81.017 18.5568C80.2362 18.5568 79.5718 18.3376 79.0238 17.8992C78.4895 17.4472 78.2224 16.8033 78.2224 15.9677C78.2224 15.3101 78.5375 14.7074 79.1676 14.1594C79.8115 13.5978 80.8184 13.1868 82.1882 12.9265C82.4211 12.8717 82.6883 12.8238 82.9896 12.7827C83.3047 12.7279 83.6129 12.6731 83.9143 12.6183V11.1594C83.9143 10.2278 83.7979 9.58399 83.565 9.22782C83.3321 8.87164 82.9622 8.69356 82.4554 8.69356H82.3937C82.0787 8.69356 81.8252 8.7963 81.6334 9.00178C81.4554 9.19357 81.3389 9.51549 81.2841 9.96756L81.2225 10.2347C81.1814 10.7826 81.0375 11.1868 80.791 11.447C80.5444 11.6936 80.2362 11.8169 79.8663 11.8169C79.5238 11.8169 79.2361 11.7142 79.0033 11.5087C78.7841 11.2895 78.6745 11.0087 78.6745 10.6662C78.6745 10.1045 78.8663 9.63193 79.2498 9.24836C79.6334 8.86479 80.1403 8.57712 80.7704 8.38533C81.4143 8.19355 82.1061 8.09766 82.8458 8.09766C84.065 8.09766 84.9965 8.39218 85.6404 8.98124C86.2842 9.57029 86.6061 10.5292 86.6061 11.858V16.4403C86.6061 17.0293 86.887 17.3239 87.4486 17.3239H87.9212L88.1267 17.5499C87.8801 17.865 87.6062 18.1047 87.3048 18.2691C87.0034 18.4472 86.5924 18.5362 86.0719 18.5362C85.4828 18.5362 85.0102 18.3992 84.6541 18.1253C84.3116 17.8376 84.0855 17.4609 83.976 16.9951C83.5376 17.4609 83.1061 17.8376 82.6814 18.1253C82.2567 18.4129 81.7019 18.5568 81.017 18.5568ZM82.2088 17.2417C82.4828 17.2417 82.7431 17.18 82.9896 17.0567C83.2362 16.9335 83.5444 16.728 83.9143 16.4403V13.1526C83.5581 13.2074 83.1951 13.2758 82.8252 13.358C82.2362 13.495 81.7567 13.7485 81.3869 14.1183C81.0307 14.4882 80.8526 14.9882 80.8526 15.6184C80.8526 16.1663 80.9759 16.5773 81.2225 16.8513C81.4828 17.1115 81.8115 17.2417 82.2088 17.2417Z"
            fill="#151623"
         />
         <path
            d="M72.1843 18.3272L68.5677 9.63525C68.4171 9.25168 68.1499 9.0051 67.7664 8.8955L67.3348 8.79276V8.40234H72.5336V8.79276L71.9993 8.91605C71.561 9.02564 71.4308 9.30647 71.6089 9.75854L73.7665 15.4915L75.7802 9.79963C75.8487 9.56675 75.8624 9.38182 75.8213 9.24483C75.7939 9.09414 75.6638 8.9914 75.4309 8.9366L74.835 8.79276V8.40234H77.9583V8.79276L77.2803 8.95715C76.883 9.05304 76.6159 9.29962 76.4789 9.69689L73.3761 18.3272H72.1843Z"
            fill="#151623"
         />
         <path
            d="M61.743 18.5568C60.7293 18.5568 59.8389 18.3444 59.0717 17.9198C58.3183 17.4951 57.7292 16.8924 57.3046 16.1115C56.8936 15.3307 56.6881 14.3992 56.6881 13.3169C56.6881 12.2347 56.9073 11.3032 57.3457 10.5224C57.7977 9.74153 58.4005 9.14562 59.1539 8.73466C59.9211 8.30999 60.7841 8.09766 61.743 8.09766C62.7019 8.09766 63.5581 8.30999 64.3116 8.73466C65.0787 9.14562 65.6814 9.74153 66.1198 10.5224C66.5719 11.2895 66.7979 12.221 66.7979 13.3169C66.7979 14.3992 66.5856 15.3375 66.1609 16.1321C65.7499 16.9129 65.1677 17.5157 64.4143 17.9403C63.6609 18.3513 62.7704 18.5568 61.743 18.5568ZM61.743 17.9814C62.2362 17.9814 62.6334 17.8513 62.9348 17.591C63.2499 17.317 63.4759 16.8444 63.6129 16.1732C63.7499 15.5019 63.8184 14.5635 63.8184 13.358C63.8184 12.1388 63.7499 11.1936 63.6129 10.5224C63.4759 9.83742 63.2499 9.36481 62.9348 9.10453C62.6334 8.83055 62.2362 8.69356 61.743 8.69356C61.2498 8.69356 60.8526 8.83055 60.5512 9.10453C60.2498 9.36481 60.0238 9.83742 59.8731 10.5224C59.7361 11.1936 59.6676 12.1388 59.6676 13.358C59.6676 14.5635 59.7361 15.5019 59.8731 16.1732C60.0238 16.8444 60.2498 17.317 60.5512 17.591C60.8526 17.8513 61.2498 17.9814 61.743 17.9814Z"
            fill="#151623"
         />
         <path
            d="M44.0309 18.27V17.8385L44.6268 17.6741C44.8871 17.6056 45.0652 17.4754 45.161 17.2836C45.2706 17.0919 45.3254 16.859 45.3254 16.585V6.63959C45.2021 6.37931 45.0925 6.18753 44.9967 6.06424C44.9145 5.92725 44.8254 5.81081 44.7295 5.71492C44.6336 5.61903 44.5035 5.50943 44.3391 5.38614L43.9692 5.11901V4.6875H47.483L54.3051 14.0165V6.39301C54.3051 6.11904 54.2571 5.8793 54.1612 5.67382C54.079 5.46834 53.901 5.33135 53.627 5.26285L53.0105 5.11901V4.6875H56.0722V5.11901L55.5585 5.26285C55.2982 5.34505 55.1339 5.48889 55.0654 5.69437C55.0106 5.88615 54.9832 6.11904 54.9832 6.39301V18.3522H53.8325L46.0035 7.58482V16.5645C46.0035 16.8521 46.0446 17.0919 46.1268 17.2836C46.209 17.4754 46.3802 17.6056 46.6405 17.6741L47.1953 17.8385V18.27H44.0309Z"
            fill="#151623"
         />
         <path
            d="M124.011 35.2375V34.847L124.319 34.7648C124.771 34.6142 124.997 34.2717 124.997 33.7374C124.997 33.2717 124.997 32.8059 124.997 32.3401C125.011 31.8607 125.018 31.3881 125.018 30.9223V23.1139C125.018 22.8262 124.97 22.6139 124.874 22.4769C124.778 22.3262 124.593 22.2166 124.319 22.1481L124.011 22.0659V21.6755L127.607 20.8125L127.874 20.9974L127.792 23.8948V33.7374C127.806 34.0114 127.86 34.2374 127.956 34.4155C128.066 34.5799 128.251 34.6963 128.511 34.7648L128.799 34.847V35.2375H124.011Z"
            fill="#151623"
         />
         <path
            d="M116.394 35.5255C115.613 35.5255 114.949 35.3063 114.401 34.868C113.867 34.4159 113.599 33.7721 113.599 32.9364C113.599 32.2789 113.915 31.6761 114.545 31.1282C115.189 30.5665 116.195 30.1556 117.565 29.8953C117.798 29.8405 118.065 29.7925 118.367 29.7514C118.682 29.6966 118.99 29.6418 119.291 29.587V28.1281C119.291 27.1966 119.175 26.5527 118.942 26.1966C118.709 25.8404 118.339 25.6623 117.832 25.6623H117.771C117.456 25.6623 117.202 25.765 117.011 25.9705C116.832 26.1623 116.716 26.4842 116.661 26.9363L116.6 27.2034C116.558 27.7514 116.415 28.1555 116.168 28.4158C115.921 28.6624 115.613 28.7857 115.243 28.7857C114.901 28.7857 114.613 28.6829 114.38 28.4774C114.161 28.2583 114.052 27.9774 114.052 27.635C114.052 27.0733 114.243 26.6007 114.627 26.2171C115.01 25.8335 115.517 25.5459 116.147 25.3541C116.791 25.1623 117.483 25.0664 118.223 25.0664C119.442 25.0664 120.374 25.3609 121.017 25.95C121.661 26.539 121.983 27.498 121.983 28.8268V33.409C121.983 33.9981 122.264 34.2926 122.826 34.2926H123.298L123.504 34.5187C123.257 34.8337 122.983 35.0735 122.682 35.2378C122.38 35.4159 121.97 35.505 121.449 35.505C120.86 35.505 120.387 35.368 120.031 35.094C119.689 34.8063 119.463 34.4296 119.353 33.9638C118.915 34.4296 118.483 34.8063 118.058 35.094C117.634 35.3817 117.079 35.5255 116.394 35.5255ZM117.586 34.2104C117.86 34.2104 118.12 34.1488 118.367 34.0255C118.613 33.9022 118.922 33.6967 119.291 33.409V30.1213C118.935 30.1761 118.572 30.2446 118.202 30.3268C117.613 30.4638 117.134 30.7172 116.764 31.0871C116.408 31.4569 116.23 31.957 116.23 32.5871C116.23 33.1351 116.353 33.546 116.6 33.82C116.86 34.0803 117.189 34.2104 117.586 34.2104Z"
            fill="#151623"
         />
         <path
            d="M110.16 23.9357C109.708 23.9357 109.324 23.7919 109.009 23.5042C108.694 23.2165 108.536 22.8535 108.536 22.4151C108.536 21.963 108.694 21.6 109.009 21.326C109.324 21.0384 109.708 20.8945 110.16 20.8945C110.612 20.8945 110.995 21.0384 111.31 21.326C111.625 21.6 111.783 21.963 111.783 22.4151C111.783 22.8535 111.625 23.2165 111.31 23.5042C110.995 23.7919 110.612 23.9357 110.16 23.9357ZM107.797 35.2373V34.8469L108.105 34.7647C108.379 34.6825 108.564 34.5592 108.66 34.3948C108.755 34.2304 108.81 34.0112 108.824 33.7373V29.9564C108.824 29.5317 108.824 29.1413 108.824 28.7851C108.824 28.4152 108.817 28.0385 108.803 27.6549C108.803 27.381 108.749 27.1686 108.639 27.0179C108.543 26.8536 108.358 26.744 108.084 26.6892L107.797 26.6275V26.2577L111.392 25.0658L111.639 25.2919L111.577 28.2097V33.7578C111.577 34.0318 111.625 34.251 111.721 34.4154C111.831 34.5797 112.016 34.703 112.276 34.7852L112.502 34.8469V35.2373H107.797Z"
            fill="#151623"
         />
         <path
            d="M98.8461 35.2378V34.8474L99.1543 34.7447C99.4283 34.6762 99.6132 34.5598 99.7091 34.3954C99.8187 34.231 99.8735 34.0118 99.8735 33.7378V27.7171C99.8598 27.4158 99.805 27.1897 99.7091 27.039C99.6132 26.8747 99.4283 26.7651 99.1543 26.7103L98.8461 26.6281V26.2582L102.195 25.0664L102.442 25.2924L102.627 27.1007V27.2445C102.832 26.861 103.093 26.5048 103.408 26.176C103.723 25.8472 104.065 25.5801 104.435 25.3746C104.805 25.1691 105.175 25.0664 105.545 25.0664C106.065 25.0664 106.456 25.2034 106.716 25.4774C106.976 25.7514 107.107 26.1007 107.107 26.5253C107.107 26.9911 106.976 27.3473 106.716 27.5939C106.456 27.8267 106.141 27.9432 105.771 27.9432C105.223 27.9432 104.75 27.6966 104.353 27.2034L104.312 27.1623C104.175 26.998 104.017 26.9089 103.839 26.8952C103.675 26.8678 103.511 26.95 103.346 27.1418C103.209 27.2788 103.079 27.4432 102.956 27.635C102.846 27.813 102.743 28.0254 102.648 28.272V33.6556C102.648 33.9159 102.702 34.1351 102.812 34.3132C102.921 34.4776 103.106 34.594 103.367 34.6625L103.983 34.8474V35.2378H98.8461Z"
            fill="#151623"
         />
         <path
            d="M92.8459 35.5255C91.8322 35.5255 90.9418 35.3132 90.1746 34.8885C89.4212 34.4639 88.8321 33.8611 88.4075 33.0803C87.9965 32.2994 87.791 31.3679 87.791 30.2857C87.791 29.2035 88.0102 28.272 88.4486 27.4911C88.9006 26.7103 89.5034 26.1144 90.2568 25.7034C91.024 25.2787 91.887 25.0664 92.8459 25.0664C93.8048 25.0664 94.661 25.2787 95.4145 25.7034C96.1816 26.1144 96.7844 26.7103 97.2227 27.4911C97.6748 28.2583 97.9008 29.1898 97.9008 30.2857C97.9008 31.3679 97.6885 32.3063 97.2638 33.1008C96.8528 33.8817 96.2706 34.4844 95.5172 34.9091C94.7638 35.32 93.8733 35.5255 92.8459 35.5255ZM92.8459 34.9502C93.3391 34.9502 93.7363 34.82 94.0377 34.5598C94.3528 34.2858 94.5788 33.8132 94.7158 33.1419C94.8528 32.4707 94.9213 31.5323 94.9213 30.3268C94.9213 29.1076 94.8528 28.1624 94.7158 27.4911C94.5788 26.8062 94.3528 26.3336 94.0377 26.0733C93.7363 25.7993 93.3391 25.6623 92.8459 25.6623C92.3528 25.6623 91.9555 25.7993 91.6541 26.0733C91.3527 26.3336 91.1267 26.8062 90.976 27.4911C90.839 28.1624 90.7705 29.1076 90.7705 30.3268C90.7705 31.5323 90.839 32.4707 90.976 33.1419C91.1267 33.8132 91.3527 34.2858 91.6541 34.5598C91.9555 34.82 92.3528 34.9502 92.8459 34.9502Z"
            fill="#151623"
         />
         <path
            d="M69.8896 35.2378V34.8269L70.3417 34.7036C70.5609 34.6351 70.7047 34.5392 70.7732 34.4159C70.8417 34.2789 70.8828 34.1008 70.8965 33.8817V29.8131C70.8965 29.361 70.8965 28.9774 70.8965 28.6624C70.8965 28.3473 70.8897 27.9774 70.876 27.5528C70.876 27.3199 70.8417 27.1418 70.7732 27.0185C70.7047 26.8815 70.554 26.7856 70.3212 26.7308L69.8896 26.6281V26.2582L73.2185 25.0664L73.4651 25.2924L73.6089 26.6692C74.0884 26.2308 74.6226 25.8541 75.2117 25.539C75.8007 25.2239 76.4035 25.0664 77.0199 25.0664C77.7049 25.0664 78.2391 25.2034 78.6227 25.4774C79.0063 25.7514 79.3008 26.1692 79.5063 26.7308C80.0268 26.2103 80.6022 25.8061 81.2323 25.5185C81.8625 25.2171 82.4858 25.0664 83.1022 25.0664C84.0338 25.0664 84.7324 25.2993 85.1982 25.7651C85.6639 26.2308 85.8968 26.9637 85.8968 27.9637V33.9022C85.8968 34.3406 86.0886 34.6145 86.4722 34.7241L86.8626 34.8269V35.2378H82.2392V34.8269L82.6091 34.7241C82.979 34.6008 83.1639 34.32 83.1639 33.8817V27.861C83.1639 27.2719 83.0543 26.8747 82.8351 26.6692C82.6296 26.45 82.2872 26.3404 81.8077 26.3404C81.4378 26.3404 81.0885 26.4157 80.7597 26.5664C80.431 26.7171 80.0679 26.95 79.6707 27.2651C79.7529 27.676 79.794 28.1487 79.794 28.6829V33.9022C79.8077 34.1351 79.8488 34.32 79.9173 34.457C79.9994 34.5803 80.1501 34.6693 80.3693 34.7241L80.7186 34.8269V35.2378H76.0747V34.8269L76.5062 34.7036C76.7254 34.6351 76.8692 34.5392 76.9377 34.4159C77.0199 34.2789 77.061 34.1008 77.061 33.8817V27.8815C77.061 27.3199 76.9583 26.9226 76.7528 26.6897C76.5473 26.4568 76.198 26.3404 75.7048 26.3404C75.0747 26.3404 74.3966 26.6418 73.6705 27.2445V33.9022C73.6705 34.3543 73.8623 34.6282 74.2459 34.7241L74.5952 34.8269V35.2378H69.8896Z"
            fill="#151623"
         />
         <path
            d="M64.6922 25.0664C65.5553 25.0664 66.295 25.2445 66.9115 25.6007C67.5279 25.9568 68.0005 26.45 68.3293 27.0801C68.6718 27.6966 68.843 28.4089 68.843 29.2172C68.843 29.3953 68.8361 29.5665 68.8224 29.7309C68.8088 29.8816 68.7814 30.0254 68.7403 30.1624H62.5552C62.5689 31.5734 62.8292 32.5871 63.336 33.2036C63.8566 33.82 64.61 34.1282 65.5964 34.1282C66.2813 34.1282 66.8361 34.0186 67.2608 33.7995C67.6854 33.5666 68.0759 33.2447 68.432 32.8337L68.7608 33.1419C68.3498 33.8954 67.7882 34.4844 67.0758 34.9091C66.3772 35.32 65.5416 35.5255 64.5689 35.5255C63.5963 35.5255 62.7401 35.32 62.0004 34.9091C61.2607 34.4981 60.6784 33.9091 60.2538 33.1419C59.8428 32.3611 59.6373 31.4295 59.6373 30.3473C59.6373 29.224 59.8771 28.272 60.3565 27.4911C60.8497 26.7103 61.4798 26.1144 62.247 25.7034C63.0278 25.2787 63.8429 25.0664 64.6922 25.0664ZM64.5895 25.6623C64.1922 25.6623 63.8429 25.7787 63.5415 26.0116C63.2538 26.2308 63.021 26.6281 62.8429 27.2034C62.6785 27.7651 62.5826 28.5596 62.5552 29.587H66.2334C66.384 28.2035 66.3292 27.2034 66.069 26.587C65.8087 25.9705 65.3155 25.6623 64.5895 25.6623Z"
            fill="#151623"
         />
         <path
            d="M43.7427 35.2387V34.7867L44.1536 34.6634C44.4139 34.5812 44.592 34.451 44.6879 34.2729C44.7838 34.0812 44.8317 33.8346 44.8317 33.5332V23.3618C44.8317 23.0878 44.7975 22.8686 44.729 22.7042C44.6605 22.5261 44.4893 22.3891 44.2153 22.2932L43.7427 22.1083V21.6562H47.6674L51.5305 31.7044L55.1676 21.6562H59.1745V22.1083L58.8457 22.2111C58.5854 22.2932 58.4074 22.4234 58.3115 22.6015C58.2156 22.7659 58.1676 22.985 58.1676 23.259C58.1539 24.0536 58.1471 24.8481 58.1471 25.6426C58.1471 26.4235 58.1471 27.218 58.1471 28.0262V28.8687C58.1471 29.6633 58.1471 30.4578 58.1471 31.2523C58.1471 32.0469 58.1539 32.8346 58.1676 33.6154C58.1676 33.9031 58.2019 34.1223 58.2704 34.2729C58.3389 34.4236 58.5033 34.5469 58.7635 34.6428L59.1745 34.7867V35.2387H54.1812V34.7867L54.6333 34.6428C54.8936 34.5469 55.0511 34.4236 55.1059 34.2729C55.1744 34.1086 55.2087 33.8894 55.2087 33.6154V29.1564L55.2498 23.6494L51.0579 35.2387H49.9072L45.4893 23.7727L45.5715 28.4578V33.5538C45.5715 33.8551 45.6126 34.0949 45.6948 34.2729C45.777 34.451 45.9482 34.5743 46.2085 34.6428L46.6194 34.7867V35.2387H43.7427Z"
            fill="#151623"
         />
         <path
            d="M18.4562 25.6146C16.8992 22.5729 14.9076 21.8818 12.204 20.1927C11.7024 19.9075 12.7265 19.6024 12.9004 19.3755C13.7149 18.8296 14.6329 18.3619 15.3744 17.736C17.1913 16.4242 18.371 14.5108 19.6637 12.7313C19.7723 12.5905 19.8436 12.6287 19.9245 12.7435C20.2905 13.3111 20.7051 13.8466 21.0581 14.4204C21.3954 14.8403 21.7135 15.2767 22.0082 15.7279C22.3899 16.1182 22.6924 16.5807 23.0697 16.9771C23.8503 17.7029 24.8022 18.1898 25.6542 18.8148C25.935 18.9948 26.2253 19.1599 26.487 19.3677C26.7252 19.5842 27.4293 19.7302 27.4276 20.0614C23.7964 22.4712 23.0897 22.3443 20.5782 26.2292C19.4542 28.0253 19.9114 27.6549 18.4553 25.6155L18.4562 25.6146Z"
            fill="#151623"
         />
         <path
            d="M36.0795 12.0387C36.781 12.2786 39.8654 13.6009 39.5046 14.4754C39.0543 15.9359 38.4136 17.4007 37.3869 18.5412C37.1731 18.6777 36.9418 19.3245 36.6732 19.0628C35.6518 18.017 34.4347 17.1972 33.1299 16.5461C32.9812 16.4331 32.5092 16.3975 32.6787 16.1367C33.1899 15.3439 33.6645 14.5163 34.0331 13.6409C34.1983 13.214 34.3513 12.7837 34.5147 12.3569C34.6503 12.2108 34.5303 12.0074 34.6408 11.8527C35.0015 11.491 35.6579 11.9448 36.0803 12.0378L36.0795 12.0387Z"
            fill="#151623"
         />
         <path
            d="M6.59119 23.877C6.02961 24.9202 5.43065 25.9512 5.06466 27.097C4.83256 27.3943 5.00729 28.215 4.49265 28.1671C2.86181 27.7629 1.17619 27.1196 0 25.88C0.109534 23.9822 1.2779 22.2201 2.488 20.7962C2.84355 20.4954 3.21997 21.316 3.52162 21.4742C4.38833 22.1845 5.32981 22.8278 6.38256 23.2259C6.79027 23.3511 6.96326 23.5441 6.59119 23.877Z"
            fill="#151623"
         />
         <path
            d="M11.6812 35.2227C18.9644 32.7147 14.1405 31.0334 18.8297 36.8457C19.3339 37.1769 17.3466 38.4105 17.0649 38.6539C16.2521 39.1668 15.3741 39.5815 14.4596 39.8805C14.2214 39.8831 13.9545 40.1457 13.7554 39.8953C13.5172 39.5815 13.2704 39.272 13.1182 38.9025C12.6636 38.2436 12.3471 37.5012 12.0716 36.7562C11.9629 36.252 11.6065 35.7469 11.6812 35.2236V35.2227Z"
            fill="#151623"
         />
         <path
            d="M32.6588 23.74C32.5945 23.4861 32.8283 23.4783 32.9544 23.4209C34.3853 22.802 35.658 21.8674 36.782 20.793C38.1564 21.924 39.0075 23.7652 39.4752 25.489C39.3613 26.5453 35.9796 28.0579 34.9451 28.1848C34.5444 28.2257 34.6191 27.6345 34.4861 27.3842C34.2349 26.567 33.8541 25.8029 33.4795 25.0466C33.1769 24.6302 32.9526 24.1625 32.6571 23.7408L32.6588 23.74Z"
            fill="#151623"
         />
         <path
            d="M13.1518 1.03824C13.4796 0.647919 13.7151 -0.234442 14.355 0.0585194C15.0878 0.261071 15.7937 0.539254 16.4587 0.906977C16.8334 1.11474 19.2406 2.59433 18.792 2.99769C17.7671 4.09478 16.9551 5.39528 16.3918 6.78793C16.1814 7.38603 15.4329 6.3637 15.093 6.24635C14.481 5.80473 13.7838 5.53959 13.111 5.21446C12.8363 5.00843 11.7061 4.86847 11.7305 4.56595C11.9643 3.29761 12.4998 2.12924 13.1527 1.03824H13.1518Z"
            fill="#151623"
         />
         <path
            d="M27.5687 3.90888C27.6035 4.18011 27.7417 4.45481 27.6887 4.72865C21.9442 6.76634 24.3375 8.49976 21.8851 4.46872V4.46698C20.4733 2.51101 19.9674 3.1743 21.5365 1.86511L21.5348 1.86337C21.8521 1.58605 22.2033 1.35916 22.5788 1.17052C23.2647 0.809749 25.1025 -0.0873898 25.7875 0.176884C26.6421 1.27049 27.201 2.57969 27.5687 3.90714V3.90888Z"
            fill="#151623"
         />
         <path
            d="M4.90821 17.2395C4.51963 17.5003 4.14408 17.7785 3.77462 18.0602C3.53642 18.2558 3.31909 18.4748 3.07916 18.6678C2.94703 18.7669 2.84792 18.8999 2.73056 19.0138C2.43413 19.3433 1.26141 17.4264 1.12493 17.1387C0.651149 16.3094 0.365142 15.3948 0.11217 14.4821C-0.0112739 14.183 0.0513172 14.0709 0.29125 13.8457C1.04234 13.1242 1.93861 12.6226 2.87226 12.1923C3.2991 12.1123 4.70653 11.2777 4.78738 11.9975C5.16379 13.4197 5.78188 14.7985 6.55992 16.0468C6.71553 16.2998 6.69902 16.3676 6.43909 16.485C5.89402 16.6658 5.40199 16.9553 4.90821 17.2404V17.2395Z"
            fill="#151623"
         />
         <path
            d="M26.4784 34.7458C26.7366 34.9883 27.8023 35.0814 27.7067 35.4326C27.4529 36.5505 26.5966 38.7821 25.6708 39.3915C25.6673 39.3871 25.6629 39.3819 25.6569 39.3749C25.6716 39.3932 25.6855 39.4114 25.6934 39.434C25.6221 39.6383 25.5769 39.6575 25.3135 39.5984C24.4172 39.4975 23.5601 39.1741 22.7707 38.7412C22.0448 38.4109 21.4798 37.8145 20.8287 37.3685C20.5626 37.139 20.4279 37.013 20.7278 36.7383C24.9805 32.2908 21.0442 31.8544 26.4792 34.7449L26.4784 34.7458Z"
            fill="#151623"
         />
         <path
            d="M30.608 10.3695C30.2655 10.4347 29.916 10.4634 29.5709 10.5086C29.158 10.6051 29.0597 10.5312 29.1214 10.1218C29.1675 8.78648 29.2388 7.42773 29.038 6.10462C28.9841 5.49088 28.9815 5.4761 29.5804 5.37439C30.9774 5.13794 32.4614 5.08838 33.8175 5.56043L33.8158 5.55869C34.087 6.13592 34.406 10.3226 33.7514 10.4156C32.7117 10.3339 31.6564 10.273 30.6071 10.3686L30.608 10.3695Z"
            fill="#151623"
         />
         <path
            d="M33.9547 33.3408C33.8478 33.6807 33.913 34.1971 33.4757 34.2901C32.1405 34.8247 30.6435 34.7378 29.2526 34.5535C28.9275 34.4813 28.9153 34.4161 28.9814 34.0927C29.2526 32.6558 29.1848 31.1649 29.1092 29.7105C29.0709 29.2437 29.5369 29.4749 29.8046 29.4775C31.0634 29.7105 32.3787 29.6366 33.6435 29.4775C33.9539 29.4549 33.9713 29.4636 34.0052 29.7731C34.1964 30.9493 34.1886 32.1715 33.9539 33.3408H33.9547Z"
            fill="#151623"
         />
         <path
            d="M5.55865 6.51398C5.67949 5.97847 5.70557 5.56642 6.3254 5.46123C7.63025 5.10568 9.04463 5.1135 10.3556 5.44384C10.4999 5.46818 10.4668 5.60641 10.4529 5.71072C10.2521 6.86257 10.2078 8.02399 10.2617 9.18975C10.173 9.51661 10.6946 10.6911 10.0861 10.5459C8.5978 10.199 7.06606 10.3277 5.55518 10.3746C5.31872 9.11151 5.31524 7.77015 5.55952 6.51398H5.55865Z"
            fill="#151623"
         />
         <path
            d="M6.07755 34.3862C5.25952 34.028 5.39514 30.369 5.55683 29.5223C5.84979 29.3824 6.19056 29.4815 6.50439 29.4649C7.35806 29.6545 8.26476 29.5727 9.13061 29.4771C9.42618 29.4276 9.73826 29.4962 10.0277 29.3719C10.4007 29.278 10.6232 29.3832 10.4815 29.7805C10.2555 30.8724 10.1694 32.0381 10.3511 33.1474C10.365 33.4603 10.3416 33.7716 10.3903 34.0845C10.4346 34.3662 10.3833 34.4201 10.0982 34.467C8.79592 34.7861 7.37023 34.7817 6.07668 34.3862H6.07755Z"
            fill="#151623"
         />
         <path
            d="M17.4063 7.07381C17.9313 5.83677 18.6877 4.71795 19.57 3.71302C19.7986 3.17404 21.2608 5.5186 21.4017 5.77678C21.5495 5.96108 21.6277 6.18449 21.7503 6.38357C21.912 6.80606 22.6283 7.63018 22.1901 8.00312C21.3226 8.79594 20.5976 9.68351 19.8465 10.5833C19.6848 10.8171 19.5057 10.525 19.4083 10.3746C18.7259 9.56876 18.1026 8.70292 17.3193 7.99008C17.0029 7.73276 17.3133 7.36677 17.4054 7.07294L17.4063 7.07381Z"
            fill="#151623"
         />
         <path
            d="M33.8173 18.2041C34.5753 18.6901 35.3038 19.2204 35.9515 19.8454C36.1749 20.0845 35.283 20.5991 35.1274 20.8025C34.218 21.4537 33.2774 22.1274 32.2308 22.522C31.9369 22.6785 31.9422 22.6716 31.7353 22.4056C28.3675 18.6058 28.6735 21.2667 31.7387 17.4165C32.0221 17.0314 33.4678 18.0181 33.8173 18.2041Z"
            fill="#151623"
         />
         <path
            d="M18.926 35.3008C18.2515 34.4349 17.7386 33.4648 17.2856 32.4703C17.2013 32.2851 17.0909 32.0991 17.2778 31.9087C18.088 31.2141 18.8174 30.443 19.4624 29.5928C19.7215 29.2512 19.7241 29.2521 19.9805 29.585C20.5743 30.39 21.2689 31.1133 22.0104 31.7818C22.5094 32.166 22.399 32.0713 22.1947 32.5607C21.6722 33.8238 20.8472 34.9218 19.9884 35.9736C19.5946 36.3188 19.6971 36.231 19.3859 35.8841C19.2225 35.7015 19.0843 35.4912 18.926 35.2999V35.3008Z"
            fill="#151623"
         />
         <path
            d="M12.2022 5.9876C13.4679 6.44574 14.605 7.19335 15.6534 8.02877C15.7881 8.13657 15.8038 8.24002 15.7525 8.38432C15.4326 9.27712 15.3022 10.2099 15.1735 11.1427C15.0666 11.4235 15.3656 12.2858 14.8171 11.9937C13.9156 11.6165 12.9915 11.3018 12.0674 10.9845C11.7753 10.8358 11.5084 10.8628 11.4893 10.455C11.2572 9.03718 11.2598 7.59237 11.4502 6.17016C11.4745 5.64335 11.8805 5.92241 12.2022 5.9876Z"
            fill="#151623"
         />
         <path
            d="M24.1435 10.1094C24.0575 9.59128 23.9714 9.07229 23.8141 8.56983C23.6871 8.16472 23.7306 8.04302 24.1105 7.76918C24.8825 7.21282 25.6935 6.72513 26.5602 6.33045C26.908 6.11921 27.3148 6.03836 27.6817 5.85928C27.8338 5.79147 27.9416 5.81408 27.9868 5.9975C28.2476 7.28497 28.1459 8.62372 28.112 9.93205C27.9529 10.3067 28.1511 10.8283 27.6173 10.9013C26.6385 11.2604 25.6162 11.5368 24.653 11.9532C24.4043 12.1193 24.3287 11.6742 24.3148 11.4455C24.3148 10.9926 24.2157 10.5475 24.1435 10.1094Z"
            fill="#151623"
         />
         <path
            d="M8.64162 18.6684C10.0012 20.1697 10.668 19.6768 9.33534 20.6713C8.72334 21.1659 8.20001 21.7492 7.6845 22.3386C7.56019 22.4803 7.44891 22.5351 7.2594 22.456C6.1771 22.0092 5.15738 21.4154 4.25155 20.673C4.05161 20.4244 3.26748 20.0723 3.57609 19.7228C4.28806 19.0143 5.08523 18.4163 5.94673 17.9042C7.91226 16.9158 7.28635 17.2175 8.64249 18.6692L8.64162 18.6684Z"
            fill="#151623"
         />
         <path
            d="M10.7702 27.9234C10.6337 28.6876 6.64789 28.4276 5.90462 28.352C5.73076 28.099 5.95156 27.7843 6.03328 27.5305C6.41752 26.2639 7.05473 25.0834 7.798 23.9932C7.86494 23.8898 7.9397 23.8872 8.05619 23.9237C9.01679 24.3218 10.0635 24.3679 11.0597 24.6078C11.324 24.6783 11.9873 24.5035 11.7091 25.0147C11.2796 25.9483 10.9954 26.9341 10.7693 27.9234H10.7702Z"
            fill="#151623"
         />
         <path
            d="M14.322 32.8632C13.9326 33.0223 11.4263 34.6158 11.4237 33.8429C11.3663 32.2955 11.2629 30.7047 11.5993 29.186C12.2896 28.9113 13.0259 28.7513 13.7257 28.4888H13.7248C14.0621 28.3367 14.4411 28.2914 14.7636 28.0793C14.9332 27.975 15.067 28.0646 15.1435 28.2523C15.2331 29.3981 15.5182 30.5117 15.8451 31.614C16.1128 31.8948 14.5741 32.7189 14.322 32.8623V32.8632Z"
            fill="#151623"
         />
         <path
            d="M24.1392 30.0474C24.6677 27.8211 23.4715 27.5081 25.9761 28.5026C28.6423 29.4267 27.9911 28.5791 28.1546 32.1355C28.0633 32.478 28.2137 34.3897 27.6339 34.0541C26.7602 33.6985 25.8726 33.3752 25.0972 32.8284C24.6921 32.565 24.3044 32.2755 23.9123 31.9938C23.5228 31.7078 23.7697 31.5183 23.8488 31.1619C23.9931 31.0071 23.9262 30.7881 24.0131 30.6159C24.0618 30.4282 24.0835 30.2343 24.1392 30.0474Z"
            fill="#151623"
         />
         <path
            d="M11.5899 14.986C11.6352 15.2433 11.6334 15.2268 11.3596 15.2554C10.2269 15.3459 9.10804 15.571 8.02573 15.9179C7.87534 15.9665 7.75972 15.9318 7.65975 15.7805C7.35288 15.398 5.15871 11.4191 6.09323 11.4487C7.56238 11.3496 9.075 11.3331 10.4946 11.7651C10.7824 11.8494 10.6676 12.2102 10.7641 12.4241C10.9797 13.2925 11.244 14.1566 11.5908 14.9851L11.5899 14.986Z"
            fill="#151623"
         />
         <path
            d="M30.7409 15.7196C29.809 15.498 28.8649 15.3337 27.9174 15.2432C27.7739 15.1624 27.8731 15.0329 27.8548 14.9277C28.2077 14.0019 28.5494 13.0612 28.7763 12.0998C28.7467 11.7399 29.0614 11.6486 29.3431 11.6164C29.9047 11.5243 30.4749 11.4452 31.0565 11.3861C31.5068 11.4704 33.7349 11.1522 33.6888 11.6842C33.2011 12.9917 32.6282 14.3044 31.8345 15.4614L31.8293 15.4597C31.5564 16.0813 31.2599 15.8752 30.7418 15.7196H30.7409Z"
            fill="#151623"
         />
         <path
            d="M32.5206 25.5308C32.6554 25.8403 34.0541 28.4813 33.4673 28.4187C31.9817 28.6151 30.4664 28.563 28.9955 28.2778C28.7434 28.1631 28.8286 27.9049 28.7469 27.6989C28.64 27.4181 28.6165 27.1147 28.5313 26.8296C28.3288 26.2202 28.1245 25.6142 27.9558 24.9979C27.9367 24.5519 28.64 24.7241 28.9382 24.6267C31.9173 24.1564 31.2184 23.1462 32.5206 25.5308Z"
            fill="#151623"
         />
         <path
            d="M8.89668 17.3679C8.75932 17.2775 8.71934 17.1115 8.60893 16.9994C8.51505 16.9046 8.62023 16.8768 8.68109 16.855C9.75122 16.496 10.8796 16.3187 12.0071 16.25C12.3783 16.3761 12.6478 17.3766 12.9251 17.7313C13.0068 17.8808 12.986 17.9721 12.8356 18.0686C10.7414 19.2439 11.4925 19.8586 9.68602 18.1851C9.41479 17.92 9.16443 17.6348 8.89755 17.3679H8.89668Z"
            fill="#151623"
         />
         <path
            d="M12.8988 22.3581C12.0304 24.1011 12.312 23.6317 10.3961 23.4257C10.1326 23.3083 8.37054 23.2353 8.77129 22.7937C8.99036 22.5364 9.22247 22.2903 9.46501 22.0547H9.46588C9.87533 21.7696 10.7351 20.4204 11.2541 20.7621C11.6227 21.1706 13.3361 21.7018 12.8988 22.359V22.3581Z"
            fill="#151623"
         />
         <path
            d="M28.7493 18.8952C28.5806 19.0186 28.3955 19.3003 28.1694 19.1351C27.627 18.7769 27.0428 18.484 26.529 18.0806C26.2326 17.8685 26.5794 17.7372 26.6212 17.5077C26.7716 17.213 26.9333 16.9244 27.1002 16.6384C27.2036 15.9994 27.8365 16.3324 28.2659 16.3359C29.037 16.3593 29.7612 16.6427 30.5201 16.7314C31.4137 16.8436 29.0049 18.6526 28.7501 18.8952H28.7493Z"
            fill="#151623"
         />
         <path
            d="M27.0924 23.2697C26.9629 22.8107 26.2144 22.2161 26.7021 21.8006C27.0055 21.5841 27.3098 21.3702 27.6227 21.1677C27.9331 20.986 28.2382 20.4409 28.6059 20.826C29.3761 21.4633 30.1029 22.1483 30.7358 22.9246C30.9496 23.2845 28.8259 23.5609 28.5294 23.6209C28.1982 23.6922 27.8592 23.6513 27.5254 23.6974C27.288 23.7174 27.1941 23.4357 27.0933 23.2697H27.0924Z"
            fill="#151623"
         />
         <path
            d="M23.3127 27.4009C23.3727 28.4893 23.1553 29.5681 22.9206 30.6261C22.8945 30.7425 22.8789 30.9025 22.7746 30.9355C21.9705 30.4383 21.3437 29.5238 20.6882 28.8196C20.4935 28.5997 20.3335 28.2945 20.5961 28.0694C21.0159 27.6156 21.2733 27.0566 21.6853 26.6107C21.7514 26.5507 21.807 26.5394 21.894 26.5933C22.3486 26.8958 22.8624 27.1036 23.3127 27.4017V27.4009Z"
            fill="#151623"
         />
         <path
            d="M16.6707 9.1192C17.0445 8.84971 18.6371 11.1908 18.9926 11.5542C19.2274 11.8636 18.1816 12.7929 18.0373 13.192C17.7739 13.5693 17.1158 12.9642 16.7802 12.8721C16.5611 12.7582 16.1604 12.7312 16.15 12.4165C16.1195 11.6176 16.2891 10.837 16.3986 10.0502C16.5194 9.75207 16.5281 9.41998 16.6698 9.12007L16.6707 9.1192Z"
            fill="#151623"
         />
         <path
            d="M20.582 11.3315C21.2714 10.5665 21.8964 9.57893 22.7353 9.02344C23.0257 9.25381 23.0222 10.3422 23.2161 10.6795C23.2891 12.4712 23.9385 12.2851 22.1729 13.1006C21.946 13.2449 21.6408 13.457 21.4505 13.1458C21.1549 12.7328 20.8645 12.3156 20.5811 11.8939C20.3473 11.6114 20.3473 11.5749 20.5811 11.3306L20.582 11.3315Z"
            fill="#151623"
         />
         <path
            d="M17.6777 26.7082C17.8411 26.5908 18.0454 26.8647 18.1019 27.009C18.2992 27.2646 18.4601 27.5427 18.6643 27.794C18.9208 28.0635 19.179 28.4181 18.8026 28.7198C18.2879 29.5091 17.5734 30.1689 16.9075 30.8401C16.5693 31.4329 16.0607 27.8148 16.1555 27.5966C16.2129 27.3124 16.5962 27.3228 16.7988 27.1785C17.0944 27.0255 17.3725 26.8429 17.6777 26.7091V26.7082Z"
            fill="#151623"
         />
         <path
            d="M26.8743 27.6647C26.384 27.463 24.2342 26.9692 24.4167 26.3563C24.535 26.0017 24.1255 24.6681 24.6749 24.7455C26.9665 24.908 26.5709 24.0744 27.3064 26.3172C27.4263 26.6189 27.4889 26.9492 27.5767 27.2665C27.8358 28.0724 27.4003 27.8203 26.8743 27.6647Z"
            fill="#151623"
         />
         <path
            d="M14.4613 27.0537C14.1509 27.1511 11.6056 28.3864 11.9376 27.5362C12.1176 27.0633 12.6514 24.6518 13.1512 24.7483C13.7137 24.7353 14.2779 24.7822 14.8386 24.7048C15.0437 24.6762 15.1228 24.7561 15.1124 24.9691C15.0846 25.5307 15.0481 26.0931 15.0663 26.6556C15.0472 26.9286 14.6604 26.9486 14.4613 27.0537Z"
            fill="#151623"
         />
         <path
            d="M12.2002 13.5922C12.1533 13.3271 11.5004 12.004 12.0429 12.1161C12.8262 12.3317 13.6007 12.6229 14.3292 12.9828C14.5996 13.0941 15.1394 13.1332 15.109 13.5209C15.0916 13.9904 15.1255 14.4563 15.1881 14.9214C15.2055 15.0466 15.2029 15.157 15.0273 15.1561C12.7957 14.9979 12.9679 15.8646 12.2002 13.5939V13.5922Z"
            fill="#151623"
         />
         <path
            d="M27.3174 12.1586C27.6956 12.1325 27.6834 12.129 27.5887 12.5046C27.4139 12.9957 26.8767 15.2151 26.3594 15.1934C23.9601 15.0386 24.4417 15.8262 24.4348 13.3513C24.3913 13.0279 26.9141 12.2681 27.3174 12.1577V12.1586Z"
            fill="#151623"
         />
         <path
            d="M15.2822 16.3582C15.2683 16.5416 13.9026 17.5648 13.8409 17.1493C13.7974 16.9311 13.0768 16.1991 13.5401 16.1904C13.8105 16.2539 15.3944 16.0244 15.2831 16.3591L15.2822 16.3582Z"
            fill="#151623"
         />
         <path
            d="M13.5874 23.3566C14.0446 22.163 14.0672 22.6612 14.9339 23.1436C15.0913 23.1819 15.2808 23.4192 15.1939 23.587C14.8192 23.8556 14.1915 23.6383 13.7369 23.7044C13.4005 23.7409 13.5057 23.5809 13.5882 23.3566H13.5874Z"
            fill="#151623"
         />
         <path
            d="M25.7907 16.7578C25.3404 17.6567 25.4508 17.242 24.4842 16.683C24.2538 16.5222 24.1538 16.1719 24.5485 16.244C24.9223 16.2388 25.2952 16.2406 25.669 16.2423C26.1263 16.2319 25.9255 16.4996 25.7907 16.7578Z"
            fill="#151623"
         />
         <path
            d="M24.707 23.1421C25.2008 22.8821 25.4703 22.3727 25.7902 23.1403C25.8659 23.3342 26.2649 23.7463 25.8163 23.7184C25.4025 23.6941 23.683 23.8401 24.707 23.1429V23.1421Z"
            fill="#151623"
         />
         <path
            d="M22.3718 25.7423C22.3519 25.3798 22.7352 25.0556 22.936 24.7609C23.3898 24.2775 23.3385 25.2999 23.3568 25.5546C23.5411 26.6108 22.8691 26.157 22.3718 25.7415V25.7423Z"
            fill="#151623"
         />
         <path
            d="M16.4137 13.8039C16.658 13.9074 17.4508 14.143 17.1248 14.4881C16.8353 14.8558 16.199 15.8573 16.2372 14.7341C16.2929 14.4655 15.8904 13.6518 16.4137 13.8039Z"
            fill="#151623"
         />
         <path
            d="M22.5016 14.6227C22.4216 14.4602 22.2104 14.3072 22.3617 14.1551C22.5877 14.1264 23.4222 13.4805 23.3909 13.9447C23.311 14.1577 23.3779 15.4964 23.0667 15.3173C22.9093 15.06 22.7163 14.8323 22.5016 14.6219V14.6227Z"
            fill="#151623"
         />
         <path
            d="M17.0133 25.8659C16.7403 25.8833 16.07 26.5718 16.1187 25.9476C16.1918 25.6433 16.1266 24.854 16.3048 24.6758C16.5099 24.7332 16.6238 25.007 16.7925 25.1426C16.8377 25.4043 17.3732 25.6355 17.0141 25.8659H17.0133Z"
            fill="#151623"
         />
      </svg>
   );
};

export default LogoDark;
