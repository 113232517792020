import { memo } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import { getAlertsData } from "./selectors";

import AlertComponent from "./AlertComponent";

function AlertsHolder() {
   const alertsData = useSelector(getAlertsData);

   return (
      <Box
         sx={{
            display: "flex",
            position: "fixed",
            bottom: "24px",
            right: "24px",
            flexDirection: "column",
            gap: "8px",
            zIndex: 100,
            pointerEvents: "none",
         }}
      >
         {alertsData.map((alert, index) => (
            <AlertComponent {...alert} key={index} />
         ))}
      </Box>
   );
}

export default memo(AlertsHolder);
