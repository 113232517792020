import { useState } from "react";
import dayjs from "dayjs";
import Dropzone from "react-dropzone";
import { t } from "i18next";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import NovaButton from "../shared/NovaButton";
import EditIcon from "@mui/icons-material/Edit";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import NovaText, { NovaTextTypes } from "../shared/NovaText";
import { UploadIcon } from "../shared/icons/upload-icon";
import MarkdownEditor from "../md-editor/markdown-editor";
import NovaTextInput from "../shared/NovaTextInput";
import { useSelector } from "react-redux";
import { HomeSelectors } from "../../pages/home/selectors";
import { limitations } from "../../pages/memorial-page/limitations";
import TranslatedText from "../translated-text";

export const NovaModal = ({
   generalInformation,
   onSave,
   handleClose,
}: {
   generalInformation: any;
   onSave: (form: any) => void;
   handleClose: () => void;
}) => {
   const editMemorialPageData = useSelector(HomeSelectors.getEditedMemorialPage);

   const [form, setForm] = useState<any>({
      photoBase64: null,
      description: "",
      date: null,
      name: "",
   });

   const maxMemoryDescriptionLength =
      limitations[
         editMemorialPageData?.subscription
            ? editMemorialPageData.subscription.subscriptionPlan.type
            : "free"
      ]?.memoryDescriptionLength;

   return (
      <Modal open={true} onClose={handleClose}>
         <Box
            sx={{
               position: "absolute",
               top: "50%",
               left: "50%",
               transform: "translate(-50%, -50%)",
               width: 400,
               background: "#fff",
               borderRadius: "24px",
               boxShadow: 24,
               overflow: "hidden",
               outline: "none",
            }}
         >
            <Box sx={{ borderBottom: "1px solid #CAD1E2" }}>
               <Dropzone
                  accept={{
                     "image/png": [".png"],
                     "image/jpeg": [".jpg"],
                  }}
                  onDrop={(acceptedFiles) => {
                     if (acceptedFiles.length > 0) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                           const imageDataUrl = reader.result;
                           setForm({ ...form, photoBase64: imageDataUrl });
                        };
                        reader.readAsDataURL(acceptedFiles[0]);
                     }
                  }}
               >
                  {({ getRootProps, getInputProps }) => (
                     <Box
                        sx={{
                           maxWidth: "100%",
                           width: "100%",
                           maxHeight: "180px",
                           height: "100%",
                           borderRadius: "16px",
                           cursor: "pointer",
                        }}
                     >
                        <div
                           {...getRootProps()}
                           style={{
                              height: "100%",
                           }}
                        >
                           <input {...getInputProps()} />
                           <div
                              style={{
                                 width: "100%",
                                 height: "180px",
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                              }}
                           >
                              {form.photoBase64 && (
                                 <Box
                                    sx={{
                                       width: "100%",
                                       height: "100%",
                                       position: "relative",
                                       "&:hover > .edit-button": {
                                          display: "inline-flex",
                                          background: "#fff",
                                       },
                                    }}
                                 >
                                    <Box
                                       sx={{
                                          background: `url(${form.photoBase64})`,
                                          backgroundSize: "cover",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center center",
                                          width: "100%",
                                          height: "100%",
                                       }}
                                    />
                                    <Box
                                       className="edit-button"
                                       sx={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                          background: "rgba(255, 255, 255, .8)",
                                          display: "none",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          border: "1px solid #E9DDC8",
                                          borderRadius: "50%",
                                          width: "50px",
                                          height: "50px",
                                          color: "#1B1B48",
                                       }}
                                    >
                                       <EditIcon />
                                    </Box>
                                 </Box>
                              )}
                              {!form.photoBase64 && (
                                 <Box
                                    sx={{
                                       display: "inline-flex",
                                       alignItems: "center",
                                       gap: "4px",
                                       border: "1px solid #E9DDC8",
                                       borderRadius: "32px",
                                       padding: "10px 24px",
                                    }}
                                 >
                                    <UploadIcon />
                                    Upload
                                 </Box>
                              )}
                           </div>
                        </div>
                     </Box>
                  )}
               </Dropzone>
            </Box>

            <Box
               sx={{
                  padding: "16px 18px",
                  display: "flex",
                  flexDirection: "column",
               }}
            >
               <NovaText
                  type={NovaTextTypes.LGBold}
                  style={{
                     margin: 0,
                  }}
               >
                  <TranslatedText text="create-memorial-page.memories.leave-a-memory" />
               </NovaText>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                  }}
               >
                  <Box
                     sx={{
                        marginTop: "8px",
                     }}
                  >
                     <NovaTextInput
                        label={t("create-memorial-page.memories.memory-title")}
                        value={form.name}
                        fullwidth
                        onChange={(event) =>
                           setForm({ ...form, name: event.target.value })
                        }
                     />
                  </Box>
                  <Box
                     sx={{
                        marginTop: "24px",
                     }}
                  >
                     <MarkdownEditor
                        value={form.description}
                        placeholder={t("create-memorial-page.memories.share-your-memory")}
                        maxSymbols={maxMemoryDescriptionLength}
                        onChange={(event) => setForm({ ...form, description: event })}
                     />
                  </Box>
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        margin: "16px 0 0 0",
                     }}
                  >
                     <TranslatedText text="create-memorial-page.memories.date" />
                  </NovaText>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                     <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                           sx={{
                              width: "100%",
                           }}
                           value={form.date && dayjs(form.date)}
                           slotProps={{ textField: { size: "small" } }}
                           openTo="year"
                           views={["year", "month", "day"]}
                           minDate={dayjs("01-01-1000")}
                           maxDate={dayjs()}
                           onChange={(event) =>
                              setForm({
                                 ...form,
                                 date: dayjs(event).format("YYYY-MM-DD"),
                              })
                           }
                        />
                     </DemoContainer>
                  </LocalizationProvider>
               </Box>
            </Box>

            <Box
               sx={{
                  padding: "12px 12px 24px 12px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
               }}
            >
               <NovaButton
                  disabled={
                     !form.photoBase64 ||
                     !form.description ||
                     !form.date ||
                     !form.name ||
                     form.description.length > maxMemoryDescriptionLength
                  }
                  style={{
                     width: "100%",
                     boxSizing: "border-box",
                     borderRadius: "34px",
                  }}
                  onClick={() => onSave(form)}
               >
                  <TranslatedText text="buttons.save" />
               </NovaButton>
               <NovaButton
                  style={{
                     width: "100%",
                     boxSizing: "border-box",
                     borderRadius: "34px",
                  }}
                  background="#fff"
                  onClick={handleClose}
               >
                  <TranslatedText text="buttons.cancel" />
               </NovaButton>
            </Box>
         </Box>
      </Modal>
   );
};
