import { useMemo } from "react";
import { Box } from "@mui/material";

import { STEPS } from ".";

import NovaButton from "../shared/NovaButton";
import NovaTitle from "../shared/NovaTitle";
import SubscriptionPlanCard from "../subscriptions/subscription-plan-card";
import TranslatedText from "../translated-text";

const PlansStep = ({
   page,
   plans,
   selectedPlan,
   onStepChange,
   onPlanSelect,
}: {
   page: any;
   plans: any;
   selectedPlan: any;
   onStepChange: (step: any) => void;
   onPlanSelect: (id: number) => void;
}) => {
   const readyPlans = useMemo(
      () =>
         plans
            .filter((plan: any) => plan.type !== 0)
            .map((plan: any) =>
               plan.type === 1
                  ? {
                       ...plan,
                       monthly: plans.find((p: any) => p.type === 0),
                    }
                  : plan,
            ),
      [plans],
   );

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
         }}
      >
         <NovaTitle
            variant="h3"
            style={{
               textAlign: "center",
               margin: 0,
            }}
         >
            <TranslatedText text="plans.title" />
         </NovaTitle>

         <Box
            sx={{
               display: "flex",
               flexDirection: {
                  xs: "column",
                  sm: "row",
               },
               alignItems: "stretch",
               gap: "10px",
               overflow: {
                  xs: "scroll",
                  sm: "visible",
               },
               height: {
                  xs: "calc(100vh - 300px)",
                  sm: "100%",
               },
               padding: {
                  xs: "0 0 16px 0",
                  sm: 0,
               },
            }}
         >
            {readyPlans.map((plan: any) => (
               <SubscriptionPlanCard
                  {...plan}
                  page={page}
                  selectedPlan={selectedPlan}
                  setSelectedPlan={onPlanSelect}
                  key={plan.id}
               />
            ))}
         </Box>

         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               gap: "16px",
               width: "100%",
               justifyContent: "flex-end",
            }}
         >
            <NovaButton
               style={{
                  borderRadius: "32px",
                  border: "1px solid #CAA45D",
                  background: "#fff",
                  color: "#1B1B48",
               }}
               onClick={() => onStepChange(STEPS.UPGRADE)}
            >
               <TranslatedText text="buttons.back" />
            </NovaButton>
            <NovaButton
               disabled={!selectedPlan}
               style={{ borderRadius: "32px" }}
               onClick={() => onStepChange(STEPS.CONFIRM)}
            >
               <TranslatedText text="buttons.next" />
            </NovaButton>
         </Box>
      </Box>
   );
};

export default PlansStep;
