import { KeyboardBackspace } from "@mui/icons-material";
import { Box, Container } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import { agencies } from "./agencies";
import VouchersTable from "./vouchers-table";
import { columns } from "./columns";

const SingleAgency = () => {
   const { agencyId }: any = useParams();

   const usedVouchers = agencies[agencyId].vouchers.filter((v: any) => {
      return v.usageStatus === "used" && v.paymentStatus === "paid";
   });

   const usedAndNotPaidVouchers = agencies[agencyId].vouchers.filter((v: any) => {
      return v.usageStatus === "used" && v.paymentStatus === "pending payment";
   });

   const currentNetAmount = usedAndNotPaidVouchers.reduce(
      (sum, transaction) => sum + transaction.netAmount,
      0,
   );
   const totalNetAmount = usedVouchers.reduce(
      (sum, transaction) => sum + transaction.netAmount,
      0,
   );

   return (
      <Container
         sx={{
            padding: "32px 0 120px 0",
         }}
      >
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between",
            }}
         >
            <Link
               to="../vouchers"
               style={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "8px",
                  textDecoration: "none",
                  color: "#1B1B48",
                  borderRadius: "32px",
                  background: "#fff",
                  padding: "12px 24px",
                  boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.05)",
               }}
            >
               <KeyboardBackspace />
               Back to Agencies
            </Link>
         </Box>
         <Box
            sx={{
               marginTop: "36px",
               display: "flex",
               alignItems: "center",
               gap: "36px",
            }}
         >
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "column",
               }}
            >
               <NovaText
                  type={NovaTextTypes.LGBold}
                  style={{ fontWeight: "900", margin: 0 }}
               >
                  {agencies[Number(agencyId)].name}
               </NovaText>
               <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
                  {agencies[Number(agencyId)].usedVouchers} used vouchers from{" "}
                  <b>{agencies[Number(agencyId)].totalVouchers}</b> in total
               </NovaText>
            </Box>
            <Box>
               <NovaText
                  type={NovaTextTypes.LGBold}
                  style={{ fontWeight: "900", margin: 0 }}
               >
                  {currentNetAmount}
                  {agencies[agencyId].currency}
               </NovaText>
               <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
                  Accumulated net amount
               </NovaText>
            </Box>
            <Box>
               <NovaText
                  type={NovaTextTypes.LGBold}
                  style={{ fontWeight: "900", margin: 0 }}
               >
                  {totalNetAmount}
                  {agencies[agencyId].currency}
               </NovaText>
               <NovaText type={NovaTextTypes.BaseNormal} style={{ margin: 0 }}>
                  Paid net amount
               </NovaText>
            </Box>
         </Box>
         <Box sx={{ marginTop: "24px" }}>
            <VouchersTable rows={agencies[agencyId].vouchers} columns={columns} />
         </Box>
      </Container>
   );
};

export default SingleAgency;
