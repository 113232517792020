import { Container, Grid } from "@mui/material";
import Widget from "./widget";
import { useEffect } from "react";
import { getOrders } from "../orders/async-actions";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";
import { OrdersSelectors } from "../orders/selectors";

const Dashboard = () => {
   const dispatch = useAppDispatch();
   const orders = useSelector(OrdersSelectors.getOrdersList);

   useEffect(() => {
      dispatch(getOrders());
   }, []);

   return (
      <Container
         sx={{
            padding: "32px 0 120px 0",
         }}
      >
         <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
               <Widget data={orders && orders.length} />
            </Grid>
         </Grid>
      </Container>
   );
};

export default Dashboard;
