import dayjs from "dayjs";
import { memo, useMemo } from "react";
import { Box } from "@mui/material";

import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import NovaTitle from "../../components/shared/NovaTitle";
import MarkdownRenderer from "../../components/md-editor/markdown-renderer";
import NovaButton from "../../components/shared/NovaButton";
import TranslatedText from "../../components/translated-text";

type TributeProps = {
   image: string;
   name: string;
   description: string;
   birthDay: string;
   inEditMode?: boolean;
   onClickImage?: () => void;
   onClickRemove?: () => void;
};

function Tribute({
   image,
   name,
   description,
   birthDay,
   inEditMode,
   onClickImage,
   onClickRemove,
}: TributeProps) {
   const date = useMemo<string>(() => {
      return birthDay ? dayjs(birthDay).format("DD/MM/YYYY") : "";
   }, [birthDay]);

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: {
               xs: "column",
               md: "row",
            },
            gap: "20px",
         }}
      >
         <Box
            sx={{
               width: {
                  xs: "100%",
                  md: "200px",
               },
               height: {
                  xs: "240px",
                  md: "200px",
               },
               backgroundColor: "#F5F5F5",
               borderRadius: "16px",
               flexShrink: 0,
               overflow: "hidden",
            }}
         >
            <img
               src={image}
               alt="Profile"
               width={200}
               height={200}
               style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
               }}
               onClick={onClickImage}
            />
         </Box>

         <Box
            sx={{
               flex: 1,
               display: "flex",
               flexDirection: "column",
            }}
         >
            <NovaTitle
               variant="h4"
               style={{
                  margin: 0,
               }}
            >
               {name}
            </NovaTitle>

            <Box
               sx={{
                  marginBlock: "20px",
               }}
            >
               <MarkdownRenderer markdownText={description}>
                  {(parsedText: any) => (
                     <NovaText type={NovaTextTypes.BaseNormal} renderHTML>
                        {parsedText.__html}
                     </NovaText>
                  )}
               </MarkdownRenderer>
            </Box>

            <NovaText
               type={NovaTextTypes.BaseNormal}
               style={{
                  color: "#CAA45D",
                  margin: 0,
               }}
            >
               {date}
            </NovaText>
         </Box>

         {inEditMode && (
            <NovaButton
               color="inherit"
               style={{
                  borderRadius: "32px",
                  background: "#fff",
                  color: "#000",
                  border: "1px solid #CAA45D",
                  ":hover": {
                     border: "1px solid #1B1B48",
                  },
                  alignSelf: "flex-start",
               }}
               onClick={onClickRemove}
            >
               <TranslatedText text="buttons.remove" />
            </NovaButton>
         )}
      </Box>
   );
}

export default memo(Tribute);
