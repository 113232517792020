import { GET_ALL_PAYMENTS_FAIL, GET_ALL_PAYMENTS_SUCCESS } from "./types";

const getPaymentsSuccess = (payload: any) => ({
   type: GET_ALL_PAYMENTS_SUCCESS,
   payload,
});

const getPaymentsFail = () => ({
   type: GET_ALL_PAYMENTS_FAIL,
});

export const PaymentsActions = {
   getPaymentsSuccess,
   getPaymentsFail,
};
