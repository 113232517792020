import { memo } from "react";
import { Box, Modal } from "@mui/material";

import NovaTitle from "./shared/NovaTitle";
import NovaText, { NovaTextTypes } from "./shared/NovaText";
import NovaButton from "./shared/NovaButton";
import TranslatedText from "./translated-text";

type ConfirmationModalProps = {
   isOpened?: boolean;
   /** @default "Are you sure you want to continue?" */
   message?: string;
   onCancelCallback?: () => void;
   onConfirmCallback?: () => void;
};

function ConfirmationModal({
   isOpened = false,
   message = "Are you sure you want to continue?",
   onCancelCallback,
   onConfirmCallback,
}: ConfirmationModalProps) {
   return (
      <Modal open={isOpened} onClose={onCancelCallback}>
         <Box
            sx={{
               position: "absolute",
               width: "100%",
               maxWidth: "550px",
               top: "50%",
               left: "50%",
               transform: "translate(-50%, -50%)",
               backgroundColor: "#ffffff",
               borderRadius: "24px",
               boxShadow: "24",
               padding: "40px 40px 20px 40px",
               outline: "none",
            }}
         >
            <NovaTitle
               variant="h2"
               style={{
                  marginTop: "0px",
                  marginBottom: "20px",
               }}
            >
               <TranslatedText text="messages.confirmation-needed.title" />
            </NovaTitle>
            <NovaText
               type={NovaTextTypes.BaseNormal}
               style={{
                  marginBottom: "40px",
               }}
            >
               {message}
            </NovaText>

            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "10px",
               }}
            >
               <NovaButton
                  onClick={onConfirmCallback}
                  style={{
                     backgroundColor: "transparent",
                     border: "1px solid #CAA45D",
                     ":hover": {
                        border: "1px solid #CAA45D",
                     },
                  }}
               >
                  <TranslatedText text="buttons.confirm" />
               </NovaButton>
               <NovaButton onClick={onCancelCallback}>
                  <TranslatedText text="buttons.cancel" />
               </NovaButton>
            </Box>
         </Box>
      </Modal>
   );
}

export default memo(ConfirmationModal);
