import { memo, useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useAppDispatch } from "../../store/hooks";
import { Alert } from "./reducer";

import { AlertsActions } from "./actions";
import NovaText, { NovaTextTypes } from "../shared/NovaText";

const alertColor: Record<Alert["type"], string> = {
   info: "#CAA45D",
   success: "#1B1B48",
   warn: "#ffcb21",
   error: "#e22739",
};

const alertLifeSpan = 3.4;

type AlertProps = Alert & {};

function AlertComponent({ id, type, message }: AlertProps) {
   const dispatch = useAppDispatch();

   const [isComing, setIsComing] = useState<boolean>(true);
   const [isClosing, setIsClosing] = useState<boolean>(false);

   const onClickX = useCallback(() => {
      setIsClosing(true);
      setTimeout(() => {
         dispatch(
            AlertsActions.removeAlert({
               alertId: id,
            }),
         );
      }, 0.2 * 1000);
   }, [id]);

   useEffect(() => {
      setTimeout(() => {
         setIsComing(false);
      }, 0.2 * 1000);
   }, []);

   useEffect(() => {
      setTimeout(() => {
         onClickX();
      }, alertLifeSpan * 1000);
   }, []);

   return (
      <Box
         sx={{
            display: "flex",
            minWidth: "300px",
            backgroundColor: alertColor[type],
            borderRadius: "10px",
            alignItems: "center",
            padding: "8px 12px",
            pointerEvents: "all",
            transform: isComing
               ? "translateY(100%)"
               : isClosing
               ? "translateX(100%)"
               : undefined,
            opacity: isClosing || isComing ? 0 : 1,
         }}
      >
         <Box
            sx={{
               flex: 1,
            }}
         >
            <NovaText
               type={NovaTextTypes.BaseNormal}
               style={{
                  fontWeight: 700,
                  color: "#ffffff",
                  margin: "0px",
               }}
            >
               {message}
            </NovaText>
         </Box>
         <Box
            sx={{
               cursor: "pointer",
            }}
            onClick={onClickX}
         >
            <CloseIcon
               sx={{
                  fontSize: "18px",
                  color: "#ffffff",
               }}
            />
         </Box>
      </Box>
   );
}

export default memo(AlertComponent);
