import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import { Box, Container, FormControl, MenuItem, Select } from "@mui/material";
import NovaButton from "../../components/shared/NovaButton";
import LoaderOverlay from "../../components/LoaderOverlay";
import NovaTextInput from "../../components/shared/NovaTextInput";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import { getSubscriptionPlans } from "../subscriptions/async-actions";
import { useAppDispatch } from "../../store/hooks";
import { SubscriptionsSelectors } from "../subscriptions/selectors";
import { createVoucher } from "./async-actions";

const CreateVoucher = () => {
   const dispatch = useAppDispatch();
   const [isLoading, setIsLoading] = useState<boolean>(false);

   const [selectedPlan, setSelectedPlan] = useState(0);
   const [agency, setAgency] = useState(0);
   const [currency, setCurrency] = useState(0);
   const [commissionForAffiliate, setCommissionForAffiliate] = useState("");
   const [voucherCode, setVoucherCode] = useState("");

   const affiliatePlans = useSelector(
      SubscriptionsSelectors.getAffiliatesSubscriptionsList,
   );

   const handleSelectedPlan = (event: any) => {
      setSelectedPlan(event.target.value);
      // dispatch(changeOrderStatus(orderId, event.target.value));
   };

   const handleAgencyChange = (event: any) => {
      setAgency(event.target.value);
   };

   const handleCurrencyChange = (event: any) => {
      setCurrency(event.target.value);
   };

   const handleAgencyCommissionChange = (event: any) => {
      setCommissionForAffiliate(event.target.value);
   };

   const handleVoucherCodeChange = (event: any) => {
      setVoucherCode(event.target.value);
   };

   useEffect(() => {
      dispatch(getSubscriptionPlans());
   }, []);

   const navigate = useNavigate();
   const handleCreate = () => {
      setIsLoading(true);
      dispatch(
         createVoucher(
            Number(commissionForAffiliate),
            affiliatePlans[selectedPlan].id,
            navigate,
         ),
      );
   };
   return (
      <Container
         sx={{
            padding: "32px 0 120px 0",
         }}
      >
         <LoaderOverlay isLoading={isLoading}>
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
               }}
            >
               <Link
                  to="../vouchers"
                  style={{
                     display: "inline-flex",
                     alignItems: "center",
                     gap: "8px",
                     textDecoration: "none",
                     color: "#1B1B48",
                     borderRadius: "32px",
                     background: "#fff",
                     padding: "12px 24px",
                     boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.05)",
                  }}
               >
                  <KeyboardBackspace />
                  Back to Vouchers
               </Link>
               <NovaButton
                  style={{ borderRadius: "32px" }}
                  disabled={!commissionForAffiliate || !Number(commissionForAffiliate)}
                  onClick={handleCreate}
               >
                  Create
               </NovaButton>
            </Box>
            <Box
               sx={{
                  marginTop: "36px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "baseline",
                  gap: "8px",
               }}
            >
               <Box
                  sx={{
                     width: "500px",
                     marginTop: "16px",
                  }}
               >
                  <NovaTextInput
                     label="Voucher code"
                     value={voucherCode}
                     fullwidth
                     onChange={(event) => handleVoucherCodeChange(event)}
                  />
               </Box>
               <Box>
                  <NovaText
                     type={NovaTextTypes.MediumNormal}
                     style={{
                        marginBottom: "6px",
                     }}
                  >
                     Select subscription plan
                  </NovaText>
                  <FormControl sx={{ minWidth: 500 }} size="small">
                     <Select
                        value={selectedPlan}
                        onChange={handleSelectedPlan}
                        sx={{
                           background: "#fff",
                        }}
                     >
                        {affiliatePlans?.map((plan: any, index: number) => {
                           return (
                              <MenuItem value={index} key={index}>
                                 {plan.name}
                              </MenuItem>
                           );
                        })}
                     </Select>
                  </FormControl>
               </Box>
               {/* <Box>
                  <NovaText
                     type={NovaTextTypes.MediumNormal}
                     style={{
                        marginBottom: "6px",
                     }}
                  >
                     Select agency
                  </NovaText>
                  <FormControl sx={{ minWidth: 500 }} size="small">
                     <Select
                        value={agency}
                        onChange={handleAgencyChange}
                        sx={{
                           background: "#fff",
                        }}
                     >
                        {agencies.map((status: any, index: number) => {
                           return (
                              <MenuItem value={index} key={index}>
                                 {status}
                              </MenuItem>
                           );
                        })}
                     </Select>
                  </FormControl>
               </Box> */}
               <Box
                  sx={{
                     width: "500px",
                     marginTop: "16px",
                  }}
               >
                  <NovaTextInput
                     label="Commission for the agency"
                     value={commissionForAffiliate}
                     fullwidth
                     onChange={(event) => handleAgencyCommissionChange(event)}
                  />
               </Box>
               {/* <Box>
                  <NovaText
                     type={NovaTextTypes.MediumNormal}
                     style={{
                        marginBottom: "6px",
                     }}
                  >
                     Select net amount currency
                  </NovaText>
                  <FormControl sx={{ minWidth: 500 }} size="small">
                     <Select
                        value={currency}
                        onChange={handleCurrencyChange}
                        sx={{
                           background: "#fff",
                        }}
                     >
                        {currencies.map((status: any, index: number) => {
                           return (
                              <MenuItem value={index} key={index}>
                                 {status}
                              </MenuItem>
                           );
                        })}
                     </Select>
                  </FormControl>
               </Box> */}
            </Box>
         </LoaderOverlay>
      </Container>
   );
};

export default CreateVoucher;
