import { createSelector } from "@reduxjs/toolkit";

const getEkont = (state: any) => state.ekont;
const getEkontCities = createSelector(getEkont, (ekont) => ekont.cities);
const getEkontOffices = createSelector(getEkont, (ekont) => ekont.offices);

export const EkontSelectors = {
   getEkontCities,
   getEkontOffices,
};
