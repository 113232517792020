export const UploadIcon = () => {
   return (
      <svg
         width="16"
         height="16"
         viewBox="0 0 16 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M6.25 4.96515H7.40469V10.2511C7.40469 10.3198 7.46094 10.3761 7.52969 10.3761H8.46719C8.53594 10.3761 8.59219 10.3198 8.59219 10.2511V4.96515H9.75C9.85469 4.96515 9.9125 4.84484 9.84844 4.76359L8.09844 2.54796C8.08674 2.53302 8.0718 2.52094 8.05475 2.51263C8.03769 2.50432 8.01897 2.5 8 2.5C7.98103 2.5 7.96231 2.50432 7.94525 2.51263C7.92819 2.52094 7.91326 2.53302 7.90156 2.54796L6.15156 4.76202C6.0875 4.84484 6.14531 4.96515 6.25 4.96515ZM13.7188 9.78234H12.7812C12.7125 9.78234 12.6562 9.83859 12.6562 9.90734V12.3136H3.34375V9.90734C3.34375 9.83859 3.2875 9.78234 3.21875 9.78234H2.28125C2.2125 9.78234 2.15625 9.83859 2.15625 9.90734V13.0011C2.15625 13.2776 2.37969 13.5011 2.65625 13.5011H13.3438C13.6203 13.5011 13.8438 13.2776 13.8438 13.0011V9.90734C13.8438 9.83859 13.7875 9.78234 13.7188 9.78234Z"
            fill="#0F0F1C"
         />
      </svg>
   );
};
