import { Box } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { STEPS } from ".";
import NovaTitle from "../shared/NovaTitle";
import NovaText, { NovaTextTypes } from "../shared/NovaText";
import NovaButton from "../shared/NovaButton";

import affiliateFlower from "../../assets/affiliate-flower.svg";

const FinishProblemStep = ({ onStepChange }: { onStepChange: (step: any) => void }) => {
   return (
      <Box>
         <img
            src={affiliateFlower}
            alt="Heart"
            style={{
               position: "absolute",
               bottom: "-520px",
               right: "-160px",
            }}
         />

         <Box
            sx={{
               position: "relative",
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
               zIndex: 2,
            }}
         >
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#FAAD14",
                  gap: "20px",
                  margin: "12px 0 60px 0",
               }}
            >
               <WarningAmberIcon
                  sx={{
                     fontSize: "40px",
                  }}
               />
               <NovaTitle
                  variant="h3"
                  style={{
                     textAlign: "center",
                     margin: 0,
                     color: "#FAAD14",
                  }}
               >
                  Purchase Not Completed.
               </NovaTitle>
            </Box>

            <NovaTitle
               variant="h3"
               style={{
                  textAlign: "center",
                  margin: "0px 0px 20px 0px",
               }}
            >
               Unfortunately, there was a<br /> problem with your payment
            </NovaTitle>

            <NovaText
               type={NovaTextTypes.BaseNormal}
               style={{
                  textAlign: "center",
                  margin: "20px 0 60px 0",
               }}
            >
               Try again or contact us if you need help.
            </NovaText>

            <NovaButton
               style={{
                  borderRadius: "32px",
                  border: "1px solid #CAA45D",
                  background: "#fff",
                  color: "#1B1B48",
               }}
               onClick={() => onStepChange(STEPS.PLANS)}
            >
               back
            </NovaButton>
         </Box>
      </Box>
   );
};

export default FinishProblemStep;
