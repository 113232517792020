import { memo, useCallback, useState } from "react";
import { Box, FormControlLabel, Modal } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import NovaTextInput from "../shared/NovaTextInput";
import NovaButton from "../shared/NovaButton";
import ConfirmationModal from "../ConfirmationModal";
import TranslatedText from "../translated-text";

type PhotoEditModalProps = {
   isOpened?: boolean;
   photoId: number;
   photoIndex: number;
   photoUrl?: string;
   photoDescription?: string;
   photoIsMain?: boolean;
   onClose?: () => void;
   onSave?: (photoData: {
      id: number;
      index: number;
      description: string;
      isMain: boolean;
   }) => void;
   onDelete?: (photoId: number) => void;
};

function PhotoEditModal({
   isOpened = false,
   photoId,
   photoIndex,
   photoUrl,
   photoDescription: photoDescriptionProp = "",
   photoIsMain: photoIsMainProp = false,
   onClose,
   onSave,
   onDelete,
}: PhotoEditModalProps) {
   const [photoDescription, setPhotoDescription] = useState<string>(photoDescriptionProp);
   const [photoIsMain, setPhotoIsMain] = useState<boolean>(photoIsMainProp);
   const [isDeletingPhoto, setIsDeletingPhoto] = useState<boolean>(false);

   const canSave =
      photoDescription !== photoDescriptionProp || photoIsMain !== photoIsMainProp;

   const onClickSave = useCallback(() => {
      onSave?.({
         id: photoId,
         index: photoIndex,
         description: photoDescription,
         isMain: photoIsMain,
      });
   }, [onSave, photoId, photoIndex, photoDescription, photoIsMain]);
   const onClickDelete = useCallback(() => {
      onDelete?.(photoId);
      setIsDeletingPhoto(false);
   }, [onDelete, photoId]);

   return (
      <>
         <Modal open={isOpened} onClose={onClose}>
            <Box
               sx={{
                  position: "absolute",
                  width: "100%",
                  maxWidth: "1100px",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#ffffff",
                  borderRadius: "24px",
                  boxShadow: "24",
                  padding: "40px 40px 20px 40px",
                  outline: "none",
               }}
            >
               <Box
                  sx={{
                     width: "100%",
                     maxWidth: "600px",
                     height: "500px",
                     margin: "0 auto 40px auto",
                     borderRadius: "12px",
                     background: `url(${photoUrl})`,
                     backgroundSize: "cover",
                     backgroundRepeat: "no-repeat",
                     backgroundPosition: "center center",
                     padding: "6px",
                  }}
               />

               <NovaTextInput
                  value={photoDescription}
                  label="Image Description"
                  placeholder="Type here"
                  onChange={(event) => setPhotoDescription(event.target.value)}
                  style={{
                     marginBottom: "16px",
                  }}
               />

               <FormControlLabel
                  value="start"
                  control={
                     <Box
                        sx={{
                           display: "flex",
                           width: "35px",
                           height: "35px",
                           backgroundColor: photoIsMain ? "#CAA45D" : "transparent",
                           border: !photoIsMain ? "3px solid #E9E9EA" : undefined,
                           borderRadius: "4px",
                           marginLeft: "16px",
                           alignItems: "center",
                           justifyContent: "center",
                        }}
                        onClick={() => setPhotoIsMain(true)}
                     >
                        {photoIsMain && (
                           <CheckIcon
                              sx={{
                                 color: "#ffffff",
                              }}
                           />
                        )}
                     </Box>
                  }
                  label="Is Main Image"
                  labelPlacement="start"
                  sx={{
                     marginLeft: 0,
                     marginBottom: "16px",
                  }}
               />

               <Box
                  sx={{
                     display: "flex",
                     gap: "16px",
                  }}
               >
                  <NovaButton
                     style={{
                        borderRadius: "32px",
                        position: "relative",
                        backgroundColor: "transparent",
                        border: "1px solid #CAA45D",
                        ":hover": {
                           border: "1px solid #CAA45D",
                        },
                     }}
                     onClick={() => setIsDeletingPhoto(true)}
                  >
                     Delete Photo
                  </NovaButton>
                  <NovaButton
                     disabled={!canSave}
                     style={{
                        borderRadius: "32px",
                        position: "relative",
                     }}
                     onClick={onClickSave}
                  >
                     <TranslatedText text="buttons.save" />
                  </NovaButton>
               </Box>
            </Box>
         </Modal>

         <ConfirmationModal
            isOpened={isDeletingPhoto}
            onCancelCallback={() => setIsDeletingPhoto(false)}
            onConfirmCallback={onClickDelete}
         />
      </>
   );
}

export default memo(PhotoEditModal);
