export const getMonthName = (monthIndex: number, shortName?: boolean): string => {
   return [
      shortName ? "Jan" : "January",
      shortName ? "Feb" : "February",
      shortName ? "Mar" : "March",
      shortName ? "Apr" : "April",
      shortName ? "May" : "May",
      shortName ? "Jun" : "June",
      shortName ? "Jul" : "July",
      shortName ? "Aug" : "August",
      shortName ? "Sep" : "September",
      shortName ? "Oct" : "October",
      shortName ? "Nov" : "November",
      shortName ? "Dec" : "December",
   ][monthIndex];
};

export const checkEmailIsValid = (email: string) => {
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   return emailRegex.test(email);
};
