import { forwardRef, memo } from "react";
import { Box } from "@mui/material";

import { headerHeight } from "../../constants/app";

import NovaTitle from "../shared/NovaTitle";

type SectionProps = {
   id?: string;
   title?: string;
   actions?: React.ReactNode;
   backgroundColor?: string;
   /** @default 24 */
   borderRadius?: string;
   borderBottomLeftRadius?: string;
   borderBottomRightRadius?: string;
   paddingBlock?: string;
   paddingInline?: string;
   children?: React.ReactNode;
};

const Section = forwardRef<HTMLDivElement, SectionProps>(
   (
      {
         id,
         title,
         actions,
         backgroundColor = "#ffffff",
         borderRadius,
         borderBottomLeftRadius,
         borderBottomRightRadius,
         paddingBlock,
         paddingInline,
         children,
      },
      ref,
   ) => {
      return (
         <Box
            sx={{
               position: "relative",
            }}
            ref={ref}
         >
            <Box
               id={id}
               sx={{
                  position: "absolute",
                  top: {
                     xs: (headerHeight + 69 + 20 / 2) * -1, //? 69 - header height, 20 - margin
                     sm: (headerHeight + 57 + 20 / 2) * -1, //? 57 - header height, 20 - margin
                  },
               }}
            />

            <Box
               sx={{
                  position: "relative",
                  width: "100%",
                  maxWidth: "1100px",
                  backgroundColor,
                  borderRadius: borderRadius ?? "24px",
                  borderBottomLeftRadius,
                  borderBottomRightRadius,
                  margin: "0 auto",
                  padding: {
                     xs: `${paddingBlock ?? "20px"} ${paddingInline ?? "20px"}`,
                     sm: `${paddingBlock ?? "40px"} ${paddingInline ?? "60px"}`,
                     lg: `${paddingBlock ?? "80px"} ${paddingInline ?? "140px"}`,
                  },
                  zIndex: 1,
               }}
            >
               <Box
                  sx={{
                     display: "flex",
                     position: "relative",
                     alignItems: "center",
                     justifyContent: "space-between",
                     ...(title || actions
                        ? {
                             marginBottom: "30px",
                          }
                        : {}),
                  }}
               >
                  {title && (
                     <NovaTitle
                        variant="h2"
                        style={{
                           margin: 0,
                        }}
                     >
                        {title}
                     </NovaTitle>
                  )}
                  {actions}
               </Box>
               {children}
            </Box>
         </Box>
      );
   },
);

export default memo(Section);
