import * as Immutable from "seamless-immutable";
import {
   GET_AFFILIATE_VOUCHERS,
   GET_AFFILIATE_USERS,
   UPDATE_VOUCHER,
   GENERATE_VOUCHER_QR_SUCCESS,
} from "./types";

const initialState = Immutable.from<any>({
   vouchers: null,
   users: null,
   qr: null,
});

export default function AffiliatesReducer(state = initialState, action: any) {
   const { type, payload } = action;

   switch (type) {
      case GET_AFFILIATE_VOUCHERS: {
         return state.setIn(["vouchers"], payload);
      }
      case GET_AFFILIATE_USERS: {
         return state.setIn(["users"], payload);
      }
      case UPDATE_VOUCHER: {
         const { id, ...changes } = payload;
         const index = state.vouchers.findIndex((voucher: any) => voucher.id === id);

         if (index !== -1) {
            const updatedVouchers = state.vouchers.map((voucher: any, i: number) =>
               i === index ? { ...voucher, ...changes } : voucher,
            );

            return state.set("vouchers", updatedVouchers);
         }

         return state;
      }

      case GENERATE_VOUCHER_QR_SUCCESS: {
         const updatedQR =
            payload &&
            payload.replace(
               "<svg",
               '<svg viewBox="0 0 1000 1000" preserveAspectRatio="xMidYMid meet" style="width: 100%; height: auto;"',
            );
         return state.set("qr", updatedQR);
      }
      default:
         return state;
   }
}
