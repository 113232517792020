export const EmptyMemoryIcon = () => {
   return (
      <svg
         width="160"
         height="152"
         viewBox="0 0 160 152"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M67.797 151.231C105.24 151.231 135.594 145.559 135.594 138.563C135.594 131.566 105.24 125.895 67.797 125.895C30.3537 125.895 0 131.566 0 138.563C0 145.559 30.3537 151.231 67.797 151.231Z"
            fill="#E9DDC8"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M122.035 101.346L98.1096 71.9008C96.9615 70.5148 95.2835 69.6758 93.5165 69.6758H42.0765C40.3105 69.6758 38.6325 70.5148 37.4845 71.9008L13.5605 101.346V114.729H122.036V101.346H122.035Z"
            fill="#E9DDC8"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.8301 31.6719H101.763C102.824 31.6719 103.841 32.0933 104.592 32.8434C105.342 33.5936 105.763 34.611 105.763 35.6719V129.016C105.763 130.077 105.342 131.094 104.592 131.844C103.841 132.594 102.824 133.016 101.763 133.016H33.8301C32.7692 133.016 31.7518 132.594 31.0017 131.844C30.2515 131.094 29.8301 130.077 29.8301 129.016V35.6719C29.8301 34.611 30.2515 33.5936 31.0017 32.8434C31.7518 32.0933 32.7692 31.6719 33.8301 31.6719Z"
            fill="#FAF8F4"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.6776 41.625H92.9146C93.445 41.625 93.9537 41.8357 94.3288 42.2108C94.7039 42.5859 94.9146 43.0946 94.9146 43.625V68.582C94.9146 69.1124 94.7039 69.6211 94.3288 69.9962C93.9537 70.3713 93.445 70.582 92.9146 70.582H42.6776C42.1472 70.582 41.6385 70.3713 41.2634 69.9962C40.8883 69.6211 40.6776 69.1124 40.6776 68.582V43.625C40.6776 43.0946 40.8883 42.5859 41.2634 42.2108C41.6385 41.8357 42.1472 41.625 42.6776 41.625ZM42.9396 81.439H92.6526C93.2525 81.439 93.8279 81.6773 94.2521 82.1015C94.6763 82.5257 94.9146 83.1011 94.9146 83.701C94.9146 84.3009 94.6763 84.8763 94.2521 85.3005C93.8279 85.7247 93.2525 85.963 92.6526 85.963H42.9396C42.3397 85.963 41.7643 85.7247 41.3401 85.3005C40.9159 84.8763 40.6776 84.3009 40.6776 83.701C40.6776 83.1011 40.9159 82.5257 41.3401 82.1015C41.7643 81.6773 42.3397 81.439 42.9396 81.439ZM42.9396 93.202H92.6526C93.2526 93.202 93.8281 93.4404 94.2524 93.8647C94.6767 94.289 94.9151 94.8644 94.9151 95.4645C94.9151 96.0646 94.6767 96.64 94.2524 97.0643C93.8281 97.4886 93.2526 97.727 92.6526 97.727H42.9396C42.3395 97.727 41.7641 97.4886 41.3398 97.0643C40.9155 96.64 40.6771 96.0646 40.6771 95.4645C40.6771 94.8644 40.9155 94.289 41.3398 93.8647C41.7641 93.4404 42.3395 93.202 42.9396 93.202ZM121.813 136.704C121.038 139.775 118.316 142.064 115.078 142.064H20.5146C17.2766 142.064 14.5546 139.774 13.7806 136.704C13.633 136.119 13.5584 135.518 13.5586 134.914V101.347H39.8766C42.7836 101.347 45.1266 103.795 45.1266 106.767V106.807C45.1266 109.778 47.4966 112.177 50.4036 112.177H85.1886C88.0956 112.177 90.4656 109.756 90.4656 106.784V106.772C90.4656 103.8 92.8086 101.346 95.7156 101.346H122.034V134.915C122.034 135.532 121.957 136.131 121.813 136.704Z"
            fill="#CAA45D"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M125.122 33.292L118.292 35.942C118.116 36.0102 117.925 36.0275 117.74 35.992C117.555 35.9564 117.384 35.8693 117.247 35.7408C117.109 35.6123 117.011 35.4476 116.963 35.2656C116.914 35.0836 116.919 34.8917 116.975 34.712L118.912 28.505C116.323 25.561 114.803 21.971 114.803 18.097C114.803 8.102 124.921 0 137.403 0C149.882 0 160.001 8.102 160.001 18.097C160.001 28.092 149.883 36.194 137.402 36.194C132.874 36.194 128.658 35.128 125.122 33.292Z"
            fill="#FAF8F4"
         />
         <path
            d="M146.304 21.3644C147.878 21.3644 149.153 20.1041 149.153 18.5494C149.153 16.9947 147.878 15.7344 146.304 15.7344C144.731 15.7344 143.455 16.9947 143.455 18.5494C143.455 20.1041 144.731 21.3644 146.304 21.3644Z"
            fill="#E9DDC8"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M131.348 21.0119H125.65L128.548 16.0859L131.348 21.0119ZM134.909 16.0859H139.894V21.0119H134.909V16.0859Z"
            fill="#E9DDC8"
         />
      </svg>
   );
};
