import { ReactFamilyTreeIconName } from "./types";

export const icons: Record<ReactFamilyTreeIconName, (color?: string) => JSX.Element> = {
   heart: (color) => (
      <svg viewBox="0 0 512 512">
         <path
            fill={color ?? "#000000"}
            d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
         />
      </svg>
   ),
   heartBroken: (color) => (
      <svg viewBox="0 0 512 512">
         <path
            fill={color ?? "#000000"}
            d="M473.7 73.8l-2.4-2.5c-46-47-118-51.7-169.6-14.8L336 159.9l-96 64 48 128-144-144 96-64-28.6-86.5C159.7 19.6 87 24 40.7 71.4l-2.4 2.4C-10.4 123.6-12.5 202.9 31 256l212.1 218.6c7.1 7.3 18.6 7.3 25.7 0L481 255.9c43.5-53 41.4-132.3-7.3-182.1z"
         />
      </svg>
   ),
};
