import { RouteObject, createBrowserRouter } from "react-router-dom";

import PrivateRoutes, { roles } from "./components/PrivateRoutes";
import Layout from "./pages/layout";
import DesiderRoute from "./components/DesiderRoute";
import Login from "./pages/Login/Login";
import SignUp from "./pages/sign-up/SignUp";
import AffiliateRegister from "./pages/affiliate/register";
import Home from "./pages/home/home";
import CreateAMemorial from "./pages/memorial-page/Create";
import SingleMemorialPage from "./pages/single-memorial-page";
import Dashboard from "./pages/dashboard";
import Products from "./pages/products";
import ClientProducts from "./pages/products/clients";
import Product from "./pages/products/clients/Product";
import Cart from "./pages/cart";
import SingleProduct from "./pages/products/single-product";
import CreateProduct from "./pages/products/create-product";
import Checkout from "./pages/checkout";
import Orders from "./pages/orders";
import SingleOrder from "./pages/orders/single-order";
import PaymentSuccess from "./pages/orders/payment-success";
import PaymentCancel from "./pages/orders/payment-cancel";
import Payments from "./pages/payments";
import Subscriptions from "./pages/subscriptions";
import SingleSubscription from "./pages/subscriptions/single-subscription";
import CreatePlan from "./pages/subscriptions/create-plan";
import Profile from "./pages/profile";
import Vouchers from "./pages/vouchers";
import CreateVoucher from "./pages/vouchers/create";
import SingleAgency from "./pages/vouchers/single-agency";
import RegisterVoucher from "./pages/profile/register-voucher";
import Users from "./pages/users/index";

const routers: RouteObject[] = [
   {
      index: true,
      element: <DesiderRoute />,
      loader: async () => {
         return true;
      },
   },
   {
      path: "login",
      element: <Login />,
      loader: async () => {
         return true;
      },
   },
   {
      path: "sign-up",
      element: <SignUp />,
      loader: async () => {
         return true;
      },
   },
   {
      path: "affiliate",
      element: <AffiliateRegister />,
      loader: async () => {
         return true;
      },
   },
   {
      path: "dashboard",
      element: (
         <PrivateRoutes allowedRoles={[roles.CLIENT, roles.AFFILIATE]}>
            <Layout />
         </PrivateRoutes>
      ),
      children: [
         {
            index: true,
            element: <Home />,
            handle: {
               crumb: () => <>Home</>,
            },
         },
      ],
   },
   {
      element: (
         <PrivateRoutes allowedRoles={[roles.CLIENT, roles.AFFILIATE]}>
            <Layout />
         </PrivateRoutes>
      ),
      children: [
         {
            path: "profile",
            element: <Profile />,
            handle: {
               crumb: () => <>Profile</>,
            },
         },
      ],
   },
   {
      element: (
         <PrivateRoutes allowedRoles={[roles.CLIENT]}>
            <Layout />
         </PrivateRoutes>
      ),
      children: [
         {
            path: "products",
            element: <ClientProducts />,
            handle: {
               crumb: () => <>Products</>,
            },
         },
         {
            path: "product/:productId",
            element: <Product />,
            handle: {
               crumb: () => <>Product</>,
            },
         },
         {
            path: "cart",
            element: <Cart />,
            handle: {
               crumb: () => <>Cart</>,
            },
         },
         {
            path: "checkout",
            element: <Checkout />,
            handle: {
               crumb: () => <>Checkout</>,
            },
         },
         {
            path: "register-voucher",
            element: <RegisterVoucher />,
            handle: {
               crumb: () => <>Register Code</>,
            },
         },

         // orders
         {
            path: "order/:orderId/payment/success",
            element: <PaymentSuccess />,
            handle: {
               crumb: () => <>Checkout</>,
            },
         },
         {
            path: "order/:orderId/cancel",
            element: <PaymentCancel />,
            handle: {
               crumb: () => <>Checkout</>,
            },
         },
         // subscriptions
         {
            path: "subscription/:memorialPageId/payment/success",
            element: <PaymentSuccess />,
            handle: {
               crumb: () => <>Checkout</>,
            },
         },
         {
            path: "subscription/:memorialPageId/cancel",
            element: <PaymentCancel />,
            handle: {
               crumb: () => <>Checkout</>,
            },
         },
      ],
   },
   {
      element: (
         <PrivateRoutes allowedRoles={[roles.CLIENT]}>
            <Layout />
         </PrivateRoutes>
      ),
      children: [
         {
            index: true,
            path: "memorial-page/:pageId",
            element: <SingleMemorialPage />,
            handle: {
               crumb: () => <>Memorial Page</>,
            },
         },
         {
            path: "memorial-page/create",
            element: <CreateAMemorial />,
            handle: {
               crumb: () => <>Create a Memorial page</>,
            },
         },
         {
            path: "memorial-page/edit/:memorialId",
            element: <CreateAMemorial />,
            handle: {
               crumb: () => <>Edit a Memorial page</>,
            },
         },
      ],
   },
   {
      path: "admin",
      element: (
         <PrivateRoutes allowedRoles={[roles.ADMIN]}>
            <Layout />
         </PrivateRoutes>
      ),
      children: [
         {
            index: true,
            element: <Dashboard />,
         },
         {
            path: "products",
            element: <Products />,
            handle: {
               crumb: () => <>Products</>,
            },
         },
         {
            path: "products/create",
            element: <CreateProduct />,
            handle: {
               crumb: () => <>Create product</>,
            },
         },
         {
            path: "product/:productId",
            element: <SingleProduct />,
            handle: {
               crumb: () => <>Single product</>,
            },
         },
         {
            path: "orders",
            element: <Orders />,
            handle: {
               crumb: () => <>Orders</>,
            },
         },
         {
            path: "orders/:orderId",
            element: <SingleOrder />,
            handle: {
               crumb: () => <>Single Orders</>,
            },
         },
         {
            path: "subscriptions",
            element: <Subscriptions />,
            handle: {
               crumb: () => <>Subscriptions</>,
            },
         },
         {
            path: "subscriptions/:subscriptionId",
            element: <SingleSubscription />,
            handle: {
               crumb: () => <>Single Subscription</>,
            },
         },
         {
            path: "create-plan",
            element: <CreatePlan />,
            handle: {
               crumb: () => <>Create Plan</>,
            },
         },
         {
            path: "payments",
            element: <Payments />,
            handle: {
               crumb: () => <>Payments</>,
            },
         },
         {
            path: "vouchers",
            element: <Vouchers />,
            handle: {
               crumb: () => <>Vouchers</>,
            },
         },
         {
            path: "vouchers/create-voucher",
            element: <CreateVoucher />,
            handle: {
               crumb: () => <>Create Voucher</>,
            },
         },
         {
            path: "vouchers/:agencyId",
            element: <SingleAgency />,
            handle: {
               crumb: () => <>Single Voucher</>,
            },
         },
         {
            path: "users",
            element: <Users />,
            handle: {
               crumb: () => <>Users</>,
            },
         },
      ],
   },
];

const router = createBrowserRouter(routers);

export default router;
