import * as Immutable from "seamless-immutable";
import { LOGIN_SUCCESS, LOGOUT_SUCCESS, GET_USER, GET_USERS } from "./types";
// import userRole from "../../utils/user-role";

interface User {
   isLoggedIn: boolean;
   id: number;
   firstName: string;
   lastName: string;
   token: string;
   role: "admin" | "OrgOwner" | "orguser" | "client";
   email: string;
}

interface InitialState {
   user: User;
   users?: any[];
}

const initialUser: any = {
   id: null,
   isLoggedIn: false,
   fullname: null,
   token: null,
   role: null,
   email: null,
};

const initialState = Immutable.from<InitialState>({
   user: initialUser,
});

export default function UsersReducer(state = initialState, action: any) {
   const { type, payload } = action;
   switch (type) {
      case LOGIN_SUCCESS: {
         return state.set("user", { ...payload });
      }
      case LOGOUT_SUCCESS: {
         return state.set("user", initialUser);
      }
      case GET_USER: {
         return state.set("user", { ...payload });
      }
      case GET_USERS: {
         return state.set("users", payload);
      }
      default:
         return state;
   }
}
