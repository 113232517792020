import {
   REGISTRATION_SUCCESS,
   REGISTRATION_FAIL,
   AFFILIATE_REGISTRATION_SUCCESS,
   AFFILIATE_REGISTRATION_FAIL,
} from "./types";

const registrationSuccess = (payload: any) => ({
   type: REGISTRATION_SUCCESS,
   payload,
});

const registrationFail = () => ({
   type: REGISTRATION_FAIL,
});

const affiliateRegistrationSuccess = (payload: any) => ({
   type: AFFILIATE_REGISTRATION_SUCCESS,
   payload,
});

const affiliateRegistrationFail = () => ({
   type: AFFILIATE_REGISTRATION_FAIL,
});

export const SignUpActions = {
   registrationSuccess,
   registrationFail,
   affiliateRegistrationSuccess,
   affiliateRegistrationFail,
};
